import React, { createContext, ReactNode, useState } from 'react';
import { noop } from '../utils';
import { TClient } from '../containers/app/trainer/models';

type TInitialState = {
  clients: TClient[] | null;
  filteredClients: TClient[] | null;
  onSetClients: (data: TClient[] | null) => void;
  setFilteredClients: (data: TClient[] | null) => void;
  onFilterClientsOnSearch: (value: string) => void;
  onSortClients: ({ id }: { id: string }) => void;
};

const initialState: TInitialState = {
  clients: [],
  filteredClients: [],
  onSetClients: noop,
  setFilteredClients: noop,
  onFilterClientsOnSearch: noop,
  onSortClients: noop,
};

export const ClientsContext = createContext(initialState);

const ClientsProvider = ({ children }: { children: ReactNode }) => {
  const [clients, setClients] = useState<TClient[] | null>([]);
  const [filteredClients, setFilteredClients] = useState<TClient[] | null>([]);

  const onSetClients = (data: TClient[] | null) => {
    setClients(data);
    setFilteredClients(data);
  };

  const onFilterClientsOnSearch = (value: string) => {
    if (!value) {
      setFilteredClients(clients);

      return;
    }
    if ((filteredClients && filteredClients.length) || value) {
      const filtered = clients?.filter((client) => {
        return Object.values(client).some((field) => {
          if (
            typeof field === 'object' &&
            field !== null &&
            'firstName' in field &&
            'lastName' in field
          ) {
            const strongValue = `${field.firstName} ${field.lastName}`;
            return strongValue.toLowerCase().includes(value.toLowerCase());
          }

          return `${field}`.toLowerCase().includes(value.toLowerCase());
        });
      });

      if (!filtered?.length) {
        setFilteredClients(null);

        return;
      }
      setFilteredClients(filtered);
    }
  };

  const onSortClients = ({ id }: { id: string }) => {
    switch (id) {
      case 'oldest':
        if (filteredClients) {
          setFilteredClients(
            [...filteredClients].sort(
              (a, b) =>
                new Date(a.user.createdOn).getTime() -
                new Date(b.user.createdOn).getTime(),
            ),
          );
        }
        break;
      case 'newest':
        if (filteredClients) {
          setFilteredClients(
            [...filteredClients].sort(
              (a, b) =>
                new Date(b.user.createdOn).getTime() -
                new Date(a.user.createdOn).getTime(),
            ),
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <ClientsContext.Provider
      value={{
        clients,
        onSetClients,
        filteredClients,
        setFilteredClients,
        onFilterClientsOnSearch,
        onSortClients,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsProvider;
