import React from 'react';
import { PhaseComplexExercise } from '../PhaseComplexExercise/PhaseComplexExercise';
import { IconButtonContainer } from 'components/iconButtonContainer/IconButtonContainer';
import { PROGRAM } from 'constants/tooltips';
import { useHandlers } from './hooks/useHandlers';

import type { IPhaseDayProps } from 'containers/app/trainer/currentClient/clientPhases/biggerProgram/PhaseContent/models';

import { CopyIc, DeleteIc } from 'assets/svg';
import S from './PhaseDay.styled';

export const PhaseDay = ({
  day,
  isLastItem,
  onDeleteDay,
  onAddNewDay,
  onDuplicateDay,
  onDeleteExercise,
  onDeleteComplexExercise,
  onCreateExercise,
  onSwapComplexExercise,
  onEditComplexExercise,
  phaseExerciseReference,
  navigatedExerciseReference,
  programId,
}: IPhaseDayProps) => {
  const { complexExercises, name } = day;

  const { dayExerciseReference, isDayFromNavigatedReference, openCreateExerciseModal } = useHandlers({
    navigatedExerciseReference,
    phaseExerciseReference,
    day,
    onCreateExercise,
  });

  return (
    <S.DayWrapper>
      <S.DayLabel>
        <span className="dayName">{name}</span>
        <IconButtonContainer
          onClick={onDuplicateDay}
          title={PROGRAM.DUPLICATE_DAY}
          className="duplicateDayIcon"
        >
          <CopyIc/>
        </IconButtonContainer>
        <IconButtonContainer
          onClick={onDeleteDay}
          className="deleteDayIcon"
          title={PROGRAM.DELETE_DAY}
        >
          <DeleteIc/>
        </IconButtonContainer>
      </S.DayLabel>

      <S.ComplexExerciseList>
        {complexExercises.map((complexExercise, complexExerciseIndex) => (
          <PhaseComplexExercise
            key={complexExercise.id}
            onEditComplexExercise={
              onEditComplexExercise
                ? () => onEditComplexExercise(complexExercise)
                : undefined
            }
            onDeleteExercise={onDeleteExercise}
            onDeleteComplexExercise={onDeleteComplexExercise}
            onSwapComplexExercise={onSwapComplexExercise}
            complexExercise={complexExercise}
            complexExerciseIndex={complexExerciseIndex}
            isFirstComplexExercise={complexExerciseIndex === 0}
            isLastComplexExercise={
              complexExerciseIndex === complexExercises.length - 1
            }
            dayExerciseReference={dayExerciseReference}
            navigatedExerciseReference={
              isDayFromNavigatedReference && navigatedExerciseReference
            }
            programId={programId}
          />
        ))}

        <button className="addExerciseButton" onClick={openCreateExerciseModal}>
          + Add exercise
        </button>
      </S.ComplexExerciseList>
      {isLastItem && (
        <button
          className="addDayButton"
          title="Add new day"
          onClick={onAddNewDay}
        >
          + Add Training Day
        </button>
      )}
    </S.DayWrapper>
  );
};
