import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { ellipsisTextStyles, transitionOnIconsHover } from 'styles/commonStyleVariables';

const Wrapper = styled('div')`
  flex: 2 1;
  display: flex;
  flex-direction: column;
  width: 25%;
  overflow: hidden;
  ${transitionOnIconsHover};
  
  &:hover {
    background-color: #3E434B;
  }
`;

const DataWrapper = styled('div')<{
  isHighlighted: boolean;
  isCompleted: boolean;
  weekIndex: number;
}>`
  flex: 2 1;
  display: flex;
  // set border properties different for first\last and middle week blocks
  border-radius: ${({ weekIndex }) => (weekIndex === 0 ? '8px 0 0 8px' : weekIndex === 3 ? '0 8px 8px 0' : 0)};
  border: 1px solid ${({ isCompleted }) => (isCompleted ? 'rgba(51, 165, 110, 0.5)' : '#3E434B')};
  border-right-width: ${({ weekIndex }) => (weekIndex === 3 ? '1px' : 0)};
  background: ${({ isCompleted }) => (isCompleted ? 'rgba(51, 165, 110, 0.15)' : 'transparent')};
  transition: border-color 0.5s ease-in-out;
  gap: 8px;
  padding: 8px;

  ${({ isHighlighted }) => isHighlighted && css`
    border-color: #FFEF01;
    background-color: #3E434B;
    border-right-width: 1px;
    
    && * {
      color: #fff;
    }
  `};
  
  & .data {
    display: flex;
    align-items: center;
    width: 100%;
    
    & .previewValue {
      width: 80%;
      max-width: 80%;
      ${ellipsisTextStyles};
      
      font-size: 16px;
      font-weight: 500;
    }
    
    & .chatIcon {
      color: #5F6975;
    }
  }
`;

const TooltipWrapper = styled.div`
  padding: 12px;
  background: #1C1F21;
`;

export default { Wrapper, DataWrapper, TooltipWrapper };
