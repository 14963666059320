import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SignIn from 'containers/auth/signIn';
import ForgotPassword from 'containers/auth/forgotPassword';
import ResetPassword from 'containers/auth/resetPassword';
import { routes } from './routes';
import { UserContext } from 'providers/userProvider';
import TrainerRouter from 'containers/app/trainer/Router';
import NotificationProvider from 'providers/notificationsProvider';
import FirstSignIn from 'containers/auth/firstSignIn';
import { LayoutProvider } from '../providers/layout/layoutProvider';
import withPrivateTrainersRoute from '../hocs/withPrivateTrainersRoute';
import { OnboardingProvider } from 'providers/OnboardingProvider';
import { InputFocusProvider } from '../providers/InputFocusProvider';

const MainRouter = () => {
  const { user } = useContext(UserContext);

  return (
    <LayoutProvider>
      <NotificationProvider>
        <OnboardingProvider>
          <InputFocusProvider>
            <Switch>
              <Route exact path={routes.SIGN_IN} component={SignIn} />
              <Route
                exact
                path={routes.FORGOT_PASSWORD}
                component={ForgotPassword}
              />
              <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
              <Route
                path={routes.MAIN}
                render={(props) =>
                  withPrivateTrainersRoute(TrainerRouter)({ user, ...props })
                }
              />
              <Route
                exact
                path={routes.FIRST_LOG_IN}
                render={(props) =>
                  withPrivateTrainersRoute(FirstSignIn)({ user, ...props })
                }
              />
              <Route
                render={() => {
                  return <Redirect to="/trainer/trainers-clients" />;
                }}
              />
            </Switch>
          </InputFocusProvider>
        </OnboardingProvider>
      </NotificationProvider>
    </LayoutProvider>
  );
};

export default MainRouter;
