// TODO: refactor component V2
// replaced from src/containers/app/trainer/clients/Clients.tsx
import React, { FC, FormEvent, useCallback, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useSnackbar } from 'notistack';
import Modal from '@material-ui/core/Modal';
import Loader from 'components/loader/Loader';
import TextInput from 'components/fields/textInput';
import { AutoSelectField } from 'components/fields/autoSelectField/AutoSelectField';
import { postData } from 'lib/api/utils';
import gaEvents from 'utils/gaEvents';
import {
  ADD_CLIENT_FORM_MAX_VALUES, EMAIL_PATTERN,
  genderState,
  MONTHS_DATA,
} from '../../constants';

import type { TOnChangeAutoSelectPayload } from 'components/fields/autoSelectField/models';
import type {
  GenderKeys,
  TDefaultClient,
  TInputsHandlerPayload,
} from '../../models';

// TODO: refactor styles
import 'components/styles/_modal.scss';

interface IAddClientModalProps {
  modalType: string;
  setModal: (arg: boolean) => void;
  loadClients: () => void;
  setSearchValue: (arg: string) => void;
}

const defaultClient: TDefaultClient = {
  screen1: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  screen2: {
    gender: 'male',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    height: '',
    weight: '',
  },
};

export const AddClientModal: FC<IAddClientModalProps> = ({
  modalType,
  setModal,
  loadClients,
  setSearchValue,
}) => {
  const [error, setError] = useState('');
  const [activeSecondPart, setActiveSecondPart] = useState(false);
  const [isLoadingInModal, setIsLoadingInModal] = useState(false);
  const [client, setClient] = useState(defaultClient);

  const { enqueueSnackbar } = useSnackbar();

  const toggleCloseModal = useCallback(() => {
    setModal(false);
    setActiveSecondPart(false);
    setClient(defaultClient);
    setError('');
    gaEvents.closeAddClientModal();
  }, []);
  ``;
  const emailValidator = () => {
    if (!EMAIL_PATTERN.test(client.screen1.email)) {
      setError('Email address is not validated');
      return true;
    }

    setError('');
  };


  const checkDisablingScreen1 = () => {
    return !(
      // JSON.stringify(oldTrainerData) !== JSON.stringify(trainer) &&
      (Object.values(client.screen1).every((field) => !!field) && !error)
    );
  };

  const goToSecondAddClientScreen = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setActiveSecondPart(true);
  };

  const newClientHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoadingInModal(true);

    const { weight, birthDay, birthYear, birthMonth, height } = client.screen2;
    const gender: GenderKeys = client.screen2.gender;

    postData('Trainer/create-client', {
      ...client.screen1,
      weight: +weight,
      birthDate: `${birthYear}-${birthDay}-${birthMonth}`,
      height: +height,
      gender: genderState[gender],
    })
      .then((answer) => {
        if (answer?.success) {
          toggleCloseModal();
          loadClients();
          setSearchValue('');

          enqueueSnackbar('New client was successfully added', {
            variant: 'success',
          });
        } else {
          setError(answer);
          enqueueSnackbar(answer, { variant: 'error' });
        }
      })
      .finally(() => {
        setIsLoadingInModal(false);
      });
  };

  const inputsHandler = ({
    e,
    currentScreen,
    isNumber,
    maxValue,
  }: TInputsHandlerPayload) => {
    const { value, name } = e.target;
    if (isNumber) {
      if (!value.includes(' ')) {
        setClient({
          ...client,
          [currentScreen]: {
            ...client[currentScreen],
            [name]: maxValue && +value > maxValue ? maxValue : value,
          },
        });
      }
    } else {
      setClient({
        ...client,
        [currentScreen]: {
          ...client[currentScreen],
          [name]: value,
        },
      });
    }
  };

  const checkDisablingScreen2 = () => {
    return !(
      // JSON.stringify(oldTrainerData) !== JSON.stringify(trainer) &&
      Object.values(client.screen2).every((field) => !!field)
    );
  };

  const selectMonthHandler = ({ id }: TOnChangeAutoSelectPayload) => {
    setClient({
      ...client,
      screen2: {
        ...client.screen2,
        birthMonth: id,
      },
    });
  };

  return (
    <Modal onClose={toggleCloseModal} open={true}>
      <div className="modal">
        {isLoadingInModal && <Loader />}
        <div className="modal__close modal__body--sm">
          <ReactSVG onClick={toggleCloseModal} src="/assets/svg/close.svg" />
        </div>
        {modalType === 'addClient' && !activeSecondPart ? (
          <div className="modal__body">
            <div className="modal-title-block">
              <p className="modal-title">Add client</p>
              <div className="modal-title-block-steps">
                <div className="modal-title-block-steps-step active" />
                <div
                  className={`modal-title-block-steps-step ${
                    activeSecondPart ? 'active' : ''
                  }`}
                />
              </div>
            </div>
            <form onSubmit={goToSecondAddClientScreen}>
              <div className="modal-field">
                <TextInput
                  placeholder="First name"
                  type="text"
                  name="firstName"
                  value={client.screen1.firstName}
                  onChange={(e) => {
                    inputsHandler({ e, currentScreen: 'screen1' });
                  }}
                />
              </div>
              <div className="modal-field">
                <TextInput
                  placeholder="Last name"
                  type="text"
                  name="lastName"
                  value={client.screen1.lastName}
                  onChange={(e) => {
                    inputsHandler({ e, currentScreen: 'screen1' });
                  }}
                />
              </div>
              <div className="modal-field">
                <TextInput
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={client.screen1.email}
                  onBlur={emailValidator}
                  onChange={(e) => {
                    inputsHandler({ e, currentScreen: 'screen1' });
                  }}
                />
              </div>
              <div className="modal-field">
                <TextInput
                  placeholder="Phone"
                  type="text"
                  name="phoneNumber"
                  value={client.screen1.phoneNumber}
                  onChange={(e) => {
                    inputsHandler({
                      e,
                      currentScreen: 'screen1',
                      isNumber: true,
                    });
                  }}
                />
              </div>
              {error ? <p className="modal-error">{error}</p> : ''}
              <button
                disabled={checkDisablingScreen1()}
                type="submit"
                className="modal-button"
              >
                Next
              </button>
            </form>
          </div>
        ) : modalType === 'addClient' && activeSecondPart ? (
          <div className="modal__body">
            <div className="modal-title-block">
              <p className="modal-title">Add client</p>
              <div className="modal-title-block-steps">
                <div className="modal-title-block-steps-step active" />
                <div
                  className={`modal-title-block-steps-step ${
                    activeSecondPart ? 'active' : ''
                  }`}
                />
              </div>
            </div>
            <form onSubmit={newClientHandler}>
              <div className="modal-radio">
                <label
                  htmlFor="client-male"
                  className={`modal-radio-label ${
                    client.screen2.gender === 'male' ? 'checked' : ''
                  }`}
                >
                  <input
                    id="client-male"
                    type="radio"
                    value="male"
                    name="gender"
                    checked={client.screen2.gender === 'male'}
                    onChange={(e) => {
                      inputsHandler({ e, currentScreen: 'screen2' });
                    }}
                    className="modal-radio-input"
                  />
                  Male
                </label>
                <label
                  htmlFor="client-female"
                  className={`modal-radio-label ${
                    client.screen2.gender === 'female' ? 'checked' : ''
                  }`}
                >
                  <input
                    id="client-female"
                    type="radio"
                    value="female"
                    name="gender"
                    checked={client.screen2.gender === 'female'}
                    onChange={(e) => {
                      inputsHandler({ e, currentScreen: 'screen2' });
                    }}
                    className="modal-radio-input"
                  />
                  Female
                </label>
              </div>
              <div className="modal-fields">
                <div className="modal-fields-number">
                  <TextInput
                    value={client.screen2.birthDay}
                    placeholder="Day"
                    onChange={(e) => {
                      inputsHandler({
                        e,
                        currentScreen: 'screen2',
                        isNumber: true,
                        maxValue: ADD_CLIENT_FORM_MAX_VALUES.birthDay,
                      });
                    }}
                    type="text"
                    name="birthDay"
                  />
                </div>
                <div className="modal-fields-select">
                  <AutoSelectField
                    options={MONTHS_DATA}
                    placeholder="Month"
                    onChange={selectMonthHandler}
                  />
                </div>
                <div className="modal-fields-number">
                  <TextInput
                    value={client.screen2.birthYear}
                    placeholder="Year"
                    onChange={(e) => {
                      inputsHandler({
                        e,
                        currentScreen: 'screen2',
                        isNumber: true,
                        maxValue: ADD_CLIENT_FORM_MAX_VALUES.birthYear,
                      });
                    }}
                    type="text"
                    name="birthYear"
                  />
                </div>
              </div>
              <div className="modal-field height">
                <TextInput
                  placeholder="Height"
                  type="text"
                  name="height"
                  value={client.screen2.height}
                  onChange={(e) => {
                    inputsHandler({
                      e,
                      currentScreen: 'screen2',
                      isNumber: true,
                      maxValue: ADD_CLIENT_FORM_MAX_VALUES.height,
                    });
                  }}
                />
              </div>
              <div className="modal-field weight">
                <TextInput
                  placeholder="Weight"
                  type="text"
                  name="weight"
                  value={client.screen2.weight}
                  onChange={(e) => {
                    inputsHandler({
                      e,
                      currentScreen: 'screen2',
                      isNumber: true,
                      maxValue: ADD_CLIENT_FORM_MAX_VALUES.weight,
                    });
                  }}
                />
              </div>
              {error ? <p className="modal-error">{error}</p> : ''}
              <div className="modal-buttons">
                <button
                  className="modal-buttons-cancel"
                  onClick={() => setActiveSecondPart(false)}
                >
                  Back
                </button>
                <button
                  disabled={checkDisablingScreen2()}
                  type="submit"
                  className="modal-buttons-apply"
                >
                  Add Client
                </button>
              </div>
            </form>
          </div>
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};