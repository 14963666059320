import { styled } from '@mui/material';

const Wrapper = styled('div')`
  position: fixed;
  bottom: 12px;
  right: 24px;
  border-radius: 4px;
  background: #222429;
  padding: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
  line-height: 20px;
  z-index: 2;
  max-width: 420px;

  & .title {
    font-size: 20px;
    font-weight: 500;
  }

  & .description {
    font-size: 16px;
    font-weight: 300;
    opacity: 0.8;
  }

  & .actions {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-top: 20px;
    width: 100%;
    
    &__button {
      flex: 2;
    }
  }
`;

export default {
  Wrapper,
};
