import React, { memo, MouseEvent, useEffect, useState } from 'react';
import { useLayout } from 'providers/layout/hooks/useLayout';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuidv4 } from 'uuid';
import ManageComplexExerciseModal from 'components/_new/ManageComplexExerciseModal/ManageComplexExerciseModal';
import { DeleteIc } from 'assets/svg';
import Accordion from 'components/accordion';
import { PhaseDay } from 'components/_new/phase/sub/PhaseDay/PhaseDay';
import { useFetch, useHandlers } from './hooks';
import CircularProgress from 'components/_new/CircularProgress';
import ConfirmModal from 'components/_new/Modal/sub/ConfirmModal';
import { getAllExerciseIDsFromProgramDay } from 'utils/programUtils';
import { Tooltip } from 'components/tooltip/Tooltip';
import { TEMPLATES } from 'constants/tooltips';

import type { TPhaseDay } from 'components/_new/phase/models';
import {
  TCreateExerciseModalData,
  TDeleteDayProps,
  TDeleteExerciseProps,
  TDeleteTemplateData,
  TEditExerciseData,
  TExpandedItem,
} from './models';

import './styles.scss';

export const TemplatesPage = memo(() => {
  const [deleteTemplateData, setDeleteTemplateData] =
    useState<TDeleteTemplateData>(null);
  const [deleteDayData, setDeleteDayData] = useState<TDeleteDayProps | null>(
    null,
  );
  const [deleteExerciseData, setDeleteExerciseData] =
    useState<TDeleteExerciseProps | null>(null);
  const [editExerciseData, setEditExerciseData] =
    useState<TEditExerciseData | null>(null);
  const [createExerciseModalData, setCreateExerciseModalData] =
    useState<TCreateExerciseModalData | null>(null);
  const [expandedItems, setExpandedItems] = useState<TExpandedItem[]>([]);

  const {
    templateList,
    loadedPhaseId,
    fetchDuplicateDay,
    fetchTemplateItemData,
    fetchCreateDay,

    isLoadingDeleteTemplate,
    fetchDeleteTemplate,

    isLoadingDeleteExercise,
    fetchDeleteExercise,

    isLoadingDeleteDay,
    fetchDeleteDay,

    isLoadingEditExercise,
    fetchEditExercise,

    isLoadingCreateExercise,
    fetchCreateExercise,
  } = useFetch({
    setExpandedItems,
  });

  // handlers
  const {
    closeDeleteTemplateModal,
    deleteTemplateHandler,
    closeDeleteDayModal,
    submitDeleteDayHandler,
    deleteDayHandler,
    submitDeleteTemplateHandler,
    toggleAccordionExpand,
    deleteExerciseHandler,
    closeDeleteExerciseModal,
    submitDeleteExerciseHandler,
    closeEditExerciseModal,
    editExerciseHandler,
    submitEditExerciseHandler,
    closeCreateExerciseModal,
    createExerciseHandler,
    submitCreateExerciseHandler,
  } = useHandlers({
    setDeleteTemplateData,
    setDeleteDayData,
    deleteDayData,
    deleteTemplateData,
    fetchDeleteTemplate,
    fetchTemplateItemData,
    fetchDeleteDay,
    setExpandedItems,
    fetchDeleteExercise,
    deleteExerciseData,
    setDeleteExerciseData,
    setEditExerciseData,
    fetchEditExercise,
    setCreateExerciseModalData,
    fetchCreateExercise,
  });

  const { initLayoutState } = useLayout();

  useEffect(() => {
    initLayoutState({
      headerTitle: 'Templates',
      headerCountTitle: templateList.data.length || 0,
    });
  }, [templateList, initLayoutState]);
  // ==  == //

  const AccordionTitle = ({ name, id }: { name: string; id: number }) => (
    <div className="template-page-accordion-summary-title">
      <span>{name}</span>
      <Tooltip title={TEMPLATES.DELETE_TEMPLATE}>
        <DeleteIc
          className="template-page-accordion-summary-title-icon"
          onClick={(e: MouseEvent<SVGSVGElement>) =>
            deleteTemplateHandler(e, { id, name })
          }
        />
      </Tooltip>
    </div>
  );

  const getExpandedElemData = (id: number) =>
    expandedItems.find((item) => item.id === id) || null;

  const getAccordionDetails = (id: number) => {
    const data = getExpandedElemData(id)?.data;

    if (data) {
      const { days } = data;
      const daysAmount = days.length;
      const phaseId = data.id;

      const onCreateExerciseHandler = (day: TPhaseDay) => {
        const excludedExerciseIDs = getAllExerciseIDsFromProgramDay(day);

        createExerciseHandler({
          phaseId,
          dayName: day.name,
          dayOrderNumber: day.orderNumber,
          excludedExerciseIDs,
        });
      };

      // #editExerciseHandler Disabled for now as there is another edit icon to edit all complex exercise together
      // TODO: remove it it there will no be reason to use it after some time
      // const onEditExerciseHandler = (exercise: TExercise, day: TPhaseDay) => {
      //   const excludedExerciseIDs = getExcludedExerciseIDsOnEdit({ exercise, day });
      //
      //   editExerciseHandler({
      //     exercise,
      //     phaseId,
      //     excludedExerciseIDs,
      //   })
      // }

      if (daysAmount > 0) {
        return days.map((day, dayIndex) => (
          <PhaseDay
            key={`${day.name}${day.orderNumber}${dayIndex}`}
            day={day}
            isLastItem={dayIndex + 1 === daysAmount}
            // TODO: ↓
            onDuplicateDay={() =>
              fetchDuplicateDay({
                dayName: day.name,
                newOrderNumber: daysAmount,
                orderNumber: day.orderNumber,
                phaseId,
              })
            }
            onAddNewDay={() =>
              fetchCreateDay({
                orderNumber: daysAmount,
                dayName: `Day ${daysAmount + 1}`,
                phaseId,
              })
            }
            onDeleteDay={() =>
              deleteDayHandler({
                phaseId,
                dayName: day.name,
                orderNumber: day.orderNumber,
              })
            }
            onDeleteExercise={({ exerciseInfo }) =>
              deleteExerciseHandler({
                dayOrderNumber: day.orderNumber,
                exerciseInfoId: exerciseInfo.id,
                exerciseName: exerciseInfo.name,
                phaseId,
              })
            }
            onDeleteComplexExercise={
              (/*orderNumber*/) => {
                // TODO: finish after back end request will be implemented
                // deleteComplexExerciseHandler({
                //   complexExerciseOrderNumber: orderNumber,
                //   dayOrderNumber: day.orderNumber,
                //   phaseId,
                // });
              }
            }
            onCreateExercise={() => onCreateExerciseHandler(day)}
          />
        ));
      }

      return <div className="no-data">No data</div>;
    }

    return null;
  };

  return (
    <>
      <div className="template-page-wrapper">
        {!templateList.isLoading &&
          templateList.data.map(({ name, id }) => (
            <Accordion
              title={<AccordionTitle name={name} id={id} />}
              key={id}
              expanded={!!getExpandedElemData(id)}
              onChange={(e, exp) => toggleAccordionExpand(id, exp)}
              id="Template"
            >
              {getAccordionDetails(id)}
              {(getExpandedElemData(id)?.isLoading || loadedPhaseId === id) && (
                <CircularProgress />
              )}
            </Accordion>
          ))}

        {templateList.isLoading &&
          new Array(20)
            .fill(null)
            .map(() => (
              <Skeleton key={uuidv4()} className="template-page-skeleton" />
            ))}

        {templateList.data.length === 0 && !templateList.isLoading && (
          <div className="not-found">No data</div>
        )}
      </div>

      {/* delete template modal */}
      <ConfirmModal
        onCancel={closeDeleteTemplateModal}
        isLoading={isLoadingDeleteTemplate}
        onSubmit={submitDeleteTemplateHandler}
        show={!!deleteTemplateData}
        title={
          <>
            Are you sure you want to delete{' '}
            {deleteTemplateData?.name || 'this template'}?
          </>
        }
      />

      {/* delete day modal */}
      <ConfirmModal
        onCancel={closeDeleteDayModal}
        onSubmit={submitDeleteDayHandler}
        show={!!deleteDayData}
        isLoading={isLoadingDeleteDay}
        title={
          <>
            Are you sure you want to delete{' '}
            {deleteDayData?.dayName || 'this day'}?
          </>
        }
      />

      {/* delete exercise modal */}
      <ConfirmModal
        onCancel={closeDeleteExerciseModal}
        onSubmit={submitDeleteExerciseHandler}
        show={!!deleteExerciseData}
        isLoading={isLoadingDeleteExercise}
        title={
          <>
            Are you sure you want to delete{' '}
            {deleteExerciseData?.exerciseName || 'this exercise'}?
          </>
        }
      />

      {/* edit exercise modal */}
      {editExerciseData && (
        <ManageComplexExerciseModal
          title="Edit exercise"
          onClose={closeEditExerciseModal}
          isLoading={isLoadingEditExercise}
          existExercises={[editExerciseData.exercise]}
          excludedExerciseIDs={editExerciseData.excludedExerciseIDs}
          isSupportingMultipleExercises={false}
          onSubmit={(exercises) => {
            submitEditExerciseHandler({
              phaseId: editExerciseData.phaseId,
              exercise: exercises[0],
            });
          }}
        />
      )}

      {/* create complex exercise modal */}
      {createExerciseModalData && (
        <ManageComplexExerciseModal
          title="Add new exercise"
          onClose={closeCreateExerciseModal}
          isLoading={isLoadingCreateExercise}
          excludedExerciseIDs={createExerciseModalData.excludedExerciseIDs}
          onSubmit={(exercises) =>
            submitCreateExerciseHandler({
              exercises,
              dayName: createExerciseModalData.dayName,
              phaseId: createExerciseModalData.phaseId,
              dayOrderNumber: createExerciseModalData.dayOrderNumber,
            })
          }
        />
      )}
    </>
  );
});
