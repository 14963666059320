import styled from '@emotion/styled/macro';
import { flexCenteredStyles } from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2;
  gap: 28px;

  & .uploadLogo {
    position: relative;
    display: flex;
    height: 120px;
    width: 120px;

    & .logoLabel {
      width: 100%;
      height: 100%;
      border: 2px dashed #3E434B;
      border-radius: 16px;
      ${flexCenteredStyles};
    }

    &__edit {
      position: absolute;
      bottom: -12px;
      ${flexCenteredStyles};
    }

    &__editIcon {
      border-radius: 50%;
      background: #FFEF01;
      padding: 3px;
      color: black;
    }

    & .logo {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }

    & .deleteLogoButton {
      position: absolute;
      top: 5px;
      right: 5px;
      ${flexCenteredStyles};
      width: 24px;
      height: 24px;

      border-radius: 12px;
      background: rgba(15, 16, 18, 0.6);

      &__icon {
        width: 18px;
        height: auto;
      }
    }
  }

  & .label {
    width: 100%;
  }

  & .welcomeTextWrapper {
    width: 100%;
  }
`;

export default { Wrapper };