import type { Dispatch, MouseEvent, SetStateAction } from 'react';
import type { TExtendedExercise } from 'components/_new/ManageComplexExerciseModal/models';
import type {
  TCreateExerciseModalData,
  TCreateExerciseProps,
  TDeleteDayProps,
  TDeleteExerciseProps,
  TDeleteTemplateData,
  TEditExerciseData,
  TEditExerciseProps,
  TExpandedItem,
} from '../models';

type Props = {
  setDeleteTemplateData: (data: TDeleteTemplateData) => void;
  fetchDeleteTemplate: (data: TDeleteTemplateData) => Promise<void>;
  deleteTemplateData: TDeleteTemplateData;
  setDeleteDayData: (data: TDeleteDayProps | null) => void;
  deleteDayData: TDeleteDayProps | null;
  fetchTemplateItemData: (id: number) => Promise<void>;
  setExpandedItems: (items: (prev: TExpandedItem[]) => TExpandedItem[]) => void;
  fetchDeleteDay: (data: TDeleteDayProps) => Promise<boolean | null>;
  fetchDeleteExercise: (data: TDeleteExerciseProps) => Promise<boolean | null>;
  setDeleteExerciseData: (data: TDeleteExerciseProps | null) => void;
  deleteExerciseData: TDeleteExerciseProps | null;
  setEditExerciseData: (
    data:
      | ((prev: TEditExerciseData | null) => TEditExerciseData | null)
      | TEditExerciseData
      | null,
  ) => void;
  fetchEditExercise: (data: TEditExerciseProps) => Promise<boolean | null>;
  setCreateExerciseModalData: Dispatch<
    SetStateAction<TCreateExerciseModalData | null>
  >;
  fetchCreateExercise: (data: TCreateExerciseProps) => Promise<boolean | null>;
};

export const useHandlers = ({
  setDeleteTemplateData,
  setDeleteDayData,
  deleteDayData,
  deleteTemplateData,
  fetchDeleteTemplate,
  fetchTemplateItemData,
  setExpandedItems,
  fetchDeleteDay,
  fetchDeleteExercise,
  deleteExerciseData,
  fetchCreateExercise,
  setCreateExerciseModalData,
  setDeleteExerciseData,
  setEditExerciseData,
  fetchEditExercise,
}: Props) => {
  // delete template handlers
  const openDeleteTemplateModal = (data: TDeleteTemplateData) =>
    setDeleteTemplateData(data);
  const closeDeleteTemplateModal = () => setDeleteTemplateData(null);

  const deleteTemplateHandler = (
    e: MouseEvent<SVGSVGElement>,
    data: TDeleteTemplateData,
  ) => {
    e.stopPropagation();
    openDeleteTemplateModal(data);
  };

  const submitDeleteTemplateHandler = async () => {
    await fetchDeleteTemplate(deleteTemplateData);
    closeDeleteTemplateModal();
  };

  // delete day handlers
  const openDeleteDayModal = (data: TDeleteDayProps) => setDeleteDayData(data);
  const closeDeleteDayModal = () => setDeleteDayData(null);

  const deleteDayHandler = (data: TDeleteDayProps) => openDeleteDayModal(data);

  const submitDeleteDayHandler = async () => {
    if (!deleteDayData) return;

    const isSuccess = await fetchDeleteDay(deleteDayData);

    if (isSuccess) {
      closeDeleteDayModal();
    }
  };

  // delete exercise handlers //

  const openDeleteExerciseModal = (data: TDeleteExerciseProps) =>
    setDeleteExerciseData(data);
  const closeDeleteExerciseModal = () => setDeleteExerciseData(null);

  const deleteExerciseHandler = (data: TDeleteExerciseProps) =>
    openDeleteExerciseModal(data);

  const submitDeleteExerciseHandler = async () => {
    if (!deleteExerciseData) return;

    const isSuccess = await fetchDeleteExercise(deleteExerciseData);

    if (isSuccess) {
      closeDeleteExerciseModal();
    }
  };

  // == //
  const toggleAccordionExpand = async (id: number, expanded: boolean) => {
    if (expanded) {
      await fetchTemplateItemData(id);
    } else {
      setExpandedItems((prev) => prev.filter((item) => item.id !== id));
    }
  };

  // edit exercise handlers
  const openEditExerciseModal = (data: TEditExerciseData) =>
    setEditExerciseData(data);
  const closeEditExerciseModal = () => setEditExerciseData(null);

  const editExerciseHandler = (data: TEditExerciseData) =>
    openEditExerciseModal(data);

  const submitEditExerciseHandler = async (
    editExerciseData: TEditExerciseData,
  ) => {
    const { phaseId, exercise } = editExerciseData;
    const { exerciseInfo, type, weeks } = exercise;

    const isSuccess = await fetchEditExercise({
      exerciseInfoId: exerciseInfo.id,
      phaseId,
      exercises: weeks.map(({ exerciseId, exerciseRows }) => ({
        exerciseId,
        exerciseRows,
        exerciseType: type,
      })),
    });

    if (isSuccess) {
      closeEditExerciseModal();
    }
  };

  // create exercise handlers
  const openCreateExerciseModal = (data: TCreateExerciseModalData) =>
    setCreateExerciseModalData(data);
  const closeCreateExerciseModal = () => setCreateExerciseModalData(null);

  const createExerciseHandler = (data: TCreateExerciseModalData) =>
    openCreateExerciseModal(data);

  const submitCreateExerciseHandler = async ({
    dayName,
    phaseId,
    exercises,
    dayOrderNumber,
  }: TCreateExerciseModalData & {
    exercises: TExtendedExercise[];
  }) => {
    const data: TCreateExerciseProps = {
      phaseId,
      dayName,
      orderNumber: dayOrderNumber,
      exercises: exercises.map((exercise) => ({
        description: exercise.description,
        exerciseInfoId: exercise.exerciseInfo.id,
        orderNumber: exercise.orderNumber,
        exercises: exercise.weeks.map((week) => ({
          weekNumber: week.id,
          exerciseRows: week.exerciseRows,
        })),
      })),
    };

    const isSuccess = await fetchCreateExercise(data);

    if (isSuccess) {
      closeCreateExerciseModal();
    }
  };

  return {
    closeDeleteTemplateModal,
    deleteTemplateHandler,
    closeDeleteDayModal,
    submitDeleteDayHandler,
    deleteDayHandler,
    submitDeleteTemplateHandler,
    toggleAccordionExpand,
    deleteExerciseHandler,
    closeDeleteExerciseModal,
    submitDeleteExerciseHandler,
    editExerciseHandler,
    closeEditExerciseModal,
    submitEditExerciseHandler,
    closeCreateExerciseModal,
    createExerciseHandler,
    submitCreateExerciseHandler,
  };
};
