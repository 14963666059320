import { useEffect } from 'react';
import {
  changeExerciseInputDataHandler,
  getOnlyIntegerValueFromRestField,
  getRPEValue,
  roundToOneDecimal,
} from '../utils';
import { isChatPage } from 'containers/app/trainer/currentClient/clientPhases/utils';
import gaEvents from 'utils/gaEvents';

import { catalogIdsEnum, TOnSubmitExerciseMasterRowPayload } from '../models';
import type { TExtendedExercise, TOnChangeRowValue } from '../models';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { TSelection } from 'components/_new/RadioButtonsGroup/RadioButtonsGroup';
import type {
  TExercise,
  TWeek,
} from 'components/_new/phase/models';

type TUseHandlersProps = {
  setExercises: Dispatch<SetStateAction<TExtendedExercise[]>>;
  catalogOptions: Record<
    catalogIdsEnum,
    TSelection & { data: TExercise['exerciseInfo'][] }
  >;
  initExercise: TExtendedExercise;
  modalId: string;
  existExercises?: TExercise[];
  exercises: TExtendedExercise[];
};

const useHandlers = ({
  catalogOptions,
  setExercises,
  initExercise,
  modalId,
  existExercises,
  exercises,
}: TUseHandlersProps) => {
  // initialization exercises (setExercises) which is based on existExercises
  // and initExercise. This should be here as firstly initExercise needs to be
  // verified
  useEffect(() => {
    const formattedExistExercises: TExtendedExercise[] | null =
      existExercises?.map((exercise, index) => ({
        ...exercise,
        isExpanded: false,
        catalog: exercise.exerciseInfo.trainerId
          ? catalogOptions[catalogIdsEnum.trainer]
          : catalogOptions[catalogIdsEnum.admin],
      })) ?? null;

    setExercises(formattedExistExercises ?? [initExercise]);
  }, [existExercises]);

  const changeExpandedItemHandler = (index: number) => {
    const exercisesCopy = [...exercises];
    exercisesCopy[index].isExpanded = !exercisesCopy[index].isExpanded;
    setExercises(exercisesCopy);
  };

  const changeCatalogHandler = (
    exerciseId: TExtendedExercise['id'],
    catalogId: catalogIdsEnum,
  ) => {
    if (catalogId === catalogIdsEnum.admin) {
      if (isChatPage()) {
        gaEvents.chatSelectAdminExerciseCatalogOnAddOrEditComplexExerciseModal(
          modalId,
        );
      } else {
        gaEvents.selectAdminExerciseCatalogOnAddOrEditComplexExerciseModal(
          modalId,
        );
      }
    } else {
      if (isChatPage()) {
        gaEvents.chatSelectMyExerciseCatalogOnAddOrEditComplexExerciseModal(
          modalId,
        );
      } else {
        gaEvents.selectMyExerciseCatalogOnAddOrEditComplexExerciseModal(
          modalId,
        );
      }
    }

    setExercises((prev) =>
      prev.map((exercise): TExtendedExercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            catalog: catalogOptions[catalogId],
            exerciseInfo: catalogOptions[catalogId].data[0],
          };
        }

        return exercise;
      }),
    );
  };

  const changeExerciseInfoHandler = (
    exerciseId: TExtendedExercise['id'],
    exerciseInfo: TExtendedExercise['exerciseInfo'],
  ) => {
    if (isChatPage()) {
      gaEvents.chatChooseExerciseFromDropdownOnAddOrEditComplexExerciseModal(
        modalId,
      );
    } else {
      gaEvents.chooseExerciseFromDropdownOnAddOrEditComplexExerciseModal(
        modalId,
      );
    }

    setExercises((prevExercises) =>
      prevExercises.map((exercise): TExtendedExercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            weeks: exercise.weeks.map((week) => ({
              ...week,
              exerciseRows: week.exerciseRows.map((row) => ({
                ...row,

                // set weight to `0` when exercisesInfo is changed ↓
                // https://dev.azure.com/StrengthSystem/StrengthSystem/_workitems/edit/3149
                weight: 0,
              })),
            })),
            exerciseInfo,
          };
        }

        return exercise;
      }),
    );
  };

  const changeDescriptionHandler = (
    e: ChangeEvent<HTMLTextAreaElement>,
    exerciseId: TExtendedExercise['id'],
  ) => {
    setExercises((prev) =>
      prev.map((exercise): TExtendedExercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            description: e.target.value,
          };
        }

        return exercise;
      }),
    );
  };

  const changeRowValueHandler = ({
    newValue,
    userPressedBackspace,
    exerciseId,
    weekName,
    rowKey,
    rowId,
  }: TOnChangeRowValue) => {
    setExercises((prev) =>
      prev.map((exercise): TExtendedExercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            weeks: exercise.weeks.map((week) => {
              if (week.name === weekName) {
                return {
                  ...week,
                  exerciseRows: week.exerciseRows.map((row) => {
                    if (row.id === rowId) {
                      const value = changeExerciseInputDataHandler({
                        newValue,
                        key: rowKey,
                        userPressedBackspace,
                      });
                      return {
                        ...row,
                        [rowKey]: value,
                      };
                    }

                    return row;
                  }),
                };
              }

              return week;
            }),
          };
        }

        return exercise;
      }),
    );
  };

  const addSetHandler = (
    exerciseId: TExtendedExercise['id'],
    weekName: TWeek['name'],
  ) => {
    setExercises((prev) =>
      prev.map((exercise): TExtendedExercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            weeks: exercise.weeks.map((week) => {
              if (week.name === weekName) {
                const newRow =
                  week.exerciseRows?.[week.exerciseRows.length - 1];
                return {
                  ...week,
                  exerciseRows: [
                    ...(week.exerciseRows || []),
                    {
                      ...newRow,
                      id: newRow.id + 1,
                      sets: newRow.sets + 1,
                    },
                  ],
                };
              }
              return week;
            }),
          };
        }
        return exercise;
      }),
    );
  };

  const removeLastSetHandler = (
    exerciseId: TExtendedExercise['id'],
    weekName: TWeek['name'],
  ) => {
    setExercises((prev) =>
      prev.map((exercise): TExtendedExercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            weeks: exercise.weeks.map((week) => {
              if (week.name === weekName) {
                return {
                  ...week,

                  exerciseRows: week.exerciseRows?.filter(
                    (row, rowIndex, arr) => rowIndex !== arr.length - 1,
                  ),
                };
              }

              return week;
            }),
          };
        }

        return exercise;
      }),
    );
  };

  const appendExerciseHandler = () => {
    setExercises((prevExercises): TExtendedExercise[] => {
      const prevExercise = prevExercises[prevExercises.length - 1];
      return [
        ...prevExercises,
        {
          ...initExercise,
          orderNumber: prevExercise ? prevExercise.orderNumber + 1 : 0,
          id: prevExercise ? prevExercise.id + 1 : 0,
        },
      ];
    });
  };

  const handleSubmitExerciseMasterRow = ({ masterRowData, masterRowDeltas, exerciseId, week4NumberOfSets}: TOnSubmitExerciseMasterRowPayload) => {
    const { reps, rest, sets, weight, rpe = 0 } = masterRowData;

    setExercises((prev) =>
      prev.map((exercise): TExtendedExercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            // based on weekIndex and deltas values - will be calculated
            // value*delta for weeks after 1

            // e.g.(1): if delta for REPS is 2, and REPS value is 4:
            // week1 - reps: 4
            // week2 - reps: 6
            // week3 - reps: 8
            // week4 - reps: 10

            // e.g.(2): sets(delta: -2, value: 5), weight(delta: 5, value: 10):
            // week1 - sets: 5, weight: 10
            // week2 - sets: 3, weight: 15
            // week3 - sets: 1, weight: 20
            // week4 - sets: 1, weight: 25

            // min sets/reps value - 1, min rest value - 0, min rpe value - ${RPE_MIN_VALUE},
            // max rpe value - ${RPE_MAX_VALUE} (ManageComplexExerciseModal/utils/index.ts)

            weeks: exercise.weeks.map((week, weekIndex) => {
              const restNumberValue = roundToOneDecimal(
                +getOnlyIntegerValueFromRestField(rest) +
                +getOnlyIntegerValueFromRestField(masterRowDeltas.rest) * weekIndex,
              );
              const restCalculated = `${
                restNumberValue > 0 ? restNumberValue : 0
              } min`;
              const weightCalculated = +weight + +masterRowDeltas.weight * weekIndex;
              const repsCalculated = +reps + (+(masterRowDeltas.reps) || 0) * weekIndex;
              const rpeCalculated =
                getRPEValue(Number(+rpe + +masterRowDeltas.rpe * weekIndex)) || 0;

              // feature to have custom number of sets(week4NumberOfSets) ONLY
              // for week 4(weekIndex === 3) if week4NumberOfSets is passed as true number
              const customNumberOfSetsForWeek4 =
                weekIndex === 3 && week4NumberOfSets;
              const setsNumber =
                customNumberOfSetsForWeek4 ||
                sets + masterRowDeltas.sets * weekIndex;

              return ({
                ...week,
                exerciseRows: new Array(
                  Number(setsNumber > 0 ? setsNumber : 1),
                )
                  .fill(null)
                  .map((_, i) => ({
                    id: i + 1,
                    sets: i + 1,
                    reps: repsCalculated > 0 ? repsCalculated : 1,
                    rest: restCalculated,
                    weight: weightCalculated > 0 ? weightCalculated : 0,
                    rpe: rpeCalculated,
                  })),
              })
            }),
          };
        }

        return exercise;
      }),
    );
  };

  return {
    addSetHandler,
    removeLastSetHandler,
    changeCatalogHandler,
    changeRowValueHandler,
    appendExerciseHandler,
    changeDescriptionHandler,
    changeExerciseInfoHandler,
    handleSubmitExerciseMasterRow,
    changeExpandedItemHandler,
  } as const;
};

export default useHandlers;
