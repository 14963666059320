import React, { ChangeEvent, CSSProperties, FC, ReactElement, useRef } from 'react';

import S from './UploadAndDragAndDropArea.styled';

interface IUploadAndDragAndDropAreaProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fileType: string;
  styles?: CSSProperties;
  children: ReactElement;
}

export const UploadAndDragAndDropArea: FC<IUploadAndDragAndDropAreaProps> = ({
  onChange,
  fileType,
  styles,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);

    // clean the input value to have opportunity to choose the same file
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }
  return (
    <S.Wrapper style={styles}>
      <input
        ref={inputRef}
        className="field"
        type="file"
        title={" "}
        accept={fileType}
        onChange={onChangeHandler}
      />
      <div className="content">{children}</div>
    </S.Wrapper>
  );
};
