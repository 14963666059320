import React, { FC, ReactNode } from 'react';

import S from './InfiniteScrollListWrapper.styled';

interface IInfiniteScrollListWrapperProps {
  dataLength: number;
  fetchMoreMessagesHandler: () => void;
  inverse?: boolean;
  children: ReactNode;
  scrollableListId: string;
  hasMore?: boolean;
}

export const InfiniteScrollListWrapper: FC<IInfiniteScrollListWrapperProps> = ({
  dataLength,
  fetchMoreMessagesHandler,
  children,
  inverse = false,
  scrollableListId,
  hasMore = true,

}) => {
  return (
    <S.InfiniteScrollWrapper
      dataLength={dataLength}
      next={fetchMoreMessagesHandler}
      inverse={inverse}
      hasMore={hasMore}
      loader={null}
      scrollableTarget={scrollableListId}
      style={{ overflow: 'hidden' }}
    >
      {children}
    </S.InfiniteScrollWrapper>
  );
};
