import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { useLayout } from 'providers/layout/hooks/useLayout';
import { Tooltip } from '../tooltip/Tooltip';
import type { TSidebarChildItem, TSidebarItem } from 'data';
import { CHAT_ID, sidebarTrainersItems } from 'data';
import { UserContext } from 'providers/userProvider';
import gaEvents from 'utils/gaEvents';
import { SIDEBAR } from 'constants/tooltips';

import { ExpandIc, LinkIc } from 'assets/svg';
import S from './Sidebar.styled';

const DOCUMENTATION_LINK =
  'https://strengthsystem.com/program-design-documentation/';

export const Sidebar = withRouter(({ history }) => {
  const { isSidebarCollapsed, setIsSidebarCollapsedHandler } = useLayout();
  const [openSidebarItemId, setOpenSidebarItemId] = useState<string | null>(
    null,
  );
  const [isSubMenuClicked, setIsSubMenuClicked] = useState(false);

  const { user } = useContext(UserContext);
  const chatCounter = user?.totalUnreadMessages || 0;

  // to close collapsed subSidebar tooltip after subItem was clicked and route was changed
  useEffect(() => {
    if (isSubMenuClicked) {
      setIsSubMenuClicked(false);
    }
  }, [isSubMenuClicked]);

  const handleClick = (id: string, collapsable: boolean) => {
    if (collapsable) {
      if (id === openSidebarItemId) {
        setOpenSidebarItemId(null);
      } else {
        setOpenSidebarItemId(id);
      }

      gaEvents.clickCollapseExpandSidebarItem({
        pageName: id,
        isExpanded: id !== openSidebarItemId,
      });
    }
  };

  const areChildrenActive = useCallback(
    (children?: TSidebarChildItem[]) =>
      !!(children || []).find((item) =>
        history.location.pathname.slice(1).includes(item.id),
      ),
    [history.location.pathname],
  );

  const onClickSidebarItemHandler = (item: TSidebarItem) => {
    if (Number(item?.children?.length) > 0) {
      if (!isSidebarCollapsed) {
        handleClick(item.id, !!item.children);
      }
    } else {
      gaEvents.clickOnSidebarItem({ pageName: item.name });

      history.push(`/${item.id}`);
    }
  };

  const sidebarCollapsedHandler = () => {
    gaEvents.clickOnExpandCollapseSidebar({ toExpand: isSidebarCollapsed });

    setIsSidebarCollapsedHandler();
    setOpenSidebarItemId(null);
  };

  const sidebarMenuItem = (item: TSidebarItem) => {
    const renderSidebarMenuIcon = (item: TSidebarItem) => {
      switch (item.id) {
        case CHAT_ID:
          return (
            <div className="labelIcon">
              {item.iconComponent}
              {Boolean(chatCounter) && <S.Counter>{chatCounter}</S.Counter>}
            </div>
          );
        default:
          return <div className="labelIcon">{item.iconComponent}</div>;
      }
    };

    return (
      <S.SidebarItem
        isActive={history.location.pathname.slice(1).includes(item.id)}
        isActiveParentChild={areChildrenActive(item.children)}
        onClick={() => onClickSidebarItemHandler(item)}
      >
        <div className="labelWrapper">
          {renderSidebarMenuIcon(item)}
          {!isSidebarCollapsed && item.name}
        </div>
        {Number(item?.children?.length) > 0 && !isSidebarCollapsed && (
          <S.ArrowTriangleIcon isReversed={openSidebarItemId !== item.id} />
        )}
      </S.SidebarItem>
    );
  };

  const getSidebarSubMenu = (item: TSidebarItem) => {
    const subMenuItemClickHandler = ({
      id,
      name,
    }: {
      id: string;
      name: string;
    }) => {
      gaEvents.clickOnSidebarItem({ pageName: name });

      setIsSubMenuClicked(true);
      history.push(`/${id}`);
    };

    return (
      <>
        {item.children?.map((child) => (
          <S.ChildListItem
            button={true}
            isSidebarCollapsed={isSidebarCollapsed}
            isActiveChild={history.location.pathname
              .slice(1)
              .includes(child.id)}
            key={child.id}
            onClick={() => subMenuItemClickHandler(child)}
          >
            <div className="childIcon">{child.iconComponent}</div>
            <ListItemText primary={child.name} />
          </S.ChildListItem>
        ))}
      </>
    );
  };

  const getSidebarMenuItem = (item: TSidebarItem) => {
    if (isSidebarCollapsed && item.children && !isSubMenuClicked) {
      return (
        <Tooltip
          placement={'bottom-end'}
          title={getSidebarSubMenu(item)}
          withArrow={false}
        >
          {sidebarMenuItem(item)}
        </Tooltip>
      );
    }
    return sidebarMenuItem(item);
  };

  const onOpenDocumentation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    gaEvents.clickOnDocumentationLink();

    window.open(DOCUMENTATION_LINK, '_blank', 'noreferrer');
  };

  return (
    <>
      <S.Sidebar isSidebarCollapsed={isSidebarCollapsed}>
        <S.Body>
          <ul>
            {sidebarTrainersItems.map((item) => {
              return (
                <li key={item.id}>
                  <Tooltip
                    placement="bottom"
                    title={isSidebarCollapsed && item.tooltip}
                  >
                    {getSidebarMenuItem(item)}
                  </Tooltip>
                  {item?.children && (
                    <Collapse
                      in={openSidebarItemId === item.id && !!openSidebarItemId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {getSidebarSubMenu(item)}
                      </List>
                    </Collapse>
                  )}
                </li>
              );
            })}
          </ul>
          <S.Footer>
            <Tooltip title={isSidebarCollapsed && SIDEBAR.DOCUMENTATION_GUIDE}>
              <a
                href={DOCUMENTATION_LINK}
                className="docLink"
                onClick={onOpenDocumentation}
              >
                {!isSidebarCollapsed && `Documentation Guide `}
                <LinkIc />
              </a>
            </Tooltip>
            <S.CollapseExpandButton
              isSidebarCollapsed={isSidebarCollapsed}
              className="collapseExpandButton"
              onClick={sidebarCollapsedHandler}
            >
              <Tooltip
                title={
                  isSidebarCollapsed
                    ? SIDEBAR.EXPAND_MENU
                    : SIDEBAR.COLLAPSE_MENU
                }
              >
                <ExpandIc />
              </Tooltip>
            </S.CollapseExpandButton>
          </S.Footer>
        </S.Body>
      </S.Sidebar>

      <S.SidebarPlaceholder isSidebarCollapsed={isSidebarCollapsed} />
    </>
  );
});
