/* eslint-disable react/no-unescaped-entities */
// TODO: refactoring component
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactSVG } from 'react-svg';
import Loader from 'components/loader/Loader';
import { Redirect } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import Modal from '@material-ui/core/Modal';
import ReactPlayer from 'react-player';
import userPhoto from 'assets/userPhoto.png';
import { UserContext } from 'providers/userProvider';
import {
  getApiData,
  patchData,
  postData,
  putData,
} from 'lib/api/utils';
import {
  complexExerciseTypes,
  exerciseTypeData,
} from 'data';
import CreateProgramModal from '../createProgramModal';
import { QuestionnaireContext } from 'providers/questionnaireProvider';
import TextInput from 'components/fields/textInput';
import { ExercisesContext } from 'providers/exerciseProvider';
import BiggerProgram from './biggerProgram/BiggerProgram';
import { useLayout } from 'providers/layout/hooks/useLayout';
import { useSnackbar } from 'notistack';
import gaEvents from 'utils/gaEvents';
import { ClientInfo } from './clientInfo/ClientInfo';
import { parseDateToCorrectFormat } from 'utils';
import { DbdMode } from './dbdMode/DbdMode';
import { ROUTES } from '../../routes';
import { getClientIdBasedOnOpenedProgramUrl } from 'utils/clientUtils';
import { isChatPage } from './utils';
import { COLLAPSED_VIEW_ID, DBD_VIEW_ID } from './constants';
import { CollapsedMode } from './components/collapsedMode/CollapsedMode';

import S from './clientPhases.styled'
import './styles.scss';

export const exerciseCounter = [
  { color: '#0F4482' },
  { color: '#410F82' },
  { color: '#0F7B82' },
  { color: 'rgb(3, 108, 73)' },
  { color: 'rgb(119, 40, 110)' },
  { color: 'rgb(138, 33, 33)' },
  { color: 'rgb(138, 100, 10)' },
  { color: 'rgb(108, 68, 6)' },
];

const defaultComplexExercise = {
  type: complexExerciseTypes.single,
  exercises: [],
  dayId: '',
  exerciseIds: [],
};

const defaultExercise = {
  name: {
      name: '',
      id: '',
  },
  type: exerciseTypeData.static,
  description: '',
  exerciseRows: [],
};

const defaultExerciseRow = {
  sets: 1,
  reps: '',
  weight: 0,
  rest: '',
};

const ClientPhases = ({ clientIdProp, programIdProp, history }) => {
  const { user } = useContext(UserContext);
  const { questionnaire, loadQuestionnaires } =
  useContext(QuestionnaireContext);
  const { exercises, onSetExercises } = useContext(ExercisesContext);
  const [program, setProgram] = useState(null);
  const [bigProgram, setBigProgram] = useState(null);
  const [activeExercise, setActiveExercise] = useState(null);
  const [showModal, setModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [hasNotClientProgram, setHasNotClientProgram] = useState(false);
  const [showEditProgramModal, setShowEditProgramModal] = useState(false);
  const [client, setClient] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [cells, setCells] = useState([]);
  const [viewModeId, setViewModeId] = useState(COLLAPSED_VIEW_ID);
  const [newComplexExercise, setNewComplexExercise] = useState({
      ...defaultComplexExercise,
      exercises: [
          {
              ...defaultExercise,
              name: exercises?.length
                  ? { name: exercises[0].name, id: exercises[0].id }
                  : null,
              exerciseRows: [
                  {
                      ...defaultExerciseRow,
                  },
              ],
          },
      ],
  });
  const commentsRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const toggleOpenModal = useCallback(() => setModal(true), []);

  const toggleCloseModal = useCallback(() => {
      setModal(false);
      setError('');
  }, []);

  const toggleOpenEditProgramModal = useCallback(
      () => {
          setShowEditProgramModal(true);
          if (isChatPage()) {
            gaEvents.chatClickEditProgramButton();
          } else {
            gaEvents.clickEditProgramButton();
          }
      },
      [],
  );

  const toggleCloseEditProgramModal = useCallback(() => {
      setShowEditProgramModal(false);
  }, []);

  const clientId = clientIdProp || getClientIdBasedOnOpenedProgramUrl(history.location.pathname);
  const programId = programIdProp || history.location.pathname
      .split('/trainer/trainers-clients/')[1]
      .split('/phases/')[1];

  useEffect(() => {
      if (!client) {
          clientDataHandler();
      }
      if (questionnaire && !questionnaire.length) {
          loadQuestionnaires();
      }
      if (exercises || !exercises?.length) {
          loadExercises();
      }
  }, []);

  useEffect(() => {
      if (activeExercise && commentsRef && commentsRef.current) {
          commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
      }
  }, [activeExercise]);

  const loadExercises = () => {
      getApiData('ExerciseInfo?field=name&asc=true').then((answer) => {
          if (answer?.items?.length) {
              onSetExercises(answer.items);
              setNewComplexExercise({
                  ...newComplexExercise,
                  exercises: [
                      {
                          ...newComplexExercise.exercises[0],
                          name: { name: answer.items[0].name, id: answer.items[0].id },
                      },
                  ],
              });
          } else {
              onSetExercises(null);
          }
      });
  };

  const clientDataHandler = () => {
      getApiData(`User/get-client/${clientId}`).then((answer) => {
          if (answer?.success) {
              setClient(answer.item);
          } else {
              enqueueSnackbar(answer, { variant: 'error' });
          }
      });
  };

  const loadCurrentProgram = () => {
      setLoading(true);
      getApiData(`Program/${programId}`).then((answer) => {
          setLoading(false);
          if (answer?.success) {
              const currentProgram = answer.item;

              if (!Object.keys(currentProgram).length) {
                  setHasNotClientProgram(true);
              } else {
                  setProgram(currentProgram);
              }
          } else {
              enqueueSnackbar(answer, { variant: 'error' });
          }
      });
  };

  const loaderHandler = (status) => {
      setLoader(status);
  };

  const sendCommentHandler = (exercise) => {
      if (newMessage) {
          const comment = {
              complexExerciseId: exercise.complexExerciseId,
              text: newMessage,
              clientId,
          };

          setLoading(true)
          postData('Comment', comment).then(async (answer) => {
              if (answer.success) {
                  const updatedCommentsArray = [...exercise.comments];

                  updatedCommentsArray.push({
                      ...answer.item,
                      user: {
                          id: user.id,
                          displayName: `${user.firstName} ${user.lastName}`,
                          photoUrl: user.photoUrl,
                      },
                  });

                  await loadCurrentProgram();
                  setNewMessage('');
                  setActiveExercise({
                      ...exercise,
                      comments: updatedCommentsArray,
                  });
              } else {
                enqueueSnackbar(answer, { variant: 'error' });
              }
          }).finally(() => {
            setLoading(false)
          });
      }
  };

  const editProgramHandler = () => {
      history.goBack();
  };

  const editProgramCellsHandler = (e, index, name, id, isNumber) => {
      const editCurrentCell = () => {
          const newArray = [...activeExercise.exerciseRows];

          newArray[index][name] = e.target.value;

          setActiveExercise({
              ...JSON.parse(JSON.stringify(activeExercise)),
              exerciseRows: newArray,
          });

          const newCells = [...cells];

          if (newCells.some((cell) => cell.id === id)) {
              newCells.forEach((cell, i) => {
                  if (+cell.id === +id) {
                      newCells[i][name] =
            name === 'rest' ? e.target.value : +e.target.value;

                      setCells(newCells);

                      return;
                  }
              });
          } else {
              newCells.push({
                  id,
                  [name]: name === 'rest' ? e.target.value : +e.target.value,
              });
              setCells(newCells);
          }
      };

      if (isNumber) {
          if (!isNaN(e.target.value) && !e.target.value.includes(' ')) {
              editCurrentCell();
          }
      } else {
          editCurrentCell();
      }
  };

  const openConfirmModalHandler = () => {
      setModalType('updateCells');
      toggleOpenModal();
  };

  const sendEditedCellsHandler = () => {
      setLoading(true);

      patchData('ExerciseRow', { exerciseRows: cells }).then((answer) => {
          if (answer.success) {
              setError('Data changed successfully');
              setModalType('error');
              toggleOpenModal();
          } else {
              enqueueSnackbar(answer, { variant: 'error' });
          }
          setCells([]);
          setLoading(false);
      });
  };

  let sameDate = '';
  let commentId = '';

  const { initLayoutState } = useLayout();

  useEffect(() => {
    // if it is BP on Chat page - we do not need this back button
    if (!isChatPage()) {
      initLayoutState({
        routePath: {
          title: '← Back to Client\'s programs',
        },
      });
    }
  }, [initLayoutState]);

  if (hasNotClientProgram) {
      return <Redirect to={ROUTES.TRAINERS_CLIENTS} />;
  }

  const forceUpdateProgramModalHandler = () => {
      setModalType('forceUpdate');
      toggleOpenModal();
  };

  const forceUpdateProgramHandler = (e) => {
      e.preventDefault();
      loaderHandler(true);
      toggleCloseModal();
      putData('Program/force-update', { clientId: client.user.id }).then(
          (answer) => {
              if (answer.success) {
                  editProgramHandler();
              } else {
                  loaderHandler(false);
                  enqueueSnackbar(answer, { variant: 'error' });
              }
          },
      );
  };

  const renderViewMode = () => {
    switch (viewModeId) {
      case DBD_VIEW_ID:
        return (
          <Scrollbar className="phases-list-scroll">
            <DbdMode
              program={program}
              setProgram={setProgram}
              exerciseCounter={exerciseCounter}
              setActiveExercise={setActiveExercise}
              defaultComplexExercise={defaultComplexExercise}
              defaultExercise={defaultExercise}
              defaultExerciseRow={defaultExerciseRow}
              loadCurrentProgram={loadCurrentProgram}
              activeExercise={activeExercise}
              setLoading={setLoading}
              newComplexExercise={newComplexExercise}
              setNewComplexExercise={setNewComplexExercise}
              exercises={exercises}
              viewModeId={viewModeId}
              setViewModeId={setViewModeId}
              toggleOpenEditProgramModal={toggleOpenEditProgramModal}
            />
          </Scrollbar>
        );
      case COLLAPSED_VIEW_ID:
        return (
          <CollapsedMode
            setDBDProgram={setProgram}
            programId={programId}
            bigProgram={bigProgram}
            setBigProgram={setBigProgram}
            viewModeId={viewModeId}
            setViewModeId={setViewModeId}
            toggleOpenEditProgramModal={toggleOpenEditProgramModal}
            isChatPage={isChatPage()}
          />
        );
      default:
        return (
          <BiggerProgram
            setDBDProgram={setProgram}
            programId={programId}
            bigProgram={bigProgram}
            setBigProgram={setBigProgram}
            viewModeId={viewModeId}
            setViewModeId={setViewModeId}
            toggleOpenEditProgramModal={toggleOpenEditProgramModal}
            isChatPage={isChatPage()}
          />
        );
    }
  }

  if (loader) {
    return <Loader />;
  }

  return (
      <>
          {!client || (
              <CreateProgramModal
                  client={client}
                  setNewProgram={editProgramHandler}
                  loaderHandler={loaderHandler}
                  showModal={showEditProgramModal}
                  toggleCloseModal={toggleCloseEditProgramModal}
                  questionnaire={questionnaire}
                  modalType="edit"
                  forceUpdateProgramModal={forceUpdateProgramModalHandler}
              />
          )}
          <S.Wrapper>
              {loading && <Loader/>}
              <div
                  className={`phases-scrollbar ${viewModeId !== DBD_VIEW_ID && 'full-screen'}`}
              >
                  {!isChatPage() && <ClientInfo user={client?.user} />}
                  {renderViewMode()}
              </div>
              <div className={`phases-right ${viewModeId !== DBD_VIEW_ID && 'hidden'}`}>
                  {activeExercise ? (
                      <div className="phases-exercise">
                          <div className="phases-exercise-title">
                              <p
                                  className="phases-exercise-title-count"
                                  style={{ backgroundColor: activeExercise.color }}
                              >
                                  {activeExercise.letter}
                              </p>
                              <p>{activeExercise.exerciseInfo.name}</p>
                          </div>
                          <div className="phases-exercise-body">
                              <div className="phases-exercise-body-description">
                                  <Scrollbar className="phases-list-scroll">
                                      {activeExercise.exerciseInfo.description}
                                  </Scrollbar>
                              </div>
                              <div className="phases-exercise-body-video">
                                  <ReactPlayer
                                      controls
                                      width="auto"
                                      height="165"
                                      url={activeExercise.exerciseInfo.videoUrl}
                                  />
                              </div>
                          </div>
                          <div className="phases-exercise-data">
                              <div className="phases-exercise-data-item">
                                  <p className="phases-exercise-data-item-title">Set</p>
                                  {/* disabled by TASK 3059 */}
                                  {/* {activeExercise.exerciseRows.length === 1 ? (
                                      <div className="phases-exercise-data-item-count">
                                          <TextInput
                                              type="text"
                                              value={activeExercise.exerciseRows[0].sets || ''}
                                              onChange={(e) =>
                                                  editProgramCellsHandler(
                                                      e,
                                                      0,
                                                      'sets',
                                                      activeExercise.exerciseRows[0].id,
                                                  )
                                              }
                                          />
                                      </div>
                                  ) : ( */}
                                      <div className="phases-exercise-data-item-column">
                                          {activeExercise.exerciseRows.map((set, i) => (
                                              <p
                                                  key={`R-${i}`}
                                                  className="phases-exercise-data-item-count"
                                              >
                                                  {set.sets}
                                              </p>
                                          ))}
                                      </div>
                                  {/* )} */}
                              </div>
                              <div className="phases-exercise-data-item">
                                  <p className="phases-exercise-data-item-title">Rest</p>
                                  <div className="phases-exercise-data-item-count">
                                      <TextInput
                                          type="text"
                                          value={
                                              activeExercise.exerciseRows[0].rest
                                                  ? activeExercise.exerciseRows[0].rest
                                                  : ''
                                          }
                                          onChange={(e) =>
                                              editProgramCellsHandler(
                                                  e,
                                                  0,
                                                  'rest',
                                                  activeExercise.exerciseRows[0].id,
                                              )
                                          }
                                      />
                                      {/*) : (*/}
                                      {/*  '1-2'*/}
                                      {/*)}*/}
                                  </div>
                              </div>
                              <div className="phases-exercise-data-item">
                                  <p className="phases-exercise-data-item-title">Reps</p>
                                  <div className="phases-exercise-data-item-column">
                                      {activeExercise.exerciseRows.map((set, i) => (
                                          <div
                                              key={`R-${i}`}
                                              className="phases-exercise-data-item-count"
                                          >
                                              <TextInput
                                                  type="text"
                                                  value={set.reps}
                                                  onChange={(e) =>
                                                      editProgramCellsHandler(e, i, 'reps', set.id, true)
                                                  }
                                              />
                                              {/*{set.reps}*/}
                                          </div>
                                      ))}
                                  </div>
                              </div>
                              <div className="phases-exercise-data-item">
                                  <p className="phases-exercise-data-item-title">Weight</p>
                                  <div className="phases-exercise-data-item-column">
                                      {activeExercise.exerciseRows.map((set, i) => (
                                          <div
                                              key={`S-${i}`}
                                              className="phases-exercise-data-item-count"
                                          >
                                              <TextInput
                                                  type="text"
                                                  value={set.weight}
                                                  onChange={(e) =>
                                                      editProgramCellsHandler(
                                                          e,
                                                          i,
                                                          'weight',
                                                          set.id,
                                                          true,
                                                      )
                                                  }
                                              />
                                              {/*{set.weight}*/}
                                          </div>
                                      ))}
                                  </div>
                              </div>
                          </div>
                          {cells.length ? (
                              <button
                                  className="phases-exercise-save"
                                  onClick={openConfirmModalHandler}
                              >
                                  Save
                              </button>
                          ) : (
                              ''
                          )}
                          <div className="phases-exercise-comments">
                              {activeExercise.comments ? (
                                  <ul className="phases-exercise-comments-list">
                                      <Scrollbar ref={commentsRef} className="phases-list-scroll">
                                          <div className="phases-exercise-comments-list-scroll">
                                              {activeExercise.comments
                                                  .sort((a, b) => {
                                                      return (
                                                          new Date(+a.createdOn) - new Date(+b.createdOn)
                                                      );
                                                  })
                                                  .map((comment) => {
                                                      const { fullDate } = parseDateToCorrectFormat(
                                                        comment.createdOn,
                                                      );

                                                      return (
                                                          <li
                                                              key={comment.id}
                                                              className={`phases-exercise-comments-comment ${
                                comment.user.id !== clientId ? 'right' : ''
                              }`}
                                                          >
                                                              {comment.user.id === clientId ? (
                                                                  <div
                                                                      className="phases-exercise-comments-comment-photo"
                                                                      style={
                                                                          comment.user.photoUrl
                                                                              ? {
                                                                                  backgroundImage: `url(${comment.user.photoUrl})`,
                                                                              }
                                                                              : {
                                                                                  backgroundImage: `url(${userPhoto})`,
                                                                              }
                                                                      }
                                                                  />
                                                              ) : (
                                                                  ''
                                                              )}
                                                              <div
                                                                  className={`phases-exercise-comments-comment-content ${
                                  comment.user.id !== clientId ? 'right' : ''
                                }`}
                                                              >
                                                                  {fullDate === sameDate &&
                                commentId === comment.user.id ? (
                                                                          ''
                                                                      ) : (
                                                                          <p className="phases-exercise-comments-comment-content-author">
                                                                              {comment.user.id === clientId
                                                                                  ? `${comment.user.displayName} ${fullDate}`
                                                                                  : fullDate}
                                                                          </p>
                                                                      )}
                                                                  <p
                                                                      className={`phases-exercise-comments-comment-content-message ${
                                    comment.user.id !== clientId
                                        ? 'right'
                                        : ''
                                  }`}
                                                                  >
                                                                      {comment.text}
                                                                  </p>
                                                              </div>
                                                              {(sameDate = fullDate) &&
                              (commentId = comment.user.id) &&
                              false}
                                                          </li>
                                                      );
                                                  })}
                                          </div>
                                      </Scrollbar>
                                  </ul>
                              ) : (
                                  ''
                              )}

                              {/* Task 3644 - disabled writing new comment as far as it is not used new request API... */}
                              {/* ... "General messages" which have "Comments" and "Messages" combined together */}
                              {/*<div className="phases-exercise-comments-new">*/}
                              {/*    <input*/}
                              {/*        type="text"*/}
                              {/*        placeholder="Write a  message..."*/}
                              {/*        className="phases-exercise-comments-new-input"*/}
                              {/*        value={newMessage}*/}
                              {/*        onChange={(e) => setNewMessage(e.target.value)}*/}
                              {/*    />*/}
                              {/*    <ReactSVG*/}
                              {/*        src="/assets/svg/send.svg"*/}
                              {/*        className="phases-exercise-comments-new-send"*/}
                              {/*        onClick={() => sendCommentHandler(activeExercise)}*/}
                              {/*    />*/}
                              {/*</div>*/}
                          </div>
                      </div>
                  ) : (
                      ''
                  )}
              </div>
              <Modal onClose={toggleCloseModal} open={showModal}>
                  <div className="modal phases-modal">
                      <div className="modal__close modal__body--sm">
                          <ReactSVG
                              onClick={toggleCloseModal}
                              src="/assets/svg/close.svg"
                          />
                      </div>
                      <div className="modal__body">
                          {modalType === 'updateCells' ? (
                              <div className="modal-delete">
                                  <p className="modal-title">
                                      Are you sure you want to save changed data? Previous data
                                      will be lost and replaced.
                                  </p>
                                  <div className="modal-buttons">
                                      <button
                                          className="modal-buttons-cancel"
                                          onClick={toggleCloseModal}
                                      >
                                          Cancel
                                      </button>
                                      <button
                                          className="modal-buttons-delete"
                                          onClick={sendEditedCellsHandler}
                                      >
                                          Yes
                                      </button>
                                  </div>
                              </div>
                          ) : modalType === 'error' ? (
                              <div className="modal__body">
                                  <p className="modal-message">{error}</p>
                              </div>
                          ) : modalType === 'forceUpdate' ? (
                              <div className="modal__body">
                                  <div className="modal-delete">
                                      <p className="modal-title">
                                          There is a difference between old structure of this
                                          program and new. Are you sure you want to do "hard" edit
                                          for this program ? Progress will be lost
                                      </p>
                                      <div className="modal-buttons">
                                          <button
                                              className="modal-buttons-cancel"
                                              onClick={toggleCloseModal}
                                          >
                                              Cancel
                                          </button>
                                          <button
                                              className="modal-buttons-delete"
                                              onClick={forceUpdateProgramHandler}
                                          >
                                              Yes
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          ) : (
                              ''
                          )}
                      </div>
                  </div>
              </Modal>
          </S.Wrapper>
      </>
  );
};

export default ClientPhases;
