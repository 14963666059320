import * as React from 'react';
import TextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles(() => ({
  dropdownStyle: {
    '& .MuiAutocomplete-listbox, .MuiAutocomplete-paper, .MuiAutocomplete-noOptions':
      {
        background: '#23262B',
        boxShadow: '0px 8px 20px rgba(16, 17, 19, 0.6)',
        borderRadius: '8px',
        fontSize: '15px',
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        border: '1px solid #191B1F',
      },

    zIndex: 1301,
  },
}));

const Autocomplete = ({ options, value, onChange }) => {
  const classes = useStyles();

  return (
    <MuiAutocomplete
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      className={'autocomplete-wrapper'}
      id="combo-box-demo"
      options={options}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} className="autocomplete-input" />
      )}
      getOptionLabel={(option) => option.name ?? option}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e, name) => onChange(e, name)}
      PopperComponent={(props) => (
        <Popper
          {...props}
          className={classes.dropdownStyle}
          placement="bottom"
        />
      )}
    />
  );
}

Autocomplete.displayName = 'Autocomplete'

export default Autocomplete;
