import React, { memo, ReactNode } from 'react';
import Button from 'components/_new/Button';
import CircularProgress from 'components/_new/CircularProgress';
import { Modal } from '../../Modal';
import styles from './styles.module.scss';

type Props = {
  show: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onClose?: () => void;
  onCloseOnOutsideClick?: () => void;
  title?: ReactNode;
  cancelButtonText?: string;
  submitButtonText?: string;
  isLoading?: boolean;
  isSubmitButtonDangerous?: boolean;
};

export const ConfirmModal: React.FC<Props> = memo(
  ({
    show,
    onCancel,
    title = 'Are you sure?',
    onSubmit,
    submitButtonText = 'Submit',
    cancelButtonText = 'Cancel',
    isLoading = false,
    onCloseOnOutsideClick = onCancel,
    onClose = onCancel,
    isSubmitButtonDangerous = false,
  }: Props): JSX.Element => {
    const submitButtonStyles = isSubmitButtonDangerous
      ? { background: '#FF453A', color: '#fff' }
      : {};
    return (
      <Modal
        show={show}
        onClose={!isLoading ? onClose : undefined}
        onCloseOnOutsideClick={onCloseOnOutsideClick}
      >
        <div className={styles.body}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <h3 className={styles.title}>{title}</h3>
              <div className={styles.buttonsWrapper}>
                <Button onClick={onCancel} themeType="dark">
                  {cancelButtonText}
                </Button>
                <Button style={submitButtonStyles} onClick={onSubmit}>
                  {submitButtonText}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  },
);

ConfirmModal.displayName = 'ConfirmModal';
