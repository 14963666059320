import React, {
  createContext, ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

interface ILayoutState {
  headerCountTitle: number;
  headerTitle: string;
  routePath?: { title: string };
  additionalUI?: ReactElement;
}

export interface LayoutContextType extends ILayoutState {
  isSidebarCollapsed: boolean;
  initLayoutState: (layoutState: ILayoutState) => void;
  setIsSidebarCollapsedHandler: () => void;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [layoutState, setLayoutState] = useState<ILayoutState>({
    headerCountTitle: 0,
    headerTitle: '',
  });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  const setIsSidebarCollapsedHandler = useCallback(
    () => setIsSidebarCollapsed((prev) => !prev),
    [],
  );

  const initLayoutState = useCallback(
    ({ headerTitle, routePath, headerCountTitle, additionalUI }: ILayoutState) => {
      setLayoutState((prevState) => ({
        ...prevState,
        headerTitle,
        routePath,
        headerCountTitle,
        additionalUI,
      }));
    },
    [],
  );

  const value = useMemo(
    () => ({
      ...layoutState,
      isSidebarCollapsed,
      initLayoutState,
      setIsSidebarCollapsedHandler,
    }),
    [
      layoutState,
      isSidebarCollapsed,
      initLayoutState,
      setIsSidebarCollapsedHandler,
    ],
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
