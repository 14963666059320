import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { noop } from 'utils';
import { useHistory } from 'react-router';
import { ROUTES } from 'containers/app/trainer/routes';
import Loader from 'components/loader/Loader';
import { setFeedbackBannerShowNextTimeToLocalStorage } from 'containers/app/trainer/feedbackNotificationBanner/utils';

const LazyChatOnboarding = React.lazy(
  () => import('pages/ChatPage/chatOnboarding/ChatOnboarding'),
);

export const IS_ONBOARDING_HIDDEN = 'isOnboardingHidden';
const DAYS_AFTER_ONBOARDING_TO_SHOW_FEEDBACK_BANNER = 1;

const initialState: {
  isOnboardingShown: boolean;
  onSetIsOnboardingShown: (show: boolean) => void;
} = {
  isOnboardingShown: false,
  onSetIsOnboardingShown: noop,
};

export const OnboardingContext = createContext(initialState);

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [isOnboardingShown, setIsOnboardingShown] = useState(false);

  const history = useHistory();

  const isSetHiddenInStorage =
    localStorage.getItem(IS_ONBOARDING_HIDDEN) === 'true';

  useEffect(() => {
    if (!isSetHiddenInStorage && history.location.pathname === ROUTES.CHAT) {
      setIsOnboardingShown(true);
    }
  }, [isSetHiddenInStorage, history.location.pathname]);

  const onSetIsOnboardingShown = (show: boolean) => {
    setIsOnboardingShown(show);

    if (!isSetHiddenInStorage && !show) {
      setFeedbackBannerShowNextTimeToLocalStorage(
        DAYS_AFTER_ONBOARDING_TO_SHOW_FEEDBACK_BANNER,
      );

      localStorage.setItem(IS_ONBOARDING_HIDDEN, 'true');
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        isOnboardingShown,
        onSetIsOnboardingShown,
      }}
    >
      {children}
      <React.Suspense fallback={<Loader />}>
        <LazyChatOnboarding />
      </React.Suspense>
    </OnboardingContext.Provider>
  );
};
