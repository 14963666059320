// TODO: refactor all component V3
import React, { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router';
import CustomMenu, { TMenuItem } from '../menu';
import { UserContext } from '../../providers/userProvider';
import NotificationsMenu from '../notifications';
import {
    getApiData,
    putData,
} from '../../lib/api/utils';
import { ClientsContext } from '../../providers/currentClients';
import { NotificationsContext } from '../../providers/notificationsProvider';
import { OnboardingContext } from '../../providers/OnboardingProvider';
import { EditProfileModal } from './components/editProfileModal/EditProfileModal';
import { Feedback } from '../feedback/Feedback';
import {
    CHANGE_PASSWORD_ID, EDIT_PROFILE_ID,
    LOGOUT_ID, PERSONAL_BRANDING_ID,
    TRAINER_PROFILE_ACTION_ITEMS,
    USER_PROFILE_ACTION_ITEMS,
} from './constants';
import gaEvents from '../../utils/gaEvents';
import { LogoutModal } from './components/logoutModal/LogoutModal';
import { ChangePasswordModal } from './components/changePasswordModal/ChangePasswordModal';
import { Tooltip } from '../tooltip/Tooltip';
import { HEADER } from '../../constants/tooltips';

import type { TNotification } from './models';

import {
    LogoIc,
    NotificationsIc,
    OnboardingFlagIc,
    ProfileActionsIc
} from '../../assets/svg';
import userPhoto from '../../assets/userPhoto.png';
import S from './Header.styled'

import '../styles/_modal.scss';
import { PersonalBrandingModal } from './components/personalBrandingModal/PersonalBrandingModal';

interface IHeaderProps {
    headerCount: number;
    headerTitle: string;
    routePath: { title: string };
    additionalUI?: ReactElement;
}

export const Header: FC<IHeaderProps> = ({
    headerCount,
    headerTitle,
    routePath,
    additionalUI = <></>
}) => {
    const { signOut, user, onSetUser } = useContext(UserContext);
    const { onSetClients } = useContext(ClientsContext);
    const { notifications, onSetNotifications } =
        useContext(NotificationsContext);
    const { onSetIsOnboardingShown } = useContext(OnboardingContext);
    const history = useHistory();

    const [modalType, setModalType] = useState('');

    useEffect(() => {
        if (!notifications) {
            loadNotificationsHandler();
        }
    }, []);

    useEffect(() => {
        loadNotificationsHandler();
    }, [user]);

    const loadNotificationsHandler = () => {
        getApiData('Notification').then((answer) => {
            if (answer?.success) {
                onSetNotifications(answer.items);
            }
        });
    };

    const loadUserInfo = () => {
        getApiData('Account/me').then((answer) => {
            if (answer?.success) {
                onSetUser({
                    ...answer.item,
                    roles: localStorage.getItem('roles')
                        ? JSON.parse(localStorage.getItem('roles') || '')
                        : null,
                });
            }
        });
    };

    const toggleOpenModal = useCallback((type: string) => {
        setModalType(type);
    }, []);

    const toggleCloseModal = useCallback(() => {
        setModalType('');
    }, []);

    const clickOnMenuItemHandler = (item: TMenuItem) => {
        switch (item.id) {
            case LOGOUT_ID:
                gaEvents.openLogoutModalFromHeader();
                toggleOpenModal(LOGOUT_ID);
                break;
            case PERSONAL_BRANDING_ID:
                gaEvents.openPersonalBrandingModalFromHeader();
                toggleOpenModal(PERSONAL_BRANDING_ID);
                break;
            case CHANGE_PASSWORD_ID:
                gaEvents.openChangePasswordModalFromHeader();
                toggleOpenModal(CHANGE_PASSWORD_ID);
                break;
            case EDIT_PROFILE_ID:
                gaEvents.openEditProfileModalFromHeader();
                toggleOpenModal(EDIT_PROFILE_ID);
                break;
            default:
                break;
        }
    };

    const notificationsHandler = async (notification: TNotification) => {
        gaEvents.clickOnNotificationItemFromList({ isRead: notification.isRead });

        if (!notification.isRead) {
            const answer = await putData(`Notification/read/${notification.id}`);

            if (answer?.success) {
                loadNotificationsHandler();
            }
        }
    };

    const logOutHandler = () => {
        signOut();
        onSetClients?.([]);
    };

    // TODO - can we delete it and use only options for Trainers role?
    // Are we supporting another roles in this app ?
    const isTrainer = user?.roles?.some((role) => role === 'Trainers');

    const onOpenOnboarding = () => {
        onSetIsOnboardingShown(true);

        gaEvents.openOnboardingFromHeader();
    };

    const onClickOnNotificationIcon = () => {
        gaEvents.openNotificationsFromHeader();
    };
    const onClickOnProfileActionsIcon = () => {
        gaEvents.clickOnProfileActionsArrowFromHeader();
    };

    const onClickOnGoBackButton = () => {
        gaEvents.clickOnArrowWithBackRouteFromHeader({ goTo: routePath.title });
        history.goBack();
    }

    return (
        <S.Wrapper>
            <div className="logo">
                <LogoIc className="logo__icon" />
            </div>
            {headerCount ? (
                <p className="count">{headerCount}</p>
            ) : routePath ? (
                <button onClick={onClickOnGoBackButton} className="backButton">
                    {routePath.title}
                </button>
            ) : (
                ''
            )}
            <p className='title'>{headerTitle}</p>
            {additionalUI}
            <div className="feedback">
                <Feedback />
            </div>
            <Tooltip title={HEADER.ONBOARDING}>
                <button onClick={onOpenOnboarding} className="actionIcon">
                    <OnboardingFlagIc />
                </button>
            </Tooltip>
            {notifications && notifications.length ? (
                <>
                    <Tooltip title={HEADER.NOTIFICATIONS}>
                        <div
                            className={`actionIcon notifications ${
                                notifications?.some((notification) => !notification.isRead) && 'new'
                            }`}
                        >
                            <NotificationsMenu
                                clickOnMenuItem={notificationsHandler}
                                menuItems={notifications}
                                button={<NotificationsIc onClick={onClickOnNotificationIcon} className='notifications__icon' />}
                            />
                        </div>
                    </Tooltip>
                    {isTrainer && user ? (
                        <div className='user'>
                            <div
                                className='user__photo'
                                style={{ backgroundImage: `url(${user.photoUrl || userPhoto})` }}
                            />
                            <p>{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className='profileActions'>
                        <CustomMenu
                            clickOnMenuItem={clickOnMenuItemHandler}
                            menuItems={
                                isTrainer
                                    ? TRAINER_PROFILE_ACTION_ITEMS
                                    : USER_PROFILE_ACTION_ITEMS
                            }
                            button={
                                isTrainer ? (
                                    <ProfileActionsIc onClick={onClickOnProfileActionsIcon} className='profileActions__icon' />
                                ) : (
                                    <ReactSVG
                                        src='/assets/svg/settings.svg'
                                        className='profileActions__icon'
                                    />
                                )
                            }
                        />
                    </div>
                </>
            ) : (
                ''
            )}
            {modalType === LOGOUT_ID && (
                <LogoutModal
                  logOutHandler={logOutHandler}
                  toggleCloseModal={toggleCloseModal}
                />
            )}
            {modalType === PERSONAL_BRANDING_ID && (
                <PersonalBrandingModal toggleCloseModal={toggleCloseModal} />
            )}
            {modalType === CHANGE_PASSWORD_ID && (
                <ChangePasswordModal
                  toggleCloseModal={toggleCloseModal}
                />
            )}
            {modalType === EDIT_PROFILE_ID && user && (
              <EditProfileModal
                user={user}
                loadUserInfo={loadUserInfo}
                toggleCloseModal={toggleCloseModal}
              />
            )}
        </S.Wrapper>
    );
};
