import React, { useCallback, useRef, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import Modal from '@material-ui/core/Modal';
import ModalNew from 'components/_new/Modal';
import TextInput from 'components/fields/textInput';
import RadioButtons from 'components/radioButton';
import '../styles.scss';
import 'components/styles/_modal.scss';
import {
    getApiData,
    patchData,
    postData,
    putData,
} from 'lib/api/utils';
import CircularProgress from 'components/_new/CircularProgress';
import { useSnackbar } from 'notistack';

const CreateProgramModal = ({
    client,
    setNewProgram,
    // loaderHandler,
    showModal,
    toggleCloseModal,
    questionnaire,
    modalType,
    forceUpdateProgramModal,
    setShowExitPrompt
}) => {
    const [activeModalPage, setActiveModalPage] = useState(0);
    const [answers, setAnswers] = useState({});
    const [oldAnswers, setOldAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const modalRef = useRef(null);

    const getAnswers = () => {
        getApiData(`Answer/${client.user.id}`).then((answer) => {
            if (answer?.success) {
                setOldAnswers(answer.items);
            } else {
                setOldAnswers(null);
            }
        });
    }

    useEffect(() => {
        if (oldAnswers && !oldAnswers.length) {
            getAnswers();
        }
    }, []);

    useEffect(() => {
        if (questionnaire && questionnaire.length) {
            loadStateFields();
        }
    }, [questionnaire, oldAnswers]);

    useEffect(() => {
        if (modalRef && modalRef.current) {
            modalRef.current.scrollTop = 0;
        }
    }, [modalRef]);

    const closeModal = useCallback(() => {
        toggleCloseModal();
        setActiveModalPage(0);
    }, []);

    const loadStateFields = () => {
        const answerFields = {};

        questionnaire.forEach((categorie) => {
            answerFields[categorie.id] = [];
            categorie.questions.forEach((question) => {
                let answer = '';
                let answerId = 0;

                if (oldAnswers && oldAnswers.length) {
                    oldAnswers.forEach((oldAnswer) => {
                        if (oldAnswer.questionId === question.id) {
                            answer = oldAnswer.value;
                            answerId = oldAnswer.id;
                        }
                    });
                }
                answerFields[categorie.id].push({
                    key: question.key,
                    answer: question.formula ? question.formula : answer,
                    answerId,
                    id: question.id,
                });
            });
        });
        setAnswers(answerFields);
    };

    const questionsHandler = (e, key, categoryName) => {
        const categoryArray = answers[categoryName].map((question) => {
            if (question.key === key) {
                return {
                    ...question,
                    answer: e.target.value.split('-&&&')[0],
                };
            }

            return question;
        });

        setAnswers({
            ...answers,
            [categoryName]: categoryArray,
        });
    };

    const checkDisabling = () => {
        return !Object.values(answers[questionnaire[activeModalPage].id]).every(
            (field) => !!field.answer
        );
    };

    const createProgramHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // toggleCloseModal();

        let allAnswers = [];

        Object.keys(answers).forEach((windows) => {
            allAnswers = [...allAnswers, ...answers[windows]];
        });
        const finalAnswers = allAnswers.map((answer) => {
            const returnedObj = {
                value: answer.answer,
                questionId: answer.id,
                clientId: client.user.id,
            };

            if (oldAnswers && oldAnswers.length) {
                returnedObj.id = answer.answerId;
            }

            return { ...returnedObj };
        });

        setShowExitPrompt?.(true);
        if (oldAnswers && oldAnswers.length) {
            await patchData('Answer', { answers: finalAnswers }).then(async (answer) => {
                if (answer?.success) {
                    setOldAnswers(answer.items);

                    if (modalType === 'create') {
                        await postData('Program', { clientId: client.user.id }).then((answer) => {
                            if (answer?.success) {
                                setNewProgram();
                                enqueueSnackbar(
                                    `Program was successfully created.`,
                                    { variant: 'success' },
                                );
                            } else {
                                enqueueSnackbar( `Error - ${answer}`,
                                    { variant: 'error',},
                                );
                            }
                        });
                        setIsLoading(false);
                        toggleCloseModal();

                      
                    }
                    if (modalType === 'edit') {
                        putData('Program', { clientId: client.user.id }).then((answer) => {
                            if (answer?.success) {
                                setNewProgram();
                                enqueueSnackbar(
                                    `Program was successfully edited.`,
                                    { variant: 'success' },
                                );
                            } else {
                                forceUpdateProgramModal();
                            }

                            setIsLoading(false);
                            toggleCloseModal();
                    });
                    }
                } else {
                    setIsLoading(false);
                    toggleCloseModal();
                }
            });
        } else {
            await postData('Answer', { answers: finalAnswers }).then((answer) => {
                if (answer?.success) {
                    setOldAnswers(answer.items);
                    postData('Program', { clientId: client.user.id }).then((answer) => {
                        if (answer?.success) {
                            setNewProgram();
                            enqueueSnackbar(
                                `Program was successfully created.`,
                                { variant: 'success' },
                            );
                        } else {
                            alert(`Error - ${answer}`);
                        }

                        setIsLoading(false);
                        toggleCloseModal();
                    });
                } else {
                    setIsLoading(false);
                    toggleCloseModal();
                    alert(`Error - ${answer}`);
                }
            });
        }
        setShowExitPrompt?.(false);
    };

    const goToNextScreen = () => {
        setActiveModalPage(activeModalPage + 1);
        modalRef.current.scrollTop = 0;
    };

    const goToPreviousScreen = () => {
        setActiveModalPage(activeModalPage - 1);
        modalRef.current.scrollTop = 0;
    };

    let counter = 0;
    

    if (isLoading) {
      return (
        <ModalNew show={true}>
          <div className="create-program-loader">
            <CircularProgress />
            <div className="create-program-loader-title">
              Program generation in progress
            </div>
            <div className="create-program-loader-subtitle">
              Please do not close the page until generation is complete
            </div>
          </div>
        </ModalNew>
      );
    }

    return (
        <Modal onClose={closeModal} open={showModal}>
            <div className='modal' ref={modalRef}>
                <div className='modal__close modal__body--sm'>
                    <ReactSVG onClick={closeModal} src='/assets/svg/close.svg' />
                </div>
                <div className='modal__body'>
                    <p className='modal-title'>
                        {modalType === 'create' ? 'Create program' : 'Edit program'}
                    </p>
                    {questionnaire && questionnaire.length && Object.keys(answers).length ? (
                        <div>
                            {questionnaire.map((category, i) => {
                                return (
                                    <p
                                        className={`client-no-modal-category ${
                      activeModalPage === i ? 'active' : ''
                    }`}
                                        key={`${category.id} + ${i}`}
                                    >
                                        {category.title}
                                    </p>
                                );
                            })}
                            <ul className='client-no-modal-list'>
                                {questionnaire[activeModalPage].questions.map(
                                    (question, questionIndex) => {
                                        if (!question.formula) {
                                            counter = counter + 1;
                                        }
                                        let inputValue = '';

                                        answers[questionnaire[activeModalPage].id].forEach(
                                            (field) => {
                                                if (field.key === question.key) {
                                                    inputValue = field.answer;
                                                }
                                            }
                                        );

                                        return question.formula ? (
                                            ''
                                        ) : (
                                            <li
                                                className='client-no-modal-item'
                                                key={`${question.id}-${questionIndex}`}
                                            >
                                                <p className='client-no-modal-item-title'>
                                                    {`${counter} ${question.title}`}
                                                </p>
                                                <div className='client-no-modal-item-body'>
                                                    {question.options && question.options.length ? (
                                                        <RadioButtons
                                                            onchange={(e) =>
                                                                questionsHandler(
                                                                    e,
                                                                    question.key,
                                                                    questionnaire[activeModalPage].id
                                                                )
                                                            }
                                                            isNotChecked
                                                            radioArray={question.options.map((variant) => {
                                                                return variant.value;
                                                            })}
                                                            value={inputValue}
                                                        />
                                                    ) : (
                                                        <TextInput
                                                            value={inputValue}
                                                            onChange={(e) =>
                                                                questionsHandler(
                                                                    e,
                                                                    question.key,
                                                                    questionnaire[activeModalPage].id
                                                                )
                                                            }
                                                            type='text'
                                                            placeholder=''
                                                        />
                                                    )}
                                                </div>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                            {activeModalPage === questionnaire.length - 1 ? (
                                // <div className="client-no-modal-create">
                                //   <button
                                //     onClick={createProgramHandler}
                                //     className="modal-button"
                                //     disabled={checkDisabling()}
                                //   >
                                //     Create program
                                //   </button>
                                // </div>
                                <div className='modal-buttons main'>
                                    <button
                                        className='modal-buttons-cancel'
                                        onClick={goToPreviousScreen}
                                    >
                                        Back
                                    </button>
                                    <button
                                        onClick={createProgramHandler}
                                        disabled={checkDisabling()}
                                        className='modal-buttons-apply main-button'
                                    >
                                        {modalType === 'create' ? 'Create program' : 'Edit program'}
                                    </button>
                                </div>
                            ) : (
                                <div className={'client-no-modal-buttons'}>
                                    {activeModalPage !== 0 ? (
                                        <button
                                            className='client-no-modal-buttons-prev'
                                            onClick={goToPreviousScreen}
                                        >
                                            <ReactSVG src={'/assets/svg/arrowNext.svg'} /> Back
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                    <button
                                        className='client-no-modal-buttons-next'
                                        disabled={checkDisabling()}
                                        onClick={goToNextScreen}
                                    >
                                        Go to the next step
                                        <ReactSVG src={'/assets/svg/arrowNext.svg'} />
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default CreateProgramModal;
