import React, { useMemo } from 'react';
import { useHandlers } from 'components/_new/phase/sub/PhaseWeek/hooks/useHandlers';
import { ChatIcon } from 'components/_new/phase/sub/ChatIcon/ChatIcon';
import { WeekDataGrid } from 'components/_new/phase/sub/WeekDataGrid/WeekDataGrid';
import { Tooltip } from 'components/tooltip/Tooltip';

import type { IPhaseWeekProps } from 'components/_new/phase/sub/PhaseWeek/models';

import S from './Week.styled';

export const Week = ({
                       week,
                       weekIndex,
                       navigatedExerciseReference,
                       exerciseReference,
                       exerciseName,
                     }: IPhaseWeekProps) => {
  const { isDeleted, exerciseRows, isCompleted } = week;
  const {
    navigatedWeekRef,
    generalExerciseReference,
    isGeneratingReferenceDisabled,
    generateReferenceHandler,
    onShowPopoverCallback,
    isHighlighted,
  } = useHandlers({
    navigatedExerciseReference,
    exerciseReference,
    exerciseName,
    weekIndex,
    week,
  });

  const formattedValue = useMemo(() => {
    const reps: number[] = [];
    const weights: number[] = [];
    const firstRepValue = exerciseRows[0].reps;
    const firstWeightValue = exerciseRows[0].weight;

    exerciseRows.forEach((row) => {
      reps.push(row.reps);
      weights.push(row.weight);
    });

    const hasRepsAllSameValues = reps.every((rep) => rep === firstRepValue);
    const hasWeightAllSameValues = weights.every(
      (weight) => weight === firstWeightValue,
    );

    // if reps or weight for each set is the same - to show only once this value
    // if no - join array of reps/weight and show in row with commas
    // if reps for each set is the same - also show sets number in format {sets}x{reps}
    const finalRepsValue = hasRepsAllSameValues
      ? `${exerciseRows.length}x${firstRepValue}`
      : reps.join(',');
    const finalWeightValue = hasWeightAllSameValues
      ? firstWeightValue
      : weights.map((weight) => (weight === 0 ? '-' : weight));
    // if finalWeightValue isn't 0 - show '@' symbol with weights data
    // and if no - without second part (weights data)
    return `${finalRepsValue} ${
      finalWeightValue ? `@ ${finalWeightValue}` : ''
    }`;
  }, [exerciseRows]);

  const tooltipWeekData = (
    <S.TooltipWrapper>
      <WeekDataGrid isDeleted={isDeleted} exerciseRows={exerciseRows} />
    </S.TooltipWrapper>
  );

  return (
    <S.Wrapper ref={navigatedWeekRef} id={'scrolling'}>
      <S.DataWrapper
        isHighlighted={isHighlighted}
        isCompleted={isCompleted}
        weekIndex={weekIndex}
      >
        {!isDeleted && (
          <div className="data">
            <Tooltip title={tooltipWeekData}>
              <p className="previewValue">{formattedValue}</p>
            </Tooltip>
            <ChatIcon
              generalExerciseReference={generalExerciseReference}
              isGeneratingReferenceDisabled={isGeneratingReferenceDisabled}
              generateReferenceHandler={generateReferenceHandler}
              onShowPopoverCallback={onShowPopoverCallback}
              styles={{ marginLeft: 'auto' }}
            />
          </div>
        )}
      </S.DataWrapper>
    </S.Wrapper>
  );
};
