import React from 'react';
import { useHandlers } from 'components/_new/phase/sub/PhaseExercise/hooks/useHandlers';
import { ColoredLabel } from 'components/coloredLabel/ColoredLabel';
import { Tooltip } from 'components/tooltip/Tooltip';
import { PROGRAM } from 'constants/tooltips';
import { isChatPage } from '../../../utils';
import { Week } from '../week/Week';

import type { IPhaseExerciseProps } from 'components/_new/phase/sub/PhaseExercise/models';

import { FilterPlusIc } from 'assets/svg';
import S from './Exercise.styled';

export const Exercise = ({
  exercise,
  isLastExercise,
  complexExerciseIndex,
  complexExerciseLetter,
  onSwapComplexExercise,
  isSuperset,
  complexExerciseReference,
  navigatedExerciseReference,
  programId,
}: IPhaseExerciseProps) => {
  const { weeks, exerciseInfo } = exercise;

  const { exerciseName, onGenerateChatFilter, exerciseReference } = useHandlers(
    {
      exercise,
      complexExerciseIndex,
      complexExerciseLetter,
      complexExerciseReference,
      onSwapComplexExercise,
      programId,
    },
  );

  return (
    <S.Wrapper>
      <S.ActionsWrapper>
        <div className="coloredLabelWrapper">
          <ColoredLabel index={complexExerciseIndex}>
            {exerciseName}
          </ColoredLabel>
        </div>
        {isSuperset && !isLastExercise && <div className="marker notLast" />}
        {!isLastExercise && <div className="marker" />}
      </S.ActionsWrapper>

      <S.Body>
        <S.Content>
          <p className="title">{exerciseInfo.name}</p>
          {exercise.description && (
            <div className="exerciseDescription">
              <Tooltip title={exercise.description}>
                <p className="exerciseDescriptionValue">
                  {exercise.description}
                </p>
              </Tooltip>
            </div>
          )}
          {isChatPage() && (
            <Tooltip title={PROGRAM.FILTER_BUTTON}>
              <button className="filter" onClick={onGenerateChatFilter}>
                <FilterPlusIc />
              </button>
            </Tooltip>
          )}
        </S.Content>

        <S.WeeksWrapper>
          {weeks.map((week, weekIndex) => (
            <Week
              week={week}
              key={week.exerciseId}
              navigatedExerciseReference={navigatedExerciseReference}
              exerciseReference={exerciseReference}
              weekIndex={weekIndex}
              exerciseName={exerciseName}
            />
          ))}
        </S.WeeksWrapper>
      </S.Body>
    </S.Wrapper>
  );
};