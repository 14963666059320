import React, { ChangeEvent, FC, memo } from 'react';
import Label from '../../label/label';

import { ReactSVG } from 'react-svg';
import './styles.scss';

interface ITextInputProps {
  placeholder?: string;
  type?: string;
  name?: string;
  iconPath?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  noneRightBorder?: boolean;
  value: string;
  radio?: boolean;
  disabled?: boolean;
  label?: string;
  required?: boolean;
}

const TextInput: FC<ITextInputProps> = ({
  placeholder,
  type,
  name,
  iconPath,
  onChange,
  onBlur,
  noneRightBorder,
  value,
  radio,
  disabled,
  label,
  required,
}) => {
  return (
    <div className="input-block">
      {iconPath ? (
        <ReactSVG
          className={`input-block-icon ${radio ? 'radio' : ''}`}
          src={iconPath}
        />
      ) : (
        ''
      )}
      {label && <Label label={label} required={required} htmlFor='input'/>}
      <input
        id="input"
        onChange={onChange}
        className={`input-block-field ${iconPath ? 'iconned' : ''} ${radio ? 'radio' : ''}`}
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        style={noneRightBorder ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
        disabled={disabled}
        title={disabled ? value : ''}
      />
    </div>
  );
};

export default memo(TextInput);

