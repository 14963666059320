import React from 'react';
import CircularProgress from '../_new/CircularProgress';

import S from './Loader.styled';

const Loader = ({ relatedToParent = false }) => {
  return (
    <S.Wrapper relatedToParent={relatedToParent}>
      <CircularProgress />
    </S.Wrapper>
  );
};

export default Loader;
