import styled from '@emotion/styled/macro';
import { transitionOnIconsHover } from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  padding: 26px 40px;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__search {
      display: flex;
      width: 100%;
      flex: 2;

      &--block {
        max-width: 475px;
        width: 100%;
      }

      &--filter {
        background: rgba(28, 31, 33, 0.9);
      }
    }

    &__button {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #1c1f21;
      background: linear-gradient(162.44deg, #ffef01 16.76%, #ffd600 89.31%);
      border-radius: 10px;
      padding: 19px 40px;
      border: none;
      cursor: pointer;
    }

    & .filter {
      margin: 0 12px 0 12px;
    }
  }

  & .list {
    margin: 50px 0;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
  }

  & .not-found {
    color: #6e7987;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #1c1f21;
  color: #fff;
  margin-left: 40px;
  padding: 8px;
  border-radius: 8px;

  & .infoIcon {
    cursor: pointer;
    color: #5f6975;
    ${transitionOnIconsHover};

    &:hover {
      color: #ffef01;
    }
  }
`;

export default { Wrapper, HeaderContent };
