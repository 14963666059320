import React, { FC } from 'react';
import Loader from 'components/loader/Loader';
import BiggerProgramPhase from './biggerProgramPhase/BiggerProgramPhase';
import { BiggerPictureWrapper } from '../components/biggerPictureWrapper/BiggerPictureWrapper';
import { useFetch } from './hooks/useFetch';

import type { IBiggerProgramProps } from '../models';

const BiggerProgram: FC<IBiggerProgramProps> = ({ bigProgram, ...props }) => {
  const onSuccessCallback = () => {
    getBPProgramMutation();
  };

  const {
    getBPProgramMutation,
    isLoadingGetBPProgram,

    deletePhaseMutation,
    isLoadingDeletePhase,
  } = useFetch({
    programId: props.programId,
    setBigProgram: props.setBigProgram,
    onAddPhaseSuccessCallback: onSuccessCallback,
    onDeletePhaseSuccessCallback: onSuccessCallback,
  });

  const loading =
    isLoadingGetBPProgram ||
    isLoadingDeletePhase;

  return (
    <BiggerPictureWrapper bigProgram={bigProgram} {...props}>
      <ul>
        {loading && <Loader/>}
        {bigProgram?.phases.map((phase, phaseIndex) => (
          <li key={phase.id}>
            <BiggerProgramPhase
              bigProgram={bigProgram}
              setDBDProgram={props.setDBDProgram}
              phase={phase}
              phaseIndex={phaseIndex}
              loadBigProgramHandler={getBPProgramMutation}
              onDeletePhase={deletePhaseMutation}
            />
          </li>
        ))}
      </ul>
    </BiggerPictureWrapper>
  );
};

export default BiggerProgram;
