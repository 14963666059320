import { useEffect, useState } from 'react';
import { getApiData, postData, putData, deleteApiData, deleteUploadedData } from 'lib/api/utils';
import instance from 'lib/api/axios';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from 'constants/index';

export type TExercise = {
  name: string;
  description: string;
  videoUrl: string;
  id: number;
  trainerAlternativeExerciseInfoId?: number;
  alternativeVideoUrl?: string;
};

type TState<T> = {
  data: T;
  isLoading: boolean;
  error: string;
};

type TUploadResponse = {
  item: { fileUrl: string };
  total: number;
  success: boolean;
};

const exerciseInfo = 'ExerciseInfo';

const useFetch = () => {
  const [exercises, setExercises] = useState<TState<TExercise[]>>({
    data: [],
    isLoading: false,
    error: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchExercises();
  }, []);

  async function fetchExercises() {
    setExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    await getApiData(`${exerciseInfo}`).then(
      ({ items }: { items: TExercise[] }) => {
        setExercises((prev) => ({ ...prev, data: items || [] }));
      },
    );

    setExercises((prev) => ({
      ...prev,
      isLoading: false,
    }));
  }

  async function fetchAddAlternativeVideo(id: number, value: string) {
    setExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const { success, item: resItem } = await postData(
      `${exerciseInfo}/add-trainer-alternative`,
      {
        exerciseInfoId: id,
        alternativeVideoUrl: value,
      },
    );

    if (success) {
      setExercises((prev) => ({
        ...prev,
        data: prev.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              alternativeVideoUrl: resItem.alternativeVideoUrl || value,
              trainerAlternativeExerciseInfoId:
                resItem.id || item.trainerAlternativeExerciseInfoId,
            };
          }

          return item;
        }),
      }));
    }

    setExercises((prev) => ({
      ...prev,
      isLoading: false,
    }));
  }

  async function fetchUpdateAlternativeVideo(
    exercise: Pick<TExercise, 'id' | 'trainerAlternativeExerciseInfoId'>,
    value: string,
  ) {
    const { id, trainerAlternativeExerciseInfoId } = exercise;

    const { success, item: resItem } = await putData(
      `${exerciseInfo}/update-trainer-alternative?id=${trainerAlternativeExerciseInfoId}`,
      { alternativeVideoUrl: value },
    );

    if (success) {
      setExercises((prev) => ({
        ...prev,
        data: prev.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              alternativeVideoUrl: resItem.alternativeVideoUrl || value,
              trainerAlternativeExerciseInfoId:
                resItem.id || item.trainerAlternativeExerciseInfoId,
            };
          }

          return item;
        }),
      }));
    }
  }

  async function DeleteFile(alternativeUrl: string | undefined) {
    setExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      await deleteUploadedData(`File/delete`, { data: { containerType: 0, fileUrl: alternativeUrl } });
    } catch (err) {
      const error = err as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' });
      }
    }

    setExercises((prev) => ({
      ...prev,
      isLoading: false,
    }));
  }

  async function fetchDeleteAlternativeVideo(
    exercise: Pick<TExercise, 'id' | 'trainerAlternativeExerciseInfoId'>,
  ) {
    setExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const { id, trainerAlternativeExerciseInfoId } = exercise;

    const { success } = await deleteApiData(
      `${exerciseInfo}/delete-trainer-alternative?id=${trainerAlternativeExerciseInfoId}`,
    );

    if (success) {
      setExercises((prev) => ({
        ...prev,
        data: prev.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              alternativeVideoUrl: '',
            };
          }

          return item;
        }),
      }));

      setExercises((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  }

  
  async function UploadFile(file: File) {
    setExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await instance.post<TUploadResponse>(
        `File/upload?Data.ContainerType=0`,
        formData,
      );

      return data.item.fileUrl;
    } catch (err) {
      const error = err as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' });
      }
    }

    setExercises((prev) => ({
      ...prev,
      isLoading: false,
    }));

    return null;
  }

  return {
    exercises,
    UploadFile,
    fetchAddAlternativeVideo,
    fetchUpdateAlternativeVideo,
    DeleteFile,
    fetchDeleteAlternativeVideo,
  };
};

export default useFetch;
