import { useCallback, useReducer } from 'react';
import {
    createCategory as createCategoryService,
    updateCategory as updateCategoryService,
    deleteCategory as deleteCategoryService,
    getCategories as getCategoriesService,
} from 'lib/api/services/strengthSystem/category';

const initialState = {
    dataCategories: [],
    errorCategories: null,
    isLoadingCategories: false,
    errorCreateCategory: null,
    isLoadingCreateCategory: false,
    errorUpdateCategory: null,
    isLoadingUpdateCategory: false,
    errorDeleteCategory: null,
    isLoadingDeleteCategory: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setDataCategories':
            return { ...state, dataCategories: action.payload };
        case 'setErrorCategories':
            return { ...state, errorCategories: action.payload };
        case 'setIsLoadingCategories':
            return { ...state, isLoadingCategories: action.payload };
        case 'setErrorCreateCategory':
            return { ...state, errorCreateCategory: action.payload };
        case 'setIsLoadingCreateCategory':
            return { ...state, isLoadingCreateCategory: action.payload };
        case 'setErrorUpdateCategory':
            return { ...state, errorUpdateCategory: action.payload };
        case 'setIsLoadingUpdateCategory':
            return { ...state, isLoadingUpdateCategory: action.payload };
        case 'setErrorDeleteCategory':
            return { ...state, errorDeleteCategory: action.payload };
        case 'setIsLoadingDeleteCategory':
            return { ...state, isLoadingDeleteCategory: action.payload };
        default:
            return state;
    }
};

export const useCategories = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const getCategories = useCallback(async () => {
        dispatch({ type: 'setErrorCategories', payload: null });
        dispatch({ type: 'setIsLoadingCategories', payload: true });

        try {
            const { items } = await getCategoriesService();

            dispatch({ type: 'setDataCategories', payload: items });
            dispatch({ type: 'setIsLoadingCategories', payload: false });

            return items;
        } catch (err) {
            dispatch({ type: 'setErrorCategories', payload: err });
            dispatch({ type: 'setIsLoadingCategories', payload: false });

            throw new Error(err);
        }
    }, []);

    const createCategory = useCallback(async (categoryData) => {
        dispatch({ type: 'setErrorCreateCategory', payload: null });
        dispatch({ type: 'setIsLoadingCreateCategory', payload: true });

        try {
            const response = await createCategoryService(categoryData);

            dispatch({ type: 'setIsLoadingCreateCategory', payload: false });


            return response;
        } catch (err) {
            dispatch({ type: 'setErrorCreateCategory', payload: err });
            dispatch({ type: 'setIsLoadingCreateCategory', payload: false });

            throw new Error(err);
        }
    }, []);

    const updateCategory = useCallback(async (categoryId, categoryData) => {
        dispatch({ type: 'setErrorUpdateCategory', payload: null });
        dispatch({ type: 'setIsLoadingUpdateCategory', payload: true });

        try {
            const response = await updateCategoryService(categoryId, categoryData);

            dispatch({ type: 'setIsLoadingUpdateCategory', payload: false });

            return response;
        } catch (err) {
            dispatch({ type: 'setErrorUpdateCategory', payload: err });
            dispatch({ type: 'setIsLoadingUpdateCategory', payload: false });

            throw new Error(err);
        }
    }, []);

    const deleteCategory = useCallback(async (categoryId) => {
        dispatch({ type: 'setErrorDeleteCategory', payload: null });
        dispatch({ type: 'setIsLoadingDeleteCategory', payload: true });

        try {
            const response = await deleteCategoryService(categoryId);

            dispatch({ type: 'setIsLoadingDeleteCategory', payload: false });

            return response;
        } catch (err) {
            dispatch({ type: 'setErrorDeleteCategory', payload: err });
            dispatch({ type: 'setIsLoadingDeleteCategory', payload: false });

            throw new Error(err);
        }
    }, []);

    return {
        isLoadingCategories: state.isLoadingCategories,
        errorCategories: state.errorCategories,
        dataCategories: state.dataCategories,
        getCategories,
        updateCategory,
        createCategory,
        deleteCategory,
        errorCreateCategory: state.errorCreateCategory,
        isLoadingCreateCategory: state.isLoadingCreateCategory,
        errorUpdateCategory: state.errorUpdateCategory,
        isLoadingUpdateCategory: state.isLoadingUpdateCategory,
        errorDeleteCategory: state.errorDeleteCategory,
        isLoadingDeleteCategory: state.isLoadingDeleteCategory,
    };
};
