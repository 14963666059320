import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import instance from 'lib/api/axios';

import { ERROR_MESSAGE } from 'constants/index';
import type { TMessage } from 'pages/ChatPage/models';

export type TCallbackOnSuccess = { callbackOnSuccess: (messages: TMessage[]) => void };

type TGetMessagesByExercisePayload = {
  programId: number;
  exerciseInfoId: number;
} & TCallbackOnSuccess;

type TGetMessagesByExerciseResponse = {
  data: {
    items: TMessage[];
  };
} & TCallbackOnSuccess;

export const useFetch = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: getMessagesByExerciseMutation,
    isLoading: isLoadingGetMessagesByExercise,
  } = useMutation<
    TGetMessagesByExerciseResponse,
    Error,
    TGetMessagesByExercisePayload
  >({
    mutationFn: async ({ programId, exerciseInfoId, callbackOnSuccess }) => {
      const { data } = await instance.get(
        `GeneralMessage/by-exercise-info/${programId}/${exerciseInfoId}`,
      );
      return {
        data,
        callbackOnSuccess,
      }
    },
    onSuccess: ({ data: { items }, callbackOnSuccess }) => {
      callbackOnSuccess(items);
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return {
    getMessagesByExerciseMutation,
    isLoadingGetMessagesByExercise,
  };
};
