import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { Tooltip } from 'components/tooltip/Tooltip';
import { EMOJI_UNICODE } from '../../constants';

import type { TDeleteMessagePayload, TReactOnMessageResponse } from '../../models';
import type { TReactions } from '../../../../models';

import { DeleteIc, ReplyIc } from 'assets/svg';
import S from './HoveredMenuWrapper.styled';

interface IHoveredMenuWrapper {
  messageId: string;
  isMessageFromCurrentUser?: boolean;
  reactions?: TReactions[];
  isDeleteTimerActivated: boolean;
  setIsDeleteTimerActivated: (timerActivated: boolean) => void;
  deleteMessageMutation: (response: TDeleteMessagePayload) => void;
  reactOnMessageMutation: (response: TReactOnMessageResponse) => void;
  onSuccessAddReaction: (response: TReactOnMessageResponse) => void;
  onReply: () => void;
  readOnly?: boolean;
  children: ReactElement;
}

export const DELETE_MESSAGE_TIMEOUT = 10000;

export const HoveredMenuWrapper: FC<IHoveredMenuWrapper> = ({
  messageId,
  isMessageFromCurrentUser,
  reactions,
  isDeleteTimerActivated,
  setIsDeleteTimerActivated,
  deleteMessageMutation,
  reactOnMessageMutation,
  onSuccessAddReaction,
  onReply,
  readOnly,
  children,
}) => {
  const deletionRequested = useRef(false);

  // TODO: fix it after adding support some reactions on one message
  const reaction = reactions?.[0]?.code;

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isDeleteTimerActivated) {
      timer = setTimeout(() => {
        deleteMessageMutation({ messageId });
      }, DELETE_MESSAGE_TIMEOUT);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isDeleteTimerActivated]);

  const handleUndo = () => {
    setIsDeleteTimerActivated(false);
    deletionRequested.current = false;
  };

  const onReactOnEmoji = (emoji: string) => {
    const newReaction = emoji === reaction ? '' : emoji;
    const payload = { messageId, reaction: newReaction };
    onSuccessAddReaction(payload);
    reactOnMessageMutation(payload);
  };

  const replyUI = !readOnly && (
    <button className="replyButton" onClick={onReply}>
      <ReplyIc />
    </button>
  );

  const tooltip = (
    <S.TooltipMenu>
      <div className="menu">
        {isMessageFromCurrentUser ? (
          <>
            {replyUI}
            {!readOnly && <div className="separator"></div>}
            <button
              className="deleteButton"
              onClick={() => setIsDeleteTimerActivated(true)}
            >
              <DeleteIc />
            </button>
          </>
        ) : (
          <>
            <div className="emojis">
              {EMOJI_UNICODE.map((emoji, index) => (
                <button
                  key={index}
                  className={`emoji ${emoji === reaction && 'isActive'}`}
                  onClick={() => onReactOnEmoji(emoji)}
                >
                  {emoji}
                </button>
              ))}
            </div>
            {!readOnly && <div className="separator"></div>}
            {replyUI}
          </>
        )}
      </div>
    </S.TooltipMenu>
  );

  const tooltipStyles = {
    borderRadius: '40px',
    margin: 0,
    // if it is messages rendered inside modal - to make zIndex bigger than modal zIndex
    zIndex: readOnly ? 9999 : 0,
  };

  if (isDeleteTimerActivated) {
    return (
      <div className="messageTextWrapper undo">
        <p className="undoMessage">This message is being deleted.</p>
        <button className="undoButton" onClick={handleUndo}>
          Undo
        </button>
        <div className="progressBar"></div>
      </div>
    );
  }

  return (
    <>
      <Tooltip
        placement={isMessageFromCurrentUser ? 'top-end' : 'top-start'}
        title={tooltip}
        styles={tooltipStyles}
        timeout={1000}
      >
        {children}
      </Tooltip>
    </>
  );
};
