import React, { FC } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import { IAutoSelectFieldProps } from './models';

const useStyles = makeStyles({
    listbox: {
        background: '#23262B',
        boxShadow: '0px 8px 20px rgba(16, 17, 19, 0.6)',
        borderRadius: '8px',
        fontSize: '15px',
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        border: '1px solid #191B1F',
    },
    option: {
        padding: '10px 20px',
        '&:hover': {
            background: '#191B1F',
            color: '#FFEF01',
        },
    },
    paper: {
        background: 'transparent',
        padding: '5px 0',
    },
});

export const AutoSelectField: FC<IAutoSelectFieldProps> = ({
    options,
    placeholder,
    onChange,
    value,
    disableClearable,
    defaultValue,
}) => {
    const classes = useStyles();

    return (
        <Autocomplete
            defaultValue={defaultValue}
            className="select"
            // getOptionSelected={(option, value) => option === value}
            value={value}
            options={options}
            onChange={(_, newValue) => {
                let id = '';

                if (newValue) {
                    options.forEach((opt) => {
                        if (opt.id === newValue.id) {
                          id = opt.id;
                        }
                    });
                    onChange({ newValue, id });
                } else {
                    onChange({ id });
                }
            }}
            classes={classes}
            disableClearable={!!disableClearable}
            getOptionLabel={(option) => (option.name)}
            renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
            renderInput={(params) => {
                return (
                    <TextField
                        className="select-field"
                        {...params}
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                        }}
                    />
                );
            }}
        />
    );
}
