import styled from '@emotion/styled/macro';
import MuiModal from '@material-ui/core/Modal';

const Modal = styled(MuiModal)`
  & .modalWrapper {
    display: flex;
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .modalBody {
    display: flex;
    max-height: 100%;
    margin: 30px;
    border-radius: 10px;
    color: white;
    background-color: #23262b;
    overflow: hidden;
    position: relative;
  }

  & .modalCloseIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 2;
  }
`;

export default { Modal };