import React, { ChangeEvent, useState } from 'react';
import ConfirmModal from 'components/_new/Modal/sub/ConfirmModal';
import { Tooltip } from 'components/tooltip/Tooltip';
import { avoidDecimalsInNumberInput, isUserPressedBackspace } from 'utils';
import {
  changeExerciseInputDataHandler,
  excludeSpecSymbolsForNumberInput,
  getOnlyIntegerValueFromNumberField,
  getOnlyIntegerValueFromRestField,
  getRPEValue,
  REPS_KEY,
  REST_KEY,
  RPE_KEY,
  SETS_KEY,
  WEIGHT_KEY,
} from '../../utils';
import gaEvents from 'utils/gaEvents';
import { isChatPage } from 'containers/app/trainer/currentClient/clientPhases/utils';
import { PROGRAM } from 'constants/tooltips';
import { TOOLTIP_STYLES } from '../../constants';

import type { TRowData, TWeekGroupProps } from '../../models';

import { ArrowTriangleIc, CopyIc, InfoIc } from 'assets/svg';
import S from '../WeekGroup/WeekGroup.styled';
import { Checkbox } from '../../../../checkbox/Checkbox';

const EXERCISE_FIELDS_DATA = {
  [SETS_KEY]: 1,
  [REPS_KEY]: 0,
  [WEIGHT_KEY]: 0,
  [REST_KEY]: '',
  [RPE_KEY]: '',
} as TRowData;

const DELTA_FIELDS_DATA = {
  [SETS_KEY]: '',
  [REPS_KEY]: '',
  [WEIGHT_KEY]: 0,
  [REST_KEY]: '',
  [RPE_KEY]: '',
} as TRowData;

const DEFAULT_VALUE_WEEK_4_NUMBER_OF_SETS = 2;
const MIN_VALUE_WEEK_4_NUMBER_OF_SETS = 1;
const MAX_VALUE_WEEK_4_NUMBER_OF_SETS = 10;

export const MasterRow = ({
  exerciseId,
  onSubmitExerciseMasterRow,
  modalId,
}: Pick<
  TWeekGroupProps,
  'onSubmitExerciseMasterRow' | 'exerciseId' | 'modalId'
>) => {
  const [masterRowData, setMaterRowData] =
    useState<TRowData>(EXERCISE_FIELDS_DATA);
  const [masterRowDeltas, setMaterRowDeltas] =
    useState<TRowData>(DELTA_FIELDS_DATA);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isCustomSetsForLastWeekChecked, setIsCustomSetsForLastWeekChecked] =
    useState(false);
  const [week4NumberOfSets, setWeek4NumberOfSets] = useState<number | ''>(
    DEFAULT_VALUE_WEEK_4_NUMBER_OF_SETS,
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof TRowData,
    isDeltaValue = false,
  ) => {
    const userPressedBackspace = isUserPressedBackspace(e);

    const updatedState = {
      [key]: changeExerciseInputDataHandler({
        newValue: e.target.value,
        key,
        userPressedBackspace,
        isDeltaValue,
      }),
    };

    if (isDeltaValue) {
      setMaterRowDeltas((prev) => ({
        ...prev,
        ...updatedState,
      }));
    } else {
      setMaterRowData((prev) => ({
        ...prev,
        ...updatedState,
      }));
    }
  };

  const handleChangeRPEOnArrowButton = (
    shouldBeIncreased: boolean,
    isDeltaValue: boolean,
  ) => {
    // to handle click on master row arrows or deltas arrows
    const rpeArrowClickCallback = isDeltaValue
      ? setMaterRowDeltas
      : setMaterRowData;

    rpeArrowClickCallback((prev: TRowData) => {
      if (shouldBeIncreased) {
        const newRPEValue = (+prev[RPE_KEY] || 0) + 0.5;
        return {
          ...prev,
          // allow negative values only for rpe delta on arrow up\down click
          [RPE_KEY]: isDeltaValue ? newRPEValue : getRPEValue(newRPEValue),
        };
      }
      const newRPEValue = (+prev[RPE_KEY] || 0) - 0.5;
      return {
        ...prev,
        // allow negative values only for rpe delta on arrow up\down click
        [RPE_KEY]: isDeltaValue ? newRPEValue : getRPEValue(newRPEValue),
      };
    });
  };

  const getExerciseDataInputValueBasedOnFieldKey = (
    key: keyof TRowData,
    isDeltaValue = false,
  ) => {
    const cellValue = isDeltaValue ? masterRowDeltas[key] : masterRowData[key];

    // if for delta row values are "0" - don't show anything ('')
    if (isDeltaValue && !cellValue) {
      return '';
    }
    if (key === REST_KEY) {
      return getOnlyIntegerValueFromRestField(cellValue, isDeltaValue);
    }
    if (key === WEIGHT_KEY || key === RPE_KEY) {
      return getOnlyIntegerValueFromNumberField(cellValue, isDeltaValue);
    }
    return cellValue;
  };

  const renderInputFieldBasedOnFieldKey = (
    key: keyof TRowData,
    isDeltaValue = false,
  ) => {
    if (key === REST_KEY) {
      const value = getExerciseDataInputValueBasedOnFieldKey(key, isDeltaValue);
      return (
        <div>
          <S.CellInputWrapper>
            <input
              className="cellInput"
              name={key}
              value={value}
              onChange={(e) => handleChange(e, key, isDeltaValue)}
              onKeyDown={(e) =>
                excludeSpecSymbolsForNumberInput(e, isDeltaValue)
              }
              type={'number'}
            />
          </S.CellInputWrapper>
        </div>
      );
    }

    return (
      <S.CellInputWrapper isWithArrows={key === RPE_KEY}>
        <input
          name={key}
          value={getExerciseDataInputValueBasedOnFieldKey(key, isDeltaValue)}
          onChange={(e) => handleChange(e, key, isDeltaValue)}
          className="cellInput"
        />
        {key === RPE_KEY && (
          <div className="arrows">
            <button
              className="arrowButton up"
              onClick={() => handleChangeRPEOnArrowButton(true, isDeltaValue)}
            >
              <ArrowTriangleIc />
            </button>
            <button
              className="arrowButton down"
              onClick={() => handleChangeRPEOnArrowButton(false, isDeltaValue)}
            >
              <ArrowTriangleIc />
            </button>
          </div>
        )}
      </S.CellInputWrapper>
    );
  };

  const openAddToFourWeeksModal = () => {
    setIsOpenConfirmModal(true);
    if (isChatPage()) {
      gaEvents.chatOpenAddToFourWeeksAddOrEditComplexExerciseConfirmationModal(
        modalId,
      );
    } else {
      gaEvents.openAddToFourWeeksAddOrEditComplexExerciseConfirmationModal(
        modalId,
      );
    }
  };
  const cancelAddToFourWeeksModal = () => {
    setIsOpenConfirmModal(false);
    if (isChatPage()) {
      gaEvents.chatCancelAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    } else {
      gaEvents.cancelAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    }
  };
  const closeAddToFourWeeksModal = () => {
    setIsOpenConfirmModal(false);
    if (isChatPage()) {
      gaEvents.chatCloseAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    } else {
      gaEvents.closeAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    }
  };
  const outsideCloseAddToFourWeeksModal = () => {
    setIsOpenConfirmModal(false);
    if (isChatPage()) {
      gaEvents.chatOutsideCloseAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    } else {
      gaEvents.outsideCloseAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    }
  };

  const submitExerciseMasterRowHandler = () => {
    onSubmitExerciseMasterRow({
      masterRowData,
      masterRowDeltas,
      exerciseId,
      week4NumberOfSets:
        isCustomSetsForLastWeekChecked && week4NumberOfSets
          ? week4NumberOfSets
          : 0,
    });
    setIsOpenConfirmModal(false);

    if (isChatPage()) {
      gaEvents.chatSubmitAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    } else {
      gaEvents.submitAddToFourWeeksAddOrEditComplexExerciseConfirmationModal();
    }
  };

  const onChangeWeek4NumberOfSets = (value: string | number) => {
    if (value === '') {
      setWeek4NumberOfSets('');
    } else if (+value < MIN_VALUE_WEEK_4_NUMBER_OF_SETS) {
      setWeek4NumberOfSets(MIN_VALUE_WEEK_4_NUMBER_OF_SETS);
    } else if (+value > MAX_VALUE_WEEK_4_NUMBER_OF_SETS) {
      setWeek4NumberOfSets(MAX_VALUE_WEEK_4_NUMBER_OF_SETS);
    } else {
      setWeek4NumberOfSets(+value);
    }
  };

  const isCustomSetsNumberDisabled = !isCustomSetsForLastWeekChecked;

  return (
    <>
      <div>
        <S.WeekName withContent>
          <p>Add to All Weeks</p>
          <S.CustomMasterLastWeekWrapper>
            <Checkbox
              label="Number of sets in Week 4:"
              onChange={() =>
                setIsCustomSetsForLastWeekChecked(
                  !isCustomSetsForLastWeekChecked,
                )
              }
              checked={isCustomSetsForLastWeekChecked}
            />
            <S.CellInputWrapper
              isMasterHeaderInput
              isWithArrows
              disabled={isCustomSetsNumberDisabled}
            >
              <input
                name="isCustomSetsForLastWeekChecked"
                value={isCustomSetsNumberDisabled ? '' : week4NumberOfSets}
                onChange={(e) => onChangeWeek4NumberOfSets(e.target.value)}
                onKeyDown={avoidDecimalsInNumberInput}
                className="cellInput"
                min={MIN_VALUE_WEEK_4_NUMBER_OF_SETS}
                type="number"
                disabled={isCustomSetsNumberDisabled}
              />
              <div className="arrows">
                <button
                  className="arrowButton up"
                  onClick={() =>
                    onChangeWeek4NumberOfSets(+week4NumberOfSets + 1)
                  }
                  disabled={isCustomSetsNumberDisabled}
                >
                  <ArrowTriangleIc />
                </button>
                <button
                  className="arrowButton down"
                  onClick={() =>
                    onChangeWeek4NumberOfSets(+week4NumberOfSets - 1)
                  }
                  disabled={isCustomSetsNumberDisabled}
                >
                  <ArrowTriangleIc />
                </button>
              </div>
            </S.CellInputWrapper>
          </S.CustomMasterLastWeekWrapper>
        </S.WeekName>
        <S.SetItem>
          {Object.keys(masterRowData).map((key) => {
            const isRPEField = key === RPE_KEY;
            return (
              <div key={key} className="cellsColumn">
                <div className="cellLabelWrapper">
                  {isRPEField && (
                    <Tooltip
                      title={PROGRAM.RPE_SELECTOR_INFO}
                      styles={TOOLTIP_STYLES}
                    >
                      <InfoIc className="infoIcon" />
                    </Tooltip>
                  )}
                  <span className={`cellLabel ${isRPEField && 'uppercase'}`}>
                    {key}
                  </span>
                </div>
                {renderInputFieldBasedOnFieldKey(key as keyof TRowData)}
                <div className="deltasWrapper">
                  <div className="cellLabelWrapper">
                    <span className={`cellLabel ${isRPEField && 'uppercase'}`}>
                      {key}
                    </span>
                    Delta
                  </div>
                  {renderInputFieldBasedOnFieldKey(key as keyof TRowData, true)}
                </div>
              </div>
            );
          })}
        </S.SetItem>
        <p className="deltaLabel">
          To increment use positive values in delta fields and to decrement use
          negative values.
        </p>{' '}
        <S.ActionButtons>
          <button
            type="button"
            disabled={masterRowData.sets < 1}
            className="actionButton addButton"
            onClick={openAddToFourWeeksModal}
          >
            <CopyIc />
            Add to All Weeks
          </button>
        </S.ActionButtons>
      </div>

      <ConfirmModal
        show={isOpenConfirmModal}
        title="This action will erase all previously entered data! Are you sure you want to proceed?"
        onCancel={cancelAddToFourWeeksModal}
        onClose={closeAddToFourWeeksModal}
        onCloseOnOutsideClick={outsideCloseAddToFourWeeksModal}
        onSubmit={submitExerciseMasterRowHandler}
      />
    </>
  );
};
