import { styled } from '@mui/material';
import { flexCenteredStyles } from 'styles/commonStyleVariables';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  margin-bottom: 8px;
  overflow-x: hidden;

  & .addDayButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1f21;
    width: 100%;
    color: #ffef01;
    padding: 10px;
    font-size: 14px;
  }
`;

const Label = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  background: #31363f;
  color: #fff;
  border-radius: 4px;

  & .dayName {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }

  & .duplicateDayIcon,
  .deleteDayIcon {
    cursor: pointer;
    color: #5f6975;
  }

  & .weeks {
    display: flex;
    align-items: center;
    width: 100%;

    font-weight: 400;
    font-size: 12px;

    & .weekLabelWrapper {
      ${flexCenteredStyles};
      flex: 4;
      gap: 8px;

      &:first-child {
        flex: 3;
      }

      & .weekLabel {
        white-space: nowrap;
      }

      & .checkedIcon {
        color: #33a56e;
      }

      & .date {
        color: #6e7987;
      }
    }
  }
`;

const ComplexExerciseList = styled('ul')`
  flex: 2 1;
  color: #fff;
  position: relative;

  & .addExerciseButton {
    color: #ffef01;
    font-weight: 400;
    font-size: 14px;
  }
`;

export default { Wrapper, Label, ComplexExerciseList };
