import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';

type TUseFetch = {
  clientId: string;
  setClientData: () => void;
};

export const useFetch = ({ clientId, setClientData }: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: changeClientStatusMutation,
    isLoading: isLoadingClientStatus,
  } = useMutation({
    mutationFn: () => instance.post(`Trainer/change-client-status/${clientId}`),
    onSuccess: () => {
      setClientData();
      enqueueSnackbar('Client status is successfully changed', {
        variant: 'success',
      });
    },
    onError: (e) => {
      enqueueSnackbar(String(e) || ERROR_MESSAGE, { variant: 'error' });
    },
  });

  return {
    changeClientStatusMutation,
    isLoadingClientStatus,
  };
};
