import { styled } from '@mui/material';

const DayWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  gap: 16px;
  
  & .addDayButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1C1F21;
    width: 100%;
    color: #FFEF01;
    padding: 10px;
    font-size: 14px;
  }
`;

const DayLabel = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  background: #ffef01;
  color: #090b0c;

  & .dayName {
    font-size: 16px;
    font-weight: 600;
  }
  
  & .duplicateDayIcon, .deleteDayIcon {
    cursor: pointer;
    color: #5F6975;
  }
`;

const ComplexExerciseList = styled('ul')`
  flex: 2 1;
  color: #fff;
  position: relative;

  & .addExerciseButton {
    color: #ffef01;
    font-weight: 400;
    font-size: 14px;
  }
`;

export default { DayWrapper, DayLabel, ComplexExerciseList };
