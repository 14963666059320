import { PROGRAM } from 'constants/tooltips';

import type { TProgramView } from '../models';

export const DBD_VIEW_ID = 0;
export const BP_VIEW_ID = 1;
export const COLLAPSED_VIEW_ID = 2;

export const PROGRAM_VIEWS_LIST: TProgramView[] = [
  {
    id: DBD_VIEW_ID,
    tooltip: PROGRAM.DBD_VIEW,
    iconPath: '/assets/svg/singleMode.svg',
    isShownOnChatPage: false,
  },
  {
    id: BP_VIEW_ID,
    tooltip: PROGRAM.BP_VIEW,
    iconPath: '/assets/svg/biggerPictureMode.svg',
    isShownOnChatPage: true,
  },
  {
    id: COLLAPSED_VIEW_ID,
    tooltip: PROGRAM.COLLAPSED_VIEW,
    iconPath: '/assets/svg/collapsedMode.svg',
    isShownOnChatPage: true,
  },
];
