import styled from '@emotion/styled/macro';
import { flexCenteredStyles } from 'styles/commonStyleVariables';

const Phase = styled.div<{ isActive: boolean }>`  
  & .outerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    min-width: 50px;
    border-radius: 8px;
    border: 1px solid #31363F;
    background: ${({ isActive }) => isActive ? '#31363F' : 'transparent'};
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #31363F;

      & .actionButton, .saveAsButton {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  & .actionButton, .saveAsButton {
    visibility: hidden;
    opacity: 0;
    color: #5f6975;
    
    &.duplicate {
      margin-right: 4px;
    }

    &:hover {
      color: #ebc604;
    }
  }
  
  & .saveAsButton {
    ${flexCenteredStyles};
  }

  & .accessToPhaseIcon {
    margin-left: 15px;
    color: #6E7987;
  }

  & .progressDoneIcon {
    color: #33A56E;
    line-height: 0;
  }
  
  & .innerWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  & .outerTitle {
    margin-left: 5px;
    white-space: nowrap;
  }
`;

export default { Phase };
