import styled from '@emotion/styled';

const Wrapper = styled.div`
  background: #222429;
  cursor: default;
  padding: 12px;
  border: 1px solid #fff;
  border-radius: 4px;

  & .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 12px;
  }

  & .textareaWrapper {
    width: 100%;
    background: inherit;
    padding: 0;
  }

  & .sendButton {
    color: #ffef01;
  }
`;

export default { Wrapper };
