import { styled } from '@mui/material';
import { Box, CircularProgress, Typography } from '@material-ui/core';



const ProgressContainer = styled(Box)`
  & .blank {
    color: #5f6975;
  }
`;

const FilledProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'finished',
})<{
  finished: boolean;
}>`
  &&& {
    position: absolute;
    left: 0;
    color: ${({ finished }) => (finished ? '#33A56E' : '#FFEF01')};
  }
`;

const Value = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'finished' && prop !== 'empty',
})<{
  finished: boolean;
  empty: boolean;
}>`
  &&& {
    font-size: 10px;
    color: ${({ finished, empty }) =>
      finished ? '#33A56E' : empty ? '#6E7987' : '#FFEF01'};
  }
`;

export default { ProgressContainer, FilledProgress, Value };
