import React, { ChangeEvent, FC } from 'react';
import { UploadAndDragAndDropArea } from 'components/fields/uploadAndDragAndDropArea/UploadAndDragAndDropArea';
import { TextArea } from 'components/fields/textArea/TextArea';
import {
  DEFAULT_LOGO_FILE_DATA,
  WELCOME_TEXT_MAX_SYMBOLS,
} from '../../../../constants';

import type { IHomePageStep, TBrandingData } from '../../../../models';

import { CloseIc, EditIc } from 'assets/svg';
import S from './Constructor.styled';

export const Constructor: FC<IHomePageStep> = ({
  brandingData,
  setBrandingData,
  onChangeBrandingFields,
}) => {
  const onUploadLogo = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const file = e.target.files?.[0] || null;

    reader.onloadend = () => {
      setBrandingData((prev) => ({
        ...prev,
        logoFile: {
          url: String(reader.result),
          file,
        },
      }));
    };
    file && reader.readAsDataURL(file);
  };

  const onDeleteLogo = () => {
    setBrandingData((prev: TBrandingData) => ({
      ...prev,
      logoFile: DEFAULT_LOGO_FILE_DATA,
    }));
  };

  return (
    <S.Wrapper>
      <div className="uploadLogo">
        {brandingData.logoFile.url ? (
          <>
            <img src={brandingData.logoFile.url} alt="logo" className="logo" />
            <button onClick={onDeleteLogo} className="deleteLogoButton">
              <CloseIc className="deleteLogoButton__icon" />
            </button>
          </>
        ) : (
          <p className="logoLabel">Logo</p>
        )}
        <div className="uploadLogo__edit">
          <UploadAndDragAndDropArea
            onChange={onUploadLogo}
            fileType={'image/*,.svg'}
            styles={{ border: 'none', borderRadius: '50%' }}
          >
            <EditIc className="uploadLogo__editIcon" />
          </UploadAndDragAndDropArea>
        </div>
      </div>
      <p className="label">Supported formats: JPG, PNG and SVG.</p>
      <div className="welcomeTextWrapper">
        <TextArea
          onChange={({ target }) =>
            onChangeBrandingFields?.({
              key: 'welcomeText',
              value: target.value,
            })
          }
          value={brandingData.welcomeText}
          placeholder="Welcome text"
          rows={2}
          maxLength={WELCOME_TEXT_MAX_SYMBOLS}
          resizable={false}
        />
      </div>
    </S.Wrapper>
  );
};
