import { isChatPage } from 'containers/app/trainer/currentClient/clientPhases/utils';
import gaEvents from 'utils/gaEvents';

import type { IPhaseDayProps } from 'containers/app/trainer/currentClient/clientPhases/biggerProgram/PhaseContent/models';

export const useHandlers = ({
  navigatedExerciseReference,
  phaseExerciseReference,
  day,
  onCreateExercise,
}: Pick<IPhaseDayProps,
  | 'navigatedExerciseReference'
  | 'phaseExerciseReference'
  | 'day'
  | 'onCreateExercise'>) => {
  const isDayFromNavigatedReference =
    navigatedExerciseReference &&
    day.complexExercises?.some(
      ({ id }) => id === navigatedExerciseReference.complexExerciseId,
    );

  const dayExerciseReference = phaseExerciseReference && {
    ...phaseExerciseReference,
    dayName: day.name,
    dayIds: day.dayIds,
  };

  const openCreateExerciseModal = () => {
    onCreateExercise();
    if (isChatPage()) {
      gaEvents.chatOpenCreateExerciseModal();
    } else {
      gaEvents.openCreateExerciseModal();
    }
  };
  return {
    dayExerciseReference,
    isDayFromNavigatedReference,
    openCreateExerciseModal,
  };
};