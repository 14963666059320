import styled from '@emotion/styled/macro';
import { flexCenteredStyles } from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  position: absolute;
  left: -25px;

  padding: 6px 10px;
  border-radius: 40px;

  background: #1c1f21;
  border: 1px solid #3e434b;
  color: #5F6975;

  & .saveAsButton {
    margin-left: 4px;
  }
  
  & button {
    ${flexCenteredStyles};
    
    &:hover {
      color: #FFEF01;
    }
  }
`;

export default { Wrapper };
