import styled from '@emotion/styled';
import { DoubleCheckMarkIc } from 'assets/svg';
import { flexCenteredStyles } from 'styles/commonStyleVariables';
import { DELETE_MESSAGE_TIMEOUT } from './components/hoveredMenuWrapper/HoveredMenuWrapper';

const Wrapper = styled.div<{
  isMessageFromCurrentUser: boolean;
  isUserDataShown: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isMessageFromCurrentUser }) =>
          isMessageFromCurrentUser ? 'flex-end' : 'flex-start'};

  max-width: 90%;
  word-wrap: break-word;
  padding: 0 12px;
  margin-left: ${({ isMessageFromCurrentUser }) =>
          isMessageFromCurrentUser ? 'auto' : 0};
  margin-top: ${({ isUserDataShown }) => (isUserDataShown ? '12px' : '0')};
  z-index: 0;

  & .messageInfo {
    display: flex;
    align-items: center;
  }

  & .messageDate {
    color: #6e7987;
  }

  & .messageDate,
  & .userName {
    margin-right: 8px;
  }

  & .messageTextWrapper {
    position: relative;

    max-width: 100%;
    background-color: ${({ isMessageFromCurrentUser }) =>
            isMessageFromCurrentUser ? '#5F6975' : '#31363F'};
    margin-top: 5px;
    padding: 12px 16px;
    line-height: 20px;
    border-radius: ${({ isMessageFromCurrentUser }) =>
            isMessageFromCurrentUser ? '12px 0px 12px 12px' : '0 12px 12px 12px'};
    overflow-wrap: anywhere;

    & .exerciseReferenceWrapper {
      display: flex;
      margin-top: 8px;
      padding-bottom: 4px;
    }
    
    &.undo {
      display: flex;
      align-items: center;
      
      white-space: nowrap;
      max-width: unset;
      overflow: hidden;
      
      & .undoMessage {
        font-style: italic;
        margin-right: 10px;
        padding-top: 3px;
      }

      & .undoButton {
        position: relative;
        color: #FFEF01;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          height: 1px;
          background: #FFEF01;
        }
      }
      
      & .progressBar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
        background: #FFEF01;
        animation: progress ${`${DELETE_MESSAGE_TIMEOUT / 1000}`}s linear forwards;

        @keyframes progress {
          from {
            width: 100%;
          }
          to {
            width: 0%;
          }
        }
      }
    }
    
    &.withReaction {
      margin-bottom: 26px;
      
      & .reaction {
        ${flexCenteredStyles};

        width: 28px;
        position: absolute;
        bottom: -22px;
        right: 5px;
        padding: 2px 0;
        background: #1c1f21;
        border: 1px solid #fff;
        border-radius: 50%;
        box-sizing: border-box;
      }
      
    }
    
    & .attachment {
      margin-top: 8px;
      
      & .image {
        max-height: 200px;
        max-width: 100%;
      }
      
      & .unknownFileIcon {
        width: 50px;
        height: auto;
      }
    }
  }

  & .messageSeen {
    position: absolute;
    right: 6px;
    bottom: 6px;
    color: #1C1F21;
  }

  .videoWrapper {
    ${flexCenteredStyles};
    
    position: relative;
    max-width: 200px;
    max-height: 200px;
    min-width: 100px;
    min-height: 100px;
    overflow: hidden;

    margin: 8px 0;
    border-radius: 4px;

    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.2),
    0px 8px 10px rgba(0, 0, 0, 0.08),
    0px 16px 24px rgba(0, 0, 0, 0.06);
  }
  
  & .videoPlayButton {
    position: absolute;
  }
`;

const Icon = styled(DoubleCheckMarkIc, {
  shouldForwardProp: (prop) => prop !== 'seen',
})<{
  seen?: boolean;
}>`
  position: absolute;
  right: 6px;
  bottom: 6px;
  color: ${({ seen }) => seen ? '#FFEF01' : '#1C1F21'};
`;

export default { Wrapper, Icon }
