import { useEffect, useState } from 'react';
import {
  TDeleteTemplateData,
  TExpandedItem,
  TTemplateListItem,
  TTemplateItemData,
  TDuplicateDayProps,
  TDeleteDayProps,
  TAddDayProps,
  TDeleteExerciseProps,
  TEditExerciseProps,
  TCreateExerciseProps,
} from '../models';
import instance from 'lib/api/axios';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { ERROR_MESSAGE } from 'constants/index';

type TState<T> = {
  isLoading: boolean;
  error: string;
  data: T;
};

const exportedPhase = 'ExportedPhase';
const maxExpandedElements = 3;

type Props = {
  setExpandedItems: (items: (prev: TExpandedItem[]) => TExpandedItem[]) => void;
};

export const useFetch = ({ setExpandedItems }: Props) => {
  const [templateList, setTemplateList] = useState<TState<TTemplateListItem[]>>(
    {
      isLoading: false,
      error: '',
      data: [],
    },
  );
  const [isLoadingDeleteTemplate, setIsLoadingDeleteTemplate] = useState(false);
  const [isLoadingDeleteDay, setIsLoadingDeleteDay] = useState(false);
  const [isLoadingDeleteExercise, setIsLoadingDeleteExercise] = useState(false);
  const [isLoadingEditExercise, setIsLoadingEditExercise] = useState(false);
  const [isLoadingCreateExercise, setIsLoadingCreateExercise] = useState(false);
  const [loadedPhaseId, setLoadedPhaseId] = useState<null | number>(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchTemplateList();
  }, []);

  // == == //

  async function fetchTemplateList() {
    setTemplateList((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const { data } = await instance.get<{ items: TTemplateListItem[] }>(
        `${exportedPhase}/list`,
      );

      setTemplateList((prev) => ({
        ...prev,
        isLoading: false,
        data: data.items || [],
      }));
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      setTemplateList((prev) => ({
        ...prev,
        isLoading: false,
        error: error,
      }));

      enqueueSnackbar(error, { variant: 'error' });
    }
  }

  // == == //

  const fetchDeleteTemplate = async (data: TDeleteTemplateData) => {
    if (!data) return;

    const { id, name } = data;

    setIsLoadingDeleteTemplate(true);

    try {
      const { data } = await instance.delete(`${exportedPhase}/${id}`);

      if (data.success) {
        enqueueSnackbar(`${name || 'Template'} was successfully deleted.`, {
          variant: 'success',
        });

        setTemplateList((prev) => ({
          ...prev,
          isLoading: false,
          data: prev.data.filter(({ id: templateId }) => id !== templateId),
        }));
      }
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      enqueueSnackbar(error, { variant: 'error' });
    }

    setIsLoadingDeleteTemplate(false);
  };

  // == == //

  const fetchTemplateItemData = async (id: number) => {
    setExpandedItems((prev) => [...prev, { isLoading: true, id, data: null }]);

    try {
      const { data } = await instance.get<{ item: TTemplateItemData }>(
        `${exportedPhase}/${id}`,
      );

      setExpandedItems((prev) => {
        if (prev.length > maxExpandedElements) {
          return [
            ...prev.slice(1).filter((item) => item.id !== id),
            { isLoading: false, data: data.item, id },
          ];
        } else {
          return [
            ...prev.filter((item) => item.id !== id),
            { isLoading: false, data: data.item, id },
          ];
        }
      });
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      setExpandedItems((prev) => prev.filter((item) => item.id !== id));

      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  // == == //

  const fetchDuplicateDay = async ({
    orderNumber,
    phaseId,
    newOrderNumber,
    dayName,
  }: TDuplicateDayProps) => {
    setLoadedPhaseId(phaseId);

    try {
      const { data } = await instance.put(
        `${exportedPhase}/duplicate-bulk-day`,
        {
          data: { orderNumber, phaseId, newOrderNumber },
        },
      );

      if (data.success) {
        enqueueSnackbar(
          `${dayName || 'The day'} was successfully duplicated.`,
          { variant: 'success' },
        );

        setExpandedItems((prev) =>
          prev.map((phase) => {
            if (phase.id === phaseId) {
              return {
                ...phase,
                data: phase.data
                  ? {
                      ...phase.data,
                      days: (() => {
                        const newDay = phase.data.days.find(
                          (day) => day.orderNumber === orderNumber,
                        );

                        return newDay
                          ? [
                              ...phase.data.days,
                              {
                                ...newDay,
                                orderNumber,
                                name: `Day ${newOrderNumber + 1}`,
                              },
                            ]
                          : phase.data.days;
                      })(),
                    }
                  : null,
              };
            }

            return phase;
          }),
        );
      }
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      enqueueSnackbar(error, { variant: 'error' });
    }

    setLoadedPhaseId(null);
  };

  // == == //

  const fetchDeleteDay = async ({
    orderNumber,
    phaseId,
    dayName,
  }: TDeleteDayProps): Promise<boolean | null> => {
    setIsLoadingDeleteDay(true);

    try {
      const { data } = await instance.post(`${exportedPhase}/delete-bulk-day`, {
        data: { orderNumber, phaseId },
      });

      if (data.success) {
        enqueueSnackbar(`${dayName || 'The day'} was successfully deleted.`, {
          variant: 'success',
        });

        await fetchTemplateItemData(phaseId);
      }

      setIsLoadingDeleteDay(false);
      return !!data.success;
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      enqueueSnackbar(error, { variant: 'error' });

      setIsLoadingDeleteDay(false);
      return null;
    }
  };

  // == == //

  const fetchCreateDay = async ({
    orderNumber,
    phaseId,
    dayName,
  }: TAddDayProps) => {
    setLoadedPhaseId(phaseId);

    try {
      const { data } = await instance.post(`${exportedPhase}/create-bulk-day`, {
        data: { orderNumber, phaseId },
      });

      if (data.success) {
        enqueueSnackbar(`${dayName || 'The new day'} was successfully added.`, {
          variant: 'success',
        });

        setExpandedItems((prev) =>
          prev.map((phase) => {
            if (phase.id == phaseId) {
              return {
                ...phase,
                data: phase.data
                  ? {
                      ...phase.data,
                      days: [
                        ...phase.data.days,
                        {
                          name: dayName,
                          orderNumber,
                          complexExercises: [],
                          id: uuidv4(),
                        },
                      ],
                    }
                  : null,
              };
            }

            return phase;
          }),
        );
      }

      setLoadedPhaseId(null);
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      enqueueSnackbar(error, { variant: 'error' });

      setLoadedPhaseId(null);
    }
  };

  // == == //

  const fetchDeleteExercise = async ({
    exerciseInfoId,
    dayOrderNumber,
    phaseId,
    exerciseName,
  }: TDeleteExerciseProps) => {
    setIsLoadingDeleteExercise(true);

    try {
      const { data } = await instance.post(
        `${exportedPhase}/delete-bulk-exercise`,
        {
          data: {
            exerciseInfoId,
            dayOrderNumber,
            phaseId,
          },
        },
      );

      if (data.success) {
        enqueueSnackbar(
          `${exerciseName || 'Exercise'} was successfully deleted.`,
          {
            variant: 'success',
          },
        );

        await fetchTemplateItemData(phaseId);
      }

      setIsLoadingDeleteExercise(false);

      return !!data.success;
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      enqueueSnackbar(error, { variant: 'error' });

      setIsLoadingDeleteExercise(false);

      return null;
    }
  };

  // == == //

  const fetchEditExercise = async ({
    exerciseInfoId,
    exercises,
    phaseId,
  }: TEditExerciseProps): Promise<boolean | null> => {
    setIsLoadingEditExercise(true);

    try {
      const { data } = await instance.put(
        `${exportedPhase}/update-bulk-exercise`,
        {
          data: { exerciseInfoId, exercises },
        },
      );

      if (data.success) {
        enqueueSnackbar(`Exercise was successfully edited.`, {
          variant: 'success',
        });

        await fetchTemplateItemData(phaseId);
      }

      setIsLoadingEditExercise(false);
      return !!data.success;
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      enqueueSnackbar(error, { variant: 'error' });

      setIsLoadingEditExercise(false);
      return null;
    }
  };

  // == == //

  const fetchCreateExercise = async (
    data: TCreateExerciseProps,
  ): Promise<boolean | null> => {
    setIsLoadingCreateExercise(true);

    try {
      const {
        data: { success },
      } = await instance.post(`${exportedPhase}/create-bulk-exercise`, {
        data,
      });

      if (success) {
        enqueueSnackbar(`Exercise was successfully added.`, {
          variant: 'success',
        });

        await fetchTemplateItemData(data.phaseId);
      }

      setIsLoadingCreateExercise(false);
      return !!success;
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;

      enqueueSnackbar(error, { variant: 'error' });

      setIsLoadingCreateExercise(false);
      return null;
    }
  };

  // == == //

  return {
    templateList,
    fetchDeleteTemplate,
    isLoadingDeleteTemplate,
    fetchTemplateItemData,
    fetchDuplicateDay,
    fetchDeleteDay,
    isLoadingDeleteDay,
    fetchCreateDay,
    isLoadingDeleteExercise,
    fetchDeleteExercise,
    isLoadingEditExercise,
    fetchEditExercise,
    isLoadingCreateExercise,
    fetchCreateExercise,
    loadedPhaseId,
  };
};
