// TODO - improve to use styled component
import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'components/tooltip/Tooltip';
import { PROGRAM } from 'constants/tooltips';
import { PROGRAM_VIEWS_LIST } from '../../constants';

import gaEvents from 'utils/gaEvents';
import { AddPhaseMenu } from '../addPhaseMenu/AddPhaseMenu';

import type { TBiggerProgram, TProgram } from '../../../../models';
import type { TViewModeId } from '../../models';

interface ProgramHeaderRow {
  viewModeId: TViewModeId;
  setViewModeId: (id: TViewModeId) => void;
  program: TBiggerProgram | TProgram | null;
  toggleOpenEditProgramModal: () => void;
  addNewPhaseHandler: () => void;
  importTemplateHandler: () => void;
  isChatPage: boolean;
}

export const ProgramHeaderRow: FC<ProgramHeaderRow> = ({
  viewModeId,
  setViewModeId,
  program,
  toggleOpenEditProgramModal,
  addNewPhaseHandler,
  importTemplateHandler,
  isChatPage,
}) => {
  if (!program) {
    return null;
  }

  return (
    <div className="phases-list-title">
      <p>{program?.name}</p>
      {!program?.isCompleted && !program?.isDraft ? (
        <Tooltip title={PROGRAM.EDIT_PROGRAM}>
          <ReactSVG
            className="phases-list-title-edit"
            onClick={toggleOpenEditProgramModal}
            src="/assets/svg/edit-exercise.svg"
          />
        </Tooltip>
      ) : (
        ''
      )}
      <div className="phases-list-title-full-block">
        {PROGRAM_VIEWS_LIST.map(
          (mode) =>
            ((isChatPage && mode.isShownOnChatPage) || !isChatPage) && (
              <Tooltip key={mode.id} title={mode.tooltip} timeout={600}>
                <ReactSVG
                  src={mode.iconPath}
                  className={`phases-list-title-full ${
                    mode.id === viewModeId && 'active'
                  }`}
                  onClick={() => {
                    setViewModeId(mode.id);
                    gaEvents.clickDBDButton();
                  }}
                />
              </Tooltip>
            ),
        )}
      </div>
      <AddPhaseMenu
        viewModeId={viewModeId}
        addNewPhaseHandler={addNewPhaseHandler}
        importTemplateHandler={importTemplateHandler}
      />
    </div>
  );
};
