import React, { FC, useState } from 'react';
import classNames from 'classnames';
import {
  BLOCK_PHASE,
  LockUnlockPhaseModal,
  UNBLOCK_PHASE,
} from './lockUnlockPhaseModal/LockUnlockPhaseModal';
import Loader from 'components/loader/Loader';
import { useSnackbar } from 'notistack';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';
import gaEvents from 'utils/gaEvents';
import { Tooltip } from 'components/tooltip/Tooltip';
import { PROGRAM } from 'constants/tooltips';

import type {
  TBiggerPhase,
  TBiggerProgram,
  TEventOnClick,
  TProgram,
} from '../../models';
import type { TModalType } from './lockUnlockPhaseModal/models';

import { BlockIc, UnblockIc } from 'assets/svg/index';
import classes from './_LockUnlockPhase.module.scss';
import { isChatPage } from '../clientPhases/utils';

interface ILockUnlockPhase {
  program: TBiggerProgram | TProgram;
  phaseIndex: number;
  setHoveredPhase: (phaseIndex: number) => void;
  hoveredPhase: number | null;
  phase: TBiggerPhase;
  setDBDProgram: (program: TProgram) => void;
  loadCallbackData: () => void;
  pageId?: string;
}

export const LockUnlockPhase: FC<ILockUnlockPhase> = ({
  program,
  phaseIndex,
  setHoveredPhase,
  hoveredPhase,
  phase,
  setDBDProgram,
  loadCallbackData,
  pageId = '',
}) => {
  const [modalType, setModalType] = useState<TModalType>('');
  const [loading, setLoading] = useState(false);
  const [lockingActivePhaseIndex, setLockingActivePhaseIndex] =
    useState<number>(0);
  const [isLockingModalShown, setIsLockingModalShown] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // TODO: add useMemo (deep structure of program object)
  const blockedPhaseIndex = program?.phases?.findIndex(
    (phase: TBiggerPhase) => !phase.isVisible,
  );
  const firstBlockedPhaseIndex =
    blockedPhaseIndex === -1 ? program?.phases?.length || 0 : blockedPhaseIndex;

  const openLockingPhaseHandler = (e: TEventOnClick) => {
    e.stopPropagation();

    setLockingActivePhaseIndex(phaseIndex);
    setHoveredPhase(-1);
  };

  const openLockUnlockModal = (
    e: TEventOnClick,
    phaseActionType: TModalType,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setModalType(phaseActionType);
    setIsLockingModalShown(true);

    openLockingPhaseHandler(e);
  };

  // TODO: improve requests logic
  // TODO: add useMemo (deep structure of program object)
  const blockPhaseHandler = async (shouldBeVisible: boolean) => {
    setLoading(true);
    try {
      const { data } = await instance.put(
        `Program/update-phase/${program.phases[lockingActivePhaseIndex].id}`,
        {
          data: { isVisible: shouldBeVisible },
        },
      );

      if (data.success) {
        enqueueSnackbar('Phase access successfully updated', {
          variant: 'success',
        });
        loadCallbackData();

        const currentProgramRef = { ...program };
        // TODO: remade with "loadCurrentProgram" function
        currentProgramRef.phases[lockingActivePhaseIndex].isVisible =
          shouldBeVisible;
        setDBDProgram(currentProgramRef);
      }
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const blockPhase = (e: TEventOnClick, shouldBeLocked: boolean) => {
    e.stopPropagation();

    blockPhaseHandler(shouldBeLocked);
    setIsLockingModalShown(false);
    setHoveredPhase(-1);

    if (isChatPage()) {
      gaEvents.chatSubmitLockPhase(shouldBeLocked);
    } else {
      gaEvents.submitLockPhase(shouldBeLocked);
    }
  };

  const closeModal = (e: TEventOnClick) => {
    e.stopPropagation();
    setIsLockingModalShown(false);
    setModalType('');
    setHoveredPhase(-1);
  };

  const onBlockIconHandler = (e: TEventOnClick) => {
    openLockUnlockModal(e, BLOCK_PHASE);

    if (isChatPage()) {
      gaEvents.chatOpenLockPhaseModal({ goingToLock: true, pageId });
    } else {
      gaEvents.openLockPhaseModal({ goingToLock: true, pageId });
    }
  };

  const onUnblockIconHandler = (e: TEventOnClick) => {
    openLockUnlockModal(e, UNBLOCK_PHASE);
    if (isChatPage()) {
      gaEvents.chatOpenLockPhaseModal({ goingToLock: false, pageId });
    } else {
      gaEvents.openLockPhaseModal({ goingToLock: false, pageId });
    }
  };

  const hoveredPhaseOnMouseEnterHandler = () => {
    setHoveredPhase(phaseIndex);
  };

  const hoveredPhaseOnMouseLeaveHandler = () => {
    setHoveredPhase(-1);
  };

  return (
    <>
      {loading && <Loader />}
      {phase.isVisible ? (
        <div className={classes.phaseLock}>
          {hoveredPhase === phaseIndex &&
          firstBlockedPhaseIndex - 1 === phaseIndex ? (
            <Tooltip placement="right" title={PROGRAM.LOCK_PHASE}>
              <BlockIc
                onClick={onBlockIconHandler}
                className={classes['phaseLock__lockIcon']}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div
          className={classNames(classes.phaseLock, {
            [classes['phaseLock__lockIcon--disabled']]:
              firstBlockedPhaseIndex !== phaseIndex,
          })}
          onMouseEnter={hoveredPhaseOnMouseEnterHandler}
          onMouseLeave={hoveredPhaseOnMouseLeaveHandler}
        >
          {hoveredPhase === phaseIndex &&
          firstBlockedPhaseIndex === phaseIndex ? (
            <Tooltip placement="right" title={PROGRAM.UNLOCK_PHASE}>
              <UnblockIc
                onClick={onUnblockIconHandler}
                className={classes['phaseLock__lockIcon']}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="right" title={PROGRAM.LOCK_PHASE}>
              <BlockIc className={classes['phaseLock__lockIcon']} />
            </Tooltip>
          )}
        </div>
      )}
      <LockUnlockPhaseModal
        modalType={modalType}
        blockPhase={blockPhase}
        isLockingModalShown={isLockingModalShown}
        closeModal={closeModal}
      />
    </>
  );
};
