import React, { FC, useState } from 'react';
import { CustomPopover } from 'components/customPopover/CustomPopover';
import { FilterPopover } from '../filterPopover/FilterPopover';

import type { IFilterProps } from '../../models';
import S from './Filter.styled';

export const Filter: FC<IFilterProps> = ({
  activatedClientsChecked,
  deactivatedClientsChecked,
  onSetActivatedClientsChecked,
  onSetDeactivatedClientsChecked,
  onClearFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const clickedElementInsideButton = <S.FilterIcon />;

  return (
    <CustomPopover
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      clickedElement={clickedElementInsideButton}
    >
      <FilterPopover
        activatedClientsChecked={activatedClientsChecked}
        deactivatedClientsChecked={deactivatedClientsChecked}
        onSetActivatedClientsChecked={onSetActivatedClientsChecked}
        onSetDeactivatedClientsChecked={onSetDeactivatedClientsChecked}
        onClearFilter={onClearFilter}
      />
    </CustomPopover>
  );
};
