import { useMutation } from 'react-query';
import { ERROR_MESSAGE } from 'constants/index';
import { useSnackbar } from 'notistack';
import instance from 'lib/api/axios';

import type { TFileResponse } from 'models';
import type { TUser } from 'containers/app/trainer/models';

type TUseFetch = {
  user: TUser | null;
  setUser: (data: TUser) => void;
  onCloseModal: () => void;
};

type TUpdateTrainerBrandingDataResponse = Pick<
  TUser['trainerDetails'],
  'welcomeText' | 'selfIntroductoryText' | 'logoUrl'
>;

type TUpdateTrainerBrandingDataPayload = {
  data: {
    item: TUpdateTrainerBrandingDataResponse;
  };
};

export const useFetch = ({ user, setUser, onCloseModal }: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: uploadBrandingLogoMutation,
    isLoading: isLoadingUploadBrandingLogo,
  } = useMutation<TFileResponse, Error, FormData>({
    mutationFn: (formData) => {
      return instance.post('File/upload?containertype=1', formData);
    },
    onError: (e: Error) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const {
    mutate: updateTrainerBrandingDataMutation,
    isLoading: isLoadingUpdateTrainerBrandingData,
  } = useMutation<
    TUpdateTrainerBrandingDataPayload,
    Error,
    TUpdateTrainerBrandingDataResponse
  >({
    mutationFn: (data) => {
      return instance.put(`User/update-trainer/${user?.id}`, {
        data,
      });
    },
    onSuccess: ({
      data: {
        item: { welcomeText, selfIntroductoryText, logoUrl },
      },
    }) => {
      enqueueSnackbar('User data was successfully updated', {
        variant: 'success',
      });
      onCloseModal();

      // update user info to see updated data after opening "Branding" modal
      // one more time and avoid additional request
      if (user) {
        setUser({
          ...user,
          trainerDetails: {
            ...user.trainerDetails,
            welcomeText,
            selfIntroductoryText,
            logoUrl,
          },
        });
      }
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return {
    uploadBrandingLogoMutation,
    isLoadingUploadBrandingLogo,

    updateTrainerBrandingDataMutation,
    isLoadingUpdateTrainerBrandingData,
  };
};
