import React, { FC, ReactNode, Suspense } from 'react';
import Loader from 'components/loader/Loader';
import { Sidebar } from 'components/sidebar/Sidebar';
import { Header } from '../header/Header';
import { useLayout } from 'providers/layout/hooks/useLayout';

import S from './Layout.styled';

interface ILayoutProps {
  children: ReactNode;
}

const DEFAULT_ROUTE_PATH = { title: '' };

export const Layout: FC<ILayoutProps> = ({ children }) => {
  const { headerTitle, routePath, headerCountTitle, additionalUI } = useLayout();

  return (
    <Suspense fallback={<Loader />}>
      <Header
        headerCount={headerCountTitle ? headerCountTitle : 0}
        headerTitle={headerTitle}
        routePath={routePath || DEFAULT_ROUTE_PATH}
        additionalUI={additionalUI}
      />
      <S.Wrapper>
        <Sidebar />
        <div className="right">
          <main className="main">{children}</main>
        </div>
      </S.Wrapper>
    </Suspense>
  );
};
