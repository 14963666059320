// TODO - implement common error structure on both sides - FE + BE
import { AxiosError } from 'axios';
import { ERROR_MESSAGE } from '../constants';

export const getErrorInValidFormat = (e: AxiosError) => {
  if (e?.response?.data?.errors?.[0]?.message) {
    return e.response.data.errors[0].message
  } else if (e.response?.data?.errors?.[0]?.code) {
    return e.response.data.errors[0].code
      ?.replace(/_+/g, ' ')
      ?.trim()
      ?.toLowerCase()
  } else if (e.response?.statusText) {
    return e.response.statusText
  } else {
    return String(e) || ERROR_MESSAGE;
  }
}