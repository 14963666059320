import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { noop } from 'utils';

import S from './CustomPopover.styled';

interface ICustomPopoverProps extends PropsWithChildren {
  anchorEl: HTMLElement | null;
  setAnchorEl: (el: HTMLElement | null) => void;
  clickedElement: ReactElement;
  onChangePopoverVisibilityCallback?: (isShown: boolean) => void;
  children: ReactElement;
}

export const CustomPopover: FC<ICustomPopoverProps> = ({
  anchorEl,
  setAnchorEl,
  clickedElement,
  onChangePopoverVisibilityCallback = noop,
  children,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePopoverVisibilityCallback(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onChangePopoverVisibilityCallback(false);
  };

  return (
    <>
      <S.Button onClick={handleClick}>{clickedElement}</S.Button>
      <S.CustomPopoverPaper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </S.CustomPopoverPaper>
    </>
  );
};
