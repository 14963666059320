import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { NoMessagesScreen } from 'components/noMessagesScreen/NoMessagesScreen';
import ClientPhases from 'containers/app/trainer/currentClient/clientPhases';
import { useFetch } from './hooks/useFetch';

import type { TClientWithMessages } from '../models';

import S from './ActiveRightContent.styled';

interface IActiveRightContentProps {
  activeClient: TClientWithMessages | null;
}

export const ActiveRightContent: FC<IActiveRightContentProps> = ({
  activeClient,
}) => {
  const [clientActiveProgramId, setClientActiveProgramId] = useState<number>(0);
  const history = useHistory();

  const { getClientActiveProgram, isLoadingClientActiveProgram } = useFetch({
    clientId: activeClient?.id || '',
    setClientActiveProgramId,
  });

  useEffect(() => {
    if (activeClient && !clientActiveProgramId) {
      getClientActiveProgram();
    }
    if (!activeClient && clientActiveProgramId) {
      setClientActiveProgramId(0);
    }
  }, [activeClient]);

  return (
    <S.Wrapper>
      {!isLoadingClientActiveProgram && (
        <>
          {activeClient && clientActiveProgramId ? (
            <ClientPhases
              clientIdProp={activeClient.id}
              programIdProp={clientActiveProgramId}
              history={history}
            />
          ) : activeClient ? (
            <S.NoProgramLabel>
              <p>This client does not have an active program at the moment.</p>
              <p>Please create a new program first.</p>
            </S.NoProgramLabel>
          ) : (
            <NoMessagesScreen />
          )}
        </>
      )}
    </S.Wrapper>
  );
};
