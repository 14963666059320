import styled from '@emotion/styled';
import { HEADER_HEIGHT } from 'constants/index';
import WeekBlock from 'components/_new/phase/sub/PhaseWeek/PhaseWeek.styled';
import { screenHDPlus } from 'constants/screenSizes';

const Wrapper = styled.div`
  display: flex;
  flex: 7;
  position: relative;

  height: calc(100vh - ${HEADER_HEIGHT}px);
  transition: 2s ease-in-out all;
  overflow: auto;
  
  & ${WeekBlock.Wrapper} {
    @media screen and (max-width: ${screenHDPlus - 1}px) {
      flex-basis: 48%;
    }
  }
`;

const NoProgramLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  color: #fff;
`;

export default { Wrapper, NoProgramLabel };