export const ADD_CLIENT_FORM_MAX_VALUES = {
  birthDay: 31,
  birthYear: new Date().getFullYear(),
  height: 300,
  weight: 300,
};

export const MONTHS_DATA = [
  {
    id: 'january',
    name: 'January',
  },
  {
    id: 'february',
    name: 'February',
  },
  {
    id: 'march',
    name: 'March',
  },
  {
    id: 'april',
    name: 'April',
  },
  {
    id: 'may',
    name: 'May',
  },
  {
    id: 'june',
    name: 'June',
  },
  {
    id: 'july',
    name: 'July',
  },
  {
    id: 'august',
    name: 'August',
  },
  {
    id: 'september',
    name: 'September',
  },
  {
    id: 'october',
    name: 'October',
  },
  {
    id: 'november',
    name: 'November',
  },
  {
    id: 'december',
    name: 'December',
  },
];

export const CLIENTS_SORT_OPTIONS = [
  { name: 'Oldest', id: 'oldest' },
  { name: 'Newest', id: 'newest' },
];

export const genderState = {
  male: 0,
  female: 1,
} as const;

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;