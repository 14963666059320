import React, { FC } from 'react';
import { ImportTemplateModal } from './importTemplateModal/ImportTemplateModal';
import type { TImportTemplatePayload } from './models';

interface IImportTemplateProps {
  programId?: number;
  importTemplateMutation: (payload: TImportTemplatePayload) => void;
  setIsImportTemplateModalShown: (arg: boolean) => void;
}

export const ImportTemplate: FC<IImportTemplateProps> = ({
  programId,
  importTemplateMutation,
  setIsImportTemplateModalShown,
}) => {
  const closeModalHandler = () => {
    setIsImportTemplateModalShown(false);
  };

  return (
    <>
      <ImportTemplateModal
        importTemplateMutation={importTemplateMutation}
        closeModalHandler={closeModalHandler}
        programId={programId}
      />
    </>
  );
};
