import React, { FC, useState } from 'react';
import { ExerciseReference } from '../../components/exerciseReference/ExerciseReference';
import { parseDateToCorrectNewFormat } from 'utils';
import gaEvents from 'utils/gaEvents';
import { HoveredMenuWrapper } from './components/hoveredMenuWrapper/HoveredMenuWrapper';
import { useFetch } from './hooks/useFetch';
import { renderAttachmentByFileType } from './utils';
import { RepliedMessage } from './components/repliedMessage/RepliedMessage';
import { LightboxModal } from './components/lightboxModal/LightboxModal';

import type { TGeneralExerciseReference } from 'components/_new/phase/models';
import type { IMessageProps } from './models';

import { CheckMarkIc } from 'assets/svg';
import S from './Message.styled';

export const Message: FC<IMessageProps> = ({
  messageData,
  isUserDataShown,
  currentUserId,
  setNavigatedExerciseReference,
  onSuccessDeleteMessage,
  onSuccessAddReaction,
  onReply,
  activeClient,
  getClientsMutation,
  getClientMessagesMutation,
  user,
  onSendMessage,
  readOnly,
}) => {
  const {
    text,
    createdOn,
    senderId,
    exerciseReference,
    attachmentUrl,
    attachmentThumbnailUrl,
    fileType,
    isRead,
    isSending,
    id,
    reactions,
    replied,
  } = messageData;
  const [isDeleteTimerActivated, setIsDeleteTimerActivated] = useState(false);
  const { fullDate } = parseDateToCorrectNewFormat({ date: createdOn });
  const isMessageFromCurrentUser = senderId === currentUserId;

  const { deleteMessageMutation, reactOnMessageMutation } = useFetch({
    onSuccessDeleteMessage,
    onSuccessAddReaction,
    setIsDeleteTimerActivated,
  });

  const goToTheExerciseReferenceHandler = (
    exerciseReference: TGeneralExerciseReference,
  ) => {
    gaEvents.chatClickOnExerciseReference();
    if (exerciseReference) {
      setNavigatedExerciseReference({ ...exerciseReference });
    }
  };

  const onCancelReaction = () => {
    const payload = { messageId: id, reaction: '' };
    onSuccessAddReaction(payload);
    reactOnMessageMutation(payload);
  };

  const getMediaFile = (isForPreview = false) =>
    renderAttachmentByFileType({ attachmentUrl, fileType, isForPreview, attachmentThumbnailUrl, });

  const messageIcon = isSending ? (
    <CheckMarkIc className="messageSeen" />
  ) : (
    <S.Icon seen={isRead} />
  );

  const EXERCISE_REFERENCE_STYLES = { background: isMessageFromCurrentUser ? '#31363F' : '#1C1F21' };

  const renderAttachmentBasedOnFileType = () => {
    if (!attachmentUrl) {
      return null;
    }
    if (fileType) {
      return (
        <LightboxModal
          user={user}
          onSendMessage={onSendMessage}
          getClientMessagesMutation={getClientMessagesMutation}
          activeClient={activeClient}
          getClientsMutation={getClientsMutation}
          onSuccessDeleteMessage={onSuccessDeleteMessage}
          onSuccessAddReaction={onSuccessAddReaction}
          currentUserId={currentUserId}
          mainMessage={messageData}
          getMediaFile={getMediaFile}
          readOnly={readOnly}
        />
      );
    }

    return <div className="attachment">{getMediaFile(true)}</div>;
  };

  return (
    <S.Wrapper
      isMessageFromCurrentUser={isMessageFromCurrentUser}
      isUserDataShown={isUserDataShown}
    >
      {isUserDataShown && (
        <div className="messageInfo">
          <p className="messageDate">{fullDate}</p>
        </div>
      )}
      <HoveredMenuWrapper
        messageId={id}
        reactions={reactions}
        isMessageFromCurrentUser={isMessageFromCurrentUser}
        isDeleteTimerActivated={isDeleteTimerActivated}
        setIsDeleteTimerActivated={setIsDeleteTimerActivated}
        deleteMessageMutation={deleteMessageMutation}
        reactOnMessageMutation={reactOnMessageMutation}
        onSuccessAddReaction={onSuccessAddReaction}
        onReply={onReply}
        readOnly={readOnly}
      >
        <div
          className={`messageTextWrapper ${
            reactions?.length && 'withReaction'
          }`}
        >
          {replied && (
            <RepliedMessage
              message={replied}
              goToTheExerciseReferenceHandler={goToTheExerciseReferenceHandler}
              hasParentMessageExerciseReference={Boolean(exerciseReference)}
              readOnly={readOnly}
            />
          )}
          <p>{text}</p>
          {exerciseReference && (
            <button
              onClick={() => goToTheExerciseReferenceHandler(exerciseReference)}
              className="exerciseReferenceWrapper"
            >
              <ExerciseReference
                exerciseReference={exerciseReference}
                readOnly
                styles={EXERCISE_REFERENCE_STYLES}
              />
            </button>
          )}
          {renderAttachmentBasedOnFileType()}
          {isMessageFromCurrentUser && messageIcon}
          {reactions?.length ? (
            <button onClick={onCancelReaction} className="reaction">
              {/* TODO: fix it after adding support some reactions on one message*/}
              {reactions[0].code}
            </button>
          ) : (
            ''
          )}
        </div>
      </HoveredMenuWrapper>
    </S.Wrapper>
  );
};
