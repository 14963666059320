import styled from '@emotion/styled';

const Wrapper = styled.div`
  padding: 40px;
`;

const LoaderWrapper = styled.div`
  padding: 20px;
`;

export default { Wrapper, LoaderWrapper };
