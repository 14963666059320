import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { CustomPopover } from 'components/customPopover/CustomPopover';
import Textarea from '../../../Textarea/Textarea';
import { ExerciseReference } from 'pages/ChatPage/components/exerciseReference/ExerciseReference';
import { useFetch } from 'pages/ChatPage/chat/hooks/useFetch';
import { getClientIdBasedOnOpenedProgramUrl } from 'utils/clientUtils';
import gaEvents from 'utils/gaEvents';
import { PROGRAM } from 'constants/tooltips';
import { Tooltip } from 'components/tooltip/Tooltip';

import type { TGeneralExerciseReference } from '../../models';

import { ChatIc, SendAsPlaneIc } from 'assets/svg';
import S from './QuickMessage.styled';

interface IQuickMessageProps {
  generalExerciseReference: TGeneralExerciseReference;
  onShowPopoverCallback: (isShown: boolean) => void;
}

export const QuickMessage: FC<IQuickMessageProps> = ({
  generalExerciseReference,
  onShowPopoverCallback,
}) => {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // open modal listener to focus on Quick message textarea after chat icon was clicked
  useEffect(() => {
    if (anchorEl && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [anchorEl, inputRef?.current]);

  const onSuccessSentMessage = () => {
    enqueueSnackbar('Message is sent successfully', { variant: 'success' });
  };
  const { sendMessageMutation } = useFetch({});

  const onSendMessage = () => {
    gaEvents.sendQuickMessageFromBP();

    sendMessageMutation({
      messagePayload: {
        receiverIds: [
          getClientIdBasedOnOpenedProgramUrl(history.location.pathname),
        ],
        text: message,
        exerciseReference: generalExerciseReference,
      },
      updateCallback: onSuccessSentMessage,
    });
    setAnchorEl(null);
  };

  const onChangePopoverVisibilityCallback = (isShown: boolean) => {
    onShowPopoverCallback(isShown);
    setMessage('');

    if (isShown) {
      gaEvents.openQuickMessageModalFromBP();
    } else {
      gaEvents.closeQuickMessageModalFromBP();
    }
  };

  const QuickMessageButton = (
    <Tooltip title={PROGRAM.QUICK_MESSAGE}>
      <ChatIc className="chatIcon" />
    </Tooltip>
  );

  return (
    <div className="chatButton">
      <CustomPopover
        clickedElement={QuickMessageButton}
        onChangePopoverVisibilityCallback={onChangePopoverVisibilityCallback}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <S.Wrapper>
          <div className="row">
            <div className="textarea">
              <Textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type message..."
                resize="none"
                ref={inputRef}
              />
            </div>
            {message && (
              <button onClick={onSendMessage} className="sendButton">
                <SendAsPlaneIc />
              </button>
            )}
          </div>
          <ExerciseReference
            exerciseReference={generalExerciseReference}
            readOnly
          />
        </S.Wrapper>
      </CustomPopover>
    </div>
  );
};
