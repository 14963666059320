import { styled } from '@mui/material';

const ClientInfo = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 25px;

  & .clientPhoto {
    height: 48px;
    width: 48px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
  }

  & .clientName {
    color: #fff;
    margin-left: 5px;
  }
`;

export default { ClientInfo };
