import React, { memo, useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';
import { ReactSVG } from 'react-svg';
import { withStyles } from '@material-ui/core/styles';
import Scrollbar from 'react-scrollbars-custom';
import Tooltip from '@material-ui/core/Tooltip';
import { Tooltip as CustomTooltip } from 'components/tooltip/Tooltip'
import { EmptyIc } from 'assets/svg';
import { EXERCISES } from '../../constants/tooltips';

export type TExercisesItem = {
  name: string;
  description: string;
  videoUrl?: string;
  alternativeVideoUrl?: string;
  id: number;
  localFile?: {
    url: string;
    file: null | File;
  };
};

type Props = {
  exercise: TExercisesItem;
  openEditModalHandler?: (exercise: TExercisesItem) => void;
  openDeleteModalHandler?: (exercise: TExercisesItem) => void;
  additionalElem?: JSX.Element;
};

export const ExerciseItem = memo(
  ({
    exercise,
    openEditModalHandler,
    openDeleteModalHandler,
    additionalElem,
  }: Props) => {
    const ActionsTooltip = useMemo(
      () =>
        withStyles(() => ({
          arrow: {
            color: '#FFEF01',
          },
          tooltip: {
            backgroundColor: '#FFEF01',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 82,
            height: 32,
            zIndex: 10
          },
          tooltipPlacementBottom: {
            top: '-20px',
          },
          tooltipPlacementTop: {
            top: '20px',
          },
        }))(Tooltip),
      [],
    );

    const getTooltipElem = () => {
      if (openEditModalHandler && openDeleteModalHandler) {
        return (
          <div className="exercises-exercise-control-icons">
            <CustomTooltip title={EXERCISES.EDIT_EXERCISE}>
              <ReactSVG
                onClick={() => openEditModalHandler?.(exercise)}
                className="exercises-exercise-control-icons-icon"
                src="/assets/svg/edit-exercise.svg"
              />
            </CustomTooltip>
            <CustomTooltip title={EXERCISES.DELETE_EXERCISE}>
              <ReactSVG
                onClick={() => openDeleteModalHandler?.(exercise)}
                className="exercises-exercise-control-icons-icon"
                src="/assets/svg/delete-exercise.svg"
              />
            </CustomTooltip>
          </div>
        );
      }

      return '';
    };

    return (
      <ActionsTooltip
        title={getTooltipElem()}
        arrow
        interactive
        TransitionProps={{ timeout: 600 }}
        placement="bottom"
      >
        <li className="exercises-exercise">
          <div className="exercises-exercise-control">
            <div className="exercises-exercise-video">
             {exercise.videoUrl ? (
              <ReactPlayer
                controls
                width="100%"
                height="100%"
                url={exercise.videoUrl}
              />
             ) : (
              <EmptyIc />
             )}
            </div>
          </div>

          <p className="exercises-exercise-name">{exercise.name}</p>

          <div className="exercises-exercise-description">
            <Scrollbar>
              <p>{exercise.description}</p>
            </Scrollbar>
          </div>

          {additionalElem}
        </li>
      </ActionsTooltip>
    );
  },
);
