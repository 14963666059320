import React from 'react';
import S from './Autocomplete.styled';

export type TOption = { id: number; title: string };

type Props = {
  options: TOption[];
  value: TOption | null;
  onChange: (value: TOption | null) => void;
};

export const Autocomplete = ({ options, value, onChange }: Props) => {
  const isOptionEqualToValue = (option: unknown, value: unknown) =>
    (option as TOption).id === (value as TOption).id;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const PopperComponent = (props: any) => <S.Popper {...props} />;
  const renderOption = (props: object, option: unknown) => (
    <li {...props} key={(option as TOption).id}>
      {(option as TOption).title}
    </li>
  );

  return (
    <S.Autocomplete
      renderOption={renderOption}
      options={options}
      renderInput={(params) => <S.TextField {...params} />}
      getOptionLabel={(option) => (option as TOption).title ?? option}
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(e, value) => onChange((value as TOption) || null)}
      PopperComponent={PopperComponent}
    />
  );
};

Autocomplete.displayName = 'Autocomplete';
