import { useEffect, useState } from 'react';
import { getApiData, postData, putData } from 'lib/api/utils';
import { TFormData } from '../components/ModalExercise/ModalExercise';
import instance from 'lib/api/axios';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from 'constants/index';

type TExercise = {
  name: string;
  description: string;
  videoUrl?: string;
  id: number;
};

type TState<T> = {
  data: T;
  isLoading: boolean;
  error: string;
};

type TUploadResponse = {
  item: { fileUrl: string };
  total: number;
  success: boolean;
};

const exerciseInfo = 'ExerciseInfo';

const useFetch = () => {
  const [exercises, setExercises] = useState<TState<TExercise[]>>({
    data: [],
    isLoading: false,
    error: '',
  });
  const [createExercises, setCreateExercises] = useState<TState<null>>({
    data: null,
    isLoading: false,
    error: '',
  });
  const [editExercises, setEditExercises] = useState<TState<null>>({
    data: null,
    isLoading: false,
    error: '',
  });
  const [isLoadingDeleteExercise, setIsLoadingDeleteExercise] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchExercises();
  }, []);

  async function fetchExercises() {
    setExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    await getApiData(`${exerciseInfo}/trainer-exercise-infos`).then(
      ({ items }: { items: TExercise[] }) => {
        setExercises((prev) => ({ ...prev, data: items }));
      },
    );

    setExercises((prev) => ({
      ...prev,
      isLoading: false,
    }));
  }

  async function fetchCreateExercise(formData: TFormData) {
    setCreateExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const { description, videoUrl, name, localFile } = formData;

    const requestVideoUrl = await (async () => {
      if (localFile.file) {
        return (await UploadFile(localFile.file)) || videoUrl;
      }
      return videoUrl || undefined;
    })();

    const { success } = await postData(
      `${exerciseInfo}/create-trainer-exercise-info`,
      {
        name,
        description,
        videoUrl: requestVideoUrl,
      },
    );

    setCreateExercises((prev) => ({
      ...prev,
      isLoading: false,
    }));

    return success;
  }

  async function fetchEditExercise(formData: TFormData, id?: number) {
    setEditExercises((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const { description, videoUrl, name, localFile } = formData;

    const requestVideoUrl = await (async () => {
      if (localFile.file) {
        return (await UploadFile(localFile.file)) || videoUrl;
      }
      return videoUrl || undefined;
    })();

    const { success } = await putData(
      `${exerciseInfo}/update-trainer-exercise-info?id=${id}`,
      {
        name,
        description,
        videoUrl: requestVideoUrl,
      },
    );

    setEditExercises((prev) => ({
      ...prev,
      isLoading: false,
    }));

    return success;
  }

  async function fetchDeleteExercise(id?: number) {
    setIsLoadingDeleteExercise(true);

    try {
      await instance.delete(
        `${exerciseInfo}/delete-trainer-exercise-info?id=${id}`,
      );

      fetchExercises();
    } catch (err) {
      const error = err as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' });
      }
    }

    setIsLoadingDeleteExercise(false);
  }

  async function UploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await instance.post<TUploadResponse>(
        `File/upload?containertype=0`,
        formData,
      );

      return data.item.fileUrl;
    } catch (err) {
      const error = err as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' });
      }
    }

    return null;
  }

  return {
    exercises,
    fetchExercises,

    createExercises,
    fetchCreateExercise,

    editExercises,
    fetchEditExercise,

    isLoadingDeleteExercise,
    fetchDeleteExercise,
  };
};

export default useFetch;
