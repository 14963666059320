import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TrainersClients } from './clients/Clients';
import CurrentClient from './currentClient';
import ClientPhases from './currentClient/clientPhases';
import ClientsProvider from 'providers/currentClients';
import QuestionnaireProvider from 'providers/questionnaireProvider';
import ExercisesProvider from 'providers/exerciseProvider';
import MyExercisesPage from 'pages/MyExercisesPage';
import AdminExercisesPage from 'pages/SsExercisesPage';
import TemplatesPage from 'pages/TemplatesPage';
import StyledSnackbarProvider from 'providers/SnackbarProvider';
import TrainerExercisesProvider from 'providers/TrainerExercisesProvider';
import AdminExercisesProvider from 'providers/AdminExercisesProvider';
import { FeedbackNotificationBanner } from './feedbackNotificationBanner/FeedbackNotificationBanner';
import { ChatPage } from 'pages/ChatPage/ChatPage';
import { ROUTES } from './routes';
import { Layout } from '../../../components/layout/Layout';

const Router = () => {
  return (
    <TrainerExercisesProvider>
      <AdminExercisesProvider>
        <StyledSnackbarProvider>
          <Layout>
            <ClientsProvider>
              <QuestionnaireProvider>
                <ExercisesProvider>
                  <FeedbackNotificationBanner />
                  <Switch>
                    <Route
                      exact
                      path={ROUTES.TRAINERS_CLIENTS}
                      component={TrainersClients}
                    />
                    <Route
                      exact
                      path={ROUTES.CURRENT_CLIENT}
                      component={CurrentClient}
                    />
                    <Route
                      path={ROUTES.CURRENT_CLIENT_PHASES}
                      component={ClientPhases}
                    />

                    <Route
                      exact
                      path={ROUTES.MY_EXERCISES}
                      component={MyExercisesPage}
                    />
                    <Route
                      exact
                      path={ROUTES.ADMIN_EXERCISES}
                      component={AdminExercisesPage}
                    />
                    <Route
                      exact
                      path={ROUTES.TEMPLATES}
                      component={TemplatesPage}
                    />
                    <Route exact path={ROUTES.CHAT} component={ChatPage} />
                  </Switch>
                </ExercisesProvider>
              </QuestionnaireProvider>
            </ClientsProvider>
          </Layout>
        </StyledSnackbarProvider>
      </AdminExercisesProvider>
    </TrainerExercisesProvider>
  );
};

Router.displayName = 'Router';

export default Router;
