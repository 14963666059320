/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
import React from 'react';
import { Redirect, withRouter } from 'react-router';
import TextInput from 'components/fields/textInput';
import { routes } from 'router/routes';
import { UserContext } from 'providers/userProvider';
import { postData } from 'lib/api/utils';
import Loader from 'components/loader/Loader';
import { ReactSVG } from 'react-svg';
import '../styles.scss';

const ForgotPassword = () => {
    const { user } = React.useContext(UserContext);
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState('');
    const [redirectToSignIn, setRedirectToSignIn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const forgotPasswordHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        postData('Account/forgot-password', {
            email,
        }).then((answer) => {
            if (answer?.success) {
                setRedirectToSignIn(true);
            } else {
                setError(answer);
            }
            setLoading(false);
        });
    };

    if (redirectToSignIn) {
        return <Redirect to={routes.SIGN_IN} />;
    }

    if (user) {
        return <Redirect to='/' />;
    }

    const validateEmail = () => {
        const pattern =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return !pattern.test(email);
    };

    const inputHandler = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div className='sign-in forgot'>
            <div className='forgot-logo'>
                <ReactSVG src='/assets/svg/logo-new.svg' />
            </div>
            <form
                className='sign-in-form forgot-form'
                onSubmit={forgotPasswordHandler}
            >
                <div className='sign-in-form-fields'>
                    {loading ? <Loader /> : ''}
                    <p className='forgot-title'>Forgot password?</p>
                    <p className='forgot-adopt'>
                        Enter the email address and we'll send you instructions to reset
                        your password
                    </p>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            placeholder='Email'
                            type='email'
                            name='email'
                            value={email}
                            onChange={inputHandler}
                        />
                    </div>
                    {error ? <p className='error'>{error}</p> : ''}
                    <button
                        type='submit'
                        disabled={validateEmail()}
                        className='sign-in-form-button forgot-button'
                    >
                        Reset password
                    </button>
                </div>
            </form>
        </div>
    );
};

export default withRouter(ForgotPassword);
