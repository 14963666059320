import React, { memo } from 'react';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loader: { color: '#ffef01', margin: '0 auto', display: 'flex' },
});

type Props = CircularProgressProps;

export const CircularProgress = memo(({ ...props }: Props) => {
  const classes = useStyles();

  return <MuiCircularProgress className={classes.loader} {...props} />;
});
