import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import TextInput from 'components/fields/textInput';
import { useFetch } from '../../hooks/useFetch';
import Modal from 'components/_new/Modal';
import Loader from 'components/loader/Loader';
import gaEvents from 'utils/gaEvents';
import { Tooltip } from 'components/tooltip/Tooltip';
import { HEADER } from 'constants/tooltips';

import type { IEditProfileModalProps, TTrainerData } from '../../models';

import userPhoto from 'assets/userPhoto.png';
import { EditIc } from 'assets/svg';
// TODO: remade all styles with styled v2
import S from './EditProfileModal.styled';

export const EditProfileModal: FC<IEditProfileModalProps> = ({
  user,
  loadUserInfo,
  toggleCloseModal,
}) => {
  const defaultTrainerData: TTrainerData = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    photoUrl: user?.photoUrl || '',
    phoneNumber: user?.phoneNumber || '',
    localFile: {
      url: '',
    },
  };
  const [trainer, setTrainer] = useState<TTrainerData>(defaultTrainerData);
  const [error, setError] = useState('');

  const {
    uploadUserPhotoMutation,
    updateTrainerDataMutation,
    deletePreviousUserPhotoMutation,
    isLoadingUpdateTrainer,
  } = useFetch({ user, loadUserInfo, setError, toggleCloseModal });

  const editUserDataHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    gaEvents.submitSaveEditProfileFromHeader();

    const { firstName, lastName, localFile, phoneNumber } = trainer;

    if (localFile.url) {
      const data = new FormData();
      data.append('file', localFile.file || '');

      uploadUserPhotoMutation(data, {
        onSuccess: ({ data }) => {
          const { item } = data;
          updateTrainerDataMutation({
            firstName,
            lastName,
            phoneNumber,
            photoUrl: item.fileUrl,
            mobilePhotoUrl: item.thumbnailFileUrl,
          });

          if (user?.photoUrl) {
            deletePreviousUserPhotoMutation();
          }
        },
      });
    } else {
      updateTrainerDataMutation({
        firstName,
        lastName,
        phoneNumber,
      });
    }
  };

  const checkDisablingEditProfile = () => {
    return !(
      trainer.firstName !== user?.firstName ||
      trainer.lastName !== user?.lastName ||
      trainer.phoneNumber !== user?.phoneNumber ||
      trainer.localFile.url
    );
  };

  const inputsHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTrainer({
      ...trainer,
      [e.target.name]: e.target.value,
    });
  };

  const photoUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];

    reader.onloadend = () => {
      setTrainer({
        ...trainer,
        localFile: {
          url: reader.result,
          file,
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onCloseModal = () => {
    toggleCloseModal();
    gaEvents.closeEditProfileModalFromHeader();
  };
  const onOutsideCloseModal = () => {
    toggleCloseModal();
    gaEvents.outsideCloseEditProfileModalFromHeader();
  };

  return (
    <>
      <Modal
        show={true}
        onClose={onCloseModal}
        onCloseOnOutsideClick={onOutsideCloseModal}
      >
        <S.ModalWrapper>
          {isLoadingUpdateTrainer && <Loader />}
          <p className="modal-title">Edit Profile</p>
          <form onSubmit={editUserDataHandler}>
            <div className="modal-profile-photo">
              <div
                className="modal-profile-photo-image"
                style={
                  trainer.localFile.url
                    ? { backgroundImage: `url(${trainer.localFile.url})` }
                    : trainer.photoUrl
                    ? { backgroundImage: `url(${trainer.photoUrl})` }
                    : { backgroundImage: `url(${userPhoto})` }
                }
              >
                <div className="modal-profile-photo-icon">
                  <Tooltip title={HEADER.EDIT_PHOTO_PROFILE}>
                    <div className="modal-profile-photo-icon-input">
                      <input
                        className="modal-profile-photo-icon"
                        type="file"
                        accept={'image/*'}
                        onChange={photoUploadHandler}
                        title=""
                      />
                      <EditIc className="editIcon" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="modal-field">
              <TextInput
                placeholder="First name"
                type="text"
                name="firstName"
                value={trainer.firstName || ''}
                onChange={inputsHandler}
              />
            </div>
            <div className="modal-field">
              <TextInput
                placeholder="Last name"
                type="text"
                name="lastName"
                value={trainer.lastName || ''}
                onChange={inputsHandler}
              />
            </div>
            <div className="modal-field">
              <TextInput
                placeholder="Phone"
                type="text"
                name="phoneNumber"
                value={trainer.phoneNumber}
                onChange={inputsHandler}
              />
            </div>
            {error ? <p className="modal-error">{error}</p> : ''}
            <button
              disabled={checkDisablingEditProfile()}
              type="submit"
              className="modal-button"
            >
              Save
            </button>
          </form>
        </S.ModalWrapper>
      </Modal>
    </>
  );
};
