import React, { ChangeEvent, FC } from 'react';
import { Checkbox } from 'components/checkbox/Checkbox';

import type { IFilterProps } from '../../models';

import S from './FilterPopover.styled';

export const FilterPopover: FC<IFilterProps> = ({
  activatedClientsChecked,
  deactivatedClientsChecked,
  onSetActivatedClientsChecked,
  onSetDeactivatedClientsChecked,
  onClearFilter,
}) => {
  const onChangeActivatedClientsChecked = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    onSetActivatedClientsChecked(e.target.checked);
  };
  const onChangeDeactivatedClientsChecked = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    onSetDeactivatedClientsChecked(e.target.checked);
  };
  return (
    <S.Wrapper>
      <div className="header">
        <p>Client’s Status</p>
        <button onClick={onClearFilter} className="clearButton">
          Clear
        </button>
      </div>
      <div className="filterItem">
        <Checkbox
          onChange={onChangeActivatedClientsChecked}
          checked={activatedClientsChecked}
          label="Activated"
        />
      </div>
      <div className="filterItem">
        <Checkbox
          onChange={onChangeDeactivatedClientsChecked}
          checked={deactivatedClientsChecked}
          label="Deactivated"
        />
      </div>
    </S.Wrapper>
  );
};
