import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './styles.scss';

const useStyles = makeStyles(() => ({
    select: {
        background: '#1C1F21',
        borderRadius: '8px',
        padding: '5px 15px',
        color: '#fff',
        minWidth: '200px',
        width: '100%',
        height: '100%'
    },
    dropdownStyle: {
        background: '#23262B',
        boxShadow: '0px 8px 20px rgba(16, 17, 19, 0.6)',
        borderRadius: '8px',
        fontSize: '15px',
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        border: '1px solid #191B1F',
    },
}));

export default function SelectField({ options, value, onChange }) {
    const classes = useStyles();

    return (
        <div id='select-field'>
            <Select
                labelId='demo-simple-select-filled-label'
                value={value ? value.id : ''}
                onChange={(e, name) => onChange(e, name)}
                disableUnderline
                className={classes.select}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
}
