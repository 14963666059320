import React, { ChangeEvent, FC, useState } from 'react';
import { SaveTemplateModal } from './SaveTemplateModal';
import { useSnackbar } from 'notistack';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';
import { useFetch } from '../../../components/importTemplate/hooks/useFetch';
import Loader from 'components/loader/Loader';
import gaEvents from 'utils/gaEvents';
import { isChatPage } from '../../../utils';

import type { TFetchTemplateOptionsResponse } from '../../../components/importTemplate/hooks/useFetch';

interface ISaveTemplateProps {
  phaseId: number;
  isSaveTemplateModalShown: boolean;
  setIsSaveTemplateModalShown: (shouldBeShown: boolean) => void;
}

export const SaveTemplate: FC<ISaveTemplateProps> = ({ isSaveTemplateModalShown, setIsSaveTemplateModalShown, phaseId }) => {
  const [saveTemplateValue, setSaveTemplateValue] = useState('');
  const [isLoadingSaveTemplate, setIsLoadingSaveTemplate] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const changeDescriptionHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setSaveTemplateValue(e.target.value || '');

  const { fetchTemplateOptionsMutation, isLoadingFetchTemplateOptions } =
    useFetch({});

  const submitSaveTemplate = async () => {
    if (isChatPage()) {
      gaEvents.chatSubmitSaveAsTemplate();
    } else {
      gaEvents.submitSaveAsTemplate();
    }

    try {
      setIsLoadingSaveTemplate(true);
      await fetchTemplateOptionsMutation(
        undefined,
        {
          onSuccess: async ({ data: { items: templateOptions } }: TFetchTemplateOptionsResponse) => {
            const existTemplatesNames = (templateOptions || []).map(
              ({ name }) => name.toLowerCase() || '',
            );

            if (existTemplatesNames.includes(saveTemplateValue.toLowerCase())) {
              enqueueSnackbar('A Template With the Same Name Already Exists', {
                variant: 'error',
              });
            } else if (templateOptions !== null) {
              const { data } = await instance.post('ExportedPhase/export', {
                data: {
                  name: saveTemplateValue,
                  phaseId,
                },
              });

              if (data.success) {
                enqueueSnackbar('Template successfully saved', {
                  variant: 'success',
                });
              }
            }
          },
          onError: (e) => {
            const error = String(e) || ERROR_MESSAGE;
            enqueueSnackbar(error, { variant: 'error' });
          }
        },
      );
    } catch (e) {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    } finally {
      setIsLoadingSaveTemplate(false);
      setSaveTemplateValue('');
      setIsSaveTemplateModalShown(false);
    }
  };

  const isLoading = isLoadingSaveTemplate || isLoadingFetchTemplateOptions;
  return (
    <>
      {isLoading && <Loader />}
      <SaveTemplateModal
        isTemplateModalShown={isSaveTemplateModalShown}
        setIsTemplateModalShown={setIsSaveTemplateModalShown}
        saveTemplateValue={saveTemplateValue}
        submitSaveTemplate={submitSaveTemplate}
        changeDescriptionHandler={changeDescriptionHandler}
      />
    </>
  );
};
