import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/material";

export type TResize = "both" | "horizontal" | "none" | "vertical";

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  color: #fff;
  font-weight: 400;
  
  .errorMessage {
    color: red;
  }
`;

const Label = styled('span')<{
  isRequired?: boolean;
}>`
  align-items: flex-start;
  color: #6E7987;
  display: block;
  justify-content: flex-start;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;

  ${({isRequired}) => isRequired && `
    &:after {
      content: '*';
      color: red;
      margin-left: 5px;
    }
  `}
`

export const TextareaWrapper = styled('div')`
  background: rgba(28, 31, 33, 0.9);
  border-radius: 8px;
  overflow: hidden;
  padding: 8px 16px;
  transition: 0.3s ease all;
`;

const Textarea = styled(TextareaAutosize)<{
  resize?: TResize;
}>`
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  background: transparent;
  border: none;
  outline: 0;
  font-size: inherit;
  resize: ${({ resize = "both" }) => resize};
  min-height: 20px;
  width: 100%;
`;

export default { Textarea, Wrapper, Label, TextareaWrapper };
