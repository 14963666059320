import { styled } from '@mui/material';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #fff;
`;

const ExerciseName = styled('span')`
  color: #fff;
`;

export default {
  Wrapper,
  ExerciseName,
};
