import styled from '@emotion/styled';

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  text-transform: uppercase;
  color: #1C1F21;
  background: linear-gradient(162.44deg, #FFEF01 16.76%, #FFD600 89.31%);
  border-radius: 10px;
  padding: 19px 40px;
  gap: 10px;
`;

export default { MenuButton }