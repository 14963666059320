import React, { Fragment } from 'react';
import { getFormattedValue } from '../PhaseWeek/hooks/useHandlers';

import type { TExerciseRow } from '../../models';

import S from './WeekDataGrid.styled';

type TUseRenderWeekData = {
  isDeleted: boolean;
  exerciseRows: TExerciseRow[];
};

export const WeekDataGrid = ({
  isDeleted,
  exerciseRows,
}: TUseRenderWeekData) => {
  return (
    <S.Wrapper>
      <div className="weekSetsHeader">Reps</div>
      <div className="weekSetsHeader">Weight</div>
      <div className="weekSetsHeader">Rest</div>
      <div className="weekSetsHeader">RPE</div>

      {!isDeleted &&
        exerciseRows.map(({ sets, reps, weight, rest, id, rpe = '-' }) => (
          <Fragment key={id}>
            <div className="weekSetsValue">{getFormattedValue(reps)}</div>
            <div className="weekSetsValue">{getFormattedValue(weight)}</div>
            <div className="weekSetsValue">{getFormattedValue(rest)}</div>
            <div className="weekSetsValue">{rpe || '-'}</div>
          </Fragment>
        ))}
    </S.Wrapper>
  );
};
