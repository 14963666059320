import React from 'react';

import { TUser } from '../../../models';

import userPhoto from 'assets/userPhoto.png';
import S from './ClientInfo.styled';

interface IClientInfoProps {
  user: TUser | undefined;
}

export const ClientInfo: React.FC<IClientInfoProps> = ({
  user: { photoUrl, firstName, lastName } = {},
}) => {
  return (
    <S.ClientInfo>
      <div
        className={'clientPhoto'}
        style={{ backgroundImage: `url(${photoUrl || userPhoto})` }}
      />
      <p className={'clientName'}>{`${firstName || ''} ${lastName || ''}`}</p>
    </S.ClientInfo>
  );
};
