/* eslint-disable no-useless-escape */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AutoSelectField } from 'components/fields/autoSelectField/AutoSelectField';
import { ClientsContext } from 'providers/currentClients';
import Loader from 'components/loader/Loader';
import { Client } from './client/Client';
import { getApiData } from 'lib/api/utils';
import { SearchTextInputWithDebounce } from 'components/fields/searchTextInputWithDebounce/SearchTextInputWithDebounce';
import { useLayout } from 'providers/layout/hooks/useLayout';
import gaEvents from 'utils/gaEvents';
import { UserContext } from 'providers/userProvider';
import { Tooltip } from 'components/tooltip/Tooltip';
import { Filter } from './components/filter/Filter';
import { AddClientModal } from './components/addClientModal/AddClientModal';
import { CLIENTS_SORT_OPTIONS } from './constants';

import type { TOnChangeAutoSelectPayload } from 'components/fields/autoSelectField/models';

import { InfoIc } from 'assets/svg';
import S from './Clients.styled';
import STooltip from 'components/tooltip/Tooltip.styled';

// TODO: refactor all component V3

export const TrainersClients = () => {
  const {
    clients,
    onSetClients,
    filteredClients,
    setFilteredClients,
    onFilterClientsOnSearch,
    onSortClients,
  } = useContext(ClientsContext);
  const [showModal, setModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [activatedClientsChecked, setActivatedClientsChecked] = useState(false);
  const [deactivatedClientsChecked, setDeactivatedClientsChecked] =
    useState(false);

  // filter clients base on checkboxes
  useEffect(() => {
    // show all clients when none or both checkboxes are selected
    if (
      (activatedClientsChecked && deactivatedClientsChecked) ||
      (!activatedClientsChecked && !deactivatedClientsChecked)
    ) {
      setFilteredClients(clients);
      // filter clients based on isRestrictedAccess prop
    } else {
      const filteredClients = clients?.filter(({ user }) => {
        const { isRestrictedAccess } = user;
        return activatedClientsChecked
          ? !isRestrictedAccess
          : isRestrictedAccess;
      });
      setFilteredClients(filteredClients?.length ? filteredClients : null);
    }
  }, [
    activatedClientsChecked,
    deactivatedClientsChecked,
    clients,
    setFilteredClients,
  ]);

  const onSetActivatedClientsChecked = (checked: boolean) => {
    setActivatedClientsChecked(checked);
  };
  const onSetDeactivatedClientsChecked = (checked: boolean) => {
    setDeactivatedClientsChecked(checked);
  };
  const onClearFilter = () => {
    setActivatedClientsChecked(false);
    setDeactivatedClientsChecked(false);
  };

  const toggleOpenModal = useCallback(() => setModal(true), []);

  useEffect(() => {
    if (clients && !clients.length) {
      loadClients();
    }
  }, []);

  useEffect(() => {
    onSetClients([]);
    loadClients();
  }, []);

  const loadClients = () => {
    getApiData('Trainer/clients').then((answer) => {
      if (answer?.success && answer?.items?.length) {
        onSetClients(answer.items);
      } else {
        onSetClients(null);
      }
    });
  };

  const filterClientsHandler = (value: string) => {
    setSearchValue(value);
    onFilterClientsOnSearch(value);
  };

  const sortClientsHandler = ({ id }: TOnChangeAutoSelectPayload) => {
    onSortClients({ id });
    gaEvents.useSortBySelect(id);
  };

  const addClientHandler = () => {
    setModalType('addClient');
    toggleOpenModal();

    gaEvents.clickAddClientButton();
  };

  const { initLayoutState } = useLayout();
  const { user } = useContext(UserContext);

  const additionalHeaderUITooltipTitle = (
    <STooltip.Tooltip>
      To change the limit of clients please contact your{' '}
      <span className="colored">administrator</span>.
    </STooltip.Tooltip>
  );
  const getAdditionalHeaderUI = () => {
    const { activeClientsNumber = 0, clientsLimit = 0 } =
      user?.trainerDetails || {};
    return (
      <S.HeaderContent>
        <Tooltip title={additionalHeaderUITooltipTitle}>
          <InfoIc className="infoIcon" />
        </Tooltip>
        {activeClientsNumber < clientsLimit ? (
          <p>
            Active clients: {activeClientsNumber} of {clientsLimit}
          </p>
        ) : (
          <p>You have reached your limit in {clientsLimit} active clients</p>
        )}
      </S.HeaderContent>
    );
  };

  useEffect(() => {
    initLayoutState({
      headerTitle: 'Clients',
      headerCountTitle: clients ? clients.length : 0,
      additionalUI: getAdditionalHeaderUI(),
    });
  }, [clients, initLayoutState]);

  const onFocusOnSearchInput = () => {
    gaEvents.clickFocusOnClientsSearchField();
  };

  return (
    <S.Wrapper>
      {showModal && (
        <AddClientModal
          setModal={setModal}
          loadClients={loadClients}
          modalType={modalType}
          setSearchValue={setSearchValue}
        />
      )}
      <div className="header">
        <div className="header__search">
          <div className="header__search--block">
            <SearchTextInputWithDebounce
              onChange={filterClientsHandler}
              debounceTime={400}
              value={searchValue}
              styles={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onFocus={onFocusOnSearchInput}
            />
          </div>
          <div className="header__search--filter">
            <AutoSelectField
              onChange={sortClientsHandler}
              options={CLIENTS_SORT_OPTIONS}
              placeholder="Sort by"
            />
          </div>
        </div>
        <div className="filter">
          <Filter
            activatedClientsChecked={activatedClientsChecked}
            deactivatedClientsChecked={deactivatedClientsChecked}
            onSetActivatedClientsChecked={onSetActivatedClientsChecked}
            onSetDeactivatedClientsChecked={onSetDeactivatedClientsChecked}
            onClearFilter={onClearFilter}
          />
        </div>
        <button className="header__button" onClick={addClientHandler}>
          + Add Client
        </button>
      </div>
      {!filteredClients || !clients ? (
        <div className="not-found">No clients</div>
      ) : filteredClients.length ? (
        <ul className="list">
          {filteredClients.map((client) => (
            <Client
              loadClients={loadClients}
              client={client}
              key={client.user.id}
            />
          ))}
        </ul>
      ) : (
        <Loader />
      )}
    </S.Wrapper>
  );
};
