import React, { ChangeEvent } from 'react';
import WeekGroup from '../WeekGroup/WeekGroup';
import ExerciseInfo from '../ExerciseInfo/ExerciseInfo';

import type { TExercise, TWeek } from '../../../phase/models';
import type { TExtendedExercise, TOnChangeRowValuePayload, TWeekGroupProps } from '../../models';
import type { TSelection } from 'components/_new/RadioButtonsGroup/RadioButtonsGroup';

import S from './Exercise.styled';

enum catalogIdsEnum {
  admin = 0,
  trainer = 1,
}

type Props = {
  exercise: TExtendedExercise;
  exerciseIndex: number;
  trainerExercisesOptions: TExercise['exerciseInfo'][];
  catalogOptions: Record<
    catalogIdsEnum,
    TSelection & {
      data: TExercise['exerciseInfo'][];
    }
  >;
  onChangeCatalog: (
    exerciseId: TExtendedExercise['id'],
    catalogId: catalogIdsEnum,
  ) => void;
  onChangeExerciseInfo: (
    exerciseId: TExtendedExercise['id'],
    exerciseInfo: TExtendedExercise['exerciseInfo'],
  ) => void;
  onChangeDescription: (
    e: ChangeEvent<HTMLTextAreaElement>,
    exerciseId: TExtendedExercise['id'],
  ) => void;
  onChangeRowValue: TOnChangeRowValuePayload;
  onAddSetToExercise: (
    exerciseId: TExtendedExercise['id'],
    weekName: TWeek['name'],
  ) => void;
  onSubmitRemoveLastSet: (
    exerciseId: TExtendedExercise['id'],
    weekName: TWeek['name'],
  ) => void;
  onSubmitExerciseMasterRow: TWeekGroupProps['onSubmitExerciseMasterRow']
  showExerciseName: boolean;
  modalId: string;
  exercises: TExercise[];
};

const Exercise = ({
  exercise,
  exerciseIndex,
  catalogOptions,
  onChangeCatalog,
  showExerciseName,
  onAddSetToExercise,
  onChangeDescription,
  onChangeExerciseInfo,
  onSubmitRemoveLastSet,
  onChangeRowValue,
  trainerExercisesOptions,
  onSubmitExerciseMasterRow,
  modalId,
  exercises
}: Props) => {
  const {
    exerciseInfo,
    id: exerciseId,
    weeks,
    description,
  } = exercise;
  // exclude all exercises which are already used as another exercises in superset
  const catalog = {
    ...exercise.catalog,
    data: exercise.catalog.data.filter(
      (catalogExercise) =>
        catalogExercise.id === exercise.exerciseInfo.id ||
        !(
          exercises.some((exe) => exe.exerciseInfo.id === catalogExercise.id)
        ),
    ),
  };

  return (
    <S.Wrapper>
      <ExerciseInfo
        catalog={catalog}
        exerciseId={exerciseId}
        description={description}
        exerciseInfo={exerciseInfo}
        exerciseIndex={exerciseIndex}
        catalogOptions={catalogOptions}
        onChangeCatalog={onChangeCatalog}
        showExerciseName={showExerciseName}
        onChangeDescription={onChangeDescription}
        onChangeExerciseInfo={onChangeExerciseInfo}
        trainerExercisesOptions={trainerExercisesOptions}
        modalId={modalId}
      />

      <WeekGroup
        weeks={weeks}
        exerciseId={exerciseId}
        onAddSetToExercise={onAddSetToExercise}
        onSubmitRemoveLastSet={onSubmitRemoveLastSet}
        onChangeRowValue={onChangeRowValue}
        onSubmitExerciseMasterRow={onSubmitExerciseMasterRow}
        modalId={modalId}
      />
    </S.Wrapper>
  );
};

export default Exercise;
