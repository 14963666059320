import styled from '@emotion/styled/macro';
import { flexCenteredStyles, transitionOnIconsHover } from 'styles/commonStyleVariables';
import { css } from '@emotion/react';

const Wrapper = styled('div')`
  & .deltaLabel {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #75797D;
  }
`;

const WeekName = styled('div')<{ withContent?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  color: #fff;
  
  ${({ withContent }) => withContent && css`
    margin-bottom: 16px;
  `}
`;
const SetsList = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const SetItem = styled('li')`
  display: flex;
  gap: 4px;
  
  & .cellsColumn:not(:last-child) {
    border-right: 1px solid #32363D;
    padding-right: 4px;
  }
  
  & .deltasWrapper {
    margin-top: 4px;
  }
  
  & .cellLabelWrapper {
    display: flex;
    align-items: flex-end;
    gap: 4px;
    height: 28px;
    
    padding-bottom: 4px;
    color: #5F6975;
    ${transitionOnIconsHover};
    
    & .infoIcon {
      cursor: pointer;
      
      &:hover {
        color: #FFEF01;
      }
    }
  }

  & .cellLabel {
    color: #6e7987;
    text-transform: capitalize;
    
    &.uppercase {
      text-transform: uppercase;
    }
  }
  
  & .cell {
    flex: 1;
  }
`;
const CellInputWrapper = styled.div<{
  isWithArrows?: boolean;
  isMasterHeaderInput?: boolean;
  disabled?: boolean;
}>`
  & .cellInput {
    all: unset;
    background: #23262b;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    line-height: 22px;
    border: none;
    padding: 8px 10px;
    width: calc(100% - 20px);
  }
  
  ${({ isWithArrows }) => isWithArrows && css`
    position: relative;
    display: flex;
    justify-content: flex-end;
    
    & .arrows {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 2px;
      
      padding: 2px;
      border-radius: 0 6px 6px 0;
      
      & .arrowButton {
        ${flexCenteredStyles};
        flex: 1;
        
        padding: 0 5px;
        background: #31363F;
        color: #6E7987;
        ${transitionOnIconsHover};
        
        &:hover {
          color: #FFEF01;
        }
        
        &:disabled {
          cursor: default;
          
          &:hover {
            color: #6E7987;
          }
        }
        
        &.up {
          border-top-right-radius: 6px;
        }

        &.down {
          border-top-left-radius: 6px;
          transform: rotate(180deg);
        }
      }
    }
  `}
  
  ${({ isMasterHeaderInput }) => isMasterHeaderInput && css`
    max-width: 80px;
    max-height: 40px;
  `}
  
  ${({ disabled }) => disabled && css`
    opacity: 0.4;
  `}
`;

const ActionButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 8px 0;

  & .actionButton {
    background-color: transparent;
    all: unset;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  & .addButton {
    color: #ffef01;

    & svg > path {
      fill: #ffef01;
    }
  }

  & .removeButton {
    color: #ff453a;
  }
`;

const CustomMasterLastWeekWrapper = styled('div')`
  display: flex;
  align-items: center;
  
  & .cellInput {
    padding: 10px 30px 10px 10px;
  }
`;

export default {
  Wrapper,
  CellInputWrapper,
  WeekName,
  SetsList,
  SetItem,
  ActionButtons,
  CustomMasterLastWeekWrapper,
};
