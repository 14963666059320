import styled from '@emotion/styled';
import { flexCenteredStyles } from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  padding: 40px;
  width: 510px;
  
  & .title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    
    margin-bottom: 32px;
  }
  
  & .inputWrapper {
    margin-bottom: 24px;
  }
  
  & .actions {
    margin-top: 16px;
    gap: 20px;
    ${flexCenteredStyles}
  }
`;

export default { Wrapper }