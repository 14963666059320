/* eslint-disable no-self-assign */
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import TextInput from 'components/fields/textInput';
import { routes } from 'router/routes';
import { UserContext } from 'providers/userProvider';
import instance from 'lib/api/axios';
import { getApiData, postData } from 'lib/api/utils';
import logo from 'assets/signinBack.png';
import Loader from 'components/loader/Loader';

import '../styles.scss';

const SignIn = () => {
    const { user, setUser } = React.useContext(UserContext);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState({
        email: '',
        password: '',
    });

    const inputDataHandler = (e) => {
        setAuthData({
            ...authData,
            [e.target.name]: e.target.value,
        });
    };

    const loginHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        let { email, password } = authData;

        email = email;
        password = password;

        const response = await postData('Account/login', {
            email,
            password,
        });

        setLoading(false);
        if (response.success) {

            if(response.item.roles[0] !== 'Trainers') {
                setError('The email or password you entered is incorrect');
                throw new Error('The email or password you entered is incorrect')
            }

            await localStorage.setItem('token', response.item.token);
            await localStorage.setItem('refreshToken', response.item.refreshToken);

            instance.interceptors.request.use(function (config) {
                const token = localStorage.getItem('token');

                config.headers.Authorization = token ? `Bearer ${token}` : '';

                return config;
            });

            await localStorage.setItem('roles', JSON.stringify(response.item.roles));

            const loggedUser = await getApiData('Account/me');

            if (loggedUser.success) {
                setUser({
                    ...loggedUser.item,
                    roles: response.item.roles,
                    isTemporaryPasswordChanged: response.item.isTemporaryPasswordChanged,
                });
            }
        } else {
            setError(response);
        }
    };

    if (user && !user.isTemporaryPasswordChanged) {
        return <Redirect to={routes.FIRST_LOG_IN} />;
    }

    if (user && user.isTemporaryPasswordChanged) {
        return <Redirect to='/' />;
    }

    return (
        <div className='sign-in' style={{ backgroundImage: `url(${logo})` }}>
            {loading ? <Loader /> : ''}
            <form className='sign-in-form' onSubmit={loginHandler}>
                <ReactSVG
                    className='sign-in-form-logo'
                    src='/assets/svg/logo-new.svg'
                />
                <div className='sign-in-form-fields'>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            value={authData.email}
                            onChange={inputDataHandler}
                            placeholder='Email'
                            type='email'
                            name='email'
                        />
                    </div>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            value={authData.password}
                            onChange={inputDataHandler}
                            placeholder='Password'
                            type='password'
                            name='password'
                        />
                    </div>
                    <div className='sign-in-form-forgot'>
                        <NavLink
                            exact
                            className='sign-in-form-forgot-button'
                            to='/forgot-password'
                        >
                            Forgot password?
                        </NavLink>
                    </div>
                    {error ? <p className='error'>{error}</p> : ''}
                    <button
                        type='submit'
                        className='sign-in-form-button'
                    >
                        Log in
                    </button>
                </div>
            </form>
        </div>
    );
};

export default withRouter(SignIn);
