import { styled } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiPopper from '@mui/material/Popper';

const Autocomplete = styled(MuiAutocomplete)`
  background-color: rgba(28, 31, 33, 0.9);
  border-radius: 8px;
  width: 100%;

  &.MuiAutocomplete-root {
    width: 100%;
  }

  & fieldset {
    border: none;
  }

  & .MuiButtonBase-root {
    color: #6e7987;
  }

  & input {
    color: #fff;
  }
`;

const TextField = styled(MuiTextField)``;

const Popper = styled(MuiPopper)`
  & .MuiAutocomplete-listbox,
  .MuiAutocomplete-paper,
  .MuiAutocomplete-noOptions {
    background: #23262b;
    box-shadow: 0px 8px 20px rgba(16, 17, 19, 0.6);
    border-radius: 8px;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    color: #ffffff;
    border: 1px solid #191b1f;
  }
`;

export default { Autocomplete, TextField, Popper };
