import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';
import { noop } from 'utils';

import type { TImportTemplatePayload, TTemplate } from '../models';
import type { TBiggerPhase, TPhase } from '../../../../../models';

type TUseFetch = {
  onImportTemplateSuccessCallback?: (phase: TPhase | TBiggerPhase) => void;
  setTemplateOptions?: (data: TTemplate[]) => void;
  setSelectedTemplate?: (data: TTemplate | null) => void;
};

export type TFetchTemplateOptionsResponse = {
  data: {
    items: TTemplate[];
  };
};

type TImportTemplateResponse = {
  data: {
    item: TBiggerPhase | TPhase;
  };
};

export const useFetch = ({
  onImportTemplateSuccessCallback = noop,
  setTemplateOptions = noop,
  setSelectedTemplate = noop,
}: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: importTemplateMutation, isLoading: isLoadingImportTemplate } =
    useMutation<TImportTemplateResponse, unknown, TImportTemplatePayload>({
      mutationFn: ({ selectedTemplateId, programId }) => {
        return instance.post('/ExportedPhase/import', {
          data: {
            exportedPhaseId: selectedTemplateId,
            programId: programId,
          },
        });
      },
      onSuccess: ({ data: { item } }) => {
        enqueueSnackbar('Phase was successfully imported', {
          variant: 'success',
        });
        onImportTemplateSuccessCallback(item);
      },
      onError: (e) => {
        const error = String(e) || ERROR_MESSAGE;
        enqueueSnackbar(error, { variant: 'error' });
      },
    });

  const {
    mutate: fetchTemplateOptionsMutation,
    isLoading: isLoadingFetchTemplateOptions,
  } = useMutation<TFetchTemplateOptionsResponse, Error>({
    mutationFn: () => {
      return instance.get('ExportedPhase/list');
    },
    onSuccess: ({ data: { items } }) => {
      setTemplateOptions(items || []);
      setSelectedTemplate(items[0] || null);
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return {
    importTemplateMutation,
    isLoadingImportTemplate,

    fetchTemplateOptionsMutation,
    isLoadingFetchTemplateOptions,
  };
};
