import React, {
  type ButtonHTMLAttributes,
  type PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    themeType?: 'light' | 'dark';
  }
>;

export const Button = ({ children, themeType = 'light', ...props }: Props) => (
  <button
    className={classNames(styles.buttonRoot, {
      [styles.darkMode]: themeType === 'dark',
    })}
    {...props}
  >
    {children}
  </button>
);
