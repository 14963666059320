import { TDirection } from './RadioButtonsGroup';
import { Radio as muiRadio, styled } from '@mui/material';

const Wrapper = styled('div')<{ direction: TDirection }>`
  ${({ direction }) =>
    direction === 'row' &&
    `
      display: flex;
      align-items: center;
      column-gap: 15px;
    `}
`;

const Radio = styled(muiRadio)`
  &&& {
    color: #5f6975;

    &.Mui-checked {
      color: #ffef01;
    }
  }
`;

const RadioWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const RadioLabel = styled('label')`
  cursor: pointer;
`;

export default {
  Wrapper,
  Radio,
  RadioWrapper,
  RadioLabel,
};
