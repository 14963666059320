import React, { FC, ReactElement, ReactNode, useMemo } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@mui/material';

interface ITooltipProps {
  title?: ReactNode;
  placement?: TooltipProps['placement'];
  withArrow?: boolean;
  styles?: any; // TODO - fix any to CSSProperties
  timeout?: number;
  children: ReactElement;
}

export const Tooltip: FC<ITooltipProps> = ({
  title,
  placement = 'bottom',
  withArrow = false,
  styles = {},
  timeout = 600,
  children,
}) => {
  const StyledTooltip = useMemo(
    () =>
      withStyles(() => ({
        tooltip: {
          backgroundColor: '#23262B',
          color: '#fff',
          fontSize: '14px',
          border: ' 1px solid #3E434B',
          boxShadow: '0px 4px 20px 0px #0A0C0F80',
          padding: typeof title === 'string' ? '10px' : 0,
          ...styles,
        },
        arrow: withArrow
          ? {
              color: '#22262A',
            }
          : {
              display: 'none',
            },
      }))(MuiTooltip),
    [],
  );

  return (
    <StyledTooltip
      title={title || ''}
      placement={placement}
      arrow
      interactive
      TransitionProps={{ timeout }}
      PopperProps={{
        style:
          styles.zIndex !== undefined
            ? { zIndex: styles.zIndex }
            : { zIndex: 1111 },
      }}
    >
      {children}
    </StyledTooltip>
  );
};
