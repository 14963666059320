import { deleteApiData, getApiData, postData, putData } from '../../utils';

export const getCategories = () => {
    return getApiData('Category');
};

export const createCategory = (data) => {
    return postData('Category', data);
};

export const updateCategory = (id, data) => {
    return putData(`Category/${id}`, data)
}

export const deleteCategory = (id) => {
    return deleteApiData(`Category/${id}`)
}