import React, { FC } from 'react';

import { CheckboxCheckerIc } from '../../assets/svg';
import S from './Checkbox.styled';

interface ICheckboxProps {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  name?: string;
  width?: number;
  height?: number;
  checkerColor?: string;
}

export const Checkbox: FC<ICheckboxProps> = ({
  label,
  onChange,
  checked,
  name,
  width,
  height,
  checkerColor,
}) => {
  return (
    <S.Label
      control={
        <S.Checkbox
          onChange={onChange}
          checked={checked}
          name={name}
          icon={<S.Icon width={width} height={height} />}
          checkedIcon={
            <S.CheckedIcon width={width} height={height} color={checkerColor}>
              <CheckboxCheckerIc />
            </S.CheckedIcon>
          }
          disableRipple
        />
      }
      label={label}
    />
  );
};
