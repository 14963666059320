import { styled } from '@mui/material';

const Client = styled('div')`
  background: #191b1f;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;

const ClientData = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ClientPhoto = styled('div')`
  height: 100px;
  width: 100px;
  background: 50% / cover no-repeat;
  margin-right: 10px;
  border-radius: 4px;
`;

const ClientInfo = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ClientName = styled('p')`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 5px;
`;

const ClientDetails = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;

const ClientDetailsBlock = styled('div')`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const ClientDetailsLabel = styled('p')`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6e7987;
  margin-bottom: 2px;
`;

const ClientDetailsValue = styled('p')`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`;

export default {
  Client,
  ClientData,
  ClientPhoto,
  ClientInfo,
  ClientName,
  ClientDetails,
  ClientDetailsBlock,
  ClientDetailsLabel,
  ClientDetailsValue,
};
