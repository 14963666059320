import React, {
  createContext,
  PropsWithChildren,
  RefObject,
  useRef,
} from 'react';

interface IFocusContextProps<T> {
  inputRef: RefObject<T>;
}

export const InputFocusContext = createContext<
  IFocusContextProps<any> | undefined
>(undefined);

export const InputFocusProvider = <T,>({ children }: PropsWithChildren) => {
  const inputRef = useRef<T | null>(null);

  return (
    <InputFocusContext.Provider value={{ inputRef }}>
      {children}
    </InputFocusContext.Provider>
  );
};
