import React, { useState, createContext } from 'react';

const initialState = { exercises: [] };

export const ExercisesContext = createContext(initialState);

const ExercisesProvider = (props) => {
    const [exercises, setExercises] = useState([]);
    const [filteredExercises, setFilteredExercises] = useState([]);

    const onSetExercises = (data) => {
        if (data) {
            setExercises(data);
            setFilteredExercises(data);
        } else {
            setExercises(null);
            setFilteredExercises(null);
        }
    };

    const onFilterExercises = (value) => {
        if (!value) {
            setFilteredExercises(exercises);

            return;
        }
        if (exercises && exercises.length) {
            const filtered = exercises.filter((exercise) => {
                return Object.values(exercise).some((field) => {
                    return `${field}`.toLowerCase().includes(value.toLowerCase());
                });
            });

            if (!filtered.length) {
                setFilteredExercises(null);

                return;
            }
            setFilteredExercises(filtered);
        }
    };

    return (
        <ExercisesContext.Provider
            value={{
                exercises,
                onSetExercises,
                filteredExercises,
                onFilterExercises,
            }}
        >
            {props.children}
        </ExercisesContext.Provider>
    );
};

export default ExercisesProvider;
