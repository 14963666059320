import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { Tooltip } from 'components/tooltip/Tooltip';
import {
  INCORRECT_IMAGE_URL,
  MESSAGE_ATTACHMENT_IMAGE_FILETYPE,
  MESSAGE_ATTACHMENT_VIDEO_FILETYPE,
  UNKNOWN_FILE_TYPE,
} from '../constants';
import { parseDateToCorrectNewFormat } from 'utils';

import type { TFileTypeKey, TMessage } from '../../../models';
import type { TReactOnMessageResponse } from '../models';

import { PlayIc, UnknownAttachmentIc } from 'assets/svg';

export const renderAttachmentByFileType = ({
  attachmentUrl,
  attachmentThumbnailUrl,
  fileType,
  isForPreview,
}: {
  attachmentUrl: string;
  attachmentThumbnailUrl: string | undefined;
  fileType?: TFileTypeKey;
  isForPreview?: boolean;
}) => {
  const getVideoPreview = (): JSX.Element | boolean => {
    if (!isForPreview) {
      return false;
    }
    if (attachmentThumbnailUrl) {
      return <img src={attachmentThumbnailUrl} alt='Play' />;
    }
    return true;
  }

  switch (fileType) {
    case MESSAGE_ATTACHMENT_IMAGE_FILETYPE:
      return (
        <div className="imageWrapper">
          <img
            className="image"
            src={attachmentUrl}
            alt={isForPreview ? '' : `. ${INCORRECT_IMAGE_URL}`}
          />
        </div>
      );
    case MESSAGE_ATTACHMENT_VIDEO_FILETYPE:
      return (
        <div className="videoWrapper">
          {isForPreview && <PlayIc className="videoPlayButton" />}
          <ReactPlayer
            controls={!isForPreview}
            playIcon={<PlayIc className="videoPlayButton" />}
            width="100%"
            height="100%"
            url={attachmentUrl}
            playing={!isForPreview}
            light={getVideoPreview()}
          />
        </div>
      );
    default:
      return (
        <Tooltip title={UNKNOWN_FILE_TYPE} timeout={1000}>
          <UnknownAttachmentIc className="unknownFileIcon" />
        </Tooltip>
      );
  }
};

export const checkUserDataToBeShown = ({
  messageData,
  index,
  activeMessages,
}: {
  messageData: TMessage;
  index: number;
  activeMessages: TMessage[];
}) => {
  const { day } = parseDateToCorrectNewFormat({
    date: messageData.createdOn,
  });
  const { day: prevDay } = parseDateToCorrectNewFormat({
    date: activeMessages[index - 1]?.createdOn,
  });
  return (
    messageData.senderId !== activeMessages[index - 1]?.senderId ||
    day !== prevDay
  );
};

type TUpdateMessageReactionsByIdProps = {
  prevMessages: TMessage[];
  currentUserId?: string;
} & TReactOnMessageResponse;

export const updateMessageReactionsById = ({
  reaction: newReactionCode,
  messageId,
  prevMessages,
  currentUserId = '',
}: TUpdateMessageReactionsByIdProps) => {
  const updatedMessages = prevMessages?.map((message) => {
    // message in we need to change reaction
    if (message.id === messageId) {
      // if new reaction is not empty string - to add it, if isn't - to remove from state
      if (newReactionCode) {
        const isThereAlreadyReactionFromCurrentUser =
          message.reactions?.findIndex(
            (reaction) => reaction.userId === currentUserId,
          );
        const newReaction = {
          code: newReactionCode,
          userId: currentUserId || '',
        };

        return {
          ...message,
          // change reaction if user already left another
          reactions:
            isThereAlreadyReactionFromCurrentUser !== -1 &&
            message.reactions?.length
              ? message.reactions.map((reaction) => {
                  if (reaction.userId === currentUserId) {
                    return newReaction;
                  }
                  return reaction;
                })
              : [...(message.reactions || []), newReaction], // add new first one
        };
      }
      return {
        ...message,
        reactions: message.reactions?.filter((reaction) => {
          return reaction.userId !== currentUserId;
        }),
      };
    }
    return message;
  });

  return updatedMessages || null;
};
