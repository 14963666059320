import React, { FC, ReactNode } from 'react';
import { Tooltip } from '../tooltip/Tooltip';

import S from './IconButtonContainer.styled'

interface IIconButtonContainerProps {
  onClick: () => void;
  title?: string;
  className?: string;
  color?: string;
  children: ReactNode;
}

export const IconButtonContainer: FC<IIconButtonContainerProps> = ({ onClick, title, className, children }) => (
  <Tooltip title={title}>
    <S.IconWrapper onClick={onClick} className={className}>
      {children}
    </S.IconWrapper>
  </Tooltip>
)