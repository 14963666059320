import { InfoCircleIc } from "assets/svg";
import React  from "react";
import S from "./LimitedAccessPage.styled";

export const LimitedAccessPage = () => (
  <S.Wrapper>
    <InfoCircleIc className="icon"/>

    <div>
      Your access has been limited, 
      please contact 
      <br />
      <span ></span>
      <a 
        className="mail" 
        href="mailto: contact@strengthsystem.com"
      >
        contact@strengthsystem.com
      </a>{" "}
      to reactivate your account
    </div>
  </S.Wrapper>
);

