import React, { ChangeEvent } from 'react';
import { Autocomplete } from 'components/_new/Autocomplete/Autocomplete';
import RadioButtonsGroup from 'components/_new/RadioButtonsGroup';
import { TextArea } from 'components/fields/textArea/TextArea';
import { isChatPage } from 'containers/app/trainer/currentClient/clientPhases/utils';
import gaEvents from 'utils/gaEvents';

import type { TSelection } from 'components/_new/RadioButtonsGroup/RadioButtonsGroup';
import type { TExercise } from 'components/_new/phase/models';
import type { TExtendedExercise } from '../../models';

import S from './ExerciseInfo.styled';

enum catalogIdsEnum {
  admin = 0,
  trainer = 1,
}

type Props = {
  description: string;
  exerciseId: TExtendedExercise['id'];
  exerciseIndex: number;
  trainerExercisesOptions: TExercise['exerciseInfo'][];
  catalogOptions: Record<
    catalogIdsEnum,
    TSelection & {
      data: TExercise['exerciseInfo'][];
    }
  >;
  catalog: TExtendedExercise['catalog'];
  onChangeCatalog: (
    exerciseId: TExtendedExercise['id'],
    catalogId: catalogIdsEnum,
  ) => void;
  exerciseInfo: TExercise['exerciseInfo'];
  onChangeExerciseInfo: (
    exerciseId: TExtendedExercise['id'],
    exerciseInfo: TExtendedExercise['exerciseInfo'],
  ) => void;
  onChangeDescription: (
    e: ChangeEvent<HTMLTextAreaElement>,
    exerciseId: TExtendedExercise['id'],
  ) => void;
  showExerciseName: boolean;
  modalId: string;
};

const ExerciseInfo = ({
  catalog,
  exerciseId,
  description,
  exerciseInfo,
  exerciseIndex,
  catalogOptions,
  onChangeCatalog,
  showExerciseName,
  onChangeDescription,
  onChangeExerciseInfo,
  trainerExercisesOptions,
  modalId,
}: Props) => {
  const handleChangeExerciseInfo = (option: TSelection | null) => {
    const newExerciseInfo = catalog.data.find(({ id }) => id === option?.id);
    if (!newExerciseInfo) return;
    onChangeExerciseInfo(exerciseId, newExerciseInfo);
  };

  const onFocusOnDescription = () => {
    if (isChatPage()) {
      gaEvents.chatFocusOnDescriptionOnAddOrEditComplexExerciseModal(modalId);
    } else {
      gaEvents.focusOnDescriptionOnAddOrEditComplexExerciseModal(modalId);
    }
  }

  return (
    <S.Wrapper>
      {showExerciseName && (
        <S.ExerciseName>Exercise {exerciseIndex + 1}</S.ExerciseName>
      )}

      {trainerExercisesOptions.length > 0 && (
        <RadioButtonsGroup
          data={Object.values(catalogOptions)}
          selection={catalog}
          onClick={({ id }) =>
            onChangeCatalog(exerciseId, id as catalogIdsEnum)
          }
          direction="row"
        />
      )}

      <Autocomplete
        options={catalog.data.map(({ id, name }) => ({ id, title: name }))}
        value={{ id: exerciseInfo.id, title: exerciseInfo.name }}
        onChange={handleChangeExerciseInfo}
      />

      <TextArea
        placeholder="Description"
        name="description"
        value={description}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onChangeDescription(e, exerciseId)
        }
        onFocus={onFocusOnDescription}
        rows={2}
        cols={15}
        label={undefined}
        required={undefined}
      />
    </S.Wrapper>
  );
};

export default ExerciseInfo;
