import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { ERROR_MESSAGE } from 'constants/index';
import instance from 'lib/api/axios';

import type { TFileResponse } from 'models';
import type { IEditProfileModalProps, TCurrentUser } from '../models';

type TUseFetch = Pick<IEditProfileModalProps, 'user' | 'loadUserInfo' | 'setError' | 'toggleCloseModal'>

export const useFetch = ({
  user,
  loadUserInfo,
  setError,
  toggleCloseModal,
}: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateTrainerDataMutation,
    isLoading: isLoadingUpdateTrainer,
  } = useMutation<void, unknown, TCurrentUser>({
    mutationFn: (data) => {
      return instance.put(`User/update-trainer/${user?.id}`, {
        data,
      });
    },
    onSuccess: () => {
      enqueueSnackbar('User data was successfully updated', {
        variant: 'success',
      });
      loadUserInfo();
      toggleCloseModal();
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
      setError?.(error);
    },
  });

  const { mutate: deletePreviousUserPhotoMutation } = useMutation<
    void,
    unknown
  >({
    mutationFn: () => {
      return instance.delete('File/delete', {
        data: {
          data: {
            containerType: 1,
            fileUrl: user?.photoUrl,
          },
        },
      });
    },
    onError: (e) => {
      enqueueSnackbar(String(e) || ERROR_MESSAGE, { variant: 'error' });
    },
  });

  const { mutate: uploadUserPhotoMutation } = useMutation<
    TFileResponse,
    unknown,
    FormData
  >({
    mutationFn: (formData) => {
      return instance.post('File/upload?containertype=1', formData);
    },
    onError: (e) => {
      enqueueSnackbar(String(e) || ERROR_MESSAGE, { variant: 'error' });
    },
  });

  return {
    updateTrainerDataMutation,
    uploadUserPhotoMutation,
    deletePreviousUserPhotoMutation,
    isLoadingUpdateTrainer,
  };
};
