import React from 'react';
import { Exercise } from '../exercise/Exercise';
import { useHandlers } from 'components/_new/phase/sub/PhaseComplexExercise/hooks/useHandler';
import { TooltipActions } from './components/tooltipActions/TooltipActions';
import { Tooltip } from 'components/tooltip/Tooltip';
import { noop } from 'utils';
import {
  onSwapComplexExerciseDown,
  onSwapComplexExerciseUp,
} from 'components/_new/phase/sub/PhaseExercise/utils';

import type { IPhaseComplexExerciseProps } from 'components/_new/phase/sub/PhaseComplexExercise/models';

import S from './ComplexExercise.styled';

export const ComplexExercise = ({
  onDeleteComplexExercise,
  complexExercise,
  complexExerciseIndex,
  isLastComplexExercise,
  onSwapComplexExercise,
  onEditComplexExercise = noop,
  isFirstComplexExercise,
  dayExerciseReference,
  navigatedExerciseReference,
  programId,
}: IPhaseComplexExerciseProps) => {
  const { exercises, letter } = complexExercise;
  const {
    onDeleteComplexExerciseHandler,
    complexExerciseReference,
    isComplexExerciseFromNavigatedReference,
  } = useHandlers({
    complexExercise,
    onDeleteComplexExercise,
    dayExerciseReference,
    navigatedExerciseReference,
  });

  const isSuperset = exercises.length > 1;
  const tooltipActionMenuButton = (
    <TooltipActions
      isSuperset={isSuperset}
      onEditComplexExercise={onEditComplexExercise}
      onDeleteComplexExerciseHandler={onDeleteComplexExerciseHandler}
      isFirstComplexExercise={isFirstComplexExercise}
      isLastComplexExercise={isLastComplexExercise}
      handleSwapComplexExerciseDown={() =>
        onSwapComplexExerciseDown({
          onSwapComplexExercise,
          complexExerciseIndex,
        })
      }
      handleSwapComplexExerciseUp={() =>
        onSwapComplexExerciseUp({
          onSwapComplexExercise,
          complexExerciseIndex,
        })
      }
    />
  );

  const tooltipStyles = {
    left: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0
  };

  return (
    <Tooltip
      title={tooltipActionMenuButton}
      placement="left"
      styles={tooltipStyles}
    >
      <S.Wrapper>
        {exercises.map((exercise, exerciseIndex) => (
          <Exercise
            exercise={exercise}
            complexExerciseLetter={letter}
            complexExerciseIndex={complexExerciseIndex}
            onSwapComplexExercise={onSwapComplexExercise}
            key={`${exercise.id}${exercise.orderNumber}`}
            isLastExercise={exerciseIndex === exercises.length - 1}
            isSuperset={exercises.length > 1}
            complexExerciseReference={complexExerciseReference}
            navigatedExerciseReference={
              isComplexExerciseFromNavigatedReference &&
              navigatedExerciseReference
            }
            programId={programId}
          />
        ))}
      </S.Wrapper>
    </Tooltip>
  );
};
