import styled from '@emotion/styled';
import { HEADER_HEIGHT } from 'constants/index';
import { flexCenteredStyles, transitionOnIconsHover } from 'styles/commonStyleVariables';
import { StyledButtonWrapper } from '../menu';

const Wrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;

  ${flexCenteredStyles};

  height: ${HEADER_HEIGHT}px;
  width: 100%;
  padding: 12px 30px;
  background: #121315;
  border-bottom: 0.5px solid #3e434b;
  z-index: 11;

  & .logo {
    ${flexCenteredStyles};

    margin-right: 80px;

    &__icon {
      width: 160px;
      height: auto;
    }
  }

  & .count {
    color: #090b0c;
    background: linear-gradient(162.44deg, #ffef01 16.76%, #ffd600 89.31%);

    border-radius: 6px;
    padding: 10px 17px;
    margin-right: 18px;

    font-weight: 500;
    font-size: 34px;
    line-height: 34px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
  }

  & .backButton {
    color: #6e7987;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.02em;
    cursor: pointer;
    ${transitionOnIconsHover}

    &:hover {
      color: #fff;
    }
  }

  & .title {
    color: #ffffff;
    margin: 0;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
  }
  
  & .feedback {
    margin-left: auto;
  }

  & .actionIcon {
    ${flexCenteredStyles};
    ${transitionOnIconsHover};
    cursor: pointer;
    margin-left: 4px;
    position: relative;
    width: 48px;
    height: 48px;
    color: #5f6975;

    & svg {
      color: #5f6975;
    }

    &:hover {
      background: #22282f;
      border-radius: 4px;
      color: #fff;

      & svg {
        color: #fff;
      }
    }
  }

  & .notifications.new:after {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    overflow: hidden;
    top: 10px;
    right: 12px;
    background: linear-gradient(162.44deg, #ffef01 16.76%, #ffd600 89.31%);
    box-shadow: 0px 2px 6px #16191c;
  }

  & .profileActions {
    ${flexCenteredStyles};
    ${transitionOnIconsHover};
    cursor: pointer;
    margin-left: 16px;
    position: relative;

    ${StyledButtonWrapper} {
      ${flexCenteredStyles};
    }

    &:hover .profileActions__icon path {
      fill: none;
      stroke: #fff;
    }
  }

  & .user {
    ${flexCenteredStyles};

    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #ffffff;
    margin-left: 32px;

    &__photo {
      margin-right: 13px;
      height: 46px;
      width: 46px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
    }
  }

  & .refresh {
    padding: 5px 10px;
    color: #6e7987;
    border: 1px solid #6e7987;
    border-radius: 4px;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.1s;

    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }
`;

export default { Wrapper };
