import React from 'react';
import { parseDateToCorrectNewFormat } from 'utils';
import {
  MESSAGE_ATTACHMENT_DEFAULT_FILETYPE,
  MESSAGE_ATTACHMENT_IMAGE_FILETYPE,
  MESSAGE_ATTACHMENT_VIDEO_FILETYPE,
} from '../../chat/message/constants';

import type { TClientWithMessages, TFileType } from '../../models';

import { PhotoIc, UnknownAttachmentIc, VideoIc } from 'assets/svg';
import S from './Client.styled';

export const fileType: TFileType = {
  [MESSAGE_ATTACHMENT_DEFAULT_FILETYPE]: {
    name: 'Attachment',
    icon: <UnknownAttachmentIc />,
  },
  [MESSAGE_ATTACHMENT_IMAGE_FILETYPE]: {
    name: 'Photo',
    icon: <PhotoIc />,
  },
  [MESSAGE_ATTACHMENT_VIDEO_FILETYPE]: {
    name: 'Video',
    icon: <VideoIc />,
  },
};

interface IClient {
  client: TClientWithMessages;
  getClientMessages: (client: TClientWithMessages) => void;
  isActive: boolean;
}

export const Client = ({ client, getClientMessages, isActive }: IClient) => {
  const { user, message, totalUnreadMessages } = client;
  const { fullDate } = parseDateToCorrectNewFormat({
    date: message?.createdOn,
    isHoursAndMinutesVisible: false,
  });

  const messageText =
    message?.text ||
    (message?.attachmentUrl && typeof message.fileType === 'number' ? (
      <p className="attachmentPreview">
        {fileType[message.fileType]?.icon}
        {fileType[message.fileType]?.name}
      </p>
    ) : (
      ''
    ));

  return (
    <S.Wrapper isActive={isActive} onClick={() => getClientMessages(client)}>
      <div className="userInfo">
        <S.UserPhoto photoUrl={user.photoUrl} />
        <div className="userName">{`${user.firstName} ${user.lastName}`}</div>
        {Boolean(totalUnreadMessages) && (
          <S.Counter>{totalUnreadMessages}</S.Counter>
        )}
        {message?.createdOn && <div className="time">{fullDate}</div>}
      </div>
      <div className="message">
        <div className="message__value">{messageText || 'No messages yet'}</div>
      </div>
    </S.Wrapper>
  );
};
