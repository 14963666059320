import React, { FC, useContext, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { Toggle } from 'components/toggle/Toggle';
import Loader from 'components/loader/Loader';
import ConfirmModal from 'components/_new/Modal/sub/ConfirmModal';
import gaEvents from 'utils/gaEvents';
import { UserContext } from 'providers/userProvider';
import { Tooltip } from 'components/tooltip/Tooltip';

import STooltip from 'components/tooltip/Tooltip.styled';

interface IClientAccessStatusToggle {
  isRestrictedAccess: boolean;
  successCallback: () => void;
  clientId: string;
  onCloseHandler: () => void;
  onCancelHandler: () => void;
  onCloseOnOutsideClickHandler: () => void;
}

export const ClientAccessStatusToggle: FC<IClientAccessStatusToggle> = ({
  isRestrictedAccess,
  successCallback,
  clientId,
  onCloseHandler,
  onCancelHandler,
  onCloseOnOutsideClickHandler,
}) => {
  // for handling time period while clients are loading
  const [isActive, setIsActive] = useState(!isRestrictedAccess);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const activeClientToggleLabel = isActive ? 'deactivate' : 'activate';

  const { user, onSetUser } = useContext(UserContext);
  const {
    activeClientsNumber = 0,
    clientsLimit = 0,
    deactivatedClientsNumber = 0,
  } = user?.trainerDetails || {};

  const setClientData = () => {
    setIsActive((prev) => {
      // update user model - to have updated limit info on clients page (header)
      if (user) {
        onSetUser({
          ...user,
          trainerDetails: {
            ...user.trainerDetails,
            activeClientsNumber: activeClientsNumber + (prev ? -1 : 1),
            deactivatedClientsNumber:
              deactivatedClientsNumber + (prev ? 1 : -1),
          },
        });
      }

      return !prev;
    });
    successCallback();
  };

  const { isLoadingClientStatus, changeClientStatusMutation } = useFetch({
    clientId,
    setClientData,
  });

  const setClientAccessStatus = () => {
    changeClientStatusMutation();
  };

  const openConfirmationModal = () => {
    setIsOpenConfirmModal(true);
    gaEvents.openActivationClientModal();
  };

  const onSubmitClientStatus = () => {
    gaEvents.changeClientActivationStatus(!isActive);

    setIsOpenConfirmModal(false);
    setClientAccessStatus();
  };

  const onCancel = () => {
    setIsOpenConfirmModal(false);
    onCancelHandler();
  };

  const onClose = () => {
    setIsOpenConfirmModal(false);
    onCloseHandler();
  };

  const onCloseOnOutsideClick = () => {
    setIsOpenConfirmModal(false);
    onCloseOnOutsideClickHandler();
  };

  const isToggleDisabled = activeClientsNumber >= clientsLimit && !isActive;
  const toggleTooltipTitle = isToggleDisabled ? (
    <STooltip.Tooltip onClick={(e) => e.stopPropagation()} >
      You have reached your limit in {clientsLimit} active clients and can’t activate this
      user. To change the limit of clients please contact your{' '}
      <span className="colored">administrator</span>.
    </STooltip.Tooltip>
  ) : (
    ''
  );

  return (
    <>
      {isLoadingClientStatus && <Loader />}
      <Tooltip title={toggleTooltipTitle}>
        {/*Tooltip can not recognize Toggle without outer div wrapper*/}
        <div>
          <Toggle
            checked={isActive}
            setChecked={openConfirmationModal}
            label={activeClientToggleLabel}
            disabled={isToggleDisabled}
          />
        </div>
      </Tooltip>
      <ConfirmModal
        onCancel={onCancel}
        onClose={onClose}
        onCloseOnOutsideClick={onCloseOnOutsideClick}
        onSubmit={onSubmitClientStatus}
        show={isOpenConfirmModal}
        title={`Are you sure you want to ${activeClientToggleLabel} the client?`}
      />
    </>
  );
};
