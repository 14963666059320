import React, { FC } from 'react';
import { Tooltip } from 'components/tooltip/Tooltip';
import { PROGRAM } from 'constants/tooltips';

import { ArrowDownIc, ArrowUpIc, DeleteIc, EditIc } from 'assets/svg';
import S from './TooltipActions.styled';

interface IActionsProps {
  isSuperset: boolean;
  onEditComplexExercise: () => void;
  onDeleteComplexExerciseHandler: () => void;
  isFirstComplexExercise: boolean;
  isLastComplexExercise: boolean;
  handleSwapComplexExerciseUp: () => void;
  handleSwapComplexExerciseDown: () => void;
}

const TOOLTIP_PLACEMENT = 'right';

export const TooltipActions: FC<IActionsProps> = ({
  isSuperset,
  onEditComplexExercise,
  onDeleteComplexExerciseHandler,
  handleSwapComplexExerciseDown,
  handleSwapComplexExerciseUp,
  isFirstComplexExercise,
  isLastComplexExercise,
}) => {
  return (
    <S.Wrapper>
      <Tooltip
        title={
          isSuperset
            ? PROGRAM.EDIT_COMPLEX_EXERCISES
            : PROGRAM.EDIT_SINGLE_EXERCISE
        }
        placement={TOOLTIP_PLACEMENT}
      >
        <button onClick={onEditComplexExercise}>
          <EditIc />
        </button>
      </Tooltip>
      <Tooltip
        title={
          isSuperset
            ? PROGRAM.DELETE_COMPLEX_EXERCISES
            : PROGRAM.DELETE_SINGLE_EXERCISE
        }
        placement={TOOLTIP_PLACEMENT}
      >
        <button onClick={onDeleteComplexExerciseHandler}>
          <DeleteIc />
        </button>
      </Tooltip>
      {!isFirstComplexExercise && (
        <Tooltip title={PROGRAM.MOVE_EXERCISE_UP} placement={TOOLTIP_PLACEMENT}>
          <button onClick={handleSwapComplexExerciseUp}>
            <ArrowUpIc />
          </button>
        </Tooltip>
      )}

      {!isLastComplexExercise && (
        <Tooltip
          title={PROGRAM.MOVE_EXERCISE_DOWN}
          placement={TOOLTIP_PLACEMENT}
        >
          <button onClick={handleSwapComplexExerciseDown}>
            <ArrowDownIc />
          </button>
        </Tooltip>
      )}
    </S.Wrapper>
  );
};
