/* eslint-disable no-useless-escape */
import React, { FC, useState } from 'react';
import ProgressBar from 'components/progressBar';
import { NavLink } from 'react-router-dom';
import { calculateUserAge } from 'lib/functions';
import { noop, parseDateToCorrectFormat } from 'utils';
import { DONE_PROGRESS_COUNT } from 'constants/index';
import { ClientStatus } from '../components/clientStatus/ClientStatus';
import { ClientAccessStatusToggle } from '../../components/ClientAccessStatusToggle';
import { ROUTES } from '../../routes';
import gaEvents from 'utils/gaEvents';

import type { TClient } from './models';

import { DoneWithSuccessIc } from 'assets/svg';
import userPhoto from 'assets/userPhoto.png';
import S from './Client.styled';

interface IClientProps {
  client: TClient;
  loadClients: () => void;
}

export const Client: FC<IClientProps> = ({
  client: {
    user: { createdOn, lastName, photoUrl, id, firstName, isRestrictedAccess },
    birthDate,
    activeProgram,
  },
  loadClients = noop,
}) => {
  const [isActive, setIsActive] = useState(!isRestrictedAccess);
  const { day, month, year } = parseDateToCorrectFormat(createdOn);
  const { activePhaseProgress, name, activePhaseName } = activeProgram || {};

  const isPhaseProgressDone = activePhaseProgress === DONE_PROGRESS_COUNT;

  const changeUserStatusHandler = () => {
    setIsActive((prev) => !prev);
    loadClients();
  };

  const onCancelClientStatusModal = () => {
    gaEvents.closeOnCancelActivationModal();
  };

  const onCloseClientStatusModal = () => {
    gaEvents.closeOnIconActivationModal();
  };

  const onCloseOnOutsideClickClientStatusModal = () => {
    gaEvents.closeOnOutsideClickActivationModal();
  };

  return (
    <S.Client>
      <ClientStatus isActive={isActive} />
      <NavLink
        to={{
          pathname: `${ROUTES.TRAINERS_CLIENTS}/${id}`,
        }}
      >
        <div className={'client__header'}>
          <div
            className={'client__headerPhoto'}
            style={{
              backgroundImage: `url(${photoUrl ? photoUrl : userPhoto})`,
            }}
          />
          <div className={'client__headerDataWrapper'}>
            {`${firstName} ${lastName}`}
            <p className={'client__headerAge'}>
              {`${calculateUserAge(birthDate)} years`}
            </p>
            <ClientAccessStatusToggle
              isRestrictedAccess={isRestrictedAccess}
              successCallback={changeUserStatusHandler}
              clientId={id}
              onCancelHandler={onCancelClientStatusModal}
              onCloseHandler={onCloseClientStatusModal}
              onCloseOnOutsideClickHandler={
                onCloseOnOutsideClickClientStatusModal
              }
            />
          </div>
        </div>
        <div className={'client__footer'}>
          {activeProgram ? (
            <>
              {isPhaseProgressDone ? (
                <div className={'client__progressDoneIcon'}>
                  <DoneWithSuccessIc />
                </div>
              ) : (
                <ProgressBar progress={activePhaseProgress || 0} size={34} />
              )}
              <div className={'client__program'}>
                <p className={'client__phase'}>{activePhaseName}</p>
                <p className={'client__programName'}>{`${name}`}</p>
              </div>
            </>
          ) : (
            <p className={'client__noProgram'}>No program</p>
          )}
          <p
            className={'client__program--created'}
          >{`${day}/${month}/${year}`}</p>
        </div>
      </NavLink>
    </S.Client>
  );
};
