// TODO: refactor component
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactSVG } from 'react-svg';
import './styles.scss';
import userPhoto from '../../assets/userPhoto.png';
import { parseDateToCorrectFormat } from '../../utils/index';

const StyledMenu = withStyles({
    paper: {
        background: '#23262B',
        boxShadow: '0px 8px 20px rgba(16, 17, 19, 0.6)',
        borderRadius: '8px',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '18px',
        color: '#FFFFFF',
        padding: 0,
    },
    list: {
        padding: 0,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(() => ({
    root: {
        '&:hover': {
            background: '#191B1F',
            color: '#FFEF01',
        },
    },
}))(MenuItem);

const NotificationsMenu = ({ button, menuItems, clickOnMenuItem }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const chooseItemHandler = (item) => {
        clickOnMenuItem(item);
    };

    return (
        <div>
            <Button onClick={handleClick}>{button}</Button>
            {menuItems && menuItems.length ? (
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {menuItems
                        .sort((a, b) => {
                            return new Date(b.createdOn) - new Date(a.createdOn);
                        })
                        .map((item) => {
                            const { fullDate } = parseDateToCorrectFormat(item.createdOn)

                            return (
                                <StyledMenuItem
                                    onClick={() => chooseItemHandler(item)}
                                    key={item.id}
                                    style={{
                                        background: item.isRead ? '#191B1F' : '#282C33',
                                        borderBottom: item.isRead
                                            ? '1px solid #282C33'
                                            : '1px solid #191B1F',
                                    }}
                                >
                                    <div className="menu">
                                        {item.sender ? (
                                            <div
                                                className="menu-image"
                                                style={
                                                    item.sender.photoUrl
                                                        ? {
                                                            backgroundImage: `url(${item.sender.photoUrl})`,
                                                        }
                                                        : { backgroundImage: `url(${userPhoto})` }
                                                }
                                            />
                                        ) : (
                                            <ReactSVG
                                                className="menu-icon"
                                                src="/assets/svg/info.svg"
                                            />
                                        )}
                                        <div className="menu-content">
                                            <p className="menu-content-title">{`${
                        item.sender
                            ? item.sender.displayName
                            : 'System notifications'
                      }`}</p>
                                            <p className="menu-content-body">{item.text}</p>
                                        </div>
                                        <p className="menu-content-time">{fullDate}</p>
                                    </div>
                                </StyledMenuItem>
                            );
                        })}
                </StyledMenu>
            ) : (
                ''
            )}
        </div>
    );
};

export default NotificationsMenu;
