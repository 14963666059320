import React, { ReactElement, useState } from 'react';
import ManageComplexExerciseModal from 'components/_new/ManageComplexExerciseModal/ManageComplexExerciseModal';
import Loader from 'components/loader/Loader';
import ConfirmModal from 'components/_new/Modal/sub/ConfirmModal';
import gaEvents from 'utils/gaEvents';
import {
  CREATE_EXERCISE_MODAL_ID,
  EDIT_COMPLEX_EXERCISES_MODAL_ID,
} from '../../constants';
import { isChatPage } from '../../../../utils';
import {
  getAllExerciseIDsFromProgramDay,
  getExcludedComplexExerciseIDsOnEdit,
} from 'utils/programUtils';
import useFetch from '../../hooks/useFetch';

import type { TExtendedExercise } from 'components/_new/ManageComplexExerciseModal/models';
import type { TExercise, TPhaseDay } from 'components/_new/phase/models';
import type {
  IPhaseContentProps,
  TCreateExerciseModalData,
  TDeleteComplexExerciseModalData,
  TDeleteDayModalData,
  TDeleteExerciseModalData,
  TDeleteNewComplexExerciseModalData,
  TEditComplexExerciseModalData,
  TEditExerciseModalData,
  TOpenSwapExercisesPayload,
  TPhaseContentRenderProps,
  TSwapExercisesModalData,
} from '../../models';

import S from '../../PhaseContent.styled';

interface IPhaseContentWrapperProps extends IPhaseContentProps {
  renderContent: (wrapperProps: TPhaseContentRenderProps) => ReactElement;
}

export const PhaseContentWrapper = ({
  phaseData,
  setPhaseData,
  loadProgram,
  programExerciseReference,
  navigatedExerciseReference,
  programId,
  renderContent,
}: IPhaseContentWrapperProps) => {
  const { days, id: phaseId } = phaseData;
  const [createExerciseModalData, setCreateExerciseModalData] =
    useState<TCreateExerciseModalData | null>(null);
  const [deleteDayModalData, setDeleteDayModalData] =
    useState<TDeleteDayModalData | null>(null);
  const [deleteExerciseModalData, setDeleteExerciseModalData] =
    useState<TDeleteExerciseModalData | null>(null);
  const [deleteComplexExerciseModalData, setDeleteComplexExerciseModalData] =
    useState<TDeleteComplexExerciseModalData | null>(null);
  const [
    deleteNewComplexExerciseModalData,
    setDeleteNewComplexExerciseModalData,
  ] = useState<TDeleteNewComplexExerciseModalData | null>(null);
  const [swapExercisesModalData, setSwapExercisesModalData] =
    useState<TSwapExercisesModalData | null>(null);
  const [editExerciseModalData, setEditExerciseModalData] =
    useState<TEditExerciseModalData | null>(null);
  const [editComplexExerciseModalData, setEditComplexExerciseModalData] =
    useState<TEditComplexExerciseModalData | null>(null);

  const openEditComplexExerciseModal = (
    data: TEditComplexExerciseModalData,
  ) => {
    setEditComplexExerciseModalData(data);
    if (isChatPage()) {
      gaEvents.chatOpenEditComplexExerciseModal();
    } else {
      gaEvents.openEditComplexExerciseModal();
    }
  };
  const closeEditComplexExerciseModal = () => {
    setEditComplexExerciseModalData(null);
    if (isChatPage()) {
      gaEvents.chatCloseEditComplexExerciseModal();
    } else {
      gaEvents.closeEditComplexExerciseModal();
    }
  };
  const cancelEditComplexExerciseModal = () => {
    setEditComplexExerciseModalData(null);
    if (isChatPage()) {
      gaEvents.chatCancelEditComplexExerciseModal();
    } else {
      gaEvents.cancelEditComplexExerciseModal();
    }
  };
  const outsideCloseEditComplexExerciseModal = () => {
    setEditComplexExerciseModalData(null);
    if (isChatPage()) {
      gaEvents.chatOutsideCloseEditComplexExerciseModal();
    } else {
      gaEvents.outsideCloseEditComplexExerciseModal();
    }
  };

  const openEditExerciseModal = (data: TEditExerciseModalData) => {
    setEditExerciseModalData(data);
    gaEvents.clickEditExerciseButton();
  };
  const closeEditExerciseModal = () => setEditExerciseModalData(null);

  const openSwapExercisesModal = ({
    indexes,
    complexExercises,
  }: TOpenSwapExercisesPayload) => {
    const firstComplexExercise = complexExercises[indexes[0]];
    const secondComplexExercise = complexExercises[indexes[1]];

    if (!firstComplexExercise || !secondComplexExercise) {
      console.error('Complex Exercise does not exist');
      return;
    }

    const firstExerciseId = firstComplexExercise.id;
    const secondExerciseId = secondComplexExercise.id;

    setSwapExercisesModalData({
      firstExerciseId,
      secondExerciseId,
    });
  };
  const closeSwapExercisesModal = () => setSwapExercisesModalData(null);

  const openDeleteExerciseModal = (data: TDeleteExerciseModalData) => {
    setDeleteExerciseModalData(data);
    gaEvents.clickDeleteExerciseButton();
  };
  const openDeleteComplexExerciseModal = (
    data: TDeleteComplexExerciseModalData,
  ) => {
    setDeleteComplexExerciseModalData(data);
    gaEvents.clickDeleteComplexExerciseButton();
  };

  const closeDeleteExerciseModal = () => setDeleteExerciseModalData(null);
  const closeDeleteComplexExerciseModal = () =>
    setDeleteComplexExerciseModalData(null);
  const closeDeleteNewComplexExerciseModal = () =>
    setDeleteNewComplexExerciseModalData(null);

  const openDeleteDayModal = (data: TDeleteDayModalData) => {
    setDeleteDayModalData(data);

    if (isChatPage()) {
      gaEvents.chatOpenDeleteDayModal();
    } else {
      gaEvents.openDeleteDayModal();
    }
  };
  const closeDeleteDayModal = () => setDeleteDayModalData(null);

  const openCreateExerciseModal = (data: TCreateExerciseModalData) => {
    setCreateExerciseModalData(data);
    if (isChatPage()) {
      gaEvents.chatClickAddExerciseButton();
    } else {
      gaEvents.clickAddExerciseButton();
    }
  };

  const closeCreateExerciseModal = () => {
    setCreateExerciseModalData(null);
    if (isChatPage()) {
      gaEvents.chatCloseCreateExerciseModal();
    } else {
      gaEvents.closeCreateExerciseModal();
    }
  };
  const cancelCreateExerciseModal = () => {
    setCreateExerciseModalData(null);
    if (isChatPage()) {
      gaEvents.chatCancelCreateExerciseModal();
    } else {
      gaEvents.cancelCreateExerciseModal();
    }
  };
  const outsideCloseCreateExerciseModal = () => {
    setCreateExerciseModalData(null);
    if (isChatPage()) {
      gaEvents.chatOutsideCloseCreateExerciseModal();
    } else {
      gaEvents.outsideCloseCreateExerciseModal();
    }
  };

  const createExerciseHandler = (day: TPhaseDay) => {
    const excludedExerciseIDs = getAllExerciseIDsFromProgramDay(day);

    openCreateExerciseModal({
      dayName: day.name,
      dayOrderNumber: day.orderNumber,
      excludedExerciseIDs,
    });
  };

  const editComplexExerciseHandler = ({
    exercises,
    id,
    day,
    letter,
    orderNumber,
  }: {
    exercises: TExercise[];
    id: number;
    day: TPhaseDay;
    letter: string;
    orderNumber: number;
  }) => {
    const excludedExerciseIDs = getExcludedComplexExerciseIDsOnEdit({
      exercises,
      day,
    });

    openEditComplexExerciseModal({
      exercises,
      complexExerciseId: id,
      excludedExerciseIDs,
      letter,
      orderNumber,
      dayOrderNumber: day.orderNumber,
    });
  };

  const {
    handleSubmitCreateExercise,
    isLoadingCreateExercise,

    duplicateProgramDayMutation,
    isLoadingDuplicateProgramDay,

    deleteDayMutation,
    isLoadingDeleteDay,

    addDayMutation,
    isLoadingAddDay,

    deleteExerciseMutation,
    isLoadingDeleteExercise,

    deleteComplexExerciseMutation,
    isLoadingDeleteComplexExercise,

    swapExercisesMutation,
    isLoadingSwapExercises,

    editExercisesMutation,
    isLoadingEditExercises,

    handleSubmitEditComplexExercise,
    isLoadingEditComplexExercise,
  } = useFetch({
    phaseData,
    setPhaseData,
    loadProgram,
    closeDeleteDayModal,
    closeEditExerciseModal,
    closeSwapExercisesModal,
    closeCreateExerciseModal,
    closeDeleteExerciseModal,
    closeDeleteComplexExerciseModal,
    closeEditComplexExerciseModal,
    editComplexExerciseModalData,
    setEditComplexExerciseModalData,
  });

  const daysAmount = days.length;

  const onSubmitDeleteNewComplexExercise = () => {
    // this function creates "existExercises" filed inside
    // createExerciseModalData - to have possibility rerender exercises
    // inside create exercise modal, after submitting to delete it
    if (createExerciseModalData) {
      const { exercises, id } = deleteNewComplexExerciseModalData || {};
      if ((exercises?.length || 0) <= 1) {
        closeCreateExerciseModal();
      } else {
        const existExercises = exercises?.filter(
          (exercise) => exercise.id !== id,
        );
        setCreateExerciseModalData({
          ...createExerciseModalData,
          existExercises,
        });
      }
    }
    closeDeleteNewComplexExerciseModal();
  };

  const onCancelDeleteDayModal = () => {
    closeDeleteDayModal();

    if (isChatPage()) {
      gaEvents.chatCancelDeleteDayModal();
    } else {
      gaEvents.cancelDeleteDayModal();
    }
  };
  const onCloseDeleteDayModal = () => {
    closeDeleteDayModal();

    if (isChatPage()) {
      gaEvents.chatCloseDeleteDayModal();
    } else {
      gaEvents.closeDeleteDayModal();
    }
  };
  const onOutsideCloseDeleteDayModal = () => {
    closeDeleteDayModal();

    if (isChatPage()) {
      gaEvents.chatOutsideCloseDeleteDayModal();
    } else {
      gaEvents.outsideCloseDeleteDayModal();
    }
  };

  const onSubmitDeleteDay = () => {
    if (deleteDayModalData) {
      if (isChatPage()) {
        gaEvents.chatSubmitDeleteDay();
      } else {
        gaEvents.submitDeleteDay();
      }

      deleteDayMutation({
        phaseId,
        orderNumber: deleteDayModalData.dayOrderNumber,
      });
    }
  };

  const phaseExerciseReference = {
    ...programExerciseReference,
    phaseId: phaseData.id,
    phaseName: phaseData.name,
  };

  const onSubmitCreateExercise = (exercises: TExtendedExercise[]) => {
    if (createExerciseModalData) {
      handleSubmitCreateExercise({
        dayName: createExerciseModalData.dayName,
        dayOrderNumber: createExerciseModalData.dayOrderNumber,
        exercises,
      });

      if (isChatPage()) {
        gaEvents.chatSubmitAddExerciseModal();
      } else {
        gaEvents.submitAddExerciseModal();
      }
    }
  };

  const onSubmitEditComplexExercisesModal = (exercises: TExercise[]) => {
    if (editComplexExerciseModalData) {
      if (!exercises.length) {
        deleteComplexExerciseMutation({
          dayOrderNumber: editComplexExerciseModalData.dayOrderNumber,
          complexExerciseOrderNumber: editComplexExerciseModalData.orderNumber,
          phaseId,
        });

        closeEditComplexExerciseModal();
      } else {
        handleSubmitEditComplexExercise({
          complexExerciseId: editComplexExerciseModalData.complexExerciseId,
          exercises,
          dayOrderNumber: editComplexExerciseModalData.dayOrderNumber,
        });
      }
    }
  };

  const loading =
    isLoadingDuplicateProgramDay ||
    isLoadingAddDay ||
    isLoadingDeleteComplexExercise;

  return daysAmount < 1 ? (
    <S.AddDayButton
      type="button"
      disabled={isLoadingAddDay}
      onClick={() => addDayMutation({ orderNumber: 0, phaseId })}
    >
      + Day
    </S.AddDayButton>
  ) : (
    <>
      {loading && <Loader />}
      {renderContent({
        days,
        daysAmount,
        createExerciseHandler,
        duplicateProgramDayMutation,
        addDayMutation,
        openDeleteDayModal,
        openDeleteExerciseModal,
        openDeleteComplexExerciseModal,
        openSwapExercisesModal,
        editComplexExerciseHandler,
        phaseExerciseReference,
        navigatedExerciseReference,
        programId,
      })}

      {createExerciseModalData && (
        <ManageComplexExerciseModal
          title="Add new exercise"
          modalId={CREATE_EXERCISE_MODAL_ID}
          onClose={closeCreateExerciseModal}
          onCancel={cancelCreateExerciseModal}
          onOutsideClose={outsideCloseCreateExerciseModal}
          isLoading={isLoadingCreateExercise}
          excludedExerciseIDs={createExerciseModalData.excludedExerciseIDs}
          onSubmit={onSubmitCreateExercise}
        />
      )}

      {editExerciseModalData && (
        <ManageComplexExerciseModal
          title="Edit exercise"
          onClose={closeEditExerciseModal}
          isLoading={isLoadingEditExercises}
          existExercises={[editExerciseModalData.exercise]}
          isSupportingMultipleExercises={false}
          excludedExerciseIDs={editExerciseModalData.excludedExerciseIDs}
          onSubmit={(exercises) => {
            editExercisesMutation({
              exerciseInfoId: exercises[0].exerciseInfo.id,
              description: exercises[0].description,
              exercises: exercises[0].weeks,
            });
          }}
        />
      )}

      {editComplexExerciseModalData && (
        <ManageComplexExerciseModal
          title="Edit exercises"
          modalId={EDIT_COMPLEX_EXERCISES_MODAL_ID}
          onClose={closeEditComplexExerciseModal}
          onCancel={cancelEditComplexExerciseModal}
          onOutsideClose={outsideCloseEditComplexExerciseModal}
          isLoading={isLoadingEditComplexExercise}
          existExercises={editComplexExerciseModalData.exercises}
          excludedExerciseIDs={editComplexExerciseModalData.excludedExerciseIDs}
          letter={editComplexExerciseModalData.letter}
          complexExerciseOrderNumber={editComplexExerciseModalData.orderNumber}
          onSubmit={onSubmitEditComplexExercisesModal}
          infoTitle={
            'Click on “Cancel” to revert changes or “Submit” to save them.'
          }
        />
      )}

      {deleteDayModalData && (
        <ConfirmModal
          show={true}
          isLoading={isLoadingDeleteDay}
          title="Are you sure you want to delete this day?"
          onCancel={onCancelDeleteDayModal}
          onClose={onCloseDeleteDayModal}
          onCloseOnOutsideClick={onOutsideCloseDeleteDayModal}
          onSubmit={onSubmitDeleteDay}
        />
      )}

      {deleteExerciseModalData && (
        <ConfirmModal
          show={true}
          isLoading={isLoadingDeleteExercise}
          title={
            deleteExerciseModalData.isLastExercise
              ? "Are you sure you want to completely remove this exercise from the client's program?"
              : 'Are you sure you want to delete this exercise from a superset?'
          }
          onCancel={closeDeleteExerciseModal}
          onSubmit={() =>
            deleteExerciseMutation({
              ...deleteExerciseModalData,
              phaseId,
            })
          }
        />
      )}
      {/*{isLoadingDeleteComplexExercise && <Loader />}*/}
      {deleteComplexExerciseModalData && (
        <ConfirmModal
          show={true}
          isLoading={isLoadingDeleteComplexExercise}
          title="Are you sure you want to delete this superset?"
          onCancel={closeDeleteComplexExerciseModal}
          onSubmit={() =>
            deleteComplexExerciseMutation({
              ...deleteComplexExerciseModalData,
              phaseId,
            })
          }
        />
      )}
      {deleteNewComplexExerciseModalData && (
        <ConfirmModal
          show={true}
          title="Are you sure you want to delete this exercise?"
          onCancel={closeDeleteNewComplexExerciseModal}
          onSubmit={onSubmitDeleteNewComplexExercise}
        />
      )}

      {swapExercisesModalData && (
        <ConfirmModal
          show={true}
          isLoading={isLoadingSwapExercises}
          title="Are you sure you want to swap this superset?"
          onCancel={closeSwapExercisesModal}
          onSubmit={() =>
            swapExercisesMutation({
              ...swapExercisesModalData,
              phaseId,
            })
          }
        />
      )}
    </>
  );
};