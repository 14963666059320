import styled from '@emotion/styled';
import { css } from '@emotion/react';
import SChat from './chat/Chat.styled';
import SHeaderChat from './chat/components/chatHeader/ChatHeader.styled';

const Wrapper = styled.div<{
  isChatOnFullScreen?: boolean;
}>`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: all 1s ease-out;

  & .chatWrapper {
    display: flex;
    transition: all 1s ease-out;
  }

  & .relativeWrapper {
    flex: 3 1 200px;
    max-width: 500px;
    position: relative;
    transition: all 1s ease-out;

    &.allWidthFitWrapper {
      display: flex;
      flex: 8;
      max-width: 100%;
    }

    &.clientsList {
      width: 30%;
      flex: 3 1 100px;
      min-width: 230px;
    }
  }

  ${({ isChatOnFullScreen }) =>
    isChatOnFullScreen &&
    css`
      & .chatWrapper {
        flex: 2;
      }

      & ${SChat.Wrapper} {
        width: auto;
        flex: 2;
        border: none;
      }

      & ${SHeaderChat.Header} {
        border-left: 0.5px solid #6e7987;
        
        & .resize {
          transform: rotate(180deg);
        }

        & .goBackButton {
          display: none;
        }
      }
    `}
`;

export default { Wrapper };
