import gaEvents from 'utils/gaEvents';
import { isChatPage } from 'containers/app/trainer/currentClient/clientPhases/utils';

import type { TSwapComplexExercisePayload } from '../models';

export const onSwapComplexExerciseUp = ({ onSwapComplexExercise, complexExerciseIndex }: TSwapComplexExercisePayload) => {
  if (isChatPage()) {
    gaEvents.chatClickRearrangeSuperSetButton({ up: true });
  } else {
    gaEvents.clickRearrangeSuperSetButton({ up: true });
  }
  return onSwapComplexExercise?.([
    complexExerciseIndex,
    complexExerciseIndex - 1,
  ]);
};

export const onSwapComplexExerciseDown = ({ onSwapComplexExercise, complexExerciseIndex }: TSwapComplexExercisePayload) => {
  if (isChatPage()) {
    gaEvents.chatClickRearrangeSuperSetButton({ up: false });
  } else {
    gaEvents.clickRearrangeSuperSetButton({ up: false });
  }
  return onSwapComplexExercise?.([
    complexExerciseIndex,
    complexExerciseIndex + 1,
  ]);
};