import styled from '@emotion/styled/macro';
import { flexCenteredStyles } from '../../../styles/commonStyleVariables';

const Wrapper = styled.div`
  ${flexCenteredStyles};
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  border: 2px dashed #3F4345;
  border-radius: 8px;

  & .field {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 16;
  }

  & .content {
    position: absolute;
  }
`;

export default { Wrapper };