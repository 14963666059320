import styled from '@emotion/styled';
import userPhoto from 'assets/userPhoto.png';
import { css } from '@emotion/react';

const Wrapper = styled.button<{
  isActive: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;

  border-bottom: 0.5px solid #3e434b;
  padding: 8px 12px 28px 12px;

  font-size: 14px;
  transition: all 0.2s ease-out;

  &:hover {
    background: #282c33;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: #282c33;
      border-left: 2px solid #ffef01;
    `}
  & .userInfo {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    margin-bottom: 4px;
  }

  & .userName {
    max-width: 120px;
    word-wrap: break-word;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    margin-right: auto;
  }

  & .time {
    color: #6e7987;
  }

  & .message {
    display: flex;
    align-items: center;
    position: relative;

    &__value {
      color: #6e7987;

      line-height: 22px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      
      // workaround if there is very long string in message to handle 
      // responsive design when chat is expanded
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
    }
    
    & .attachmentPreview {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
`;

const Counter = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 16px;
  width: 16px;
  margin-right: 8px;

  color: #000;
  border-radius: 50%;
  background-color: #ffef01;

  font-size: 10px;
  font-weight: 900;
`;

const UserPhoto = styled.div<{ photoUrl?: string }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;

  background-image: ${({ photoUrl }) =>
    photoUrl ? `url(${photoUrl})` : `url(${userPhoto})`};
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
`;

export default { Wrapper, UserPhoto, Counter };
