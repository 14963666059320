import React, { FC } from 'react';
import S from './ClientStatus.styled'

interface IClientStatus {
  isActive: boolean;
}

export const ClientStatus: FC<IClientStatus> = ({ isActive }) => {
  const activeClientLabel = isActive ? 'active' : 'deactivated';
  return (
    <S.Status isActive={isActive}>
      {activeClientLabel}
    </S.Status>
  );
};
