import React, { createContext, ReactNode, useState } from 'react';
import { noop } from 'utils';

import type { TGeneralExerciseReference } from 'components/_new/phase/models';

export const IS_GENERATED_REFERENCE_ACTIVE_DEFAULT_VALUE = true;
export const IS_GENERATING_REFERENCE_DISABLED_DEFAULT_VALUE = false;

const initialState: {
  generatedExerciseReference: TGeneralExerciseReference | null;
  setGeneratedExerciseReference: (
    reference: TGeneralExerciseReference | null,
  ) => void;
  navigatedExerciseReference: TGeneralExerciseReference | null;
  setNavigatedExerciseReference: (
    reference: TGeneralExerciseReference | null,
  ) => void;
  isGeneratedReferenceActive: boolean;
  setIsGeneratedReferenceActive: (active: boolean) => void;
  isGeneratingReferenceDisabled: boolean;
  setIsGeneratingReferenceDisabled: (disable: boolean) => void;
} = {
  generatedExerciseReference: null,
  navigatedExerciseReference: null,
  isGeneratedReferenceActive: true,
  isGeneratingReferenceDisabled: false,
  setGeneratedExerciseReference: noop,
  setNavigatedExerciseReference: noop,
  setIsGeneratedReferenceActive: noop,
  setIsGeneratingReferenceDisabled: noop,
};

export const ExerciseReferenceContext = createContext(initialState);

export const ExerciseReferenceProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [generatedExerciseReference, setGeneratedExerciseReference] =
    useState<TGeneralExerciseReference | null>(null);
  const [navigatedExerciseReference, setNavigatedExerciseReference] =
    useState<TGeneralExerciseReference | null>(null);
  const [isGeneratedReferenceActive, setIsGeneratedReferenceActive] = useState(
    IS_GENERATED_REFERENCE_ACTIVE_DEFAULT_VALUE,
  );
  const [isGeneratingReferenceDisabled, setIsGeneratingReferenceDisabled] =
    useState(IS_GENERATING_REFERENCE_DISABLED_DEFAULT_VALUE);

  return (
    <ExerciseReferenceContext.Provider
      value={{
        generatedExerciseReference,
        setGeneratedExerciseReference,
        navigatedExerciseReference,
        setNavigatedExerciseReference,
        isGeneratedReferenceActive,
        setIsGeneratedReferenceActive,
        isGeneratingReferenceDisabled,
        setIsGeneratingReferenceDisabled,
      }}
    >
      {children}
    </ExerciseReferenceContext.Provider>
  );
};
