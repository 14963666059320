import React, { useEffect, useState } from 'react';
import { ClientsList } from './clientsList/ClientsList';
import { ActiveRightContent } from './activeRightContent/ActiveRightContent';
import { Chat } from './chat/Chat';
import { useFetch } from './clientsList/hooks/useFetch';
import Loader from 'components/loader/Loader';
import { useLayout } from 'providers/layout/hooks/useLayout';
import { ExerciseReferenceProvider } from 'providers/chatPage/ExerciseReferenceProvider';
import { FilteredExerciseProvider } from 'providers/chatPage/filteredExerciseProvider/FilteredExerciseProvider';
import gaEvents from 'utils/gaEvents';

import type { TClientWithMessages, TMessage } from './models';

import S from './ChatPage.styled';

export const ChatPage = () => {
  const [activeClient, setActiveClient] = useState<TClientWithMessages | null>(
    null,
  );
  const [activeMessages, setActiveMessages] = useState<TMessage[]>([]);
  const [clients, setClients] = useState<TClientWithMessages[] | null>(null);
  const [isChatOnFullScreen, setIsChatOnFullScreen] = useState(false);

  const { initLayoutState } = useLayout();

  useEffect(() => {
    initLayoutState({
      headerTitle: 'Chat',
      headerCountTitle: 0,
    });
  }, [initLayoutState]);

  useEffect(() => {
    if (!isChatOnFullScreen) {
      getClientsMutation({});
    }
  }, [activeMessages, isChatOnFullScreen]);

  const backToClientsListHandler = () => {
    gaEvents.chatGoBackToClientsList();

    setActiveMessages([]);
    setActiveClient(null);
  };

  const openCurrentClientChat = (data: TMessage[]) => {
    setActiveMessages(data);
  };

  const getClientMessagesHandler = (client: TClientWithMessages) => {
    gaEvents.chatChooseClientFromList();

    setActiveClient(client);
    getClientMessagesMutation({
      clientId: client.id,
      doNotConcatMessages: true,
    });
  };

  const {
    getClientsMutation,
    isLoadingClientsList,

    getClientMessagesMutation,
    isLoadingClientMessages,
  } = useFetch({
    clients,
    setClients,
    openCurrentClientChat,
    activeMessages,
  });

  const resizeChatHandler = () => {
    setIsChatOnFullScreen((prevState) => {
      const newValue = !prevState;
      if (newValue) {
        gaEvents.chatClickExpandButton();
      } else {
        gaEvents.chatClickCollapseButton();
      }
      return newValue;
    });
  };

  const showChat = activeMessages && activeClient;
  const loading =
    (isLoadingClientsList && !showChat) || isLoadingClientMessages;

  return (
    <ExerciseReferenceProvider>
      <FilteredExerciseProvider>
        {isChatOnFullScreen ? (
          <S.Wrapper isChatOnFullScreen={isChatOnFullScreen}>
            <div className="chatWrapper">
              <div className="relativeWrapper clientsList">
                {isLoadingClientsList && isChatOnFullScreen && (
                  <Loader relatedToParent />
                )}
                <ClientsList
                  clients={clients}
                  getClientMessagesHandler={getClientMessagesHandler}
                  getClientsMutation={getClientsMutation}
                  activeClient={activeClient}
                />
              </div>
              {activeClient && activeMessages && (
                <div className="relativeWrapper allWidthFitWrapper">
                  {isLoadingClientMessages && <Loader relatedToParent />}
                  <Chat
                    activeClient={activeClient}
                    activeMessages={activeMessages}
                    setActiveMessages={setActiveMessages}
                    backToClientsListHandler={backToClientsListHandler}
                    getClientsMutation={getClientsMutation}
                    getClientMessagesMutation={getClientMessagesMutation}
                    resizeChatHandler={resizeChatHandler}
                  />
                </div>
              )}
            </div>
          </S.Wrapper>
        ) : (
          <S.Wrapper>
            {loading && <Loader relatedToParent />}
            {showChat ? (
              <div className="relativeWrapper">
                <Chat
                  activeClient={activeClient}
                  activeMessages={activeMessages}
                  setActiveMessages={setActiveMessages}
                  backToClientsListHandler={backToClientsListHandler}
                  getClientsMutation={getClientsMutation}
                  getClientMessagesMutation={getClientMessagesMutation}
                  resizeChatHandler={resizeChatHandler}
                />
              </div>
            ) : (
              <div className="relativeWrapper clientsList">
                <ClientsList
                  clients={clients}
                  getClientMessagesHandler={getClientMessagesHandler}
                  getClientsMutation={getClientsMutation}
                />
              </div>
            )}
            <ActiveRightContent activeClient={activeClient} />
          </S.Wrapper>
        )}
      </FilteredExerciseProvider>
    </ExerciseReferenceProvider>
  );
};
