import styled from '@emotion/styled/macro';
import { flexCenteredStyles } from 'styles/commonStyleVariables';
import { css } from '@emotion/react';

const Wrapper = styled.div`
  padding: 40px;
  
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 630px;
  
  & .title {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
  }
  
  & .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #6E7987;
  }
  
  & .steps {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
  
  & .buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

const Button = styled.button<{ isConfirm?: boolean }>`
  ${flexCenteredStyles};
  flex: 2;
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 8px;

  background: linear-gradient(318deg, #2C3036 14.05%, #31343C 85.29%);
  box-shadow: 4px 4px 10px 0px rgba(31, 36, 39, 0.40);
  
  &:hover {
    background: linear-gradient(318deg, #FFEF01 14.05%, #FFD600 85.29%);
    color: #1C1F21;
  }
  
  ${({ isConfirm }) => isConfirm && css`
    background: linear-gradient(318deg, #FFEF01 14.05%, #FFD600 85.29%);
    color: #1C1F21;
    
    &:hover {
      background: #EBC604;
    }
  `}
`;

const Step = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  & .tab {
    width: 50px;
    height: 6px;
    background: ${({ isActive }) => isActive ? '#FFEF01' : '#32363D'};
    border-radius: 4px;
  }
`;

export default { Wrapper, Step, Button };