import React, { FC, PropsWithChildren } from 'react';

import S from './ColoredLabel.styled';

interface IColoredLabelProps extends PropsWithChildren {
  index: number;
}

export const ColoredLabel: FC<IColoredLabelProps> = ({ index, children }) => {
  return (
    <S.Wrapper index={index}>
      <span>{children}</span>
    </S.Wrapper>
  );
};
