import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import TextInput from 'components/fields/textInput';
import Modal from 'components/_new/Modal';
import Loader from 'components/loader/Loader';
import gaEvents from 'utils/gaEvents';
import { PASSWORD_DATA } from '../../constants';
import { putData } from 'lib/api/utils';

// TODO: remade all styles with styled
import S from './ChangePasswordModal.styled';

interface IChangePasswordModalProps {
  toggleCloseModal: () => void;
}

export const ChangePasswordModal: FC<IChangePasswordModalProps> = ({
  toggleCloseModal,
}) => {
  const [password, setPassword] = useState(PASSWORD_DATA);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const resetPasswordHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    gaEvents.submitSaveChangePasswordFromHeader();

    setLoading(true);

    if (password.newPassword === password.currentPassword) {
      setError('Please create new password');
    } else {
      setError('');

      putData('Account/change-password', {
        oldPassword: password.currentPassword,
        newPassword: password.newPassword,
      })
        .then((answer) => {
          if (answer?.success) {
            toggleCloseModal();
          } else {
            setError('Something went wrong...');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const changePasswordHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const checkDisabling = () => {
    return !(
      Object.values(password).every((field) => {
        return !!field;
      }) && password.newPassword === password.confirmPassword
    );
  };

  const checkMatchingNewPassword = () => {
    if (password.newPassword && password.confirmPassword) {
      if (password.newPassword !== password.confirmPassword) {
        setError('New password is not verified');
      } else {
        setError('');
      }
    }
  };

  const onCloseModal = () => {
    setPassword(PASSWORD_DATA);
    toggleCloseModal();
    gaEvents.closeChangePasswordModalFromHeader();
  };
  const onOutsideCloseModal = () => {
    setPassword(PASSWORD_DATA);
    toggleCloseModal();
    gaEvents.outsideCloseChangePasswordModalFromHeader();
  };

  return (
    <Modal
      show={true}
      onClose={onCloseModal}
      onCloseOnOutsideClick={onOutsideCloseModal}
    >
      <S.ModalWrapper>
        {loading && <Loader />}
        <p className="modal-title">Change password</p>
        <p className="modal-description">
          Your new password must be different from previous used password
        </p>
        <form onSubmit={resetPasswordHandler}>
          <div className="modal-field">
            <TextInput
              placeholder="Current password"
              type="password"
              name="currentPassword"
              value={password.currentPassword}
              onChange={changePasswordHandler}
            />
          </div>
          <div className="modal-field">
            <TextInput
              placeholder="New password"
              type="password"
              name="newPassword"
              value={password.newPassword}
              onChange={changePasswordHandler}
              onBlur={checkMatchingNewPassword}
            />
          </div>
          <div className="modal-field">
            <TextInput
              placeholder="Confirm password"
              type="password"
              name="confirmPassword"
              value={password.confirmPassword}
              onChange={changePasswordHandler}
              onBlur={checkMatchingNewPassword}
            />
          </div>
          {error ? <p className="modal-error">{error}</p> : ''}
          <button
            disabled={checkDisabling()}
            type="submit"
            className="modal-button"
          >
            Save
          </button>
        </form>
      </S.ModalWrapper>
    </Modal>
  );
};
