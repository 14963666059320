import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Wrapper = styled.div<{
  relatedToParent?: boolean;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  
  ${({ relatedToParent }) => relatedToParent && css`
    position: absolute;
  `}
`

export default { Wrapper }