import React, { useContext, useEffect, useRef, useState } from 'react';
import { ExerciseReferenceContext } from 'providers/chatPage/ExerciseReferenceProvider';
import { FilteredExerciseContext } from 'providers/chatPage/filteredExerciseProvider/FilteredExerciseProvider';
import { useInputFocus } from 'hooks/useInputFocus';
import gaEvents from 'utils/gaEvents';

import type { IPhaseWeekProps } from '../models';
import type { TGeneralExerciseReference } from '../../../models';

export const getFormattedValue = (value: string | number) => {
  if (
    value === 0 ||
    value === '0' ||
    value === '0 min' ||
    (typeof value === 'string' && !/\d/.test(value))
  ) {
    return '-';
  }

  if (!value) {
    return '';
  }

  if (typeof value === 'number') {
    return parseFloat(value.toFixed(2));
  }

  return value;
};

export const useHandlers = ({
  navigatedExerciseReference,
  exerciseName,
  exerciseReference,
  weekIndex,
  week: { name, exerciseId },
}: Pick<
  IPhaseWeekProps,
  | 'navigatedExerciseReference'
  | 'exerciseName'
  | 'exerciseReference'
  | 'weekIndex'
  | 'week'
>) => {
  const isWeekFromNavigatedReference =
    navigatedExerciseReference &&
    navigatedExerciseReference?.weekName === name &&
    navigatedExerciseReference.exerciseName === exerciseName;

  const [isHighlighted, setIsHighlighted] = useState(false);
  const navigatedWeekRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (navigatedWeekRef.current && isWeekFromNavigatedReference) {
      navigatedWeekRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (isWeekFromNavigatedReference) {
      highlightWeekBlocks();
    }
  }, [navigatedExerciseReference, isWeekFromNavigatedReference]);

  const generalExerciseReference: TGeneralExerciseReference | undefined =
    exerciseReference && {
      ...exerciseReference,
      exerciseId,
      weekName: name,
      weekId: exerciseReference.weeks?.[weekIndex]?.id || 0,
      dayId: exerciseReference.dayIds?.[weekIndex] || 0,
    };
  const {
    setGeneratedExerciseReference,
    setIsGeneratedReferenceActive,
    isGeneratingReferenceDisabled,
  } = useContext(ExerciseReferenceContext);
  const { filteredExercise, setFilteredExercise } = useContext(
    FilteredExerciseContext,
  );

  const inputRef = useInputFocus<HTMLInputElement>();
  const generateReferenceHandler = () => {
    gaEvents.chatClickAttachExerciseReferenceToMessage();

    // to reset exercise filter in chat if there is click to leave
    // new message with new exercise reference, which is not related to
    // filtered exercise
    if (
      filteredExercise?.exerciseInfo.id !==
      generalExerciseReference?.exerciseInfoId
    ) {
      setFilteredExercise(null);
    }

    if (generalExerciseReference) {
      highlightWeekBlocks();
      // to focus on Input ("Type message...") in chat section
      // after chat icon was clicked and reference was activated
      inputRef?.current?.focus();

      const { weeks, dayIds, ...finalExerciseReference } =
        generalExerciseReference;
      setGeneratedExerciseReference(finalExerciseReference);
      setIsGeneratedReferenceActive(true);
    }
  };

  const highlightWeekBlocks = () => {
    setTimeout(() => {
      setIsHighlighted(true);
      // Reset border color after 2 seconds
      setTimeout(() => {
        setIsHighlighted(false);
      }, 2000);
    }, 200);
  };

  const onShowPopoverCallback = (isShown: boolean) => {
    if (isShown) {
      highlightWeekBlocks();
    }
  };

  return {
    navigatedWeekRef,
    generalExerciseReference,
    isGeneratingReferenceDisabled,
    generateReferenceHandler,
    onShowPopoverCallback,
    isHighlighted,
  };
};
