export const USER_PROFILE_ACTION_ITEMS = [
  {
    value: 'Change password',
    id: 'changePassword',
  },
  {
    value: 'Logout',
    id: 'logout',
  },
];

export const EDIT_PROFILE_ID = 'editProfile';
export const PERSONAL_BRANDING_ID = 'personalBranding';
export const CHANGE_PASSWORD_ID = 'changePassword';
export const LOGOUT_ID = 'logout';
export const TRAINER_PROFILE_ACTION_ITEMS = [
  {
    value: 'Edit Profile',
    id: EDIT_PROFILE_ID,
  },
  {
    value: 'Personal Branding',
    id: PERSONAL_BRANDING_ID,
  },
  {
    value: 'Change password',
    id: CHANGE_PASSWORD_ID,
  },
  {
    value: 'Logout',
    id: LOGOUT_ID,
  },
];

export const PASSWORD_DATA = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};