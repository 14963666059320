import axios from 'axios';
import { postData } from './utils';

const refreshTokenUrl = 'Account/refresh-token'

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json-patch+json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.token ? localStorage.token : ''}`,
    },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const setToken = (originalRequest, token) => {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    instance.defaults.headers['Authorization'] = 'Bearer ' + token;
    originalRequest.headers['Authorization'] = 'Bearer ' + token;
};

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401
	        && !originalRequest._retry && originalRequest.url !== refreshTokenUrl) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
		              failedQueue.push({ resolve, reject });
	              })
		              .then((token) => {
			              setToken(originalRequest, token);

			              return instance(originalRequest);
		              })
		              .catch((err) => {
                        return Promise.reject(err);
		              });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return postData(refreshTokenUrl, {
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken'),
            })
                .then(async (answer) => {
                    const token = answer.item?.token;
                    const refreshToken = answer.item?.refreshToken;

                    if (answer?.success) {
                        await localStorage.setItem('token', token);
                        await localStorage.setItem('refreshToken', refreshToken);

                        setToken(originalRequest, token);

                        processQueue(null, token);

                        return instance(originalRequest);
                    }
                })
                .catch((err) => {
                    processQueue(err, null);

                    return err;
                })
                .finally(() => {
                    isRefreshing = false;
                });
        }

        return Promise.reject(error);
    }
);

export default instance;
