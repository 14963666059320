import { useMutation } from 'react-query';
import instance from 'lib/api/axios';
import { useSnackbar } from 'notistack';

import { ERROR_MESSAGE } from 'constants/index';

import type { TProgram } from 'containers/app/trainer/models';

type TUseFetch = {
  clientId: string;
  setClientActiveProgramId: (id: number) => void;
};

type TGetProgramResponse = {
  data: {
    items: TProgram[];
  };
};

export const useFetch = ({ clientId, setClientActiveProgramId }: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: getClientActiveProgram,
    isLoading: isLoadingClientActiveProgram,
  } = useMutation<TGetProgramResponse, Error>({
    mutationFn: () => {
      return instance.get(`Program/${clientId}`);
    },
    onSuccess: ({ data: { items } }) => {
      const currentProgram = items.find(({ isCompleted }) => !isCompleted);
      setClientActiveProgramId(currentProgram?.id || 0);
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return {
    getClientActiveProgram,
    isLoadingClientActiveProgram,
  };
};
