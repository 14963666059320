import styled from '@emotion/styled/macro';
import { flexCenteredStyles } from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  height: 190px;
  width: 100%;

  & .contentArea {
    ${flexCenteredStyles};
    flex-direction: column;

    & .label {
      color: #ffffff;
      margin-bottom: 6px;
    }
  }
`;

export default { Wrapper };