import React from 'react';
import { PhaseExercise } from '../PhaseExercise/PhaseExercise';
import { useHandlers } from './hooks/useHandler';

import type { IPhaseComplexExerciseProps } from './models';

import S from './PhaseComplexExercise.styled';

export const PhaseComplexExercise = ({
  onDeleteExercise,
  onDeleteComplexExercise,
  complexExercise,
  complexExerciseIndex,
  isLastComplexExercise,
  onSwapComplexExercise,
  onEditComplexExercise,
  isFirstComplexExercise,
  dayExerciseReference,
  navigatedExerciseReference,
  programId,
}: IPhaseComplexExerciseProps) => {
  const { exercises, letter } = complexExercise;
  const {
    onDeleteComplexExerciseHandler,
    complexExerciseReference,
    isComplexExerciseFromNavigatedReference,
  } = useHandlers({
    complexExercise,
    onDeleteComplexExercise,
    dayExerciseReference,
    navigatedExerciseReference,
  });

  return (
    <S.Wrapper>
      {exercises.map((exercise, exerciseIndex) => (
        <PhaseExercise
          onDeleteExercise={onDeleteExercise}
          onDeleteComplexExercise={onDeleteComplexExerciseHandler}
          exercise={exercise}
          complexExerciseLetter={letter}
          isFirstExercise={exerciseIndex === 0}
          complexExerciseIndex={complexExerciseIndex}
          isLastComplexExercise={isLastComplexExercise}
          onSwapComplexExercise={onSwapComplexExercise}
          onEditComplexExercise={onEditComplexExercise}
          key={`${exercise.id}${exercise.orderNumber}`}
          isFirstComplexExercise={isFirstComplexExercise}
          isLastExercise={exerciseIndex === exercises.length - 1}
          isSuperset={exercises.length > 1}
          complexExerciseReference={complexExerciseReference}
          navigatedExerciseReference={
            isComplexExerciseFromNavigatedReference &&
            navigatedExerciseReference
          }
          programId={programId}
        />
      ))}
    </S.Wrapper>
  );
};
