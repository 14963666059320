import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomPopoverPaper = styled(Popover)`
  .MuiPaper-root {
    background-color: transparent;
  }
`;

export default { Button, CustomPopoverPaper }