import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';

type TTrainerExercise = {
  name: string;
  description: string;
  videoUrl: string;
  trainerId: string;
  id: number;
};

type TState = {
  isLoading: boolean;
  items: TTrainerExercise[];
  error: string;
};

const initialState: TState = {
  isLoading: false,
  error: '',
  items: [],
};
const exerciseInfo = 'ExerciseInfo';

export const TrainerExercisesContext = createContext<TState>(initialState);

const TrainerExercisesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TState>(initialState);

  useEffect(() => {
    (async () => {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      try {
        const { data } = await instance.get<{ items: TTrainerExercise[] }>(
          `${exerciseInfo}/trainer-exercise-infos`,
        );

        setState((prev) => ({
          ...prev,
          isLoading: false,
          error: '',
          items: data.items || [],
        }));
      } catch (e) {
        const error = String(e) || ERROR_MESSAGE;

        setState((prev) => ({
          ...prev,
          isLoading: false,
          error: error,
        }));
      }
    })();
  }, []);

  return (
    <TrainerExercisesContext.Provider value={state}>
      {children}
    </TrainerExercisesContext.Provider>
  );
};

export default TrainerExercisesProvider;
