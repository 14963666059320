import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
  gap: 12px;
  
  color: #fff;
  font-weight: 400;

  & .title {
    font-size: 24px;
  }
  
  & .description {
    font-size: 16px;
    color: #6E7987;
  }
`;

export default { Wrapper }