import React from 'react';
import PhaseWeek from '../PhaseWeek/PhaseWeek';
import { Tooltip } from 'components/tooltip/Tooltip';
import { ColoredLabel } from 'components/coloredLabel/ColoredLabel';
import { PROGRAM } from 'constants/tooltips';
import { isChatPage } from 'containers/app/trainer/currentClient/clientPhases/utils';
import { useHandlers } from './hooks/useHandlers';

import type { IPhaseExerciseProps } from './models';

import {
  ArrowDownIc,
  ArrowUpIc,
  DeleteIc,
  EditIc,
  FilterPlusIc,
} from 'assets/svg';
import S from './PhaseExercise.styled';

export const PhaseExercise = ({
  exercise,
  isFirstExercise,
  isLastExercise,
  complexExerciseIndex,
  complexExerciseLetter,
  onDeleteExercise,
  onDeleteComplexExercise,
  onSwapComplexExercise,
  isFirstComplexExercise,
  isLastComplexExercise,
  onEditComplexExercise,
  isSuperset,
  complexExerciseReference,
  navigatedExerciseReference,
  programId,
}: IPhaseExerciseProps) => {
  const { weeks, exerciseInfo } = exercise;

  const {
    exerciseName,
    handleSwapComplexExerciseUp,
    handleSwapComplexExerciseDown,
    onGenerateChatFilter,
    exerciseReference,
  } = useHandlers({
    exercise,
    complexExerciseIndex,
    complexExerciseLetter,
    complexExerciseReference,
    onSwapComplexExercise,
    programId,
  });

  return (
    <S.Wrapper>
      <S.ActionsWrapper>
        <div className="coloredLabelWrapper">
          <ColoredLabel index={complexExerciseIndex}>
            {exerciseName}
          </ColoredLabel>
        </div>
        {isSuperset && !isLastExercise && <div className="marker notLast" />}
        {!isLastExercise && <div className="marker" />}
        {isLastExercise && onEditComplexExercise && (
          <div className="actionIconsContainer">
            <Tooltip
              title={
                isSuperset
                  ? PROGRAM.EDIT_COMPLEX_EXERCISES
                  : PROGRAM.EDIT_SINGLE_EXERCISE
              }
            >
              <button type="button" onClick={onEditComplexExercise}>
                <EditIc className="editIc" />
              </button>
            </Tooltip>
            <Tooltip
              title={
                isSuperset
                  ? PROGRAM.DELETE_COMPLEX_EXERCISES
                  : PROGRAM.DELETE_SINGLE_EXERCISE
              }
            >
              <button
                type="button"
                className="actionsButton"
                onClick={onDeleteComplexExercise}
              >
                <DeleteIc />
              </button>
            </Tooltip>
          </div>
        )}
      </S.ActionsWrapper>

      <S.Body>
        <S.Content>
          {isFirstExercise && onSwapComplexExercise && (
            <>
              {!isFirstComplexExercise && (
                <Tooltip title={PROGRAM.MOVE_EXERCISE_UP}>
                  <button type="button" onClick={handleSwapComplexExerciseUp}>
                    <ArrowUpIc className="arrowIc" />
                  </button>
                </Tooltip>
              )}

              {!isLastComplexExercise && (
                <Tooltip title={PROGRAM.MOVE_EXERCISE_DOWN}>
                  <button type="button" onClick={handleSwapComplexExerciseDown}>
                    <ArrowDownIc className="arrowIc" />
                  </button>
                </Tooltip>
              )}
            </>
          )}

          {exerciseInfo.name}
          {/* #editExerciseHandler Disabled for now as there is another edit icon to edit all complex exercise together */}
          {/*TODO: remove it after 6-12 months from current date (9/04/2024)*/}
          {/* - if there will no be reason to use it after some time */}
          {/*<button*/}
          {/*  type="button"*/}
          {/*  className="actionsButton"*/}
          {/*  onClick={() => onEditExercise(exercise)}*/}
          {/*>*/}
          {/*  <EditIc />*/}
          {/*</button>*/}

          {/*TODO remove this code above after 6-12 months from current date*/}
          {/* (30/05/2024). For now current ticket (3785) says that this*/}
          {/* button should be deleted, but let's wait some time, until*/}
          {/* client will not request to revert it as useful feature*/}
          {/*{isSuperset && (*/}
          {/*  <button*/}
          {/*    type="button"*/}
          {/*    className="actionsButton"*/}
          {/*    onClick={() => onDeleteExercise(exercise)}*/}
          {/*  >*/}
          {/*    <DeleteIc />*/}
          {/*  </button>*/}
          {/*)}*/}
          {exercise.description && (
            <div className="exerciseDescription">
              <Tooltip title={exercise.description}>
                <p className="exerciseDescriptionValue">
                  {exercise.description}
                </p>
              </Tooltip>
            </div>
          )}
          {isChatPage() && (
            <Tooltip title={PROGRAM.FILTER_BUTTON}>
              <button className="filter" onClick={onGenerateChatFilter}>
                <FilterPlusIc />
              </button>
            </Tooltip>
          )}
        </S.Content>

        <S.WeeksWrapper>
          {weeks.map((week, weekIndex) => (
            <PhaseWeek
              week={week}
              key={week.exerciseId}
              navigatedExerciseReference={navigatedExerciseReference}
              exerciseReference={exerciseReference}
              weekIndex={weekIndex}
              exerciseName={exerciseName}
            />
          ))}
        </S.WeeksWrapper>
      </S.Body>
    </S.Wrapper>
  );
};
