import styled from '@emotion/styled/macro';
import {
  flexCenteredStyles,
  renderTextWithThreePointsByRowsCount,
} from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  padding: 12px;
  margin-bottom: 8px;

  background: #31363f;
  border-radius: 12px;
  border-left: 2px solid #ffef01;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  &.expanded {
    max-height: none;
  }

  & .text {
    margin: 0;
    padding: 0;
    ${renderTextWithThreePointsByRowsCount(2)};
    transition: all 0.3s ease-in-out;

    &.expanded {
      -webkit-line-clamp: unset;
      display: block;
    }
  }

  & .reference {
    display: flex;
    padding-top: 4px;
  }

  &&& .videoWrapper,
  .imageWrapper {
    ${flexCenteredStyles};

    height: 70px;
    min-height: 0;
    min-width: 50px;

    & .image {
      width: 100%;
      height: 100%;
    }
  }
  
  & .videoPlayButton {
    height: 35px;
    width: auto;
  }
`;

export default { Wrapper };
