import { Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';
import { noop } from 'utils';

import type { TBiggerPhase } from 'containers/app/trainer/models';

type TUseFetch = {
  setPhaseData: Dispatch<SetStateAction<TBiggerPhase | null>>;
  setShowAccordionBody?: (state: (prev: boolean) => boolean) => void;
  loadBigProgramHandler: () => void;
};

interface PhaseData {
  item: TBiggerPhase;
  doNotSetAccordionBody?: boolean;
}

interface FetchParams {
  phaseId: number;
  doNotSetAccordionBody?: boolean;
}

export const useFetch = ({ setPhaseData, setShowAccordionBody = noop, loadBigProgramHandler }: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: getCurrentPhaseMutation, isLoading: isLoadingCurrentPhase } =
    useMutation<PhaseData, Error, FetchParams>(
      async ({ phaseId, doNotSetAccordionBody = true }) => {
        const { data } =
          (await instance.get<{ item: TBiggerPhase }>(
            `Phase/formatted/${phaseId}`,
          )) || {};
        return { item: data.item, doNotSetAccordionBody };
      },
      {
        onSuccess: ({ item, doNotSetAccordionBody = true }) => {
          setPhaseData(item);
          if (doNotSetAccordionBody) {
            setShowAccordionBody((prev: boolean) => !prev);
          }
        },
        onError: (e) => {
          enqueueSnackbar(String(e) || ERROR_MESSAGE, { variant: 'error' });
        },
      },
    );

  const { mutate: duplicatePhaseMutation, isLoading: isLoadingDuplicatePhase } =
    useMutation<void, Error, number>({
      mutationFn: (phaseId) => {
        return instance.put(`Phase/duplicate/${phaseId}`);
      },
      onSuccess: () => {
        loadBigProgramHandler();
        enqueueSnackbar('Phase was successfully duplicated', { variant: 'success' });
      },
      onError: (e) => {
        enqueueSnackbar(String(e) || ERROR_MESSAGE, { variant: 'error' });
      },
    });

  return {
    getCurrentPhaseMutation,
    isLoadingCurrentPhase,

    duplicatePhaseMutation,
    isLoadingDuplicatePhase,
  };
};
