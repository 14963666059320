import { styled } from "@mui/material";

const Wrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: red;
  font-size: 20px;
  text-align: center;
  color: #808B9A;

  & > .icon {
    text-align: center;
    width: 64px;
    height: 64px;
  }

  & .mail {
    color: rgb(15, 68, 130);
  }
`;

export default { Wrapper };
