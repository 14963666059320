import React, { ChangeEvent, FC } from 'react';
import Label from 'components/label/label';
import { noop } from 'utils';

import S from './TextArea.styled';

const TEXTAREA_ID = 'textarea';

interface IAreaFieldProps {
  placeholder?: string;
  name?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  value: string;
  rows?: number;
  cols?: number;
  label?: string;
  required?: boolean;
  maxLength?: number;
  resizable?: boolean;
}

export const TextArea: FC<IAreaFieldProps> = ({
  placeholder,
  name,
  onChange,
  onFocus= noop(),
  value,
  rows = 5,
  cols,
  label,
  required = false,
  maxLength,
  resizable = true,
}) => {
  return (
    <S.Wrapper resizable={resizable}>
      {label && (
        <Label label={label} required={required} htmlFor={TEXTAREA_ID} />
      )}
      <textarea
        id={TEXTAREA_ID}
        className="textarea"
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        maxLength={maxLength}
      />
      {maxLength && (
        <p className="limit">{value.length}/{maxLength}</p>
      )}
    </S.Wrapper>
  );
};
