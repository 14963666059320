import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import ProgressBar from 'components/progressBar';
import { DONE_PROGRESS_COUNT } from 'constants/index';
import { LockUnlockPhase } from '../lockUnlockPhase/LockUnlockPhase';
import { noop } from 'utils';
import gaEvents from 'utils/gaEvents';

import type { TBiggerPhase, TBiggerProgram } from '../../models';

import { DoneWithSuccessIc } from 'assets/svg';
import classes from './_CurrentClientListPhase.module.scss';

interface ICurrentClientListPhaseProps {
  phase: TBiggerPhase;
  phaseIndex: number;
  clientId: string;
  programId: number;
  goToPhasesPage: (clientId: string, programId: number) => void;
  program: TBiggerProgram;
  loadProgramsHandler: () => void;
}

export const CurrentClientListPhase: FC<ICurrentClientListPhaseProps> = ({
  phase,
  phaseIndex,
  clientId,
  programId,
  goToPhasesPage,
  program,
  loadProgramsHandler,
}) => {
  const [phaseProgress, setPhaseProgress] = useState(0);
  const [hoveredPhase, setHoveredPhase] = useState<number | null>(null);

  const getPhaseProgress = useCallback(() => {
    setPhaseProgress(() => {
      const allWeeksProgress = phase.weeks.reduce(
        (accumulator, currentValue) => accumulator + currentValue.progress,
        0,
      );
      return allWeeksProgress / phase.weeks.length;
    });
  }, [phase.weeks]);

  useEffect(() => {
    getPhaseProgress();
  }, [phase.weeks]);

  const hoveredPhaseOnMouseEnterHandler = () => {
    setHoveredPhase(phaseIndex)
  }

  const hoveredPhaseOnMouseLeaveHandler = () => {
    setHoveredPhase(null)
  }

  const clickOnPhaseHandler = () => {
    if (program.isDraft) {
      gaEvents.clickOnDraftProgram({ clientId, programId });
    } else {
      gaEvents.clickOnCurrentProgram({ clientId, programId });
    }

    goToPhasesPage(clientId, programId)
  }

  const isPhaseProgressDone = phaseProgress === DONE_PROGRESS_COUNT

  return (
    <li
      className={classNames(classes.phase, {
        [classes['phase--blocked']]: !phase.isVisible,
      })}
      onClick={clickOnPhaseHandler}
      onMouseEnter={hoveredPhaseOnMouseEnterHandler}
      onMouseLeave={hoveredPhaseOnMouseLeaveHandler}
    >
      <div className={classes['phase__header']}>
        <p className={classes['phase__title']}>{`Phase ${phaseIndex + 1}`}</p>
        <div className={classes['phase__accessToPhaseIcon']}>
          <LockUnlockPhase
            program={program}
            hoveredPhase={hoveredPhase}
            setHoveredPhase={setHoveredPhase}
            phaseIndex={phaseIndex}
            phase={phase}
            setDBDProgram={noop}
            loadCallbackData={loadProgramsHandler}
            pageId="programsList"
          />
        </div>
        {isPhaseProgressDone ? (
          <div className={classes['phase__progressDoneIcon']}>
            <DoneWithSuccessIc />
        </div>
        ) : (
          <ProgressBar progress={phaseProgress} size={40} />
        )}
      </div>
      <ul>
        {phase.weeks.map((week, weekIndex) => (
          <li
            className={classes['phase__week']}
            key={`${week.name}-${weekIndex}`}
          >
            <div>
              {week.name}
              <p className={classes['phase__daysCount']}>
                {`${week.days.length} days`}
              </p>
            </div>
            <ProgressBar progress={week.progress || 0} size={34} />
          </li>
        ))}
      </ul>
    </li>
  );
};
