import styled from '@emotion/styled';
import { flexCenteredStyles, transitionOnIconsHover } from 'styles/commonStyleVariables';

const TooltipMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  & .emojis {
    display: flex;
    align-items: center;

    & .emoji {
      position: relative;
      font-size: 20px;
      padding: 1px;
      ${transitionOnIconsHover};
      ${flexCenteredStyles};
      
      &.isActive {
        &:before {
          content: '';
          position: absolute;
          top: 115%;
          width: 60%;
          height: 2px;
          background: #FFEF01;
          border-radius: 10%;
        }
      }
      
      &:hover {
        font-size: 24px;
      }
    }
  }
  
  & .menu {
    display: flex;
    align-items: center;
    gap: 12px;
    
    padding: 0 10px;
    color: #6e7987;
  }
  
  & .replyButton, & .deleteButton {
    ${flexCenteredStyles};
    ${transitionOnIconsHover};

    &:hover {
      color: #FFEF01;
    }
  }
  
  & .separator {
    height: 22px;
    width: 1px;
    background: #3E434B;
  }
`;

export default { TooltipMenu };
