import React, { FC } from 'react';

import type { IHomePageStep } from '../../../../models';

import previewHomePhoto from 'assets/img/brandingHomePreview.png';
import S from './Preview.styled';

export const Preview: FC<IHomePageStep> = ({ brandingData }) => {
  return (
    <S.Wrapper>
      <img src={previewHomePhoto} alt="preview"/>
      <S.WelcomeTextPreview isEmpty={!brandingData.welcomeText}>
        <p className="text">{brandingData.welcomeText || 'Welcome text'}</p>
      </S.WelcomeTextPreview>
      <div className="logoWrapper">
        {brandingData.logoFile.url ? (
          <img className="logo" src={brandingData.logoFile.url} alt=""/>
        ) : (
          <p className="logoLabel">Logo</p>
        )}
      </div>
    </S.Wrapper>
  );
};
