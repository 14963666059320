import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import instance from 'lib/api/axios';
import type { TExtendedExercise } from 'components/_new/ManageComplexExerciseModal/models';
import gaEvents from 'utils/gaEvents';
import { ERROR_MESSAGE } from 'constants/index';
import { AxiosError } from 'axios';
import { getErrorInValidFormat } from 'utils/errorUtils';
import { isChatPage } from '../../../utils';

import type { TBiggerPhase } from '../../../../../models';
import type {
  TAddDayRequest,
  TCreateExerciseModalData,
  TCreateExerciseRequest,
  TDeleteDayRequest,
  TDeleteExerciseRequest,
  TDuplicateProgramDayRequest,
  TEditComplexExerciseModalData,
  TEditComplexExerciseRequest,
  TEditExercisesRequest,
  TPhaseResponse,
  TSwapExercisesRequest,
} from '../models';
import type { TDeleteExerciseResponse } from '../models';

type Props = {
  phaseData: TBiggerPhase;
  setPhaseData: (phase: TBiggerPhase) => void;
  loadProgram: () => void;
  closeDeleteDayModal: () => void;
  closeEditExerciseModal: () => void;
  closeSwapExercisesModal: () => void;
  closeCreateExerciseModal: () => void;
  closeDeleteExerciseModal: () => void;
  closeDeleteComplexExerciseModal: () => void;
  closeEditComplexExerciseModal: () => void;
  editComplexExerciseModalData: TEditComplexExerciseModalData | null;
  setEditComplexExerciseModalData: (
    data: TEditComplexExerciseModalData,
  ) => void;
};

const useFetch = ({
  phaseData,
  setPhaseData,
  loadProgram,
  closeDeleteDayModal,
  closeEditExerciseModal,
  closeSwapExercisesModal,
  closeCreateExerciseModal,
  closeDeleteExerciseModal,
  closeDeleteComplexExerciseModal,
  closeEditComplexExerciseModal,
  editComplexExerciseModalData,
  setEditComplexExerciseModalData,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createExerciseMutation, isLoading: isLoadingCreateExercise } =
    useMutation<TPhaseResponse, Error, TCreateExerciseRequest>({
      mutationFn: (data) => {
        return instance.post('Exercise/create-bulk', {
          data,
        });
      },
      onSuccess: ({ data: { item } }) => {
        enqueueSnackbar('Exercise successfully added', { variant: 'success' });
        closeCreateExerciseModal();

        setPhaseData(item);
      },
      onError: (e) => {
        enqueueSnackbar(String(e) || ERROR_MESSAGE, {
          variant: 'error',
        });
        closeCreateExerciseModal();
      },
    });
  const handleSubmitCreateExercise = ({
    dayName,
    exercises,
    dayOrderNumber,
  }: { exercises: TExtendedExercise[] } & TCreateExerciseModalData) => {
    createExerciseMutation({
      dayName: dayName,
      phaseId: phaseData.id,
      // orderNumber in create exercise request is responsible for dayOrderNumber
      orderNumber: dayOrderNumber,
      exercises: exercises.map((exercise) => ({
        description: exercise.description,
        exerciseInfoId: exercise.exerciseInfo.id,
        orderNumber: exercise.orderNumber,
        exercises: exercise.weeks.map((week) => ({
          weekNumber: week.id,
          exerciseRows: week.exerciseRows,
        })),
      })),
    });
  };

  // == //

  const {
    mutate: duplicateProgramDayMutation,
    isLoading: isLoadingDuplicateProgramDay,
  } = useMutation<void, Error, TDuplicateProgramDayRequest>({
    mutationFn: (data) => {
      return instance.put('Day/duplicate-bulk', {
        data,
      });
    },
    onMutate: () => {
      if (isChatPage()) {
        gaEvents.chatClickDuplicateDayButton();
      } else {
        gaEvents.clickDuplicateDayButton();
      }
    },
    onSuccess: () => {
      loadProgram();
      enqueueSnackbar('Day successfully duplicated', {
        variant: 'success',
      });
    },
    onError: (e) => {
      enqueueSnackbar(String(e) || ERROR_MESSAGE, {
        variant: 'error',
      });
    },
  });

  // == //

  const { mutate: deleteDayMutation, isLoading: isLoadingDeleteDay } =
    useMutation<TDeleteDayRequest, Error, TDeleteDayRequest>({
      mutationFn: async (data) => {
        await instance.post('Day/delete-bulk', { data });
        return data;
      },
      onSuccess: (data) => {
        // in scope of 3606 ticket - optimize deleting only LAST element
        if (
          data.orderNumber ===
          phaseData.days[phaseData.days.length - 1].orderNumber
        ) {
          const phaseWithDeletedLastDay = phaseData.days.slice(0, -1);
          setPhaseData({
            ...phaseData,
            days: phaseWithDeletedLastDay,
          });
        } else {
          loadProgram();
        }
        closeDeleteDayModal();
        enqueueSnackbar('Day successfully deleted', {
          variant: 'success',
        });
      },
      onError: (e) => {
        closeDeleteDayModal();
        enqueueSnackbar(String(e) || ERROR_MESSAGE, {
          variant: 'error',
        });
      },
    });

  // == //

  const { mutate: addDayMutation, isLoading: isLoadingAddDay } = useMutation<
    TPhaseResponse,
    AxiosError,
    TAddDayRequest
  >({
    mutationFn: (data) => {
      return instance.post('Day/create-bulk', {
        data,
      });
    },
    onMutate: () => {
      if (isChatPage()) {
        gaEvents.chatClickAddDayButton();
      } else {
        gaEvents.clickAddDayButton();
      }
    },
    onSuccess: ({ data: { item } }) => {
      setPhaseData(item);
      enqueueSnackbar('Day successfully added', {
        variant: 'success',
      });
    },
    onError: (e) => {
      enqueueSnackbar(getErrorInValidFormat(e), {
        variant: 'error',
      });
    },
  });

  // == //

  const { mutate: deleteExerciseMutation, isLoading: isLoadingDeleteExercise } =
    useMutation<TDeleteExerciseResponse, Error, TDeleteExerciseRequest>({
      mutationFn: async ({ id, ...data }) => {
        await instance.post('Exercise/delete-bulk', { data });
        return {
          isLastExercise: data.isLastExercise,
          id,
        };
      },
      onSuccess: ({ isLastExercise, id }) => {
        closeDeleteExerciseModal();
        loadProgram();
        enqueueSnackbar('Exercise successfully deleted', {
          variant: 'success',
        });
        if (isLastExercise) {
          closeEditComplexExerciseModal();
        } else if (editComplexExerciseModalData) {
          const exercises = editComplexExerciseModalData.exercises.filter(
            (exercise) => {
              return exercise.id !== id;
            },
          );
          setEditComplexExerciseModalData({
            ...editComplexExerciseModalData,
            exercises,
          });
        }
      },
      onError: (e) => {
        closeDeleteExerciseModal();
        enqueueSnackbar(String(e) || ERROR_MESSAGE, {
          variant: 'error',
        });
      },
    });

  // == //

  const {
    mutate: deleteComplexExerciseMutation,
    isLoading: isLoadingDeleteComplexExercise,
  } = useMutation<TDeleteExerciseRequest, Error, TDeleteExerciseRequest>({
    mutationFn: async (data) => {
      await instance.post('Exercise/delete-complex-exercise-bulk', { data });
      return data;
    },
    onSuccess: ({ dayOrderNumber, complexExerciseOrderNumber }) => {
      // in scope of 3606 ticket - optimize deleting only LAST element
      const currentDay = phaseData.days.find(
        (day) => day.orderNumber === dayOrderNumber,
      );
      // check if it is the last complex exercise of day
      if (
        currentDay?.complexExercises[currentDay.complexExercises.length - 1]
          .orderNumber === complexExerciseOrderNumber
      ) {
        setPhaseData({
          ...phaseData,
          // find and delete last complex exercise from currentDay in phaseData structure
          days: phaseData.days.map((day) => {
            if (day.orderNumber === currentDay?.orderNumber) {
              const dayWithDeletedLastComplexExercise =
                day.complexExercises.slice(0, -1);
              return {
                ...day,
                complexExercises: dayWithDeletedLastComplexExercise,
              };
            }
            return day;
          }),
        });
      } else {
        loadProgram();
      }
      closeDeleteComplexExerciseModal();
      enqueueSnackbar('Superset successfully deleted', {
        variant: 'success',
      });
    },
    onError: (e) => {
      closeDeleteComplexExerciseModal();
      enqueueSnackbar(String(e) || ERROR_MESSAGE, {
        variant: 'error',
      });
    },
  });

  // == //

  const { mutate: swapExercisesMutation, isLoading: isLoadingSwapExercises } =
    useMutation<void, Error, TSwapExercisesRequest>({
      mutationFn: (data) => {
        return instance.put('Exercise/swap-complex-exercises', {
          data,
        });
      },
      onSuccess: () => {
        loadProgram();
        enqueueSnackbar('Exercises have been successfully swapped', {
          variant: 'success',
        });
        closeSwapExercisesModal();
      },
      onError: (e) => {
        closeSwapExercisesModal();
        enqueueSnackbar(String(e) || ERROR_MESSAGE, {
          variant: 'error',
        });
      },
    });

  // == //

  const { mutate: editExercisesMutation, isLoading: isLoadingEditExercises } =
    useMutation<void, Error, TEditExercisesRequest>({
      mutationFn: (data) => {
        return instance.put('Exercise/update-bulk', {
          data,
        });
      },
      onMutate: () => {
        gaEvents.submitEditExerciseModal();
      },
      onSuccess: () => {
        loadProgram();
        enqueueSnackbar('Exercise successfully edited', {
          variant: 'success',
        });
        closeEditExerciseModal();
      },
      onError: (e) => {
        closeEditExerciseModal();
        enqueueSnackbar(String(e) || ERROR_MESSAGE, {
          variant: 'error',
        });
      },
    });

  // == //

  const {
    mutate: editComplexExerciseMutation,
    isLoading: isLoadingEditComplexExercise,
  } = useMutation<void, Error, TEditComplexExerciseRequest>({
    mutationFn: (data) => {
      return instance.put('Exercise/update-complex-exercise-bulk', {
        data,
      });
    },
    onMutate: () => {
      if (isChatPage()) {
        gaEvents.chatSubmitEditComplexExerciseModal();
      } else {
        gaEvents.submitEditComplexExerciseModal();
      }
    },
    onSuccess: () => {
      loadProgram();
      enqueueSnackbar('Exercises successfully edited', {
        variant: 'success',
      });
      closeEditComplexExerciseModal();
    },
    onError: (e) => {
      closeEditComplexExerciseModal();
      enqueueSnackbar(String(e) || ERROR_MESSAGE, {
        variant: 'error',
      });
    },
  });
  const handleSubmitEditComplexExercise = ({
    exercises,
    complexExerciseId,
  }: TEditComplexExerciseModalData) => {
    editComplexExerciseMutation({
      complexExerciseId,
      exercises: exercises.map((exercise) => ({
        description: exercise.description,
        exerciseInfoId: exercise.exerciseInfo.id,
        exercises: exercise.weeks,
      })),
    });
  };
  // == //

  return {
    handleSubmitCreateExercise,
    isLoadingCreateExercise,

    duplicateProgramDayMutation,
    isLoadingDuplicateProgramDay,

    deleteDayMutation,
    isLoadingDeleteDay,

    addDayMutation,
    isLoadingAddDay,

    deleteExerciseMutation,
    isLoadingDeleteExercise,

    deleteComplexExerciseMutation,
    isLoadingDeleteComplexExercise,

    swapExercisesMutation,
    isLoadingSwapExercises,

    editExercisesMutation,
    isLoadingEditExercises,

    handleSubmitEditComplexExercise,
    isLoadingEditComplexExercise,
  } as const;
};

export default useFetch;
