import React, { useState } from 'react';
import Button from 'components/_new/Button';
import { FeedbackModal } from 'components/feedback/feedbackModal/FeedbackModal';
import {
  isShowBanner,
  setFeedbackBannerShowNextTimeToLocalStorage,
} from './utils';
import gaEvents from 'utils/gaEvents';

import S from './FeedbackNotificationBanner.styled';

export const FeedbackNotificationBanner = () => {
  const [isBannerShown, setIsBannerShown] = useState(isShowBanner());
  const [isFeedbackModalShown, setIsFeedbackModalShown] = useState(false);

  const handleSkip = () => {
    setIsBannerShown(false);
    setFeedbackBannerShowNextTimeToLocalStorage();

    gaEvents.clickSkipFeedbackButtonOnBanner();
  };

  const handleComplete = () => {
    setIsBannerShown(false);
    setFeedbackBannerShowNextTimeToLocalStorage();

    setIsFeedbackModalShown(true);

    gaEvents.openFeedbackModalFromBanner();
  };

  const onCloseFeedbackModal = () => {
    setFeedbackBannerShowNextTimeToLocalStorage();
  };

  return (
    <>
      {isBannerShown && (
        <S.Wrapper>
          <span className="title">Share your thoughts</span>
          <p className="description">
            Your feedback will help us enhance your experience and that of other
            users. You can also leave your feedback anytime in the Profile
            section.
          </p>
          <div className="actions">
            <Button
              style={{ flex: 1 }}
              type="button"
              themeType="dark"
              onClick={handleSkip}
            >
              SKIP
            </Button>
            <Button
              style={{ flex: 2 }}
              type="button"
              themeType="light"
              onClick={handleComplete}
            >
              SEND FEEDBACK
            </Button>
          </div>
        </S.Wrapper>
      )}
      <FeedbackModal
        isShown={isFeedbackModalShown}
        setIsShown={setIsFeedbackModalShown}
        onCloseFeedbackModal={onCloseFeedbackModal}
      />
    </>
  );
};
