import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UserProvider from './providers/userProvider';
import './index.css';
import 'components/styles/_modal.scss';
import 'components/styles/autocomplete.scss';

import history from './lib/history';

const queryClient = new QueryClient();

const gaApiKey = process.env.REACT_APP_GA_KEY;

if (gaApiKey) {
  ReactGA.initialize(gaApiKey);
}

ReactDOM.render(
    <UserProvider>
        <BrowserRouter history={history}>
            <QueryClientProvider client={queryClient}>
                <App history={history} />
            </QueryClientProvider>
        </BrowserRouter>
    </UserProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
