import React, { useState, createContext, ReactNode } from 'react';
import { noop } from '../utils';

import type { TNotification } from '../components/header/models';

type TInitialState = {
  notifications: TNotification[];
  onSetNotifications: (data: TNotification[]) => void;
}

const initialState: TInitialState = { notifications: [], onSetNotifications: noop };

export const NotificationsContext = createContext(initialState);

const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<TNotification[]>([]);

  const onSetNotifications = (data: TNotification[]) => {
    setNotifications(data);
  };

  return (
    <NotificationsContext.Provider
      value={{ notifications, onSetNotifications }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
