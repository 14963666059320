import React, { ChangeEvent, FC } from 'react';
import { UploadAndDragAndDropArea } from '../uploadAndDragAndDropArea/UploadAndDragAndDropArea';

import { ReactSVG } from 'react-svg';
import S from './UploadVideoField.styled';

interface IUploadVideoFieldProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const UploadVideoField: FC<IUploadVideoFieldProps> = ({ onChange }) => {
  return (
    <S.Wrapper>
      <UploadAndDragAndDropArea onChange={onChange} fileType={"video/*"}>
        <div className="contentArea">
          <ReactSVG src="/assets/svg/dragAndDrop.svg" />
          <p className="label">Drag and drop a video</p>
        </div>
      </UploadAndDragAndDropArea>
    </S.Wrapper>
  );
};
