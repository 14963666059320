import React, { ReactElement } from 'react';
import { AdminExercisesIc, ChatIc, ClientsIc, ExercisesIc, MyExercisesIc, TemplateIc } from '../assets/svg';

export const CHAT_ID = 'trainer/chat';

export type TSidebarChildItem = {
    name: string;
    id: string;
    iconComponent: ReactElement;
}
export type TSidebarItem = Pick<TSidebarChildItem, 'name' | 'id' | 'iconComponent'> & {
    children?: TSidebarChildItem[],
    tooltip?: string,
}

export type TSidebarItems = TSidebarItem[]

export const strengthSystemSidebarItems = [
    {
        name: 'Trainers',
        iconSrc: '/assets/svg/trainers.svg',
        id: 'strength-system/trainers',
    },
    {
        name: 'Clients',
        iconSrc: '/assets/svg/clients.svg',
        id: 'strength-system/clients',
    },
    {
        name: 'Questionnaire',
        iconSrc: '/assets/svg/questionnaires.svg',
        id: 'strength-system/questionnaire',
    },
    {
        name: 'Exercises',
        iconSrc: '/assets/svg/exercises.svg',
        id: 'strength-system/exercises',
    },
    {
        name: 'Formulas',
        iconSrc: '/assets/svg/formulas.svg',
        id: 'strength-system/formulas',
    },
];

export const baseBodyBabesSidebarItems = [
    {
        name: 'Clients',
        iconSrc: '/assets/svg/clients.svg',
        id: 'base-body-babes/clients',
    },
    {
        name: 'Questionnaire',
        iconSrc: '/assets/svg/questionnaires.svg',
        id: 'base-body-babes/questionnaire',
    },
    {
        name: 'Exercises',
        iconSrc: '/assets/svg/exercises.svg',
        id: 'base-body-babes/exercises',
    },
    {
        name: 'Formulas',
        iconSrc: '/assets/svg/formulas.svg',
        id: 'base-body-babes/formulas',
    },
];

export const sidebarTrainersItems: TSidebarItems = [
    {
        name: 'Clients',
        id: 'trainers-clients',
        tooltip: 'Clients',
        iconComponent: <ClientsIc />,
    },
    {
        name: 'Exercises',
        iconComponent: <ExercisesIc />,
        id: 'exercises',
        children: [
            {
                name: 'My Exercises',
                id: 'trainer/my-exercises',
                iconComponent: <MyExercisesIc />,
            },
            {
                name: 'Admin Exercises',
                id: 'trainer/admin-exercises',
                iconComponent: <AdminExercisesIc />,
            },
        ],
    },
    {
        name: 'Templates',
        id: 'trainer/templates',
        iconComponent: <TemplateIc />,
        tooltip: 'Templates',
    },
    {
        name: 'Chat',
        id: CHAT_ID,
        iconComponent: <ChatIc />,
        tooltip: 'Chat',
    },
];

export const headCellsTrainers = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'phoneNumber', numeric: true, disablePadding: false, label: 'Phone' },
    {
        id: 'createdOn',
        numeric: true,
        disablePadding: false,
        label: 'Date created',
    },
    {
        id: 'clientsNumber',
        numeric: true,
        disablePadding: false,
        label: 'Actual clients',
    },
    {
        id: 'clientsLimit',
        numeric: true,
        disablePadding: false,
        label: 'Allowed сlients',
    },
];

export const autocompleteTriggers = ['@', '#', '$'];

export const complexExerciseTypes = {
    single: 0,
    superset: 1,
};
export const exerciseTypeData = {
    static: 0,
    dynamic: 1,
};

export const PER_PAGE = 10;
