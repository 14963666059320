import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  & .phases {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
    gap: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

export default { Wrapper }