/* eslint-disable @typescript-eslint/no-empty-function */
// TODO: edit to tsx component
// TODO: separate to logic smaller components
import React, { useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import Modal from '@material-ui/core/Modal';
import { TextArea } from 'components/fields/textArea/TextArea';
import TextInput from 'components/fields/textInput';
import { deleteApiData, getApiData, postData, putData } from 'lib/api/utils';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from 'components/autocomplete/Autocomplete';
import gaEvents from 'utils/gaEvents';
import { LockUnlockPhase } from '../../lockUnlockPhase/LockUnlockPhase';
import { ImportTemplate } from '../components/importTemplate/ImportTemplate';
import { useFetch } from '../biggerProgram/hooks/useFetch';
import Loader from 'components/loader/Loader';
import { ProgramHeaderRow } from '../components/programHeaderRow/ProgramHeaderRow';
import { useFetch as importTemplateUseFetch } from '../components/importTemplate/hooks/useFetch';
import { AddButton } from '../components/addButton/AddButton';

// TODO: replace all styles to the module
import classes from './_DbdMode.module.scss'

const catalogOptionId = {
    'Admin Exercises': 0,
    'My Exercises': 1,
};

const catalogOptions = [
    { id: catalogOptionId['Admin Exercises'], name: 'Admin Exercises' },
    { id: catalogOptionId['My Exercises'], name: 'My Exercises' },
];

export const DbdMode = ({
    program,
    exerciseCounter,
    activeExercise,
    setActiveExercise,
    defaultExercise,
    defaultExerciseRow,
    loadBigProgramHandler,
    loadCurrentProgram,
    setLoading,
    newComplexExercise,
    setNewComplexExercise,
    exercises,
    setProgram,
    viewModeId,
    setViewModeId,
    toggleOpenEditProgramModal,
}) => {
    const [hoveredPhase, setHoveredPhase] = useState(null);
    const [activePhase, setActivePhase] = useState(null);
    const [activeDay, setActiveDay] = useState(null);
    const [activeExerciseForDeleting, setActiveExerciseForDeleting] =
      useState(null);
    const [showModal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [error, setError] = useState('');
    const [editingExercise, setEditingExercise] = useState(null);
    const [currentCatalog, setCurrentCatalog] = useState(catalogOptions[0]);
    const [myExercises, setMyExercises] = useState([]);
    const [selectedMyExercise, setSelectedMyExercise] = useState(null);
    const [isImportTemplateModalShown, setIsImportTemplateModalShown] =
      useState(false);

    useEffect(() => {
      fetchMyExercises();
      loadCurrentProgram();
    }, []);

    async function fetchMyExercises() {
      await getApiData(`ExerciseInfo/trainer-exercise-infos`).then(
        ({ items }) => {
          setMyExercises(items.map(({ name, id }) => ({ name, id })));
          setSelectedMyExercise(items[0] || null);
        },
      );
    }

    const onChangeCatalogHandler = (e) => {
      if (Number(e.target.value) === catalogOptionId['Admin Exercises']) {
        setCurrentCatalog(catalogOptions[0]);

        const adminExercises = exercises.filter((exercise) =>
          newComplexExercise.exerciseIds.every(
            (id) =>
              id !== exercise.id ||
              editingExercise?.exerciseInfo?.id === exercise.id,
          ),
        );

        setEditingExercise((prev) => ({
          ...prev,
          exerciseInfo: adminExercises[0],
          exerciseInfoId: adminExercises[0].id,
        }));

        gaEvents.selectAdminExerciseCatalogOnAddExerciseModal();
      } else if (Number(e.target.value) === catalogOptionId['My Exercises']) {
        setCurrentCatalog(catalogOptions[1]);

        setEditingExercise((prev) => ({
          ...prev,
          exerciseInfo: myExercises[0],
          exerciseInfoId: myExercises[0].id,
        }));
        gaEvents.selectMyExerciseCatalogOnAddExerciseModal();
      }
    };

    const onChangeMyExercisesHandler = (e, item) => {
      setSelectedMyExercise(
        myExercises.find(({ id }) => id === item.id) || myExercises[0] || null,
      );
    };

    const toggleOpenModal = useCallback(() => setModal(true), []);

    const toggleCloseModal = useCallback(() => {
        setActiveDay(null);
        setActiveExerciseForDeleting(null);
        setModal(false);
        setError('');
    }, []);

    const setActivePhaseHandler = (i) => {
        if (activePhase === i) {
            setActivePhase(null);
        } else {
            setActivePhase(i);
        }
    };

    const exerciseHandler = (exercise) => {
        setActiveExercise(exercise);
    };

    const openAddExerciseModalHandler = (day) => {
        const exerciseIds = [];

        day.complexExercises.forEach((complex) => {
            complex.exercises.forEach((exercise) => {
                exerciseIds.push(exercise.exerciseInfo.id);
            });
        });

        setNewComplexExercise({
            ...newComplexExercise,
            dayId: day.id,
            exerciseIds,
            exercises: [
                {
                    ...defaultExercise,
                    name: exercises.length
                        ? exercises.filter((exercise) =>
                            exerciseIds.every((id) => id !== exercise.id)
                        )[0]
                        : null,
                    exerciseRows: [
                        {
                            ...defaultExerciseRow,
                            weight: 0,
                        },
                    ],
                },
            ],
        });
        setModalType('addExercise');
        toggleOpenModal();
    };

    const openEditExerciseModalHandler = (e, exercise, day) => {
        e.stopPropagation();

        const exerciseIds = [];

        day.complexExercises.forEach((complex) => {
            complex.exercises.forEach((exercise) => {
                exerciseIds.push(exercise.exerciseInfo.id);
            });
        });

        setCurrentCatalog(
          exercise?.exerciseInfo?.trainerId
            ? catalogOptions[1]
            : catalogOptions[0],
        );

        setNewComplexExercise({
            ...newComplexExercise,
            exerciseIds,
        });

        const newExerciseRowsArray = exercise.exerciseRows.map((row) => {
            return { ...row };
        });

        setEditingExercise({
            ...exercise,
            exerciseRows: newExerciseRowsArray,
            exerciseInfo: {
                name: exercise.exerciseInfo.name,
                id: exercise.exerciseInfo.id,
            },
        });
        setModalType('editExercise');
        toggleOpenModal();
    };

    const openDeletingPhaseModalHandler = (e, phase) => {
        e.stopPropagation();
        e.preventDefault();

        setActivePhase(phase);
        setModalType('deletePhase');
        toggleOpenModal();

        gaEvents.clickDeletePhaseButton();
    };

    const openDeletingDayModalHandler = (e, day) => {
        e.stopPropagation();
        e.preventDefault();

        setActiveDay(day);
        setModalType('deleteDay');
        toggleOpenModal();
    };

    const openDeleteExerciseModalHandler = (e, exercise) => {
        e.stopPropagation();
        e.preventDefault();

        setActiveExerciseForDeleting(exercise);
        setModalType('deleteExercise');
        toggleOpenModal();
    };

    const deleteDayHandler = () => {
        setLoading(true);
        toggleCloseModal();
        deleteApiData(`/Day/${activeDay.id}`).then((answer) => {
            if (answer?.success) {
                loadCurrentProgram();
                loadBigProgramHandler();
            } else {
                setError('Error - ', answer);
                setModalType('error');
                toggleOpenModal();
                setLoading(false);
            }
        });
    };

    const newExerciseHandler = (e) => {
      e.preventDefault();
      toggleCloseModal();
      setLoading(true);

      const data = {
        type: newComplexExercise.type,
        exercises: newComplexExercise.exercises.map((exercise) => {
          return {
            type: exercise.type,
            description: exercise.description,
            exerciseInfoId:
              currentCatalog.id === catalogOptionId['Admin Exercises']
                ? exercise.name.id
                : selectedMyExercise.id,
            exerciseRows: [...exercise.exerciseRows],
          };
        }),
      };

      postData(`Exercise/${newComplexExercise.dayId}`, data).then((answer) => {
        if (answer.success) {
          loadCurrentProgram();
          loadBigProgramHandler();
        } else {
          setLoading(false);
          setError('Error - ', answer);
          setModalType('error');
          toggleOpenModal();
        }
      });
    };

    const chooseExerciseHandler = (e, item, exerciseIndex) => {
        const newExercises = [...newComplexExercise.exercises];

        newExercises[exerciseIndex].name = {
            name: item.name,
            id: item.id,
        };
        setNewComplexExercise({
            ...newComplexExercise,
            exercises: newExercises,
        });
    };

    const checkDisabling = () => {
        return !newComplexExercise.exercises.every((exercise) => {
            return exercise && exercise.name
                ? exercise.name.id &&
            exercise.exerciseRows.every((row) => {
                return Object.entries(row).every(([key, field]) =>
                    key === 'sets' ? field : key === 'weight' ? true : field + ''
                );
            })
                : false;
        });
    };

    const inputsExerciseHandler = (e, exerciseIndex) => {
        const newExercises = [...newComplexExercise.exercises];

        newExercises[exerciseIndex][e.target.name] = e.target.value;
        setNewComplexExercise({
            ...newComplexExercise,
            exercises: newExercises,
        });
    };

    const inputsRowsHandler = (e, exerciseIndex, exerciseRowIndex, isNumber) => {
        if (isNumber) {
            if (!isNaN(e.target.value) && !e.target.value.includes(' ')) {
                const newExercises = [...newComplexExercise.exercises];

                newExercises[exerciseIndex].exerciseRows[exerciseRowIndex][
                    e.target.name
                ] =
          e.target.name === 'reps' ||
          e.target.name === 'weight' ||
          e.target.name === 'sets'
              ? +e.target.value
              : e.target.value;
                setNewComplexExercise({
                    ...newComplexExercise,
                    exercises: newExercises,
                });
            }
        } else {
            const newExercises = [...newComplexExercise.exercises];

            newExercises[exerciseIndex].exerciseRows[exerciseRowIndex][
                e.target.name
            ] = e.target.value;
            setNewComplexExercise({
                ...newComplexExercise,
                exercises: newExercises,
            });
        }
    };

    const editExerciseHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        toggleCloseModal();

        putData(`Exercise/${editingExercise.id}`, editingExercise).then(
            (answer) => {
                if (answer.success) {
                    if (activeExercise && editingExercise.id === activeExercise.id) {
                        setActiveExercise(answer.item);
                    }
                    loadCurrentProgram();
                    loadBigProgramHandler();
                } else {
                    setLoading(false);
                    setError('Error - ', answer);
                    setModalType('error');
                    toggleOpenModal();
                }
            }
        );
    };

    const editExerciseNameHandler = (e, item) => {
        if (!item) return;

        const newExerciseRowsArray = editingExercise.exerciseRows.map((row) => {
            return { ...row, weight: 0 };
        });

        setEditingExercise({
            ...editingExercise,
            exerciseRows: newExerciseRowsArray,
            exerciseInfo: {
                name: item.name,
                id: item.id,
            },
            exerciseInfoId: item.id,
        });
    };

    const editExerciseDescriptionHandler = (e) => {
        setEditingExercise({
            ...editingExercise,
            description: e.target.value,
        });
    };

    const inputsRowsExerciseHandler = (e, index, isNumber) => {
        const editRow = () => {
            const newRows = [...editingExercise.exerciseRows];

            newRows[index][e.target.name] =
              e.target.name === 'reps' ||
              e.target.name === 'sets' ||
              e.target.name === 'weight'
                ? +e.target.value
                : e.target.value;
            setEditingExercise({
                ...editingExercise,
                exerciseRows: newRows,
            });
        };

        if (isNumber) {
            if (!isNaN(e.target.value) && !e.target.value.includes(' ')) {
                editRow();
            }
        } else {
            editRow();
        }
    };

    const checkDisablingEditExerciseModal = () => {
        return !(
          editingExercise.exerciseInfo.id &&
          editingExercise.exerciseRows.every((row) => {
              return row.reps && row.sets && (row.weight || row.weight === 0);
          })
        );
    };

    const addNewDayHandler = (phase, week) => {
        let newDayNameNumber = week?.days?.length || 1;

        week.days.forEach((day) => {
            const dayNumber = day?.name?.split(' ')[1];

            if (dayNumber && +dayNumber > newDayNameNumber) {
                newDayNameNumber = +dayNumber;

                return;
            }
        });

        postData('Day', {
            name: `Day ${+newDayNameNumber + 1}`,
            weekId: week.id,
            orderNumber: +newDayNameNumber,
        }).then((answer) => {
            if (answer.success) {
                loadCurrentProgram();
                loadBigProgramHandler();
            } else {
                setLoading(false);
                setError('Error - ', answer);
                setModalType('error');
                toggleOpenModal();
            }
        });
    };

    const deleteLocalPhaseHandler = () => {
        toggleCloseModal();
        deletePhaseMutation(activePhase?.id);
    };

    const deleteExerciseHandler = (activeExerciseForDeletingId) => {
        setLoading(true);
        toggleCloseModal();
        deleteApiData(`/Exercise/${activeExerciseForDeletingId}`).then((answer) => {
            if (answer?.success) {
                loadCurrentProgram();
                loadBigProgramHandler();
            } else {
                setError(`Error - ${answer}`);
                setModalType('error');
                toggleOpenModal();
                setLoading(false);
            }
        });
    };

    const {
      deletePhaseMutation,
      isLoadingDeletePhase,

      addPhaseMutation,
      isLoadingAddPhase,
    } = useFetch({
      programId: program?.id,
      onAddPhaseSuccessCallback: loadCurrentProgram,
      onDeletePhaseSuccessCallback: loadCurrentProgram,
    });

		const addNewPhaseHandler = () => {
			addPhaseMutation(program);
		};

    const importTemplateHandler = () => {
      setIsImportTemplateModalShown(true);
      gaEvents.clickImportFromTemplateButton();
    };
    const { importTemplateMutation, isLoadingImportTemplate } =
      importTemplateUseFetch({
        onImportTemplateSuccessCallback: loadCurrentProgram,
      });

    const loading = isLoadingDeletePhase || isLoadingAddPhase || isLoadingImportTemplate;

    if (!program) {
        return null
    }

    return (
      <>
          {loading && <Loader />}
          <ProgramHeaderRow
            viewModeId={viewModeId}
            setViewModeId={setViewModeId}
            program={program}
            toggleOpenEditProgramModal={toggleOpenEditProgramModal}
            addNewPhaseHandler={addNewPhaseHandler}
            importTemplateHandler={importTemplateHandler}
          />
          <ul className="phases-list">
              {program.phases.map((phase, i) => {
                  return (
                    <li className="phases-list-item" key={phase.name}>
                        <div
                          className="phases-list-item-outer"
                          onClick={() => setActivePhaseHandler(i)}
                          onMouseEnter={() => setHoveredPhase(i)}
                          onMouseLeave={() => setHoveredPhase(null)}
                        >
                            <div className="phases-list-item-outer-title">
                                Phase {i + 1}
                            </div>
                            <div className={classes["phase__accessToPhaseIcon"]}>
                                <LockUnlockPhase
                                  program={program}
                                  hoveredPhase={hoveredPhase}
                                  setHoveredPhase={setHoveredPhase}
                                  phaseIndex={i}
                                  phase={phase}
                                  setProgram={setProgram}
                                  loadCallbackData={loadBigProgramHandler}
                                  pageId="DBD"
                                />
                            </div>
                            <ReactSVG
                              src={'/assets/svg/delete.svg'}
                              className="phases-list-item-outer-delete"
                              onClick={(e) => openDeletingPhaseModalHandler(e, phase)}
                            />
                        </div>
                        {activePhase === i ? (
                          <ul className="phases-list-item-inner">
                              {phase?.weeks?.map((week, weekIndex) => (
                                <li
                                  key={week.name}
                                  className={`phases-list-item-inner-week ${
                                    !week.days?.length ? 'empty' : ''
                                  }`}
                                >
                                    <p className="phases-list-item-inner-week-title">
                                        {week.name}
                                    </p>
                                    <ul className="phases-list-item-inner-week-days">
                                        {week.days
                                          .sort((a, b) => {
                                              return a.name > b.name;
                                          })
                                          .map((day, dayIndex) => {
                                              let exercisesCount = 0;

                                              day.complexExercises.forEach((item) => {
                                                  exercisesCount += item.exercises.length;
                                              });

                                              return (
                                                <li
                                                  key={day.name}
                                                  className="phases-list-item-inner-week-days-day"
                                                >
                                                    <div className="phases-list-item-inner-week-days-day-title">
                                                        Day {dayIndex + 1}
                                                        <ReactSVG
                                                          src={'/assets/svg/delete.svg'}
                                                          className="phases-list-item-inner-week-days-day-title-delete"
                                                          onClick={(e) =>
                                                            openDeletingDayModalHandler(e, day)
                                                          }
                                                        />
                                                    </div>
                                                    <p className="phases-list-item-inner-week-days-day-count">
                                                        {`${exercisesCount} ${
                                                          day.complexExercises.length === 1
                                                            ? 'Exercise'
                                                            : 'Exercises'
                                                        }`}
                                                    </p>
                                                    <ul className="phases-list-item-inner-week-days-day-exercises">
                                                        {day.complexExercises.map(
                                                          (exerciseSet, exerciseSetIndex) => {
                                                              return exerciseSet.exercises.map(
                                                                (exercise, exerciseIndex) => {
                                                                    return exercise.exerciseInfo &&
                                                                    !exercise.isDeleted ? (
                                                                      <li
                                                                        className="phases-list-item-inner-week-days-day-exercises-exercise"
                                                                        key={
                                                                            exercise.exerciseInfo
                                                                              .description +
                                                                            exerciseIndex +
                                                                            exerciseSetIndex
                                                                        }
                                                                      >
                                                                          <p
                                                                            className="phases-list-item-inner-week-days-day-exercises-exercise-count"
                                                                            style={{
                                                                                backgroundColor:
                                                                                exerciseCounter[
                                                                                exerciseSetIndex %
                                                                                exerciseCounter.length
                                                                                  ].color,
                                                                            }}
                                                                          >
                                                                              {`${exerciseSet.letter}${
                                                                                exercise.orderNumber + 1
                                                                              }`}
                                                                          </p>
                                                                          <p
                                                                            className="phases-list-item-inner-week-days-day-exercises-exercise-title"
                                                                            onClick={() =>
                                                                              exerciseHandler({
                                                                                  ...exercise,
                                                                                  color:
                                                                                  exerciseCounter[
                                                                                  exerciseSetIndex %
                                                                                  exerciseCounter.length
                                                                                    ]?.color,
                                                                                  phaseIndex: i,
                                                                                  letter: `${
                                                                                    exerciseSet.letter
                                                                                  }${
                                                                                    exercise.orderNumber + 1
                                                                                  }`,
                                                                                  weekIndex,
                                                                                  dayIndex,
                                                                                  exerciseIndex,
                                                                                  exerciseSetIndex,
                                                                                  complexExerciseId:
                                                                                  exerciseSet.id,
                                                                                  comments:
                                                                                  exerciseSet.comments,
                                                                              })
                                                                            }
                                                                          >
                                                                              {exercise.exerciseInfo.name}
                                                                          </p>
                                                                          <ReactSVG
                                                                            onClick={(e) =>
                                                                              openEditExerciseModalHandler(
                                                                                e,
                                                                                exercise,
                                                                                day,
                                                                              )
                                                                            }
                                                                            src="/assets/svg/edit-exercise.svg"
                                                                            className="phases-list-item-inner-week-days-day-exercises-exercise-edit phases-list-title-edit"
                                                                          />
                                                                          <ReactSVG
                                                                            onClick={(e) =>
                                                                              openDeleteExerciseModalHandler(
                                                                                e,
                                                                                exercise,
                                                                              )
                                                                            }
                                                                            src="/assets/svg/delete.svg"
                                                                            className="phases-list-item-inner-week-days-day-exercises-exercise-edit delete"
                                                                          />
                                                                      </li>
                                                                    ) : (
                                                                      ''
                                                                    );
                                                                },
                                                              );
                                                          },
                                                        )}
                                                    </ul>
                                                    {/* disabled TASK 3059 */}
                                                    {/* <button
                                                                    onClick={() =>
                                                                        openAddExerciseModalHandler(day)
                                                                    }
                                                                    className='phases-list-item-inner-week-add exercise'
                                                                >
                                                                    + Add exercise
                                                                </button> */}
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                            <div className='phases-list-item-inner-week-add-right'>
                                                <button
                                                    onClick={() => addNewDayHandler(phase, week)}
                                                    className='phases-list-item-inner-week-add day'
                                                >
                                                    + Add new day
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                ''
                            )}
                        </li>
                    );
                })}
            </ul>
            <AddButton title='+ Add New Phase' onClickFunction={addNewPhaseHandler} />
            {isImportTemplateModalShown && (
              <ImportTemplate
                programId={program?.id}
                importTemplateMutation={importTemplateMutation}
                setIsImportTemplateModalShown={setIsImportTemplateModalShown}
              />
            )}
            <Modal onClose={toggleCloseModal} open={showModal}>
                <div className='modal phases-modal'>
                    <div className='modal__close modal__body--sm'>
                        <ReactSVG onClick={toggleCloseModal} src='/assets/svg/close.svg' />
                    </div>
                    <div className='modal__body'>
                        {modalType === 'addExercise' ? (
                            <div className='modal__body'>
                                <p className='modal-title'>Add new exercise</p>
                                <form onSubmit={newExerciseHandler}>
                                    {/* catalog control */}
                                    {myExercises.length > 0 && (
                                        <div className='modal-field'>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={currentCatalog.id}
                                                    onChange={onChangeCatalogHandler}
                                                    row={true}
                                                >
                                                    {catalogOptions.map(({id,name}) => (
                                                        <FormControlLabel
                                                            key={id}
                                                            value={id}
                                                            control={<Radio />}
                                                            label={name}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    )}
                                    {/* exercises select */}
                                    <div className='modal-field'>
                                        {currentCatalog.id === catalogOptionId['Admin Exercises'] ? (
                                            <Autocomplete
                                                options={
                                                    exercises
                                                        ? exercises.filter((exercise) =>
                                                            newComplexExercise.exerciseIds.every(
                                                                (id) => id !== exercise.id
                                                            )
                                                        )
                                                        : []
                                                }
                                                value={newComplexExercise.exercises[0].name}
                                                onChange={(e, name) => chooseExerciseHandler(e, name, 0)}
                                            />
                                        ) : (
                                            <Autocomplete
                                                options={myExercises}
                                                value={selectedMyExercise}
                                                onChange={onChangeMyExercisesHandler}
                                            />
                                        )}
                                    </div>
                                    {/* == */}
                                    <div className='modal-field'>
                                        <TextArea
                                            placeholder='Description'
                                            name='description'
                                            value={newComplexExercise.exercises[0].description}
                                            onChange={(e) => inputsExerciseHandler(e, 0)}
                                            rows={2}
                                            cols={15}
                                        />
                                    </div>
                                    <div className='modal-fields lined'>
                                        <div className='modal-fields-number'>
                                            <p className='modal-fields-number-label'>Sets</p>
                                            <TextInput
                                                value={
                                                    newComplexExercise.exercises[0].exerciseRows[0].sets
                                                }
                                                onChange={(e) => {
                                                    inputsRowsHandler(e, 0, 0, true);
                                                }}
                                                type='text'
                                                name='sets'
                                            />
                                        </div>
                                        <div className='modal-fields-number'>
                                            <p className='modal-fields-number-label'>Reps</p>
                                            <TextInput
                                                value={
                                                    newComplexExercise.exercises[0].exerciseRows[0].reps
                                                }
                                                onChange={(e) => {
                                                    inputsRowsHandler(e, 0, 0, true);
                                                }}
                                                type='text'
                                                name='reps'
                                            />
                                        </div>
                                        <div className='modal-fields-number'>
                                            <p className='modal-fields-number-label'>Weight</p>
                                            <TextInput
                                                value={
                                                    newComplexExercise.exercises[0].exerciseRows[0].weight
                                                }
                                                onChange={(e) => {
                                                    inputsRowsHandler(e, 0, 0, true);
                                                }}
                                                type='text'
                                                name='weight'
                                            />
                                        </div>
                                        <div className='modal-fields-number'>
                                            <p className='modal-fields-number-label'>Rest</p>
                                            <TextInput
                                                value={
                                                    newComplexExercise.exercises[0].exerciseRows[0].rest
                                                }
                                                onChange={(e) => {
                                                    inputsRowsHandler(e, 0, 0);
                                                }}
                                                type='text'
                                                name='rest'
                                            />
                                        </div>
                                    </div>
                                    {error ? <p className='modal-error'>{error}</p> : ''}
                                    <div className='modal-buttons'>
                                        <button
                                            className='modal-buttons-cancel'
                                            onClick={toggleCloseModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className='modal-buttons-delete'
                                            type='submit'
                                            disabled={checkDisabling()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                        ) : modalType === 'editExercise' ? (
                            <div className='modal__body'>
                                <p className='modal-title'>Edit exercise</p>
                                <form onSubmit={editExerciseHandler}>
                                    {myExercises.length > 0 && (
                                        <div className="modal-field">
                                            <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={currentCatalog.id}
                                                onChange={(e) => onChangeCatalogHandler(e)}
                                                row={true}
                                            >
                                                {catalogOptions.map(({ id, name }) => (
                                                <FormControlLabel
                                                    key={id}
                                                    value={id}
                                                    control={<Radio />}
                                                    label={name}
                                                />
                                                ))}
                                            </RadioGroup>
                                            </FormControl>
                                        </div>
                                    )}
                                    <div className='modal-field'>
                                        {/* <SelectField
                                            old functionality - can be deleted

                                            value={editingExercise?.exerciseInfo}
                                            options={
                                                exercises
                                                    ? exercises.filter((exercise) =>
                                                        newComplexExercise.exerciseIds.every(
                                                            (id) =>
                                                                id !== exercise.id ||
                                                editingExercise?.exerciseInfo?.id ===
                                                    exercise.id
                                                        )
                                                    )
                                                    : []
                                            }
                                            onChange={editExerciseNameHandler}
                                        /> */}
                                        <Autocomplete
                                            options={
                                            currentCatalog.id ===
                                            catalogOptionId['Admin Exercises']
                                                ? exercises
                                                ? exercises.filter((exercise) =>
                                                    newComplexExercise.exerciseIds.every(
                                                        (id) =>
                                                            id !== exercise.id ||
                                                            editingExercise?.exerciseInfo?.id ===
                                                            exercise.id
                                                    )
                                                )
                                                : []
                                                : myExercises
                                            }
                                            value={editingExercise?.exerciseInfo}
                                            onChange={editExerciseNameHandler}
                                        />
                                    </div>
                                    <div className='modal-field'>
                                        <TextArea
                                            placeholder='Description'
                                            name='description'
                                            value={editingExercise.description}
                                            onChange={editExerciseDescriptionHandler}
                                            rows={2}
                                            cols={15}
                                        />
                                    </div>
                                    <>
                                        {/* {editingExercise.exerciseRows.length > 1 && (
                                            <div className='modal-field labeled'>
                                                <p className='modal-field-label'>Rest</p>
                                                <TextInput
                                                    value={editingExercise.exerciseRows[0].rest}
                                                    onChange={(e) => inputsRowsExerciseHandler(e, 0)}
                                                    type='text'
                                                    name='rest'
                                                />
                                            </div>
                                        )} */}
                                    </>
                                    {/* {editingExercise.exerciseRows.length > 1 && (
                                        <div className='modal-fields-labels main'>
                                            <p className='modal-fields-labels-label'>Reps</p>
                                            <p className='modal-fields-labels-label'>Weight</p>
                                        </div>
                                    )} */}
                                    {editingExercise.exerciseRows.map((row, i) => (
                                        <div key={row.id}>
                                            {/* {editingExercise.exerciseRows.length > 1 && (
                                                <div className='modal-fields-labels'>
                                                    <p className='modal-fields-labels-label'>{`Set ${ i + 1 }`}</p>
                                                </div>
                                            )} */}
                                            <div className='modal-fields lined'>
                                                    <div className='modal-fields-number'>
                                                        <p className='modal-fields-number-label'>Set</p>
                                                        <TextInput
                                                            value={row.sets}
                                                            // disabled by TASK 3059
                                                            // onChange={(e) =>
                                                            //     inputsRowsExerciseHandler(e, i, true)
                                                            // }
                                                            type='text'
                                                            name='sets'
                                                            disabled={true}
                                                        />
                                                    </div>
                                                <div className='modal-fields-number'>
                                                        <p className='modal-fields-number-label'>Reps</p>
                                                    <TextInput
                                                        value={row.reps}
                                                        // disabled by TASK 3059
                                                        // onChange={(e) =>
                                                        //     inputsRowsExerciseHandler(e, i, true)
                                                        // }
                                                        type='text'
                                                        name='reps'
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className='modal-fields-number'>
                                                        <p className='modal-fields-number-label'>Weight</p>
                                                    <TextInput
                                                        value={row.weight}
                                                        // disabled by TASK 3059
                                                        // onChange={(e) =>
                                                        //     inputsRowsExerciseHandler(e, i, true)
                                                        // }
                                                        type='text'
                                                        name='weight'
                                                        disabled={true}
                                                    />
                                                </div>
                                                    <div className='modal-fields-number'>
                                                        <p className='modal-fields-number-label'>Rest</p>
                                                        <TextInput
                                                            value={row.rest}
                                                            // disabled by TASK 3059
                                                            // onChange={(e) => inputsRowsExerciseHandler(e, i)}
                                                            type='text'
                                                            name='rest'
                                                            disabled={true}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                    ))}
                                    {error ? <p className='modal-error'>{error}</p> : ''}
                                    <div className='modal-buttons'>
                                        <button
                                            className='modal-buttons-cancel'
                                            onClick={toggleCloseModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className='modal-buttons-delete'
                                            type='submit'
                                            disabled={checkDisablingEditExerciseModal()}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        ) : modalType === 'error' ? (
                            <div className='modal__body'>
                                <p className='modal-message'>{error}</p>
                            </div>
                        ) : modalType === 'deletePhase' ? (
                            <div className='modal-delete'>
                                <p className='modal-title'>
                                    Are you sure you want to delete this phase?
                                </p>
                                <div className='modal-buttons'>
                                    <button
                                        className='modal-buttons-cancel'
                                        onClick={toggleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className='modal-buttons-delete'
                                        onClick={deleteLocalPhaseHandler}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        ) : modalType === 'deleteDay' ? (
                            <div className='modal-delete'>
                                <p className='modal-title'>
                                    Are you sure you want to delete this day?
                                </p>
                                <div className='modal-buttons'>
                                    <button
                                        className='modal-buttons-cancel'
                                        onClick={toggleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className='modal-buttons-delete'
                                        onClick={deleteDayHandler}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        ) : modalType === 'deleteExercise' ? (
                            <div className='modal-delete'>
                                <p className='modal-title'>
                                    Are you sure you want to delete this exercise?
                                </p>
                                <div className='modal-buttons'>
                                    <button
                                        className='modal-buttons-cancel'
                                        onClick={toggleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className='modal-buttons-delete'
                                        onClick={() =>
                                            deleteExerciseHandler(activeExerciseForDeleting.id)
                                        }
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};
