import React, { useState } from 'react';
import ConfirmModal from 'components/_new/Modal/sub/ConfirmModal';
import { MasterRow } from '../MasterRow/MasterRow';
import { Tooltip } from 'components/tooltip/Tooltip';
import {
  excludeSpecSymbolsForNumberInput,
  getOnlyIntegerValueFromNumberField,
  getOnlyIntegerValueFromRestField,
  REPS_KEY,
  REST_KEY,
  RPE_KEY,
  WEIGHT_KEY,
} from '../../utils';
import gaEvents from 'utils/gaEvents';
import { PROGRAM } from 'constants/tooltips';
import { isUserPressedBackspace } from 'utils';
import { TOOLTIP_STYLES } from '../../constants';

import type { ChangeEvent } from 'react';
import type { TExerciseRow, TWeek } from '../../../phase/models';
import type { TWeekGroupProps } from '../../models';

import { ArrowTriangleIc, InfoIc } from 'assets/svg';
import S from './WeekGroup.styled';

const WeekGroup = ({
  exerciseId,
  weeks,
  onChangeRowValue,
  onAddSetToExercise,
  onSubmitRemoveLastSet,
  onSubmitExerciseMasterRow,
  modalId,
}: TWeekGroupProps) => {
  return (
    <S.Wrapper>
      <MasterRow
        exerciseId={exerciseId}
        onSubmitExerciseMasterRow={onSubmitExerciseMasterRow}
        modalId={modalId}
      />

      {weeks.map(({ name: weekName, exerciseRows }) => (
        <div key={weekName}>
          <S.WeekName>{weekName}</S.WeekName>

          <S.SetsList>
            {exerciseRows?.map(
              ({ id: rowId, reps, rest, weight, rpe }, index) => {
                const isFirstRow = index === 0;
                const rpeValue = rpe || rpe === 0
                  ? getOnlyIntegerValueFromNumberField(rpe)
                  : '';
                
                const onChangeRowValueHandler = ({ e, rowKey }: { e: ChangeEvent<HTMLInputElement>, rowKey: keyof TExerciseRow}) => {
                  const userPressedBackspace = isUserPressedBackspace(e);

                  onChangeRowValue({
                    newValue: e.target.value,
                    userPressedBackspace,
                    exerciseId,
                    rowKey,
                    weekName,
                    rowId,
                  });
                };

                return (
                  <S.SetItem key={rowId}>
                    <div className="cell">
                      {isFirstRow && <span className="cellLabelWrapper cellLabel">Reps</span>}
                      <S.CellInputWrapper>
                        <input
                          className="cellInput"
                          value={reps}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeRowValueHandler({
                              e,
                              rowKey: REPS_KEY,
                            })
                          }
                          type="text"
                          name={REPS_KEY}
                        />
                      </S.CellInputWrapper>
                    </div>
                    <div className="cell">
                      {isFirstRow && <span className="cellLabelWrapper cellLabel">Weight</span>}
                      <S.CellInputWrapper>
                        <input
                          className="cellInput"
                          value={getOnlyIntegerValueFromNumberField(weight)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeRowValueHandler({
                              e,
                              rowKey: WEIGHT_KEY,
                            })
                          }
                          type="text"
                          name={WEIGHT_KEY}
                        />
                      </S.CellInputWrapper>
                    </div>
                    <div className="cell">
                      {isFirstRow && <span className="cellLabelWrapper cellLabel">Rest</span>}
                      <S.CellInputWrapper>
                        <input
                          className="cellInput"
                          value={getOnlyIntegerValueFromRestField(rest)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeRowValueHandler({
                              e,
                              rowKey: REST_KEY,
                            })
                          }
                          onKeyDown={excludeSpecSymbolsForNumberInput}
                          type="number"
                          name={REST_KEY}
                        />
                      </S.CellInputWrapper>
                    </div>
                    <div className="cell">
                      {isFirstRow && (
                        <div className="cellLabelWrapper">
                          <Tooltip
                            title={PROGRAM.RPE_SELECTOR_INFO}
                            styles={TOOLTIP_STYLES}
                          >
                            <InfoIc className="infoIcon" />
                          </Tooltip>
                          <span className="cellLabel">RPE</span>
                        </div>
                      )}
                      <S.CellInputWrapper isWithArrows={true}>
                        <input
                          className="cellInput"
                          value={rpeValue}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeRowValueHandler({
                              e,
                              rowKey: RPE_KEY,
                            })
                          }
                          type="text"
                          name={RPE_KEY}
                        />
                        <div className="arrows">
                          <button
                            className="arrowButton up"
                            onClick={() => {
                              const newValue = (+rpeValue + 0.5).toString();

                              onChangeRowValue({
                                newValue,
                                exerciseId,
                                rowKey: RPE_KEY,
                                weekName,
                                rowId,
                              });
                            }}
                          >
                            <ArrowTriangleIc />
                          </button>
                          <button
                            className="arrowButton down"
                            onClick={() => {
                              const newValue = (+rpeValue - 0.5).toString();

                              onChangeRowValue({
                                newValue,
                                exerciseId,
                                rowKey: RPE_KEY,
                                weekName,
                                rowId,
                              });
                            }}
                          >
                            <ArrowTriangleIc />
                          </button>
                        </div>
                      </S.CellInputWrapper>
                    </div>
                  </S.SetItem>
                );
              },
            )}
          </S.SetsList>

          <WeekActionButtons
            weekName={weekName}
            exerciseId={exerciseId}
            onAddSetToExercise={onAddSetToExercise}
            exerciseRowsAmount={exerciseRows.length}
            onSubmitRemoveLastSet={onSubmitRemoveLastSet}
          />
        </div>
      ))}
    </S.Wrapper>
)};

function WeekActionButtons({
  weekName,
  exerciseId,
  exerciseRowsAmount,
  onAddSetToExercise,
  onSubmitRemoveLastSet,
}: {
  weekName: TWeek['name'];
  exerciseRowsAmount: number;
  exerciseId: TWeekGroupProps['exerciseId'];
  onAddSetToExercise: TWeekGroupProps['onAddSetToExercise'];
  onSubmitRemoveLastSet: TWeekGroupProps['onSubmitRemoveLastSet'];
}) {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const onAddSet = () => {
    onAddSetToExercise(exerciseId, weekName);
    gaEvents.clickAddSetButtonOnAddOrEditComplexExerciseModal();
  };
  const openRemoveSetConfirmationModal = () => {
    setIsOpenConfirmModal(true);
    gaEvents.openRemoveSetOnAddOrEditComplexExerciseConfirmationModal();
  };
  const cancelRemoveSetConfirmationModal = () => {
    setIsOpenConfirmModal(false);
    gaEvents.cancelRemoveSetOnAddOrEditComplexExerciseConfirmationModal();
  };
  const closeRemoveSetConfirmationModal = () => {
    setIsOpenConfirmModal(false);
    gaEvents.closeRemoveSetOnAddOrEditComplexExerciseConfirmationModal();
  };
  const outsideCloseRemoveSetConfirmationModal = () => {
    setIsOpenConfirmModal(false);
    gaEvents.outsideCloseRemoveSetOnAddOrEditComplexExerciseConfirmationModal();
  };
  const onSubmitRemoveSet = () => {
    setIsOpenConfirmModal(false);
    onSubmitRemoveLastSet(exerciseId, weekName);
    gaEvents.clickRemoveSetButtonOnAddOrEditComplexExerciseModal();
  };

  return (
    <>
      <S.ActionButtons>
        <button
          type="button"
          disabled={exerciseRowsAmount < 2}
          className="actionButton removeButton"
          onClick={openRemoveSetConfirmationModal}
        >
          - Remove Set
        </button>

        <button
          type="button"
          className="actionButton addButton"
          disabled={exerciseRowsAmount > 100}
          onClick={onAddSet}
        >
          + Add Set
        </button>
      </S.ActionButtons>

      <ConfirmModal
        onCancel={cancelRemoveSetConfirmationModal}
        onClose={closeRemoveSetConfirmationModal}
        onCloseOnOutsideClick={outsideCloseRemoveSetConfirmationModal}
        onSubmit={onSubmitRemoveSet}
        show={isOpenConfirmModal}
        title="Are you sure you want to delete the latest set in this exercise?"
      />
    </>
  );
}

export default WeekGroup;
