import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { renderTextWithThreePointsByRowsCount } from 'styles/commonStyleVariables';

const Wrapper = styled.div<{
  isReferenceActive: boolean;
  isInShortForm?: boolean;
}>`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 3px;
  
  background-color: #1C1F21;
  color: #A7B0B6;
  border: 1px solid #3E434B;
  border-radius: 12px;
  padding: 3px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  
  ${({ isInShortForm }) => isInShortForm && css`
    padding: 0;
    border: none;
    
    && .exerciseReferenceName {
      display: none;
    }
  `}
  
  ${({ isReferenceActive }) => !isReferenceActive && css`
    color: #6E7987;
    cursor: default;

    && .path {
      background-color: #31363F;
      color: #6E7987;
    }
  `}

  & .path {
    color: #1C1F21;
    background-color: #FFEF01;
    border-radius: 8px;
    line-height: 24px;
    padding: 2px 8px;
    white-space: nowrap;
  }
  
  & .exerciseReferenceName {
    ${renderTextWithThreePointsByRowsCount(1)};
    margin: 0 5px;
  }
  
  & .deleteButton, & .plusButton {
    display: flex;
    align-items: center;
  }
  
  & .plusButton {
    transform: rotate(45deg);
    color: #FFEF01;
  }
`;

export default { Wrapper }