import type { TStep } from '../models';

export const HOME_PAGE_STEP_ID = 'homePage'
export const TRAINERS_PROFILE_STEP_ID = 'trainersProfile'
export const ADD_BRANDING_STEPS: TStep[] = [
  {
    name: 'Home Page',
    id: HOME_PAGE_STEP_ID
  },
  {
    name: 'Trainer’s Profile',
    id: TRAINERS_PROFILE_STEP_ID
  },
];

export const DEFAULT_LOGO_FILE_DATA = { url: '', file: null };
export const WELCOME_TEXT_MAX_SYMBOLS = 50;
