import { styled } from '@mui/material';
import { ellipsisTextStyles, flexCenteredStyles } from 'styles/commonStyleVariables';

const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 8px;
  
  & .infoTitle {
    padding: 0 40px;
    
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    
    color: #6E7987;
  }
  
  & .noExercises {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    
    padding: 24px 40px;
    background: #1C1F21;
    color: #6E7987;
    
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    
    & .separator {
      width: 100%;
      height: 1px;
      background: #3E434B;
    }
    
    & .span {
      color: #fff;
    }
  }
  
  & .addExerciseButton {
    background: transparent;
    color: #ffef01;
    font-weight: 400;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 10px;
    font-size: 14px;
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  padding: 40px 40px 0 40px;
  
  & .coloredLabelWrapper {
    margin-left: 14px;
  }
`;

const Body = styled('div')`
  flex: 1;
  overflow-y: auto;
  padding: 0 40px;

  & .exerciseWrapper {
    margin-bottom: 16px;

    & .exerciseShortRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      
      & .identifier {
        white-space: nowrap;
      }
      
      & .name {
        min-width: 0;
        margin-left: 6px;
        ${ellipsisTextStyles}
      }

      & .deleteButton {
        ${flexCenteredStyles};
        padding-bottom: 2px;
        margin-left: auto;

        & .deleteIcon {
          height: 20px;
          width: auto;
          color: #6e7987;

          &:hover {
            color: #ffef01;
          }
        }
      }
    }

    & .accordion-wrapper {
      box-shadow: none;
    }

    & .accordion-summary,
    .MuiCollapse-root {
      background: #1c1f21;
    }

    & .MuiAutocomplete-root {
      background: #23262b;
    }

    & .textarea {
      background: #23262b;
    }
  }
`;

const ActionButtons = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 40px 40px 40px;

  & .actionButton {
    padding: 16px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-shadow: 4px 4px 10px rgba(31, 36, 39, 0.4);
    border-radius: 8px;
    width: 100%;
    text-align: center;
    transition: ease 0.3s all;

    &:hover {
      opacity: 0.6;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  & .addButton {
    background: linear-gradient(162.44deg, #ffef01 16.76%, #ffd600 89.31%);
    color: #1c1f21;
  }

  & .cancelButton {
    background: linear-gradient(318.21deg, #2c3036 14.05%, #31343c 85.29%);
    color: #ffffff;
  }
`;

const Loader = styled('div')`
  padding: 40px;
`;

export default {
  ModalContent,
  ActionButtons,
  Body,
  Header,
  Loader,
};
