import styled from '@emotion/styled/macro';

const Tooltip = styled.p`
  max-width: 280px;
  color: #6e7987;
  word-wrap: anywhere;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  padding: 16px 24px;

  & .colored {
    color: #fff;
  }
`;

export default { Tooltip };