import React, { FC } from 'react';
import ConfirmModal from 'components/_new/Modal/sub/ConfirmModal';
import gaEvents from 'utils/gaEvents';

interface ILogoutModalProps {
  logOutHandler: () => void;
  toggleCloseModal: () => void;
}

export const LogoutModal: FC<ILogoutModalProps> = ({
  logOutHandler,
  toggleCloseModal,
}) => {
  const onCancelModal = () => {
    toggleCloseModal();
    gaEvents.cancelLogoutModalFromHeader();
  };
  const onCloseModal = () => {
    toggleCloseModal();
    gaEvents.closeLogoutModalFromHeader();
  };
  const onOutsideCloseModal = () => {
    toggleCloseModal();
    gaEvents.outsideCloseLogoutModalFromHeader();
  };
  const onSubmitLogout = () => {
    // TODO: replace logOutHandler here with changing types for ClientsContext
    logOutHandler();
    gaEvents.submitLogoutFromHeader();
  };

  return (
    <ConfirmModal
      show={true}
      onSubmit={onSubmitLogout}
      onCancel={onCancelModal}
      onClose={onCloseModal}
      onCloseOnOutsideClick={onOutsideCloseModal}
      title="Are you sure you want to logout?"
    />
  );
};
