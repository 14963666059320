import instance from './axios';

export const postData = async (url, data) => {
    try {
        const response = await instance.post(url, {
            data,
        });

        return response.data;
    } catch (e) {
        return e.response?.data?.errors?.length
            ? e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors[0]?.code
              ?.replace(/_+/g, ' ')
              ?.trim()
              ?.toLowerCase()
            : e.response?.statusText || '';
    }
};

export const patchData = async (url, data) => {
    try {
        const response = await instance.patch(url, {
            data,
        });

        return response.data;
    } catch (e) {
        return e.response?.data?.errors?.length
            ? e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors[0]?.code
              ?.replace(/_+/g, ' ')
              ?.trim()
              ?.toLowerCase()
            : e.response?.statusText || '';
    }
};

export const postUploadData = async (url, data) => {
    try {
        const response = await instance.post(url, data);

        return response.data;
    } catch (e) {
        return e.response?.data?.errors?.length
            ? e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors[0]?.code
              ?.replace(/_+/g, ' ')
              ?.trim()
              ?.toLowerCase()
            : e.response?.statusText || '';
    }
};

export const getApiData = async (url) => {
    try {
        const response = await instance.get(url);

        return response.data;
    } catch (e) {
        return e.response?.data?.errors?.length
            ? e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors[0]?.code
              ?.replace(/_+/g, ' ')
              ?.trim()
              ?.toLowerCase()
            : e.response?.statusText || '';
    }
};

export const putData = async (url, data) => {
    try {
        const response = await instance.put(url, { data });

        return response.data;
    } catch (e) {
        return e.response?.data?.errors?.length
            ? e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors[0]?.code
              ?.replace(/_+/g, ' ')
              ?.trim()
              ?.toLowerCase()
            : e.response?.statusText || '';
    }
};

export const deleteApiData = async (url) => {
    try {
        const response = await instance.delete(url);

        return response.data;
    } catch (e) {
        return e.response?.data?.errors?.length
            ? e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors[0]?.code
              ?.replace(/_+/g, ' ')
              ?.trim()
              ?.toLowerCase()
            : e.response?.statusText || '';
    }
};

export const deleteUploadedData = async (url, data) => {
    try {
        const response = await instance.delete(url, { data });

        return response.data;
    } catch (e) {
        return e.response?.data?.errors?.length
            ? e.response?.data?.errors[0]?.message ||
          e.response?.data?.errors[0]?.code
              ?.replace(/_+/g, ' ')
              ?.trim()
              ?.toLowerCase()
            : e.response?.statusText || '';
    }
};
