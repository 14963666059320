import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import Modal from '@material-ui/core/Modal';
import gaEvents from 'utils/gaEvents';
import { isChatPage } from '../../clientPhases/utils';

import type { TEventOnClick } from '../../../models';

export const BLOCK_PHASE = 'block';
export const UNBLOCK_PHASE = 'unblock';

interface ILockUnlockPhaseModal {
  modalType: string;
  blockPhase: (e: TEventOnClick, shouldBeVisible: boolean) => void;
  closeModal: (e: TEventOnClick) => void;
  isLockingModalShown: boolean;
}

export const LockUnlockPhaseModal: FC<ILockUnlockPhaseModal> = ({
  modalType,
  blockPhase,
  isLockingModalShown,
  closeModal,
}) => {
  const cancelLockPhaseModal = (e: TEventOnClick) => {
    closeModal(e);
    if (isChatPage()) {
      gaEvents.chatCancelLockPhaseModal();
    } else {
      gaEvents.cancelLockPhaseModal();
    }
  };
  const closeLockPhaseModal = (e: TEventOnClick) => {
    closeModal(e);
    if (isChatPage()) {
      gaEvents.chatCloseLockPhaseModal();
    } else {
      gaEvents.closeLockPhaseModal();
    }
  };
  const outsideCloseLockPhaseModal = (e: TEventOnClick) => {
    closeModal(e);
    if (isChatPage()) {
      gaEvents.chatOutsideCloseLockPhaseModal();
    } else {
      gaEvents.outsideCloseLockPhaseModal();
    }
  };

  return (
    <Modal onClose={outsideCloseLockPhaseModal} open={isLockingModalShown}>
      <div className="modal phases-modal">
        <div className="modal__close modal__body--sm">
          <ReactSVG onClick={closeLockPhaseModal} src="/assets/svg/close.svg" />
        </div>
        <div className="modal__body">
          {modalType === BLOCK_PHASE || modalType === UNBLOCK_PHASE ? (
            <div className="modal-delete">
              <p className="modal-title">
                {modalType === BLOCK_PHASE
                  ? 'Are you sure you want to lock this phase?'
                  : 'Are you sure you want to unlock this phase?'}
              </p>
              <div className="modal-buttons">
                <button className="modal-buttons-cancel" onClick={cancelLockPhaseModal}>
                  Cancel
                </button>
                <button
                  className="modal-buttons-delete"
                  onClick={
                    modalType === BLOCK_PHASE
                      ? (e) => blockPhase(e, false)
                      : (e) => blockPhase(e, true)
                  }
                >
                  Yes
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Modal>
  );
};
