import React from 'react';
import PropTypes from 'prop-types';
import './label.scss';

const Label = (props) => {
    const {htmlFor, label, required} = props;

    return (
        <label className={required ? 'label required' : 'label'} htmlFor={htmlFor}>
            <span>{label}</span>
        </label>
    )
}

Label.propTypes = {
    htmlFor: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
};

Label.defaultProps = {
    htmlFor: null,
    label: null,
    required: false,
};

export default Label