import React, { createContext, ReactNode, useState } from 'react';
import { noop } from '../utils';

import type { TUser } from 'containers/app/trainer/models';

const initialState: {
  user: TUser | null;
  loading: boolean;
  signOut: () => void;
  setUser: (data: TUser | null) => void;
  onSetUser: (data: TUser) => void;
} = {
  user: null,
  loading: false,
  signOut: noop,
  setUser: noop,
  onSetUser: noop,
};

export const UserContext = createContext(initialState);

const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<TUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSetUser = (data: TUser) => {
    setUser(data);
  };

  const signOut = async () => {
    setLoading(true);
    await localStorage.removeItem('token');
    setUser(null);
    setLoading(false);
  };

  return (
    <UserContext.Provider
      value={{ user, loading, signOut, setUser, onSetUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
