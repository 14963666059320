import { useContext } from 'react';
import { ExerciseReferenceContext } from 'providers/chatPage/ExerciseReferenceProvider';
import { FilteredExerciseContext } from 'providers/chatPage/filteredExerciseProvider/FilteredExerciseProvider';

import type { IPhaseExerciseProps } from '../models';
import { onSwapComplexExerciseDown, onSwapComplexExerciseUp } from '../utils';

export const useHandlers = ({
  exercise,
  onSwapComplexExercise,
  complexExerciseIndex,
  complexExerciseLetter,
  complexExerciseReference,
  programId,
}: Pick<
  IPhaseExerciseProps,
  | 'exercise'
  | 'onSwapComplexExercise'
  | 'complexExerciseIndex'
  | 'complexExerciseLetter'
  | 'complexExerciseReference'
  | 'programId'
>) => {
  const { orderNumber, exerciseInfo } = exercise;

  const handleSwapComplexExerciseUp = () => {
    onSwapComplexExerciseUp({ onSwapComplexExercise, complexExerciseIndex });
  };

  const handleSwapComplexExerciseDown = () => {
    onSwapComplexExerciseDown({ onSwapComplexExercise, complexExerciseIndex });
  };

  const exerciseName = complexExerciseLetter + (orderNumber + 1);

  const exerciseReference = complexExerciseReference && {
    ...complexExerciseReference,
    exerciseName,
    exerciseInfoId: exerciseInfo.id,
    exerciseInfoName: exerciseInfo.name,
  };

  const { generatedExerciseReference, setGeneratedExerciseReference } =
    useContext(ExerciseReferenceContext);
  const { setFilteredExercise } = useContext(FilteredExerciseContext);

  const onGenerateChatFilter = () => {
    // to clear reference in chat new message input on turning on filter by exercise
    if (generatedExerciseReference) {
      setGeneratedExerciseReference(null);
    }
    setFilteredExercise({
      ...exercise,
      letter: complexExerciseLetter,
      complexExerciseIndex,
      programId,
    });
  };

  return {
    exerciseName,
    handleSwapComplexExerciseUp,
    handleSwapComplexExerciseDown,
    onGenerateChatFilter,
    exerciseReference,
  };
};