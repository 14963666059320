import React, { useState } from 'react';
import { FeedbackModal } from './feedbackModal/FeedbackModal';
import gaEvents from 'utils/gaEvents';
import { HEADER } from 'constants/tooltips';
import { Tooltip } from '../tooltip/Tooltip';

import { FeedbackIc } from 'assets/svg';

export const Feedback = () => {
  const [isModalShown, setIsModalShown] = useState(false);

  const onShowFeedbackModal = () => {
    setIsModalShown(true);

    gaEvents.openFeedbackModalFromHeader();
  };

  const onCloseFeedbackModal = () => {
    setIsModalShown(false);
  };

  return (
    <>
      <Tooltip title={HEADER.SEND_FEEDBACK}>
        <button onClick={onShowFeedbackModal} className="actionIcon">
          <FeedbackIc />
        </button>
      </Tooltip>
      <FeedbackModal
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        onCloseFeedbackModal={onCloseFeedbackModal}
      />
    </>
  );
};