import React, { FC, ReactNode, useState } from 'react';
import { Modal } from 'components/_new/Modal/Modal';

import S from './FullScreenMediaModal.styled';

interface FullScreenModalComponentProps {
  triggerContent: ReactNode;
  children: ReactNode;
}

export const FullScreenMediaModal: FC<FullScreenModalComponentProps> = ({
  triggerContent,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closeModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <>
      <button onClick={openModal}>{triggerContent}</button>
      <Modal show={isOpen} onClose={closeModal} style={{ overflow: 'auto' }}>
        <S.FullScreenContent>{children}</S.FullScreenContent>
      </Modal>
    </>
  );
};
