import styled from '@emotion/styled/macro';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';

const Label = styled(FormControlLabel)`
  span {
    font-size: 14px;
    font-weight: 400;
    color: #6e7987;
  }
`;

const Checkbox = styled(MuiCheckbox)<{ width?: number; height?: number }>`
  padding-top: 2px;
  padding-bottom: 2px;
  &:hover {
    color: #ffe001;
  }
  .MuiSvgIcon-root {
    width: ${({ width }) => (width ? `${width}px` : '24px')};
    height: ${({ height }) => (height ? `${height}px` : '24px')};
  }
`;

const Icon = styled('span')<{ width?: number; height?: number }>`
  border: 1px solid #6e7987;
  border-radius: 8px;
  width: ${({ width }) => (width ? `${width}px` : '24px')};
  height: ${({ height }) => (height ? `${height}px` : '24px')};
  background-color: transparent;
  z-index: 1;
  &:hover {
    border: 1px solid #ffef01;
  }
`;

const CheckedIcon = styled(Icon)<{ color?: string }>`
  background-color: #ffe001;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    color: ${({ color }) => color || '#1C1F21'};
    width: 80%;
    height: 80%;
  }
`;

export default { Label, Checkbox, Icon, CheckedIcon };
