import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        backgroundColor: '#121315',
        border: '1px solid #5F6975',
        boxSizing: 'border-box',
        zIndex: '1',

        '&:hover': {
            borderColor: '#FFEF01',
        },
    },
    checkedIcon: {
        backgroundColor: 'transparent',
        border: '4px solid #FFEF01',
    },
    groupLg: {
        flexDirection: 'column',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
    },
    groupSm: {
        flexDirection: 'row',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
    },
    item: {
        marginRight: '30px',
        fontSize: '14px',
    },
});

export function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="secondary"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
            value={props.defaultValue ? props.value : `${props.value}-&&&${props.i}`}
        />
    );
}

export default function RadioButtons({
    radioArray,
    onchange,
    isNotChecked,
    row,
    defaultValue,
    value,
    checkTypeNewQuestion,
    onClick,
    additionalContent,
}) {
    const classes = useStyles();

    return (
        <RadioGroup
            defaultValue={
                !isNotChecked ? defaultValue || checkTypeNewQuestion : null
            }
            name="customized-radios"
            className={`radio-buttons-group ${radioArray.length < 2 || row ? classes.groupSm : classes.groupLg}`}
            onChange={onchange}
            value={value ? value : null}
            onClick={onClick}
        >
            {radioArray.map((radio, i) => (
                <div className="radio-button-group" key={`${radio}-${i}`}>
                    <FormControlLabel
                        value={radio}
                        control={<StyledRadio i={i} defaultValue />}
                        label={radio}
                        className={classes.item}
                    />
                    {additionalContent && (<div>{additionalContent(radio, i)}</div>)}
                </div>
            ))}
        </RadioGroup>
    );
}
