import React, { memo } from 'react';
import S from './RadioButtonsGroup.styled';

export type TDirection = 'row' | 'column';
export type TSelection = { title: string; id: number };
interface Props {
  direction?: TDirection;
  data: TSelection[];
  selection: TSelection;
  onClick: (selection: TSelection) => void;
}

export const RadioButtonsGroup: React.FC<Props> = memo(
  ({ direction = 'column', data, onClick, selection }: Props): JSX.Element => (
    <S.Wrapper direction={direction}>
      {data.map(({ id, title }) => (
        <S.RadioWrapper key={id} onClick={() => onClick({ id, title })}>
          <S.Radio checked={id === selection.id} />
          <S.RadioLabel>{title}</S.RadioLabel>
        </S.RadioWrapper>
      ))}
    </S.Wrapper>
  ),
);

RadioButtonsGroup.displayName = 'RadioButtonsGroup';
