import React, { ReactElement } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from '@emotion/styled/macro';

export type TMenuItem = {
  id: string | number,
  value: string,
  disabled?: boolean,
  iconComponent?: ReactElement
}

interface ICustomMenuProps {
  button: ReactElement;
  menuItems: TMenuItem[];
  clickOnMenuItem: (item: TMenuItem) => void;
}

export const StyledButtonWrapper = styled.div`
  width: fit-content;
`;

const StyledMenu = withStyles({
  paper: {
    background: '#23262B',
    boxShadow: '0px 8px 20px rgba(16, 17, 19, 0.6)',
    borderRadius: '8px',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#FFFFFF',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:hover': {
      background: '#191B1F',
      color: '#FFEF01',
    },
  },
}))(MenuItem);

const CustomMenu = ({ button, menuItems, clickOnMenuItem }: ICustomMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseItemHandler = (item: TMenuItem) => {
    clickOnMenuItem(item);
    handleClose();
  };

  return (
    <>
      <StyledButtonWrapper onClick={handleClick}>{button}</StyledButtonWrapper>

      {menuItems.length ? (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuItems.map((item) => {
            return (
              <StyledMenuItem
                onClick={() => chooseItemHandler(item)}
                key={item.id}
                disabled={item.disabled}
              >
                {item.iconComponent || ''}
                <ListItemText primary={item.value} />
              </StyledMenuItem>
            );
          })}
        </StyledMenu>
      ) : (
        ''
      )}
    </>
  );
};

export default CustomMenu;
