/* eslint-disable react/no-unescaped-entities */
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react';
import { withRouter } from 'react-router';
import CreateProgram from './createProgram';
import CreateProgramModal from './createProgramModal';
import { QuestionnaireContext } from 'providers/questionnaireProvider';
import Loader from 'components/loader/Loader';
import ProgressBar from 'components/progressBar';
import { ReactSVG } from 'react-svg';
import {
    deleteApiData,
    getApiData,
    putData,
} from 'lib/api/utils';
import Textarea from 'components/_new/Textarea/Textarea';
import CreateProgramButton from './createProgramButton';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { useLayout } from 'providers/layout/hooks/useLayout';
import useExitPrompt from 'hooks/useExitPrompt';
import { useSnackbar } from 'notistack';
import gaEvents from 'utils/gaEvents';
import { CurrentClientListPhase } from './currentClientListPhase/CurrentClientListPhase';
import { ClientInfo } from './clientInfo/ClientInfo';
import { Tooltip } from 'components/tooltip/Tooltip';
import { CLIENT, PROGRAM } from 'constants/tooltips';

import './styles.scss';

const defaultCreateNewProgramOptions = [
    {
        id: 'new',
        value: 'Create new program',
        disabled: false,
    },
    {
        id: 'blank',
        value: 'Create blank template',
        disabled: false,
    },
];

const CurrentClient = ({ history }) => {
    const { questionnaire, loadQuestionnaires } =
		useContext(QuestionnaireContext);
    const [currentPrograms, setCurrentPrograms] = useState([]);
    const [client, setClient] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showModal, setModal] = useState(false);
    const [showLocalModal, setLocalModal] = useState(false);
    const [error, setError] = useState('');
    const [modalType, setModalType] = useState('');
    const [prevPrograms, setPrevPrograms] = useState([]);
    const [loadedData, setLoadedData] = useState(false);
    const [draftProgram, setDraftProgram] = useState([]);
    const [deleteProgramData, setDeleteProgramData] = useState(null);
    const [publishProgramData, setPublishProgramData] = useState(null);
    const [deletePhaseData, setDeletePhaseData] = useState(null);
    const [notesValue, setNotesValue] = useState('');
    const [
        currentClientCreateNewProgramOptions,
        setCurrentClientCreateNewProgramOptions,
    ] = useState([...JSON.parse(JSON.stringify(defaultCreateNewProgramOptions))]);
    const [isActive, setIsActive] = useState(!(client?.user.isRestrictedAccess));

    const mountedRef = useRef(true);
    const setShowExitPrompt = useExitPrompt();
    const { enqueueSnackbar } = useSnackbar();

    const id = history.location.pathname.split('/trainer/trainers-clients/')[1];
    const { user } = client || {};

    useEffect(() => {
        if (!currentPrograms.length || !client) {
            loadData();
        }
        if (questionnaire && !questionnaire.length) {
            loadQuestionnaires();
        }
    }, []);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const toggleOpenLocalModalWithType = useCallback((type) => {
        setLocalModal(true);
        setModalType(type);
    }, []);

    const toggleCloseLocalModal = useCallback(() => {
        setLocalModal(false);
        setError('');
        setModalType('');
    }, []);

    const loadData = async () => {
        setLoadedData(false);
        if (!currentPrograms.length) {
            await loadProgramsHandler();
        }
        if (!client) {
            await clientDataHandler();
        }
        setLoadedData(true);
    };

    const loadProgramsHandler = () => {
        setCurrentClientCreateNewProgramOptions([
            ...JSON.parse(JSON.stringify(defaultCreateNewProgramOptions)),
        ]);
        setLoader(true);

        return new Promise((resolve) => {
            toggleCloseModal();
            getApiData(`Program/${id}`).then((answer) => {
                if (answer?.success && answer.items) {
                    let currentPrograms = [];
                    let previousPrograms = [];
                    let draftProgram = [];

                    answer.items.forEach((program) => {
                        if (program.isCompleted) {
                            previousPrograms.push(program);
                        } else if (program.isDraft) {
                            draftProgram.push(program);

                            setCurrentClientCreateNewProgramOptions((prevState) => {
                                const copiedState = [...prevState];

                                copiedState[1].disabled = true;

                                return copiedState;
                            });
                        } else {
                            currentPrograms.push(program);

                            setCurrentClientCreateNewProgramOptions((prevState) => {
                                const copiedState = [...prevState];

                                copiedState[0].disabled = true;

                                return copiedState;
                            });
                        }
                    });

                    setPrevPrograms(previousPrograms);
                    setCurrentPrograms(currentPrograms);
                    setDraftProgram(draftProgram);
                }
                setLoader(false);
                resolve('qqq');
            });
        });
    };

    const clientDataHandler = () => {
        return new Promise((resolve) => {
            getApiData(`User/get-client/${id}`).then((answer) => {
                if (answer?.success) {
                    setClient(answer.item);
                    setIsActive(!answer.item?.user.isRestrictedAccess)
                    setNotesValue(answer.item?.trainerNote || '')
                }
                resolve('www');
            });
        });
    };

    const toggleOpenModal = useCallback(() => {
        setModal(true);
    }, []);

    const toggleCloseModal = useCallback(() => {
        setModal(false);
    }, []);

    const setNewProgram = async () => {
        loadProgramsHandler();
    };

    const loaderHandler = (status) => {
        setLoader(status);
    };

    const goToPhasesPage = (clientId, programId) => {
        history.push({
            pathname: `/trainer/trainers-clients/${clientId}/phases/${programId}`,
        });
    };

    const saveNotesHandler = () => {
        setLoader(true);

        putData(`Trainer/update-client-note/${user.id}`, {
            note: notesValue
        }).then((answer) => {
            setLoader(false);
            if (answer.success) {
                enqueueSnackbar('Notes successfully saved', {variant: 'success'});
            } else {
                enqueueSnackbar(answer, {variant: 'error'});
                
            }
        });

        gaEvents.clickSaveNotesButton();
    };

    const openDeletingProgramModal = (program) => {
        setDeleteProgramData(program);

        if (program.isDraft) {
            gaEvents.openDeleteDraftProgramModal();
        } else if (program.isCompleted) {
            gaEvents.openDeletePastProgramModal();
        } else {
            gaEvents.openDeleteCurrentProgramModal();
        }
    }
    const closeDeletingProgramModal = () => setDeleteProgramData(null);

    // const openDeletingPhaseModal = (program) => setDeletePhaseData(program);
    const closeDeletingPhaseModal = () => setDeletePhaseData(null);

    const openPublishProgramModal = (program) => {
        setPublishProgramData(program);
        gaEvents.openPublishConfirmationModal();
    };
    const onClosePublishProgramModal = () => setPublishProgramData(null);

    const submitPublishProgramHandler = () => {
        gaEvents.submitPublishProgram();

        setLoader(true);
        onClosePublishProgramModal();

        putData('Program/publish-blank-template', {
            clientId: client?.user?.id,
            blankTemplateId: publishProgramData.id,
        }).then((answer) => {
            setLoader(false);
            if (answer.success) {
                loadProgramsHandler();
            } else {
                setError(`Error - ${answer}`);
                toggleOpenLocalModalWithType('error');
            }
        });
    };

    const submitDeletePhaseHandler = (id) => {
        if (!id) return;
        
        setLoader(true);
        closeDeletingPhaseModal();

        deleteApiData(`Phase/${id}`).then((answer) => {
            setLoader(false);
            if (answer.success) {
                loadProgramsHandler();
            } else {
                setError(`Error - ${answer}`);
                toggleOpenLocalModalWithType('error');
            }
        });
    }

    const submitDeleteProgramHandler = (id) => {
        gaEvents.submitDeleteProgram();
        if (!id) return;

        setLoader(true);
        closeDeletingProgramModal();

        deleteApiData(`Program/${id}`).then((answer) => {
            setLoader(false);
            if (answer.success) {
                loadProgramsHandler();
            } else {
                setError(`Error - ${answer}`);
                toggleOpenLocalModalWithType('error');
            }
        });
    };

    const generateProgramStructureForUI = (programs) => programs.map((program) => {
        const {id: programId, isDraft, name, phases } = program;
        return (
            <div className="client-programs" key={programId}>
                <div className="client-programs-title">
                    {`${isDraft ? 'Draft' : 'Current'} program - ${name}`}
                    <Tooltip title={PROGRAM.DELETE_PROGRAM} placement="right">
                        <ReactSVG
                            className="client-programs-title-draft-delete"
                            src="/assets/svg/delete.svg"
                            onClick={() => openDeletingProgramModal(program)}
                        />
                    </Tooltip>
                    {isDraft && (
                        <button
                            className="client-programs-title-draft-publish"
                            onClick={() => openPublishProgramModal(program)}
                        >
                            Publish
                        </button>
                    )}
                </div>
                <ul className={`client-programs-list ${phases.length > 2 ? 'many' : ''}`}>
                    {phases.map((phase, phaseIndex) => {
                        return (
                          <CurrentClientListPhase
                            key={`${phase.id} + ${phaseIndex}`}
                            phase={phase}
                            phaseIndex={phaseIndex}
                            clientId={user.id}
                            programId={programId}
                            goToPhasesPage={goToPhasesPage}
                            program={program}
                            loadProgramsHandler={loadProgramsHandler}
                          />
                        )
                    })}
                </ul>
            </div>
        )
    })

    const { initLayoutState } = useLayout();

    useEffect(() => {
        initLayoutState({
            routePath: {
                title: '← Back to clients list',
            },
        });
    }, [initLayoutState]);

    const changeUserStatusHandler = () => {
        setIsActive((prev) => !prev);
        clientDataHandler()
    }

    const closePublishProgramModal = () => {
        onClosePublishProgramModal();
        gaEvents.closePublishProgramModal();
    };
    const outsideClosePublishProgramModal = () => {
        onClosePublishProgramModal();
        gaEvents.outsideClosePublishProgramModal();
    };
    const cancelPublishProgramModal = () => {
        onClosePublishProgramModal();
        gaEvents.cancelPublishProgramModal();
    };

    const cancelDeleteProgramModal = () => {
        closeDeletingProgramModal();
        gaEvents.cancelDeleteProgramModal();

    };
    const closeDeleteProgramModal = () => {
        closeDeletingProgramModal();
        gaEvents.closeDeleteProgramModal();

    };
    const outsideCloseDeleteProgramModal = () => {
        closeDeletingProgramModal();
        gaEvents.outsideCloseDeleteProgramModal();

    };

    const onClickOnPreviousProgram = (programId) => {
        goToPhasesPage(user.id, programId)
        gaEvents.clickOnPastProgram({ clientId: user.id, programId });
    }

    return (
        <>
            {loader && <Loader/>}
            {client && (currentPrograms.length || prevPrograms.length || draftProgram.length) ? (
                <div className="client">
                    <div className="client-header">
                        <ClientInfo client={client} isActive={isActive} changeUserStatusHandler={changeUserStatusHandler} />
                        <div className='notesWrapper'>
                            <div className='notesLabelWrapper'>
                                <label className='notesLabel'>Notes</label>
                                <Tooltip title={CLIENT.SAVE_NOTES} placement="right">
                                    <ReactSVG
                                        src={'/assets/svg/save.svg'}
                                        className="notesSaveBtn"
                                        onClick={saveNotesHandler}
                                    />
                                </Tooltip>
                            </div>
                            <Textarea
                                minRows={4}
                                maxRows={4}
                                resize='none'
                                value={notesValue}
                                onChange={e => setNotesValue(e.target.value || '')}
                            />
                        </div>
                        <CreateProgramButton
                            hasProgram={!!currentPrograms.length}
                            toggleOpenNewProgramModal={toggleOpenModal}
                            client={client}
                            currentClientCreateNewProgramOptions={
                                currentClientCreateNewProgramOptions
                            }
                            loadProgramsHandler={loadProgramsHandler}
                        />
                    </div>
                    {draftProgram ? generateProgramStructureForUI(draftProgram) : ''}
                    {currentPrograms.length ? generateProgramStructureForUI(currentPrograms) : null}
                    {prevPrograms.length ? (
                        <div className="client-previous">
                            <p className="client-previous-title">Previous programs</p>
                            <ul className="client-previous-programs">
                                {prevPrograms.map((prev, i) => (
                                    <li
                                        className="client-previous-programs-item"
                                        key={`${prev.id}-${i}`}
                                        onClick={() => onClickOnPreviousProgram(prev.id)}
                                    >
                                        <div className="client-previous-programs-item-main">
                                            <div className="client-previous-programs-item-main-title">
                                                {prev.name}
                                                <Tooltip title={PROGRAM.DELETE_PROGRAM} placement="right">
                                                    <ReactSVG
                                                        className="client-programs-title-draft-delete"
                                                        src="/assets/svg/delete.svg"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openDeletingProgramModal(prev);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <p className="client-previous-programs-item-main-info">{`Phases ${prev.phases.length}`}</p>
                                        </div>
                                        <ProgressBar
                                            progress={
                                                Number.isInteger(prev.activePhaseProgress)
                                                    ? prev.activePhaseProgress
                                                    : 100
                                            }
                                            size={32}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : ''}
                    <CreateProgramModal
                        client={client}
                        setNewProgram={setNewProgram}
                        loaderHandler={loaderHandler}
                        showModal={showModal}
                        toggleCloseModal={toggleCloseModal}
                        questionnaire={questionnaire}
                        modalType="create"
                        setShowExitPrompt={setShowExitPrompt}
                    />
                </div>
            ) : client && !loader && !currentPrograms.length && loadedData ? (
                <CreateProgram
                    hasProgram={!!currentPrograms.length}
                    toggleOpenNewProgramModal={toggleOpenModal}
                    client={client}
                    currentClientCreateNewProgramOptions={
                        currentClientCreateNewProgramOptions
                    }
                    loadProgramsHandler={loadProgramsHandler}
                    setNewProgram={setNewProgram}
                    loaderHandler={loaderHandler}
                    showModal={showModal}
                    toggleCloseModal={toggleCloseModal}
                    questionnaire={questionnaire}
                    setShowExitPrompt={setShowExitPrompt}
                />
            ) : (
                <Loader/>
            )}
            <Modal onClose={toggleCloseLocalModal} open={showLocalModal}>
                <div className="modal phases-modal">
                    <div className="modal__close modal__body--sm">
                        <ReactSVG
                            onClick={toggleCloseLocalModal}
                            src="/assets/svg/close.svg"
                        />
                    </div>
                    <div className="modal__body">
                        {modalType === 'error' ? (
                            <div className="modal__body">
                                <p className="modal-message">{error}</p>
                            </div>
                        ) : '' }
                    </div>
                </div>
            </Modal>

            {publishProgramData && (
                 <Modal onClose={outsideClosePublishProgramModal} open={true}>
                 <div className="modal phases-modal">
                    <div className="modal__close modal__body--sm">
                        <ReactSVG
                            onClick={closePublishProgramModal}
                            src="/assets/svg/close.svg"
                        />
                    </div>

                    <div className="modal__body">
                        <div className="modal-delete">
                            <p className="modal-title">
                                There is a difference between old structure of this program
                                and new. Are you sure you want to do "hard" publish for this
                                program ? Progress will be lost
                            </p>
                            <div className="modal-buttons">
                                <button
                                    className="modal-buttons-cancel"
                                    onClick={cancelPublishProgramModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="modal-buttons-delete"
                                    onClick={submitPublishProgramHandler}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                 </div>
             </Modal>
            )}

            {deletePhaseData && (
                <Modal onClose={closeDeletingPhaseModal} open={true}>
                    <div className="modal phases-modal">
                        <div className="modal__close modal__body--sm">
                            <ReactSVG
                                onClick={closeDeletingPhaseModal}
                                src="/assets/svg/close.svg"
                            />
                        </div>

                        <div className="modal__body">
                            <div className="modal-delete">
                                <p className="modal-title">
                                    Are you sure you want to delete {deletePhaseData.name}?
                                </p>
                                <div className="modal-buttons">
                                    <button
                                        className="modal-buttons-cancel"
                                        onClick={closeDeletingPhaseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="modal-buttons-delete"
                                        onClick={() => submitDeletePhaseHandler(deletePhaseData?.id)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}

            {deleteProgramData && (
                <Modal onClose={outsideCloseDeleteProgramModal} open={true}>
                    <div className="modal phases-modal">
                        <div className="modal__close modal__body--sm">
                            <ReactSVG
                                onClick={closeDeleteProgramModal}
                                src="/assets/svg/close.svg"
                            />
                        </div>

                        <div className="modal__body">
                            <div className="modal-delete">
                                <p className="modal-title">
                                    Are you sure you want to delete {deleteProgramData?.name} program?
                                </p>
                                <div className="modal-buttons">
                                    <button
                                        className="modal-buttons-cancel"
                                        onClick={cancelDeleteProgramModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="modal-buttons-delete"
                                        onClick={() => submitDeleteProgramHandler(deleteProgramData?.id)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

CurrentClient.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default withRouter(CurrentClient);
