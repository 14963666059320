import styled from '@emotion/styled/macro';
import { transitionOnIconsHover } from 'styles/commonStyleVariables';
import { FilterIc } from 'assets/svg';

const FilterIcon = styled(FilterIc)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 56px;
  width: 56px;
  padding: 19px;
  background: #191b1f;
  color: #5f6975;
  border-radius: 8px;
  ${transitionOnIconsHover};

  &:hover {
    color: #ffef01;
  }
`;

export default { FilterIcon };