import React, { FC, useEffect, useRef, useState } from 'react';
import { ExerciseReference } from '../../../../components/exerciseReference/ExerciseReference';
import { renderAttachmentByFileType } from '../../utils';
import { FullScreenMediaModal } from '../../../components/fullScreenMediaModal/FullScreenMediaModal';

import type { TMessage } from '../../../../models';
import type { TGeneralExerciseReference } from 'components/_new/phase/models';

import S from './RepliedMessage.styled';

interface IRepliedMessage {
  message: TMessage;
  goToTheExerciseReferenceHandler: (
    reference: TGeneralExerciseReference,
  ) => void;
  hasParentMessageExerciseReference: boolean;
  readOnly?: boolean;
}

// TODO: replace hardcoded height values to useRef.getElementHeight
const EXPANDED_MAX_HEIGHT = 200; // is important show collapsed message (maxHeight should be set to fixed value)
const MAX_HEIGHT_ATTACH_REFERENCE_COUNT = 120; // is important to correctly expand reply message if there is also attachment file or exercise reference
const MESSAGE_CONTAINER_PADDINGS = 30; // is important to correctly expand reply message if there is only text

export const RepliedMessage: FC<IRepliedMessage> = ({
  message: {
    exerciseReference,
    attachmentUrl,
    attachmentThumbnailUrl,
    text,
    fileType,
  },
  goToTheExerciseReferenceHandler,
  hasParentMessageExerciseReference,
  readOnly,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(`${EXPANDED_MAX_HEIGHT}px`);
  const textRef = useRef<HTMLParagraphElement>(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    if (textRef.current) {
      const height =
        EXPANDED_MAX_HEIGHT > textRef.current.scrollHeight
          ? EXPANDED_MAX_HEIGHT
          : textRef.current.scrollHeight;
      setMaxHeight(`${height}px`);
    }
  };

  useEffect(() => {
    if (textRef.current && isExpanded) {
      const additionalHeight =
        MESSAGE_CONTAINER_PADDINGS +
        (exerciseReference ? MAX_HEIGHT_ATTACH_REFERENCE_COUNT : 0) +
        (attachmentUrl ? MAX_HEIGHT_ATTACH_REFERENCE_COUNT : 0);
      setMaxHeight(`${textRef.current.scrollHeight + additionalHeight}px`);
    }
  }, [isExpanded]);

  const attachmentUI = renderAttachmentByFileType({
    attachmentUrl: attachmentUrl,
    fileType,
    attachmentThumbnailUrl,
    isForPreview: true,
  });

  const renderAttachmentBasedOnReadOnlyProp = () => {
    if (!attachmentUrl) {
      return null;
    }
    if (readOnly) {
      return <>{attachmentUI}</>;
    }
    return (
      <FullScreenMediaModal triggerContent={attachmentUI}>
        {renderAttachmentByFileType({
          attachmentUrl,
          attachmentThumbnailUrl,
          fileType,
        })}
      </FullScreenMediaModal>
    );
  };

  return (
    <S.Wrapper style={{ maxHeight }} className={`${isExpanded && 'expanded'}`}>
      {text && (
        <button onClick={handleToggle}>
          <p ref={textRef} className={`text ${isExpanded && 'expanded'}`}>
            {text}
          </p>
        </button>
      )}
      {renderAttachmentBasedOnReadOnlyProp()}
      {exerciseReference && !hasParentMessageExerciseReference && (
        <div className="reference">
          <button
            onClick={() => goToTheExerciseReferenceHandler(exerciseReference)}
          >
            <ExerciseReference exerciseReference={exerciseReference} readOnly />
          </button>
        </div>
      )}
    </S.Wrapper>
  );
};
