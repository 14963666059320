import React from 'react';
import { PhaseContentWrapper } from '../../../biggerProgram/PhaseContent/components/phaseContentWrapper/PhaseContentWrapper';
import Day from '../day/Day';

import type { IPhaseContentProps } from '../../../biggerProgram/PhaseContent/models';

export const PhaseContent = (props: IPhaseContentProps) => {
  return (
    <PhaseContentWrapper
      {...props}
      renderContent={({
        days,
        daysAmount,
        createExerciseHandler,
        duplicateProgramDayMutation,
        addDayMutation,
        openDeleteDayModal,
        openDeleteComplexExerciseModal,
        openSwapExercisesModal,
        editComplexExerciseHandler,
        phaseExerciseReference,
        navigatedExerciseReference,
        programId,
      }) => (
        <>
          {days.map((day, dayIndex) => (
            <Day
              day={day}
              isLastItem={dayIndex + 1 === daysAmount}
              key={`${day.name}${day.orderNumber}${dayIndex}`}
              onCreateExercise={() => createExerciseHandler(day)}
              onDuplicateDay={() => {
                duplicateProgramDayMutation({
                  phaseId: props.phaseData.id,
                  newOrderNumber: daysAmount,
                  orderNumber: day.orderNumber,
                });
              }}
              onDeleteDay={() => {
                openDeleteDayModal({ dayOrderNumber: day.orderNumber });
              }}
              onAddNewDay={() => {
                addDayMutation({
                  phaseId: props.phaseData.id,
                  orderNumber: daysAmount,
                });
              }}
              onDeleteComplexExercise={(orderNumber) => {
                openDeleteComplexExerciseModal({
                  complexExerciseOrderNumber: orderNumber,
                  dayOrderNumber: day.orderNumber,
                });
              }}
              onSwapComplexExercise={(indexes) => {
                openSwapExercisesModal({
                  indexes,
                  complexExercises: day.complexExercises,
                });
              }}
              onEditComplexExercise={({ exercises, id, letter, orderNumber }) =>
                editComplexExerciseHandler({
                  exercises,
                  id,
                  day,
                  letter,
                  orderNumber,
                })
              }
              phaseExerciseReference={phaseExerciseReference}
              navigatedExerciseReference={navigatedExerciseReference}
              programId={programId}
            />
          ))}
        </>
      )}
    />
  );
};
