export const screen4K = 3840;
export const screenUWQHD = 3440;
export const screenQHD = 2560;
export const screenFHD = 1920;
export const screenHDPlus = 1600;
export const screenHD = 1280;
export const screenSD = 1024;
export const screenXGA = 1024;
export const screenSVGA = 800;
export const screenVGA = 640;
export const screenHighDPI = 480;
export const screenLowDPI = 320;