import React, { FC, useContext, useState } from 'react';
import Modal from 'components/_new/Modal';
import Loader from 'components/loader/Loader';
import gaEvents from 'utils/gaEvents';
import { HomePageStep } from './components/homePageStep/HomePageStep';
import { TrainersProfileStep } from './components/trainersProfileStep/TrainersProfileStep';
import {
  ADD_BRANDING_STEPS,
  DEFAULT_LOGO_FILE_DATA,
  HOME_PAGE_STEP_ID,
  TRAINERS_PROFILE_STEP_ID,
} from './constants';
import { useFetch } from './hooks/useFetch';
import { UserContext } from 'providers/userProvider';

import type {
  TBrandingData,
  TOnChangeBrandingFieldsPayload,
  TStep,
} from './models';

import S from './PersonalBrandingModal.styled';

interface IPersonalBrandingModalProps {
  toggleCloseModal: () => void;
}

export const PersonalBrandingModal: FC<IPersonalBrandingModalProps> = ({
  toggleCloseModal,
}) => {
  const { user, setUser } = useContext(UserContext);

  const [activeStep, setActiveStep] = useState<TStep>(ADD_BRANDING_STEPS[0]);
  const [brandingData, setBrandingData] = useState<TBrandingData>({
    logoFile: { ...DEFAULT_LOGO_FILE_DATA, url: user?.trainerDetails.logoUrl || '' },
    welcomeText: user?.trainerDetails.welcomeText || '',
    selfIntroductionText: user?.trainerDetails.selfIntroductoryText || '',
  });

  const onCloseModal = () => {
    toggleCloseModal();
    gaEvents.closePersonalBrandingModalFromHeader();
  };
  const onOutsideCloseModal = () => {
    toggleCloseModal();
    gaEvents.outsideClosePersonalBrandingModalFromHeader();
  };

  const onChangeBrandingFields = ({
    key,
    value,
  }: TOnChangeBrandingFieldsPayload) => {
    setBrandingData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const renderStepContent = () => {
    switch (activeStep.id) {
      case HOME_PAGE_STEP_ID:
        return (
          <HomePageStep
            brandingData={brandingData}
            setBrandingData={setBrandingData}
            onChangeBrandingFields={onChangeBrandingFields}
          />
        );
      case TRAINERS_PROFILE_STEP_ID:
        return (
          <TrainersProfileStep
            brandingData={brandingData}
            setBrandingData={setBrandingData}
            onChangeBrandingFields={onChangeBrandingFields}
          />
        );
      default:
        break;
    }
  };

  const {
    uploadBrandingLogoMutation,
    isLoadingUploadBrandingLogo,

    updateTrainerBrandingDataMutation,
    isLoadingUpdateTrainerBrandingData,
  } = useFetch({
    user,
    setUser,
    onCloseModal,
  });

  const onSaveBrandingData = async () => {
    const { welcomeText, selfIntroductionText, logoFile } = brandingData;

    if (brandingData.logoFile.file) {
      const data = new FormData();
      data.append('file', brandingData.logoFile.file || '');

      await uploadBrandingLogoMutation(data, {
        onSuccess: ({ data }) => {
          updateTrainerBrandingDataMutation({
            welcomeText,
            selfIntroductoryText: selfIntroductionText,
            logoUrl: data.item.fileUrl,
          });
        },
      });
    } else {
      updateTrainerBrandingDataMutation({
        welcomeText,
        selfIntroductoryText: selfIntroductionText,
        logoUrl: logoFile.url || '',
      });
    }
  };

  const activeStepIndex =
    ADD_BRANDING_STEPS.findIndex(({ id }) => id === activeStep.id) || 0;

  const renderButtons = () => {
    if (
      activeStep.id === ADD_BRANDING_STEPS[ADD_BRANDING_STEPS.length - 1].id
    ) {
      return (
        <div className="buttons">
          <S.Button
            onClick={() =>
              setActiveStep(ADD_BRANDING_STEPS[activeStepIndex - 1])
            }
          >
            Back
          </S.Button>
          <S.Button onClick={onSaveBrandingData} isConfirm>
            Confirm
          </S.Button>
        </div>
      );
    }
    return (
      <S.Button
        onClick={() => setActiveStep(ADD_BRANDING_STEPS[activeStepIndex + 1])}
      >
        Next
      </S.Button>
    );
  };

  const isLoading =
    isLoadingUploadBrandingLogo || isLoadingUpdateTrainerBrandingData;

  return (
    <>
      <Modal
        show={true}
        onClose={onCloseModal}
        onCloseOnOutsideClick={onOutsideCloseModal}
      >
        <S.Wrapper>
          {isLoading && <Loader />}
          <p className="title">Personal Branding</p>
          <p className="description">
            Add your own logo, welcome and self-introduction texts that users
            will seen in the application.
          </p>
          <ul className="steps">
            {ADD_BRANDING_STEPS.map(({ id, name }: TStep, index) => (
              <S.Step
                isActive={activeStepIndex >= index}
                key={id}
                className="step"
              >
                <div className="tab" />
                {name}
              </S.Step>
            ))}
          </ul>
          <div className="stepContent">{renderStepContent()}</div>
          {renderButtons()}
        </S.Wrapper>
      </Modal>
    </>
  );
};
