import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { setFeedbackBannerShowNextTimeToLocalStorage } from 'containers/app/trainer/feedbackNotificationBanner/utils';
import { noop } from 'utils';
import Modal from 'components/_new/Modal';
import TextInput from 'components/fields/textInput';
import { UserContext } from 'providers/userProvider';
import { TextArea } from 'components/fields/textArea/TextArea';
import Button from 'components/_new/Button';
import Loader from 'components/loader/Loader';
import { useFetch } from './hooks/useFetch';
import gaEvents from 'utils/gaEvents';

import type { TFeedbackData } from './models';

import S from './FeedbackModal.styled';

interface IFeedbackModalProps {
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
  onCloseFeedbackModal: () => void;
}

const DEFAULT_FEEDBACK_DATA: TFeedbackData = {
  title: '',
  text: '',
};

export const FeedbackModal: FC<IFeedbackModalProps> = ({
  isShown,
  setIsShown,
  onCloseFeedbackModal,
}) => {
  const { user } = useContext(UserContext);
  const [feedbackData, setFeedbackData] = useState<TFeedbackData>(
    DEFAULT_FEEDBACK_DATA,
  );

  const { sendFeedbackMutation, isLoadingSendFeedback } = useFetch();

  const onChangeFeedbackModalData = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setFeedbackData({
      ...feedbackData,
      [e.target.name]: e.target.value,
    });
  };

  const closeModalHandler = () => {
    setIsShown(false);
    setFeedbackData(DEFAULT_FEEDBACK_DATA);

    onCloseFeedbackModal();
  };

  const onSendFeedback = () => {
    sendFeedbackMutation(feedbackData);
    setIsShown(false);
    setFeedbackData(DEFAULT_FEEDBACK_DATA);
    setFeedbackBannerShowNextTimeToLocalStorage();

    gaEvents.submitSendFeedback();
  };

  const onCancelModal = () => {
    closeModalHandler();
    gaEvents.cancelSendFeedbackModal();
  };
  const onCloseModal = () => {
    closeModalHandler();
    gaEvents.closeSendFeedbackModal();
  };
  const onOutsideCloseModal = () => {
    closeModalHandler();
    gaEvents.outsideCloseSendFeedbackModal();
  };

  return (
    <>
      {isLoadingSendFeedback && <Loader />}
      <Modal show={isShown} onClose={onCloseModal} onCloseOnOutsideClick={onOutsideCloseModal}>
        <S.Wrapper>
          <p className="title">Send Feedback</p>
          <div className="inputWrapper">
            <TextInput
              label="EMAIL"
              value={user?.email || ''}
              disabled={true}
              onChange={noop}
            />
          </div>
          <div className="inputWrapper">
            <TextInput
              label="REPORT A PROBLEM"
              placeholder="Enter your subject"
              name="title"
              value={feedbackData.title}
              onChange={onChangeFeedbackModalData}
            />
          </div>
          <div className="inputWrapper">
            <TextArea
              label="DESCRIPTION"
              name="text"
              value={feedbackData.text}
              onChange={onChangeFeedbackModalData}
            />
          </div>
          <div className="actions">
            <Button
              style={{ flex: 2 }}
              type="button"
              themeType="dark"
              onClick={onCancelModal}
            >
              CANCEL
            </Button>
            <Button
              style={{ flex: 2 }}
              type="button"
              themeType="light"
              onClick={onSendFeedback}
              disabled={!(feedbackData.text && feedbackData.title)}
            >
              SEND
            </Button>
          </div>
        </S.Wrapper>
      </Modal>
    </>
  );
};
