import { styled } from '@mui/material';

const Status = styled('p')<{
  isActive: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  background: #6E7987;
  color: #fff;
  text-transform: uppercase;
  padding: 4px 8px;
  border-bottom-left-radius: 12px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;

  ${({isActive}) => isActive && `
    background: #33A56E;
    color: #fff;
  `}
`;

export default { Status }