import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { ERROR_MESSAGE } from 'constants/index';
import { noop } from 'utils';
import instance from 'lib/api/axios';

import type { TMessage } from '../../models';
import type { TGeneralExerciseReference } from 'components/_new/phase/models';

export type TSendMessagePayload = {
  messagePayload: {
    receiverIds: string[];
    text: string;
    exerciseReference?: TGeneralExerciseReference;
    repliedId?: string;
    replied?: TMessage | null;
    // TODO: change to required after attach is implemented
    attachmentUrl?: string;
  };
  updateCallback?: (messages: TMessage[]) => void;
};

type TReadAllMessagesPayload = {
  userId: string;
};

type TMessageResponse = {
  data: {
    items: TMessage[];
  };
};

type TUseFetch = {
  updateMessagesOnSuccessSend?: (
    messages: TMessage[],
    withScrolling?: boolean,
  ) => void;
  markAllMessagesAsReadOnSuccess?: () => void;
};

export const useFetch = ({
  updateMessagesOnSuccessSend = noop,
  markAllMessagesAsReadOnSuccess,
}: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: sendMessageMutation } = useMutation<
    TMessageResponse,
    Error,
    TSendMessagePayload
  >({
    mutationFn: ({ messagePayload }) => {
      return instance.post('GeneralMessage', {
        data: messagePayload,
      });
    },
    onSuccess: ({ data }, { updateCallback = noop }) => {
      updateMessagesOnSuccessSend(data.items, false);
      updateCallback(data.items);
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const {
    mutate: readAllMessagesMutation,
    isLoading: isLoadingReadAllMessages,
  } = useMutation<void, Error, TReadAllMessagesPayload>({
    mutationFn: ({ userId }) => {
      return instance.patch(`GeneralMessage/mark-all-as-read/${userId}`);
    },
    onSuccess: () => {
      markAllMessagesAsReadOnSuccess?.();
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return {
    sendMessageMutation,

    readAllMessagesMutation,
    isLoadingReadAllMessages,
  };
};
