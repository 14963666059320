import React, { ChangeEvent, FC } from 'react';

import S from './Toggle.styled';

interface IToggle {
  checked: boolean;
  setChecked: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
}

export const Toggle: FC<IToggle> = ({
  checked,
  setChecked,
  label,
  disabled,
}) => {
  const changeToggleHandler = (e: ChangeEvent<HTMLInputElement>) => {
    // firefox doesn't react on stopPropagation(), but preventDefault() fixes such case
    e.preventDefault();
    setChecked(e.target.checked);
  };

  return (
    <S.ToggleWrapper onClick={(e) => e.stopPropagation()}>
      <S.ToggleLabel>{label}</S.ToggleLabel>
      <S.Toggle
        checked={checked}
        onChange={changeToggleHandler}
        size="small"
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
      />
    </S.ToggleWrapper>
  );
};
