import React from 'react';
import './styles.scss';

const CustomButton = ({ disabled, text, onClick, type, dataTitle }) => {
    return (
        <button
            onClick={onClick}
            type={type}
            className={`btn button ${!dataTitle ? 'withoutHint' : ''}`}
            disabled={disabled}
            data-title={dataTitle ? dataTitle : null}
        >
            {text}
        </button>
    );
};

export default CustomButton;
