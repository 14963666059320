import React, { FC, useState } from 'react';
import Loader from 'components/loader/Loader';
import ConfirmModal from 'components/modal/confirmModal';
import { PhaseContent } from '../PhaseContent/PhaseContent';
import ProgressBar from 'components/progressBar';
import { LockUnlockPhase } from '../../../lockUnlockPhase/LockUnlockPhase';
import { DONE_PROGRESS_COUNT } from 'constants/index';
import { SaveTemplate } from './saveTemplate/SaveTemplate';
import { Tooltip } from 'components/tooltip/Tooltip';
import { PROGRAM } from 'constants/tooltips';
import { useHandlers } from './hooks/useHandlers';

import type { IBiggerProgramPhaseProps } from './models';

import {
  AccordionArrowIc,
  CopyIc,
  DeleteIc,
  DoneWithSuccessIc,
  SaveIc,
} from 'assets/svg';
import S from './BiggerProgramPhase.styled';

const BiggerProgramPhase: FC<IBiggerProgramPhaseProps> = (props) => {
  const [isSaveTemplateModalShown, setIsSaveTemplateModalShown] =
    useState(false);
  const {
    phaseData,
    setPhaseData,
    isShowAccordionBody,
    hoveredPhase,
    setHoveredPhase,
    hoveredPhaseOnMouseEnterHandler,
    getCurrentPhaseHandler,
    duplicatePhaseHandler,
    getCurrentPhaseMutation,
    hoveredPhaseOnMouseLeaveHandler,
    deleteLocalPhaseHandler,
    openDeletingPhaseModalHandler,
    modalType,
    closeModal,
    isPhaseFromNavigatedReference,
    navigatedExerciseReference,
    programExerciseReference,
    loading,
  } = useHandlers(props);

  return (
    <>
      {loading && <Loader />}
      <S.Phase
        onMouseEnter={hoveredPhaseOnMouseEnterHandler}
        onMouseLeave={hoveredPhaseOnMouseLeaveHandler}
      >
        <div className="outerWrapper" onClick={getCurrentPhaseHandler}>
          <p className="outerTitle">Phase {props.phaseIndex + 1}</p>
          <div className="progressBar">
            {props.phase.progress === DONE_PROGRESS_COUNT ? (
              <div className="progressDoneIcon">
                <DoneWithSuccessIc />
              </div>
            ) : (
              <ProgressBar progress={props.phase.progress} size={34} />
            )}
          </div>
          <div className="accessToPhaseIcon">
            {props.phase && (
              <LockUnlockPhase
                program={props.bigProgram}
                setDBDProgram={props.setDBDProgram}
                hoveredPhase={hoveredPhase}
                setHoveredPhase={setHoveredPhase}
                phaseIndex={props.phaseIndex}
                phase={props.phase}
                loadCallbackData={props.loadBigProgramHandler}
                pageId="BiggerPicture"
              />
            )}
          </div>
          <Tooltip title={PROGRAM.DELETE_PHASE}>
            <DeleteIc
              className="actionButton"
              onClick={openDeletingPhaseModalHandler}
            />
          </Tooltip>
          <Tooltip title={PROGRAM.DUPLICATE_PHASE}>
            <CopyIc
              className="actionButton duplicate"
              onClick={duplicatePhaseHandler}
            />
          </Tooltip>
          <Tooltip title={PROGRAM.SAVE_AS_TEMPLATE}>
            <button
              className="saveAsButton"
              onClick={() => setIsSaveTemplateModalShown(true)}
            >
              <SaveIc />
            </button>
          </Tooltip>
          <Tooltip
            title={
              isShowAccordionBody
                ? PROGRAM.COLLAPSE_PHASE
                : PROGRAM.EXPAND_PHASE
            }
          >
            <AccordionArrowIc
              className={`arrow ${isShowAccordionBody && 'active'}`}
            />
          </Tooltip>
        </div>
        {isShowAccordionBody && phaseData && (
          <div className="innerWrapper">
            <PhaseContent
              phaseData={phaseData}
              setPhaseData={setPhaseData}
              loadProgram={() => {
                getCurrentPhaseMutation({
                  phaseId: props.phase.id,
                  doNotSetAccordionBody: false,
                });
              }}
              programExerciseReference={programExerciseReference}
              navigatedExerciseReference={
                isPhaseFromNavigatedReference && navigatedExerciseReference
              }
              programId={props.bigProgram.id}
            />
          </div>
        )}
      </S.Phase>
      {modalType === 'deletePhase' && (
        <ConfirmModal
          title="Are you sure you want to delete this Phase?"
          showModal={true}
          onOk={deleteLocalPhaseHandler}
          onCancel={closeModal}
        />
      )}
      <SaveTemplate
        isSaveTemplateModalShown={isSaveTemplateModalShown}
        setIsSaveTemplateModalShown={setIsSaveTemplateModalShown}
        phaseId={props.phase.id}
      />
    </>
  );
};

export default BiggerProgramPhase;
