import type {
  TChangeRowValueEventData,
  TExcludeSpecSymbolsForNumberInput,
  TRowData,
} from '../models';

export const SETS_KEY: keyof TRowData = 'sets';
export const REPS_KEY: keyof TRowData = 'reps';
export const WEIGHT_KEY: keyof TRowData = 'weight';
export const REST_KEY: keyof TRowData = 'rest';
export const RPE_KEY: keyof TRowData = 'rpe';

const DECIMAL_PART_AFTER_COMMA = 5;
export const changeNumberInputValueToHandleDecimalsHandler = ({
  newValue,
  userPressedBackspace = false,
  allowOnlyOneSymbolAfterComma = false,
}: TChangeRowValueEventData & {
  allowOnlyOneSymbolAfterComma?: boolean | undefined;
}) => {
  // if user wants to clear 0 value
  if (newValue === '') {
    return newValue;
  }

  // if user wants to use decimals and press "." - there is allowed use only
  // "{DECIMAL_PART_AFTER_COMMA}" after comma. So it is automatically added.
  if (newValue[newValue.length - 1] === '.' && !userPressedBackspace) {
    return Number(`${newValue}${DECIMAL_PART_AFTER_COMMA}`);
  }
  // not to allow second symbol after comma if {allowOnlyOneSymbolAfterComma}
  // is true for certain field
  if (allowOnlyOneSymbolAfterComma && newValue[newValue.length - 3] === '.') {
    return Number(`${newValue.slice(0, -1)}`);
  }
  return +newValue;
};

const RPE_MIN_VALUE = 0;
const RPE_MAX_VALUE = 10;
export const getRPEValue = (value: number | string) =>
  value < RPE_MIN_VALUE
    ? RPE_MIN_VALUE
    : value > RPE_MAX_VALUE
    ? RPE_MAX_VALUE
    : value;

export const changeExerciseInputDataHandler = ({
  newValue,
  key,
  userPressedBackspace,
  isDeltaValue,
}: TChangeRowValueEventData & {
  key: string;
}) => {
  // don't allow to put more than one "-" mark
  if (newValue.split('-').length - 1  > 1) {
    return newValue.slice(0, -1);
  }

  // allow negative values for all deltas
  if (isDeltaValue && newValue === '-') {
    return newValue;
  }

  if (key === WEIGHT_KEY) {
    return changeNumberInputValueToHandleDecimalsHandler({
      newValue,
      userPressedBackspace,
    });
  }
  if (key === REST_KEY) {
    return `${newValue} min`;
  }
  if (key === RPE_KEY) {
    const afterDecimalsHandleValue =
      changeNumberInputValueToHandleDecimalsHandler({
        newValue,
        userPressedBackspace,
        allowOnlyOneSymbolAfterComma: true,
      });
    // allow "-" symbol based on isDeltaValue field
    return isDeltaValue
      ? afterDecimalsHandleValue
      : getRPEValue(afterDecimalsHandleValue);
  }

  return +newValue || 0;
};

export const roundToOneDecimal = (value: number) => {
  return Math.round(value * 10) / 10;
};

const getIntegerValue = (value: string | number, isDeltaValue?: boolean) => {
  // allow "-" symbol based on isDeltaValue field
  const searchValue = isDeltaValue ? /[^\d.-]/g : /[^\d.-]/g;
  return `${value}`.replace(searchValue, '');
};

export const getOnlyIntegerValueFromRestField = (
  value: string | number,
  isDeltaValue?: boolean,
) => {
  const valueToNumber = getIntegerValue(value, isDeltaValue);
  return value && valueToNumber ? valueToNumber : '';
};

export const getOnlyIntegerValueFromNumberField = (
  value: string | number,
  isDeltaValue?: boolean,
) => {
  return getIntegerValue(value, isDeltaValue);
};

export const excludeSpecSymbolsForNumberInput: TExcludeSpecSymbolsForNumberInput =
  (e, isDeltaValue) => {
    const specSymbolsArray = ['e', 'E', '+'];
    // allow "-" symbol based on isDeltaValue field
    if (!isDeltaValue) {
      specSymbolsArray.push('-');
    }

    return specSymbolsArray.includes(e.key) && e.preventDefault();
  };
