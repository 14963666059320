import styled from '@emotion/styled/macro';
import {
  flexCenteredStyles,
  renderTextWithThreePointsByRowsCount,
} from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  position: relative;

  & .logoWrapper {
    position: absolute;
    right: 22px;
    top: 122px;
    width: 32px;
    height: 32px;
  }

  & .logo {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  & .logoLabel {
    width: 100%;
    height: 100%;
    ${flexCenteredStyles};

    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: #ffef01;
    border: 1px dashed #3e434b;
    border-radius: 8px;
  }
`;

const WelcomeTextPreview = styled.div<{ isEmpty: boolean }>`
  position: absolute;
  left: 87px;
  top: 108px;
  width: 110px;
  height: 42px;
  overflow: hidden;
  ${flexCenteredStyles};
  
  border: 1px dashed #3E434B;
  border-radius: 8px;
  padding: 8px;
  
  & .text {
    ${renderTextWithThreePointsByRowsCount(2)}
    line-height: 12px;
    font-size: 10px;
    color: ${({ isEmpty }) => isEmpty ? '#FFEF01' : '#fff'};
  }
`;

export default { Wrapper, WelcomeTextPreview };