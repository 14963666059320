import React, { FC, useContext, useState } from 'react';
import { BiggerPictureWrapper } from '../biggerPictureWrapper/BiggerPictureWrapper';
import { Phase } from './phase/Phase';
import { useFetch } from '../../biggerProgram/hooks/useFetch';
import { useFetch as useFetchBPPhase } from '../../biggerProgram/biggerProgramPhase/hooks/useFetch';
import { ExerciseReferenceContext } from 'providers/chatPage/ExerciseReferenceProvider';
import Loader from 'components/loader/Loader';
import { PhaseContent } from './phaseContent/PhaseContent';

import type { IBiggerProgramProps } from '../../models';
import type { TBiggerPhase } from '../../../../models';
import type { TProgramExerciseReference } from 'components/_new/phase/models';

import S from './CollapsedMode.styled';

export const CollapsedMode: FC<IBiggerProgramProps> = ({
  bigProgram,
  ...props
}) => {
  const [activePhase, setActivePhase] = useState<TBiggerPhase | null>(null);

  const { navigatedExerciseReference } = useContext(ExerciseReferenceContext);

  const onSuccessCallback = () => {
    getBPProgramMutation();
  };

  const {
    getBPProgramMutation,
    isLoadingGetBPProgram,

    deletePhaseMutation,
    isLoadingDeletePhase,
  } = useFetch({
    programId: props.programId,
    setBigProgram: props.setBigProgram,
    onAddPhaseSuccessCallback: onSuccessCallback,
    onDeletePhaseSuccessCallback: onSuccessCallback,
  });

  const programExerciseReference: TProgramExerciseReference = {
    programId: bigProgram?.id || 0,
    programName: bigProgram?.name || '',
    weeks: activePhase?.weeks,
  };

  const { getCurrentPhaseMutation, isLoadingCurrentPhase } = useFetchBPPhase({
    setPhaseData: setActivePhase,
    loadBigProgramHandler: getBPProgramMutation,
  });

  const loading =
    isLoadingGetBPProgram || isLoadingDeletePhase || isLoadingCurrentPhase;

  return (
    <S.Wrapper>
      <BiggerPictureWrapper bigProgram={bigProgram} {...props}>
        <>
          {loading && <Loader />}
          <ul className="phases">
            {bigProgram?.phases.map((phase, phaseIndex) => (
              <li key={phase.id}>
                <Phase
                  bigProgram={bigProgram}
                  setDBDProgram={props.setDBDProgram}
                  phase={phase}
                  phaseIndex={phaseIndex}
                  loadBigProgramHandler={getBPProgramMutation}
                  onDeletePhase={deletePhaseMutation}
                  setActivePhase={setActivePhase}
                  isActive={phase.id === activePhase?.id}
                />
              </li>
            ))}
          </ul>
          {bigProgram && activePhase && (
            <div className="innerWrapper">
              <PhaseContent
                phaseData={activePhase}
                setPhaseData={setActivePhase}
                loadProgram={() => {
                  getCurrentPhaseMutation({
                    phaseId: activePhase.id,
                  });
                }}
                programExerciseReference={programExerciseReference}
                navigatedExerciseReference={navigatedExerciseReference}
                programId={bigProgram.id}
              />
            </div>
          )}
        </>
      </BiggerPictureWrapper>
    </S.Wrapper>
  );
};