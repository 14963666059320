import React, { FC } from 'react';

import S from './AddButton.styled';

interface IAddButtonProps {
  title: string;
  onClickFunction: () => void;
}

export const AddButton: FC<IAddButtonProps> = ({ title, onClickFunction }) => (
  <S.Button onClick={onClickFunction}>{title}</S.Button>
);
