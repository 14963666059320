import styled from '@emotion/styled/macro';
import SClientsList from '../clientsList/ClientsList.styled';
import { HEADER_HEIGHT } from 'constants/index';
import { CHAT_HEADER_HEIGHT } from '../constants';
import {
  flexCenteredStyles,
  transitionOnIconsHover,
} from 'styles/commonStyleVariables';

const CHAT_WRITE_INPUT_HEIGHT = 58;

const Wrapper = styled(SClientsList.Wrapper)<{
  isFilterActive: boolean;
}>`
  position: relative;

  & .messages {
    display: flex;
    flex-direction: column-reverse;

    overflow: auto;
    margin-top: ${CHAT_HEADER_HEIGHT}px;
    height: calc(
      100vh - ${HEADER_HEIGHT + CHAT_HEADER_HEIGHT + CHAT_WRITE_INPUT_HEIGHT}px
    );
    padding-bottom: 12px;
    z-index: 0;

    ${({ isFilterActive }) =>
      isFilterActive &&
      `
      padding-top: 45px;
    `}
  }

  & .exercise {
    position: absolute;
    width: 100%;
    top: ${CHAT_HEADER_HEIGHT}px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #23262b;
    border-bottom: 0.5px solid #3e434b;

    padding: 8px 16px;
    z-index: 2;

    & .closeExercise {
      ${flexCenteredStyles};
      ${transitionOnIconsHover};
      margin-left: auto;
      color: #5f6975;

      &:hover {
        color: #ffef01;
      }
    }

    & .exerciseName {
      margin-left: 8px;
    }
  }
`;

export default { Wrapper };
