import React from 'react';
import { IconButtonContainer } from 'components/iconButtonContainer/IconButtonContainer';
import { PROGRAM } from 'constants/tooltips';
import { useHandlers } from 'components/_new/phase/sub/PhaseDay/hooks/useHandlers';
import { ComplexExercise } from '../complexExercise/ComplexExercise';
import { parseDateToCorrectNewFormat } from 'utils';

import type { IPhaseDayProps } from 'containers/app/trainer/currentClient/clientPhases/biggerProgram/PhaseContent/models';

import { CheckMarkWithRoundIc, CopyIc, DeleteIc } from 'assets/svg';
import S from './Day.styled';

const PhaseDay = ({
  day,
  isLastItem,
  onDeleteDay,
  onAddNewDay,
  onDuplicateDay,
  onDeleteComplexExercise,
  onCreateExercise,
  onSwapComplexExercise,
  onEditComplexExercise,
  phaseExerciseReference,
  navigatedExerciseReference,
  programId,
}: IPhaseDayProps) => {
  const { complexExercises, name } = day;

  const {
    dayExerciseReference,
    isDayFromNavigatedReference,
    openCreateExerciseModal,
  } = useHandlers({
    navigatedExerciseReference,
    phaseExerciseReference,
    day,
    onCreateExercise,
  });

  const renderWeekLabel = () => {
    return new Array(4).fill(null).map((_, index) => {
      const weekData = complexExercises?.[0]?.exercises?.[0]?.weeks?.[index];
      const { completedDate, isCompleted } = weekData || {};

      const { fullDate } =
        completedDate && isCompleted
          ? parseDateToCorrectNewFormat({
              date: completedDate,
              isHoursAndMinutesVisible: false,
            })
          : { fullDate: null };

      return (
        <div className="weekLabelWrapper" key={index}>
          {isCompleted && <CheckMarkWithRoundIc className="checkedIcon" />}
          <p className="weekLabel">Week {index + 1}</p>
          {fullDate && <p className="date">({fullDate})</p>}
        </div>
      );
    });
  };

  return (
    <S.Wrapper>
      <S.Label>
        <span className="dayName">{name}</span>
        <IconButtonContainer
          onClick={onDuplicateDay}
          title={PROGRAM.DUPLICATE_DAY}
          className="duplicateDayIcon"
        >
          <CopyIc />
        </IconButtonContainer>
        <IconButtonContainer
          onClick={onDeleteDay}
          className="deleteDayIcon"
          title={PROGRAM.DELETE_DAY}
        >
          <DeleteIc />
        </IconButtonContainer>
        <div className="weeks">{renderWeekLabel()}</div>
      </S.Label>

      <S.ComplexExerciseList>
        {complexExercises.map((complexExercise, complexExerciseIndex) => (
          <ComplexExercise
            key={complexExercise.id}
            onEditComplexExercise={
              onEditComplexExercise
                ? () => onEditComplexExercise(complexExercise)
                : undefined
            }
            onDeleteComplexExercise={onDeleteComplexExercise}
            onSwapComplexExercise={onSwapComplexExercise}
            complexExercise={complexExercise}
            complexExerciseIndex={complexExerciseIndex}
            isFirstComplexExercise={complexExerciseIndex === 0}
            isLastComplexExercise={
              complexExerciseIndex === complexExercises.length - 1
            }
            dayExerciseReference={dayExerciseReference}
            navigatedExerciseReference={
              isDayFromNavigatedReference && navigatedExerciseReference
            }
            programId={programId}
          />
        ))}

        <button className="addExerciseButton" onClick={openCreateExerciseModal}>
          + Add exercise
        </button>
      </S.ComplexExerciseList>
      {isLastItem && (
        <button
          className="addDayButton"
          title="Add new day"
          onClick={onAddNewDay}
        >
          + Add Training Day
        </button>
      )}
    </S.Wrapper>
  );
};

export default PhaseDay;
