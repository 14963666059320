import styled from '@emotion/styled';

const Wrapper = styled.div<{ resizable: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  & .textarea {
    background: rgba(28, 31, 33, 0.9);
    border-radius: 8px;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: #fff;
    border: none;
    padding: 17px 17px 20px 17px;
    width: 100%;
    font-family: inherit;
    resize: ${({ resizable }) => resizable ? 'both' : 'none'};

    &::placeholder {
      color: #7f8489;
    }

    &:focus {
      outline: none;
    }
  }
  
  & .limit {
    position: absolute;
    bottom: 2px;
    right: 8px;
    color: #6E7987;
    font-size: 11px;
  }
`;

export default { Wrapper };
