import { styled } from '@mui/material';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
  background: #1C1F21;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid #1C1F21;
  transition: all 0.2s;
  position: relative;

  & .actions {
    display: none;
    box-sizing: content-box;
  }

  &:hover {
    border: 1px solid #3E434B;

    & .actions {
      display: block;
      box-sizing: content-box;
    }
  }
`;

export default { Wrapper };
