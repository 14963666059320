import React, { FC, useState } from 'react';
import { Client } from './client/Client';
import { InfiniteScrollListWrapper } from '../components/infiniteScrollListWrapper/InfiniteScrollListWrapper';
import { CLIENTS_ON_PAGE_LIMIT } from '../utils';
import { Search } from './search/Search';

import type { TClientWithMessages, TGetClientsPayload } from '../models';

import S from './ClientsList.styled';

export const SCROLLABLE_CLIENTS_LIST_ID = 'scrollableClientsListId';

interface IClientsListProps {
  activeClient?: TClientWithMessages | null;
  clients: TClientWithMessages[] | null;
  getClientMessagesHandler: (client: TClientWithMessages) => void;
  getClientsMutation: (payload: TGetClientsPayload) => void;
}

export const ClientsList: FC<IClientsListProps> = ({
  clients,
  getClientMessagesHandler,
  getClientsMutation,
  activeClient
}) => {
  const [searchValue, setSearchValue] = useState('');

  const activePage = Math.ceil((clients?.length || 1) / CLIENTS_ON_PAGE_LIMIT);

  const fetchMoreClientsHandler = () => {
    getClientsMutation({
      page: activePage + 1,
      fullName: searchValue,
    });
  };

  const filterClientsHandler = (value: string) => {
    setSearchValue(value);
    getClientsMutation({
      page: 1,
      fullName: value,
    });
  };

  return (
    <S.Wrapper>
      <div className="header">
        <Search
          searchValue={searchValue}
          filterClientsHandler={filterClientsHandler}
        />
      </div>
      {clients?.length ? (
        <S.List id={SCROLLABLE_CLIENTS_LIST_ID}>
          <InfiniteScrollListWrapper
            dataLength={clients.length}
            fetchMoreMessagesHandler={fetchMoreClientsHandler}
            scrollableListId={SCROLLABLE_CLIENTS_LIST_ID}
          >
            {clients.map((client, index) => (
              <Client
                key={client.id + index}
                client={client}
                getClientMessages={getClientMessagesHandler}
                isActive={activeClient?.id === client.id}
              />
            ))}
          </InfiniteScrollListWrapper>
        </S.List>
      ) : null}
    </S.Wrapper>
  );
};
