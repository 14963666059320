import React from 'react';
import { PhaseContentWrapper } from './components/phaseContentWrapper/PhaseContentWrapper';
import { PhaseDay } from 'components/_new/phase/sub/PhaseDay/PhaseDay';

import type { IPhaseContentProps } from './models';

export const PhaseContent = (props: IPhaseContentProps) => {
  return (
    <PhaseContentWrapper
      {...props}
      renderContent={({
        days,
        daysAmount,
        createExerciseHandler,
        duplicateProgramDayMutation,
        addDayMutation,
        openDeleteDayModal,
        openDeleteExerciseModal,
        openDeleteComplexExerciseModal,
        openSwapExercisesModal,
        editComplexExerciseHandler,
        phaseExerciseReference,
        navigatedExerciseReference,
        programId,
      }) => (
        <>
          {days.map((day, dayIndex) => (
            <PhaseDay
              day={day}
              isLastItem={dayIndex + 1 === daysAmount}
              key={`${day.name}${day.orderNumber}${dayIndex}`}
              onCreateExercise={() => createExerciseHandler(day)}
              onDuplicateDay={() => {
                duplicateProgramDayMutation({
                  phaseId: props.phaseData.id,
                  newOrderNumber: daysAmount,
                  orderNumber: day.orderNumber,
                });
              }}
              onDeleteDay={() => {
                openDeleteDayModal({ dayOrderNumber: day.orderNumber });
              }}
              onAddNewDay={() => {
                addDayMutation({
                  phaseId: props.phaseData.id,
                  orderNumber: daysAmount,
                });
              }}
              onDeleteExercise={({ exerciseInfo, id }) => {
                openDeleteExerciseModal({
                  exerciseInfoId: exerciseInfo.id,
                  dayOrderNumber: day.orderNumber,
                  id,
                });
              }}
              onDeleteComplexExercise={(orderNumber) => {
                openDeleteComplexExerciseModal({
                  complexExerciseOrderNumber: orderNumber,
                  dayOrderNumber: day.orderNumber,
                });
              }}
              onSwapComplexExercise={(indexes) => {
                openSwapExercisesModal({
                  indexes,
                  complexExercises: day.complexExercises,
                });
              }}
              onEditComplexExercise={({ exercises, id, letter, orderNumber }) =>
                editComplexExerciseHandler({
                  exercises,
                  id,
                  day,
                  letter,
                  orderNumber,
                })
              }
              phaseExerciseReference={phaseExerciseReference}
              navigatedExerciseReference={navigatedExerciseReference}
              programId={programId}
            />
          ))}
        </>
      )}
    />
  );
};
