import React, { FC } from 'react';
import { ClientStatus } from '../../clients/components/clientStatus/ClientStatus';
import { ClientAccessStatusToggle } from '../../components/ClientAccessStatusToggle';
import { calculateUserAge } from 'lib/functions';
import gaEvents from 'utils/gaEvents';

import { TClient } from '../../models';
import S from './Clientinfo.styled';

import userPhoto from 'assets/userPhoto.png';

interface IClientInfo {
  client: TClient;
  isActive: boolean;
  changeUserStatusHandler: () => void;
}

export const ClientInfo: FC<IClientInfo> = ({
  isActive,
  client: { user, birthDate, height, weight },
  changeUserStatusHandler,
}) => {
  const clientPhoto = user?.photoUrl || userPhoto;

  const onCancelClientStatusModal = () => {
    gaEvents.closeOnCancelActivationModal();
  };

  const onCloseClientStatusModal = () => {
    gaEvents.closeOnIconActivationModal();
  };

  const onCloseOnOutsideClickClientStatusModal = () => {
    gaEvents.closeOnOutsideClickActivationModal();
  };

  return (
    <S.Client>
      <S.ClientData>
        <ClientStatus isActive={isActive} />
        <S.ClientPhoto style={{ backgroundImage: `url(${clientPhoto})` }} />
        <S.ClientInfo>
          <S.ClientName>{`${user.firstName} ${user.lastName}`}</S.ClientName>
          <S.ClientDetails>
            {birthDate && (
              <S.ClientDetailsBlock>
                <S.ClientDetailsLabel>Age</S.ClientDetailsLabel>
                <S.ClientDetailsValue>
                  {`${calculateUserAge(birthDate)} years`}
                </S.ClientDetailsValue>
              </S.ClientDetailsBlock>
            )}
            <S.ClientDetailsBlock>
              <S.ClientDetailsLabel>Height</S.ClientDetailsLabel>
              <S.ClientDetailsValue>{`${height} cm`}</S.ClientDetailsValue>
            </S.ClientDetailsBlock>
            <S.ClientDetailsBlock>
              <S.ClientDetailsLabel>Weight</S.ClientDetailsLabel>
              <S.ClientDetailsValue>{`${weight} kg`}</S.ClientDetailsValue>
            </S.ClientDetailsBlock>
          </S.ClientDetails>
        </S.ClientInfo>
      </S.ClientData>
      <ClientAccessStatusToggle
        isRestrictedAccess={user.isRestrictedAccess}
        successCallback={changeUserStatusHandler}
        clientId={user.id}
        onCancelHandler={onCancelClientStatusModal}
        onCloseHandler={onCloseClientStatusModal}
        onCloseOnOutsideClickHandler={onCloseOnOutsideClickClientStatusModal}
      />
    </S.Client>
  );
};
