import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  height: 100%;
  padding-top: 94px;

  & .right {
    width: 100%;
    background: #121315;
    display: flex;
    flex-direction: column;
    flex: 2000;
    overflow: auto;
  }

  & .main {
    width: 100%;
    flex: 1;
  }
`;

export default { Wrapper }