import styled from '@emotion/styled/macro';

const FullScreenContent = styled.div`
  max-width: 100vh;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
  
  display: flex;
  flex: 1;

  // as it is modal from MUI component - modal is rendered on the top of DOM. 
  // So we need to declare styles for child components inside modal
  & > .videoWrapper, > .imageWrapper, > .videoWrapper video, > .imageWrapper img {
    flex: 2;
    min-width: 400px;
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export default { FullScreenContent }