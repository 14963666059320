import styled from '@emotion/styled/macro';
import {
  flexCenteredStyles,
  renderTextWithThreePointsByRowsCount, transitionOnIconsHover,
} from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  background: #1c1f21;
  padding: 8px 16px;
  border-top: 0.5px solid #5f6875;
  color: #5f6975;
  overflow: hidden;

  & .videoPlayButton {
    position: absolute;
    height: 25px;
    width: auto;
  }
  
  & .label {
    font-size: 12px;
    line-height: 18px;
  }

  & .text {
    margin-right: auto;
    color: #fff;
    font-size: 14px;
    line-height: 18px;

    ${renderTextWithThreePointsByRowsCount(1)};
  }

  & .videoWrapper, .imageWrapper {
    ${flexCenteredStyles};
    height: 36px;
    width: 36px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06);
    
    & .image {
      width: auto;
      height: 100%;
    }
  }

  & .close {
    ${flexCenteredStyles};
    ${transitionOnIconsHover};
    margin-left: auto;
    
    &:hover {
      color: #FFEF01;
    } 
  }
  
  & .textWrapper {
    flex: 1;
  }
`;

export default { Wrapper }