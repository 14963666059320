import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import gaEvents from 'utils/gaEvents';
import { ExerciseReference } from '../exerciseReference/ExerciseReference';
import { useInputFocus } from 'hooks/useInputFocus';
import { noop } from 'utils';
import { CHAT_INPUT_BORDERS_HEIGHT } from '../../constants';

import type { TGeneralExerciseReference } from 'components/_new/phase/models';
import type { IMessageProps } from '../../chat/message/models';
import type { TMessage } from '../../models';

import { SendButtonIc } from 'assets/svg';
import S from './MessageInput.styled';

interface IMessageInputProps {
  onSendMessage: IMessageProps['onSendMessage'];
  updateMessagesOnSuccessSend?: (newMessages: TMessage[]) => void;
  generatedExerciseReference: TGeneralExerciseReference | null;
  setGeneratedExerciseReference: (
    reference: TGeneralExerciseReference | null,
  ) => void;
  isGeneratedReferenceActive: boolean;
  setIsGeneratedReferenceActive: (active: boolean) => void;
  isLastMessageSending?: boolean;
  isGeneratingReferenceDisabled: boolean;
  replyMessage?: TMessage | null;
}

const MAX_MESSAGE_FIELD_HEIGHT = 200;

export const MessageInput: FC<IMessageInputProps> = ({
  onSendMessage,
  generatedExerciseReference,
  setGeneratedExerciseReference,
  isGeneratedReferenceActive,
  setIsGeneratedReferenceActive,
  isLastMessageSending,
  isGeneratingReferenceDisabled,
  updateMessagesOnSuccessSend = noop,
  replyMessage,
}) => {
  const messageFieldRef = useInputFocus<HTMLTextAreaElement>();
  const [message, setMessage] = useState('');

  useEffect(() => {
    messageFieldRef?.current?.focus();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  // TODO: add also check for attached file after it will be implemented
  const isSendDisabled = !message || isLastMessageSending;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !isSendDisabled) {
      // to avoid adding enter space after sending message
      event.preventDefault();
      gaEvents.chatSendMessageOnEnter();
      handleSend();
    }
  };
  const handleSend = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage || generatedExerciseReference) {
      onSendMessage({
        text: trimmedMessage,
        updateCallback: updateMessagesOnSuccessSend,
      });
      setGeneratedExerciseReference(null);
      setMessage('');
    }
  };

  const handleSendButtonClick = () => {
    gaEvents.chatClickSendMessageButton();
    handleSend();
  };

  const handleFocus = () => {
    gaEvents.chatFocusOnTypeMessageInput();
  };

  // to calculate textarea height based on MAX_MESSAGE_FIELD_HEIGHT
  useEffect(() => {
    if (messageFieldRef?.current) {
      messageFieldRef.current.style.height = 'auto';
      messageFieldRef.current.style.height = `${Math.min(
        // to have only even value (avoid scroll if value < MAX_MESSAGE_FIELD_HEIGHT)
        (2 * Math.round(messageFieldRef.current.scrollHeight / 2)) + CHAT_INPUT_BORDERS_HEIGHT,
        MAX_MESSAGE_FIELD_HEIGHT,
      )}px`;
      messageFieldRef.current.scrollTop = messageFieldRef.current.scrollHeight;
    }
  }, [message]);

  return (
    <S.Container withTopBorder={!replyMessage}>
      <div className="inputWrapper">
        {/*Disabled for first iteration. @TODO - implement*/}
        {/*<S.AttachButton>*/}
        {/*  <AttachIc />*/}
        {/*</S.AttachButton>*/}
        <S.TextArea
          ref={messageFieldRef}
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          placeholder="Type message..."
          rows={1}
        />
        {!isSendDisabled &&
          <S.SendButton onClick={handleSendButtonClick}>
            <SendButtonIc />
          </S.SendButton>
        }
      </div>
      {generatedExerciseReference && !replyMessage?.exerciseReference && (
        <div className="exerciseReferenceWrapper">
          <ExerciseReference
            exerciseReference={generatedExerciseReference}
            isGeneratedReferenceActive={isGeneratedReferenceActive}
            setIsGeneratedReferenceActive={setIsGeneratedReferenceActive}
            readOnly={isGeneratingReferenceDisabled}
          />
        </div>
      )}
    </S.Container>
  );
};
