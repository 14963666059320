import React from 'react';
import CreateProgramButton from '../createProgramButton';
import 'components/styles/_modal.scss';
import '../styles.scss';
import CreateProgramModal from '../createProgramModal';
import PropTypes from 'prop-types';

const CreateProgram = ({
    client,
    toggleOpenNewProgramModal,
    loadProgramsHandler,
    currentClientCreateNewProgramOptions,
    hasProgram,
    setNewProgram,
    loaderHandler,
    showModal,
    toggleCloseModal,
    questionnaire,
    setShowExitPrompt
}) => (
    <div className="client-no">
        <div className="client-no-add">
            <p className="client-no-add-text">
                For starting work with the client create a program
            </p>
            <CreateProgramButton
                hasProgram={hasProgram}
                toggleOpenNewProgramModal={toggleOpenNewProgramModal}
                client={client}
                currentClientCreateNewProgramOptions={currentClientCreateNewProgramOptions}
                loadProgramsHandler={loadProgramsHandler}
                buttonName={'+ Create program'}
            />
        </div>
        <CreateProgramModal
            questionnaire={questionnaire}
            client={client}
            setNewProgram={setNewProgram}
            loaderHandler={loaderHandler}
            showModal={showModal}
            toggleCloseModal={toggleCloseModal}
            modalType="create"
            setShowExitPrompt={setShowExitPrompt}
        />
    </div>
);


CreateProgram.propTypes = {
    client: PropTypes.shape({
        user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            createdOn: PropTypes.string.isRequired,
            phoneNumber: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }),
        birthDate: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        weight: PropTypes.number.isRequired,
        gender: PropTypes.number.isRequired,
    }).isRequired,
    toggleOpenNewProgramModal: PropTypes.func.isRequired,
    loadProgramsHandler: PropTypes.func.isRequired,
    loaderHandler: PropTypes.func.isRequired,
    setNewProgram: PropTypes.func.isRequired,
    toggleCloseModal: PropTypes.func.isRequired,
    questionnaire: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        questions: PropTypes.array,
        title: PropTypes.string
    })).isRequired,
    hasProgram:PropTypes.bool.isRequired,
    currentClientCreateNewProgramOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
    })),
    showModal: PropTypes.bool,
};

CreateProgram.defaultProps = {
};

export default CreateProgram;