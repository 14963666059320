import Modal from '@material-ui/core/Modal';
import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import CircularProgress from 'components/_new/CircularProgress';

const ConfirmModal = (props) => {
    const { okText, cancelText, showModal, title, isLoading } = props;

    const handleCancel = (e) => {
        const { onCancel } = props;

        if (typeof onCancel === 'function') {
            onCancel(e);
        }
    };

    const handleOk = (e) => {
        const { onOk } = props;

        if (typeof onOk === 'function') {
            onOk(e);
        }
    };

    if (isLoading) {
        return (
            <Modal open={showModal}>
                <div className='modal'>
                    <CircularProgress />
                </div>
            </Modal>
        )
    }

    return (
        <Modal open={showModal}>
            <div className='modal'>
                <div className='modal__close modal__body--sm'>
                    <ReactSVG
                        onClick={handleCancel}
                        src='/assets/svg/close.svg'
                    />
                </div>
                <div className='modal__body'>
                    <div className='modal-delete'>
                        <p className='modal-title'>
                            {title}
                        </p>
                        <div className='modal-buttons'>
                            <button
                                className='modal-buttons-cancel'
                                onClick={handleCancel}
                            >
                                {cancelText}
                            </button>
                            <button
                                className='modal-buttons-delete'
                                onClick={handleOk}
                            >
                                {okText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )

}

ConfirmModal.propTypes = {
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    title: PropTypes.node,
    okText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showModal: PropTypes.bool,
    isLoading: PropTypes.bool,
}

ConfirmModal.defaultProps = {
    showModal: false,
    okText: 'Yes',
    cancelText: 'Cancel',
    onCancel: undefined,
    onOk: undefined,
    title: null,
}

export default ConfirmModal