import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Loader from 'components/loader/Loader';
import Modal from 'components/_new/Modal';
import { ChatHeader } from '../../../components/chatHeader/ChatHeader';
import {
  checkUserDataToBeShown,
  updateMessageReactionsById,
} from '../../utils';
import { MessageInput } from '../../../../components/messageInput/MessageInput';
import { noop } from 'utils';
import { Message } from '../../Message';
import { DEFAULT_NEW_MESSAGE } from '../../constants';
import { useFetch } from 'providers/chatPage/filteredExerciseProvider/hooks/useFetch';

import type { TMessage } from '../../../../models';
import type { IMessageProps, TReactOnMessageResponse } from '../../models';

import { CloseIc } from 'assets/svg';
import S from './LightboxModal.styled';

interface ILightboxModalMediaMessagesProps extends IMessageProps {
  mainMessage: TMessage;
  getMediaFile: (isForPreview?: boolean) => ReactNode;
  readOnly?: boolean;
}

export const LightboxModal = ({
  user,
  currentUserId,
  onSuccessDeleteMessage,
  onSuccessAddReaction,
  activeClient,
  getClientsMutation,
  getClientMessagesMutation,
  onSendMessage,
  mainMessage,
  getMediaFile,
  readOnly,
}: Pick<
  ILightboxModalMediaMessagesProps,
  | 'mainMessage'
  | 'getMediaFile'
  | 'readOnly'
  | 'user'
  | 'currentUserId'
  | 'onSuccessDeleteMessage'
  | 'onSuccessAddReaction'
  | 'onSendMessage'
  | 'activeClient'
  | 'getClientsMutation'
  | 'getClientMessagesMutation'
>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMessages, setActiveMessages] = useState<TMessage[]>([]);

  const { getMessagesByExerciseMutation, isLoadingGetMessagesByExercise } =
    useFetch();

  useEffect(() => {
    if (isOpen && mainMessage.exerciseReference?.programId) {
      // if there is exerciseReference in main message - show its history
      getMessagesByExerciseMutation({
        programId: mainMessage.exerciseReference.programId,
        exerciseInfoId: mainMessage.exerciseReference.exerciseInfoId,
        callbackOnSuccess: setActiveMessages,
      });
    }
  }, [mainMessage, isOpen]);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [activeMessages.length]);

  const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!readOnly) {
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);

    if (activeMessages.length) {
      setActiveMessages([]);
    }
  };

  const updateMessagesOnSuccessSend = (newMessages: TMessage[]) => {
    setActiveMessages((prevMessages) => {
      // replace mockedNewMessage to new from back end
      const updatedMessages = prevMessages?.slice(0, -1) || [];
      return [...updatedMessages, ...newMessages];
    });
  };

  const sendMessageHandler: IMessageProps['onSendMessage'] = ({ text }) => {
    const mockedNewMessage: TMessage = {
      ...DEFAULT_NEW_MESSAGE,
      receiverId: activeClient.id,
      senderId: currentUserId || '',
      text,
      replied: mainMessage,
    };

    setActiveMessages([...activeMessages, mockedNewMessage]);

    onSendMessage({
      text,
      repliedMessage: mainMessage,
      exerciseReference: mainMessage.exerciseReference || null,
      updateCallback: updateMessagesOnSuccessSend,
      shouldScrollToTheBottom: false,
    });
  };

  const successDeleteMessageHandler = (id: string) => {
    // update data in modal
    setActiveMessages((prevMessages) => {
      const updatedMessages = prevMessages?.filter(
        (message) => message.id !== id,
      );

      return updatedMessages || null;
    });

    // update general chat on background
    onSuccessDeleteMessage(id);
  };

  const successAddReactionHandler = ({
    reaction,
    messageId,
  }: TReactOnMessageResponse) => {
    // update data in modal
    setActiveMessages((prevMessages) => {
      return updateMessageReactionsById({
        reaction,
        messageId,
        prevMessages,
        currentUserId,
      });
    });

    // update general chat on background
    onSuccessAddReaction({ reaction, messageId });
  };

  return (
    <>
      <button onClick={openModal}>
        {<div className="attachment">{getMediaFile(true)}</div>}
      </button>
      <Modal show={isOpen} onClose={closeModal} style={{ overflow: 'auto' }}>
        <S.Wrapper>
          {getMediaFile()}
          <div className="attachmentMessages">
            {isLoadingGetMessagesByExercise && <Loader relatedToParent />}
            <ChatHeader
              user={user}
              expandHandler={closeModal}
              expandIcon={<CloseIc />}
            />
            <div className="attachmentMessages__wrapper">
              <div className="attachmentMessages__list">
                {activeMessages.map((messageData, index) => {
                  const isUserDataShown = checkUserDataToBeShown({
                    messageData,
                    index,
                    activeMessages,
                  });

                  return (
                    <Message
                      key={messageData.id}
                      messageData={messageData}
                      isUserDataShown={isUserDataShown}
                      currentUserId={currentUserId}
                      onSuccessDeleteMessage={successDeleteMessageHandler}
                      onSuccessAddReaction={successAddReactionHandler}
                      activeClient={activeClient}
                      getClientsMutation={getClientsMutation}
                      getClientMessagesMutation={getClientMessagesMutation}
                      user={user}
                      onSendMessage={onSendMessage}
                      setNavigatedExerciseReference={noop}
                      onReply={noop}
                      readOnly
                    />
                  );
                })}
                <div ref={messagesEndRef} />
              </div>
              <div>
                <MessageInput
                  onSendMessage={sendMessageHandler}
                  generatedExerciseReference={
                    mainMessage.exerciseReference || null
                  }
                  isLastMessageSending={
                    activeMessages[activeMessages.length - 1]?.isSending
                  }
                  setGeneratedExerciseReference={noop}
                  setIsGeneratedReferenceActive={noop}
                  isGeneratedReferenceActive={true}
                  isGeneratingReferenceDisabled={true}
                />
              </div>
            </div>
          </div>
        </S.Wrapper>
      </Modal>
    </>
  );
};
