import styled from '@emotion/styled/macro';
import { CHAT_HEADER_HEIGHT } from '../../../../constants';

const Wrapper = styled.div`
  min-height: 0;
  min-width: 0;

  display: flex;
  flex: 1;

  // as it is modal from MUI component - modal is rendered on the top of DOM. 
  // So we need to declare styles for child components inside modal
  & > .videoWrapper, > .imageWrapper, > .videoWrapper video, > .imageWrapper img {
    flex: 2;
    min-width: 400px;
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  & .attachmentMessages {
    position: relative;
    
    &__wrapper {
      display: flex;
      flex-direction: column;
      padding-top: ${CHAT_HEADER_HEIGHT}px;
      justify-content: space-between;
      height: 100%;
      position: relative;
      width: 350px;
    }

    &__list {
      flex: 2;
      max-height: 100%;
      overflow: auto;
      padding-bottom: 10px;
      background: #1c1f21;
    }
  }
`;

export default { Wrapper }