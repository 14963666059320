import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from 'router/routes';

const withPrivateTrainersRoute = (Component) => {
    return (
        (props) => {
            const { user } = props;

            if (user && !user.isTemporaryPasswordChanged) {
                return <Component {...props} />;
            }
            if (user && user.roles?.some((role) => role === 'Trainers')) {
                return <Component {...props} />;
            }

            return <Redirect to={routes.SIGN_IN} />;
        }
    );
};

export default withPrivateTrainersRoute;
