import ListItem from '@material-ui/core/ListItem';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import S from 'pages/ChatPage/clientsList/client/Client.styled';
import { ArrowTriangleIc } from '../../assets/svg';
import {
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '../../constants';
import { transitionOnIconsHover } from 'styles/commonStyleVariables';

const Sidebar = styled('aside')<{
  isSidebarCollapsed: boolean;
}>`
  position: fixed;
  left: 0;
  top: ${HEADER_HEIGHT}px;

  display: flex;
  flex-direction: column;
  flex: 1 0 ${SIDEBAR_WIDTH}px;
  width: ${SIDEBAR_WIDTH}px;
  min-width: ${SIDEBAR_WIDTH}px;
  min-height: calc(100% - ${HEADER_HEIGHT}px);
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: hidden;
  background: #090b0c;
  box-shadow: 10px 0 30px rgba(0, 0, 0, 0.3);
  transition: all 0.5s;

  ${({ isSidebarCollapsed }) =>
    isSidebarCollapsed &&
    css`
      flex: 1 0 ${SIDEBAR_WIDTH_COLLAPSED}px;
      width: ${SIDEBAR_WIDTH_COLLAPSED}px;
      min-width: ${SIDEBAR_WIDTH_COLLAPSED}px;
    `}
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  color: white;
  padding: 8px;
`;

const SidebarItem = styled('div')<{
  isActive: boolean;
  isActiveParentChild: boolean;
}>`
  ${transitionOnIconsHover};
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #6e7987;
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 3px 0;
  cursor: pointer;
  text-decoration: none;
  justify-content: space-between;

  &:hover {
    background-color: #161718;
    border-radius: 6px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      position: relative;
      background: #161718;
      border-radius: 6px;
      color: #fff;
    `}
  ${({ isActiveParentChild }) =>
    isActiveParentChild &&
    css`
      color: #fff;
      background-color: transparent;
    `}
  & .labelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  & .labelIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    height: 38px;
    width: 38px;
    border-radius: 4px;

    background: linear-gradient(
        313.34deg,
        rgba(0, 0, 0, 0.3) -28.92%,
        rgba(255, 255, 255, 0.3) 130.82%
      ),
      #20252c;
    background-blend-mode: soft-light, normal;
    color: ${({ isActive }) => (isActive ? '#fff' : '#5f6975')};

    & svg {
      height: 22px;
      max-width: 25px;
      width: auto;
    }
  }
`;

const Counter = styled(S.Counter)`
  position: absolute;
  top: 3px;
  right: 3px;
  width: 14px;
  height: 14px;
  margin: 0;
`;

const ChildListItem = styled(ListItem, {
  shouldForwardProp: (prop) =>
    !['isSidebarCollapsed', 'isActiveChild'].includes(prop),
})<{
  isActiveChild: boolean;
  isSidebarCollapsed: boolean;
}>`
  && {
    padding-left: ${({ isSidebarCollapsed }) =>
      isSidebarCollapsed ? 10 : '62px'};
    color: #6e7987;

    &:hover {
      background-color: #161718;
      border-radius: 6px;
    }

    & .childIcon {
      margin-right: 10px;
    }

    ${({ isActiveChild }) =>
      isActiveChild &&
      css`
        position: relative;
        background: #161718;
        border-radius: 6px;
        color: #fff;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          height: 100%;
          width: 3px;
          left: 0;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          background-color: #ffef01;
        }

        & path {
          fill: #fff;
        }
      `}
  }
`;

const ArrowTriangleIcon = styled(ArrowTriangleIc, {
  shouldForwardProp: (prop) => prop !== 'isReversed',
})<{ isReversed: boolean }>`
  transform: ${({ isReversed }) => isReversed && 'rotate(180deg)'};
`;

const Footer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  white-space: nowrap;

  & .docLink {
    display: flex;
    align-items: center;
    gap: 8px;

    color: #ffef01;
    font-size: 16px;
    font-weight: 400;
  }

  & .collapseExpandButton {
    color: #5f6975;

    &:hover {
      color: #fff;
    }
  }
`;

const SidebarPlaceholder = styled.div<{
  isSidebarCollapsed: boolean;
}>`
  width: ${SIDEBAR_WIDTH}px;
  min-width: ${SIDEBAR_WIDTH}px;
  transition: all 0.5s;

  ${({ isSidebarCollapsed }) =>
    isSidebarCollapsed &&
    css`
      width: ${SIDEBAR_WIDTH_COLLAPSED}px;
      min-width: ${SIDEBAR_WIDTH_COLLAPSED}px;
    `}
`;

const CollapseExpandButton = styled.button<{
  isSidebarCollapsed: boolean;
}>`
  transition: all 0.5s;
  transform: ${({ isSidebarCollapsed }) =>
    !isSidebarCollapsed && 'rotate(180deg)'};
`;

export default {
  Sidebar,
  Body,
  SidebarItem,
  ArrowTriangleIcon,
  ChildListItem,
  Footer,
  SidebarPlaceholder,
  CollapseExpandButton,
  Counter,
};
