import { IS_ONBOARDING_HIDDEN } from 'providers/OnboardingProvider';

export const FEEDBACK_BANNER_NEXT_DISPLAY_DATE =
  'feedbackBannerNextDisplayDate';
const BANNER_DISPLAY_FREQUENCY_DAYS = 28;

export const isShowBanner = (): boolean => {
  const now = new Date();
  const nextDisplayDateString = localStorage.getItem(
    FEEDBACK_BANNER_NEXT_DISPLAY_DATE,
  );
  const isOnboardingPassed = localStorage.getItem(IS_ONBOARDING_HIDDEN);
  if (!isOnboardingPassed) {
    return false;
  }
  if (nextDisplayDateString) {
    try {
      const nextDisplayDate = new Date(nextDisplayDateString);
      if (nextDisplayDate && now >= nextDisplayDate) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  }

  return true;
};

export const setFeedbackBannerShowNextTimeToLocalStorage = (
  daysFrequency = BANNER_DISPLAY_FREQUENCY_DAYS,
) => {
  const now = new Date();
  const daysLater = now;
  daysLater.setDate(now.getDate() + daysFrequency);
  localStorage.setItem(
    FEEDBACK_BANNER_NEXT_DISPLAY_DATE,
    daysLater.toISOString(),
  );
};
