import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  FocusEventHandler,
  useEffect,
  useState,
} from 'react';
import { useDebouncedEffect } from './useDebounceEffect';
import { noop } from 'utils';

import { SearchIc } from 'assets/svg';
import S from './SearchTextInputWithDebounce.styled';

interface ISearchTextInputWithDebounceProps {
  onChange: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value: string;
  debounceTime: number;
  styles?: CSSProperties;
}

export const SearchTextInputWithDebounce: FC<
  ISearchTextInputWithDebounceProps
> = ({
  onChange,
  onBlur = noop,
  onFocus = noop,
  value,
  debounceTime,
  styles = {},
}) => {
  const [searchTerm, setSearchTerm] = useState(value);

  useDebouncedEffect(
    () => {
      if (searchTerm !== value) {
        onChange(searchTerm);
      }
    },
    debounceTime,
    [searchTerm],
  );

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  return (
    <S.Wrapper>
      <SearchIc className="icon" />
      <input
        onChange={onChangeHandler}
        className="input"
        type="text"
        value={searchTerm}
        name="search"
        placeholder="Search"
        onBlur={onBlur}
        onFocus={onFocus}
        style={styles}
      />
    </S.Wrapper>
  );
};
