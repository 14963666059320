import styled from '@emotion/styled/macro';
import {
  flexCenteredStyles,
  renderTextWithThreePointsByRowsCount,
} from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  & .preview {
    position: relative;
  }
  
  & .textarea {
    height: 100%;
  }
`;

const TextPreview = styled.div<{ isEmpty: boolean }>`
  position: absolute;
  left: 15px;
  top: 182px;
  width: calc(100% - 30px);
  height: 88px;
  overflow: hidden;
  ${flexCenteredStyles};

  border: 1px dashed #3e434b;
  border-radius: 8px;
  padding: 8px;

  & .text {
    ${renderTextWithThreePointsByRowsCount(6)}
    line-height: 12px;
    font-size: 10px;
    color: ${({ isEmpty }) => (isEmpty ? '#FFEF01' : '#fff')};
  }
`;

export default { Wrapper, TextPreview };
