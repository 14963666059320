import React, { FC } from 'react';
import CustomMenu from 'components/menu';
import { isChatPage } from '../../utils';
import gaEvents from 'utils/gaEvents';
import { DBD_VIEW_ID } from '../../constants';

import type { TMenuItem } from 'components/menu';
import type { TViewModeId } from '../../models';

import { AngleDownIc, PlusIc } from 'assets/svg';
import S from './AddPhaseMenu.styled';

const MENU_ITEM_IDS = {
  NEW_PHASE: 0,
  TEMPLATE: 1,
};

const MENU_ITEMS: TMenuItem[] = [
  { id: MENU_ITEM_IDS.NEW_PHASE, value: 'Add New Phase' },
  { id: MENU_ITEM_IDS.TEMPLATE, value: 'Import From Templates' },
];

interface IAddPhaseMenu {
  viewModeId: TViewModeId;
  addNewPhaseHandler: () => void;
  importTemplateHandler: () => void;
}

export const AddPhaseMenu: FC<IAddPhaseMenu> = ({
  viewModeId,
  addNewPhaseHandler,
  importTemplateHandler,
}) => {
  const chooseAddPhaseOption = (item: TMenuItem) => {
    if (!item) return;

    switch (item.id) {
      case MENU_ITEM_IDS.NEW_PHASE:
        addNewPhaseHandler();
        break;

      case MENU_ITEM_IDS.TEMPLATE:
        importTemplateHandler();
        break;

      default:
        break;
    }
  };

  const openMenuHandler = () => {
    if (isChatPage()) {
      gaEvents.chatOpenAddPhaseMenu();
    } else {
      gaEvents.openAddPhaseMenu();
    }
  }

  return (
    <CustomMenu
      button={
        <S.MenuButton onClick={openMenuHandler}>
          <PlusIc />
          {viewModeId !== DBD_VIEW_ID && (
            <>
              Add Phase
              <AngleDownIc />
            </>
          )}
        </S.MenuButton>
      }
      menuItems={MENU_ITEMS}
      clickOnMenuItem={chooseAddPhaseOption}
    />
  );
};
