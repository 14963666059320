import React, { FC } from 'react';
import { TextArea } from 'components/fields/textArea/TextArea';

import type { IHomePageStep } from '../../models';

import selfIntroductionText from 'assets/img/selfIntroductionText.png';
import S from './TrainersProfileStep.styled';

const SELF_INTRO_TEXT = 'Self-introduction text';
const SELF_INTRO_TEXT_MAX_SYMBOLS = 200;

export const TrainersProfileStep: FC<IHomePageStep> = ({
  brandingData,
  onChangeBrandingFields,
}) => {
  return (
    <S.Wrapper>
      <TextArea
        onChange={({ target }) =>
          onChangeBrandingFields?.({
            key: 'selfIntroductionText',
            value: target.value,
          })
        }
        value={brandingData.selfIntroductionText}
        placeholder={SELF_INTRO_TEXT}
        maxLength={SELF_INTRO_TEXT_MAX_SYMBOLS}
        resizable={false}
      />
      <div className="preview">
        <img src={selfIntroductionText} alt="preview" />
        <S.TextPreview isEmpty={!brandingData.selfIntroductionText}>
          <p className="text">
            {brandingData.selfIntroductionText || SELF_INTRO_TEXT}
          </p>
        </S.TextPreview>
      </div>
    </S.Wrapper>
  );
};
