import React, { createContext } from 'react';
import { useCategories } from '../hooks/useCategories';

const initialState = { questionnaire: [] };

export const QuestionnaireContext = createContext(initialState);

const QuestionnaireProvider = (props) => {
    const {isLoadingCategories, errorCategories, dataCategories, getCategories} = useCategories();

    return (
        <QuestionnaireContext.Provider
            value={{
                questionnaire: dataCategories,
                loadQuestionnaires: getCategories,
                isLoadingQuestionnaires: isLoadingCategories,
                errorQuestionnaires: errorCategories
            }}
        >
            {props.children}
        </QuestionnaireContext.Provider>
    );
};

export default QuestionnaireProvider;
