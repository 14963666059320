import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { DONE_PROGRESS_COUNT } from '../../constants';

import S from './ProgressBar.styled';

function CircularProgressWithLabel(props) {
  const value = useMemo(() => Math.round(props.value), [props.value]);
  const isProgressFinished = useMemo(
    () => value === DONE_PROGRESS_COUNT,
    [value],
  );

  const progressFilledStyles = useMemo(
    () => (props.color ? { color: props.color } : {}),
    [props.color],
  );

  return (
    <S.ProgressContainer position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className="blank"
        size={props.size}
        thickness={4}
        {...props}
        value={100}
      />
      <S.FilledProgress
        variant="determinate"
        size={40}
        finished={isProgressFinished}
        style={progressFilledStyles}
        {...props}
      />
      <Box
        top={2}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <S.Value
          variant="caption"
          component="div"
          empty={!props.value}
          finished={isProgressFinished}
        >
          {`${value}%`}
        </S.Value>
      </Box>
    </S.ProgressContainer>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default function ProgressBar({ progress, size, color }) {
  return (
    <CircularProgressWithLabel value={progress} size={size} color={color} />
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};
