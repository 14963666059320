import { styled } from '@mui/material';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default {
  Wrapper,
};
