import React from 'react';
import { useHandlers } from './hooks/useHandlers';
import { WeekDataGrid } from '../WeekDataGrid/WeekDataGrid';
import { ChatIcon } from '../ChatIcon/ChatIcon';
import { parseDateToCorrectNewFormat } from 'utils';

import type { IPhaseWeekProps } from './models';
import S from './PhaseWeek.styled';

const PhaseWeek = ({
  week,
  weekIndex,
  navigatedExerciseReference,
  exerciseReference,
  exerciseName,
}: IPhaseWeekProps) => {
  const { name, isDeleted, exerciseRows, isCompleted, completedDate } = week;
  const {
    navigatedWeekRef,
    generalExerciseReference,
    isGeneratingReferenceDisabled,
    generateReferenceHandler,
    onShowPopoverCallback,
    isHighlighted,
  } = useHandlers({
    navigatedExerciseReference,
    exerciseReference,
    exerciseName,
    weekIndex,
    week,
  });

  const renderWeekLabel = () => {
    if (completedDate && isCompleted) {
      const { fullDate } = parseDateToCorrectNewFormat({ date: completedDate, isHoursAndMinutesVisible: false });
      return `${name} ${completedDate ? `(${fullDate})` : ''}`;
    }
    return name;
  };

  return (
    <S.Wrapper ref={navigatedWeekRef} id={'scrolling'}>
      <S.WeekName isCompleted={isCompleted}>
        {renderWeekLabel()}
        <ChatIcon
          generalExerciseReference={generalExerciseReference}
          isGeneratingReferenceDisabled={isGeneratingReferenceDisabled}
          generateReferenceHandler={generateReferenceHandler}
          onShowPopoverCallback={onShowPopoverCallback}
          styles={{
            position: 'absolute',
            right: '9px',
          }}
        />
      </S.WeekName>
      <S.DataWrapper isHighlighted={isHighlighted}>
        <WeekDataGrid exerciseRows={exerciseRows} isDeleted={isDeleted} />
      </S.DataWrapper>
    </S.Wrapper>
  );
};

export default PhaseWeek;
