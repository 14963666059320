import { styled } from '@mui/material';
import { transitionOnIconsHover } from 'styles/commonStyleVariables';

const Client = styled('li')`
  padding: 10px;
  background: #191B1F;
  border-radius: 10px;
  width: 23%;
  margin-right: 2%;
  min-width: 250px;
  margin-bottom: 2%;
  word-wrap: break-word;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  ${transitionOnIconsHover};

  &:hover {
    background: #282c33;
  }
  
  & .client__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-top: 10px;
  }

  & .client__headerPhoto {
    width: 70px;
    height: 70px;
    margin-right: 14px;
    background: center / cover no-repeat;
    border-radius: 4px;
  }

  & .client__headerDataWrapper {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    word-wrap: break-word;
    padding-top: 5px;
  }
  
  & .client__headerAge {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6E7987;
    margin-top: 8px;
  }

  & .client__footer {
    display: flex;
    align-items: center;
    background: #121315;
    border-radius: 6px;
    padding: 8px;
    justify-content: space-between;
  }

  & .client__program {
    margin-right: auto;
    margin-left: 15px;

    &--created {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #808B9A;
    }
  }

  & .client__phase {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #808B9A;
    margin-bottom: 2px;
  }

  & .client__programName {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
  }

  & .client__noProgram {
    color: #fff;
  }

  & .client__progressDoneIcon {
    color: #33A56E;
    line-height: 0;
  }
`;

export default { Client }