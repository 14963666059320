import styled from '@emotion/styled/macro';

const Phase = styled.div`
  margin: 0 10px 12px 0;
  border-radius: 8px;
  
  & .outerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    transition: all 0.2s;
    cursor: pointer;
    background: #1C1F21;

    &:hover {
      background-color: #282c33;

      & .actionButton, .saveAsButton {
        visibility: visible;
        opacity: 1;
      }
    }

    & .arrow {
      transition: all 0.2s;
      color: #5f6975;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  & .actionButton, & .saveAsButton {
    visibility: hidden;
    opacity: 0;
    margin-left: 5px;
    color: #5f6975;

    &.duplicate {
      margin: 0 auto 0 5px;
    }

    &:hover {
      color: #ebc604;
    }
  }
  
  & .saveAsButton {
    margin-right: 10px;
  }

  & .progressBar {
    margin-left: 20px;
  }

  & .accessToPhaseIcon {
    margin-left: 15px;
    color: #6E7987;
  }

  & .progressDoneIcon {
    color: #33A56E;
    line-height: 0;
  }
  
  & .innerWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export default { Phase };
