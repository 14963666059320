import { ChangeEvent, KeyboardEventHandler } from 'react';

export const emailValidator = (email = '', setError: (str: string) => void) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!pattern.test(email)) {
    setError('Email address is not validated');

    return true;
  }
  setError('');

  return false;
};

export const urlRegExpPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator

export const isUserPressedBackspace = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
  (e.nativeEvent as { data?: string | null }).data === null;

export const parseDateToCorrectFormat = (date = '') => {
  const day = new Date(date).getDate();
  const month = new Date(date).toLocaleString('eng', {
    month: 'short',
  });
  const year = new Date(date).getFullYear();

  const hours = new Date(date).getHours();
  let minutes: string | number = new Date(date).getMinutes();

  if (`${minutes}`.length === 1) {
    minutes = `0${minutes}`;
  }
  const fullDate =
    new Date(date).getDate() === new Date().getDate()
      ? `${hours}:${minutes}`
      : `${day}-${month}-${year}`;

  return { day, month, year, fullDate };
};

export const parseDateToCorrectNewFormat = ({
  date = '',
  isHoursAndMinutesVisible = true,
}) => {
  const messageDate = new Date(date);
  const currentDate = new Date();

  const isToday = currentDate.getDate() === messageDate.getDate();

  const day = messageDate.getDate();
  const month = messageDate.toLocaleString('eng', { month: 'short' });
  const year = messageDate.getFullYear() % 100;

  let hours = messageDate.getHours();
  let minutes: string | number = messageDate.getMinutes();
  const ampm = hours >= 12 ? ' PM' : ' AM';
  hours %= 12;
  hours || (hours = 12); // Handle midnight as 12 AM

  if (`${minutes}`.length === 1) {
    minutes = `0${minutes}`;
  }

  let fullDate = '';
  if (isToday) {
    fullDate = `${hours}:${minutes}${ampm}`;
  } else if (messageDate.getFullYear() === currentDate.getFullYear()) {
    const hoursAndMinutes = isHoursAndMinutesVisible
      ? ` ${hours}:${minutes}${ampm}`
      : '';
    fullDate = `${day} ${month}${hoursAndMinutes}`;
  } else {
    fullDate = `${day} ${month} ${year}`;
  }

  return { day, month, year, fullDate };
};

export const noop = () => undefined;

export const avoidDecimalsInNumberInput: KeyboardEventHandler<
  HTMLInputElement
> = (e) => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();
