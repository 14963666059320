import React, { ChangeEvent, memo, useState, useMemo, useEffect } from 'react';
import ConfirmModal from 'components/modal/confirmModal';
import ExerciseItem from 'components/exerciseItem/';
import ModalExercise from './components/ModalExercise';
import useFetch from './hooks/useFetch';
import TextInput from 'components/fields/textInput';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuidv4 } from 'uuid';
import { useLayout } from 'providers/layout/hooks/useLayout';
import { useSnackbar } from 'notistack';

import type { TExercisesItem } from 'components/exerciseItem/ExerciseItem';
import type { TFormData } from './components/ModalExercise/ModalExercise';

import './styles.scss';

type TModalType = 'delete' | 'edit' | 'add' | '';
type TModalData = {
  type: TModalType;
  exercise?: TExercisesItem;
};

export const MyExercisesPage = memo(() => {
  const {
    exercises,
    fetchExercises,
    createExercises,
    fetchCreateExercise,
    editExercises,
    fetchEditExercise,
    isLoadingDeleteExercise,
    fetchDeleteExercise,
  } = useFetch();

  const { enqueueSnackbar } = useSnackbar();

  const [modalData, setModalData] = useState<TModalData>({ type: '' });

  const [searchValue, setSearchValue] = useState('');
  const filteredExercises = useMemo(
    () =>
      exercises.data.filter((exercise) =>
        exercise.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [exercises.data, searchValue],
  );

  const { initLayoutState } = useLayout();

  useEffect(() => {
    initLayoutState({
      headerTitle: 'My Exercises',
      headerCountTitle: exercises.data.length || 0,
    });
  }, [exercises.data, initLayoutState]);
  // ==  == //

  const closeModalHandler = () => setModalData({ type: '' });
  const openModalHandler = (data: TModalData) => setModalData(data);

  const filterExercisesHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target?.value || '');

  const deleteExerciseHandler = async () => {
    await fetchDeleteExercise(modalData.exercise?.id);

    closeModalHandler();
  };

  const openEditModalHandler = (exercise: TExercisesItem) =>
    openModalHandler({ type: 'edit', exercise });

  const openDeleteModalHandler = (exercise: TExercisesItem) =>
    openModalHandler({ type: 'delete', exercise });
  const openAddModalHandler = () => openModalHandler({ type: 'add' });

  const submitNewExerciseHandler = async (
    e: ChangeEvent<HTMLFormElement>,
    formData: TFormData,
  ) => {
    e.preventDefault();

    const isNameExist = !!exercises.data.find(
      ({ name }) => name.toLowerCase() === formData.name.toLowerCase(),
    );

    if (!isNameExist) {
      const isSuccess = await fetchCreateExercise(formData);

      if (isSuccess) {
        fetchExercises();
      }
    } else {
      enqueueSnackbar('This name already exists', { variant: 'error' });
    }

    closeModalHandler();
  };

  const editExerciseHandler = async (
    e: ChangeEvent<HTMLFormElement>,
    formData: TFormData,
  ) => {
    e.preventDefault();

    const isNameExist = !!exercises.data
      .filter(({ id }) => modalData.exercise?.id !== id)
      .find(({ name }) => name.toLowerCase() === formData.name.toLowerCase());

    if (!isNameExist) {
      const isSuccess = await fetchEditExercise(
        formData,
        modalData.exercise?.id,
      );

      if (isSuccess) {
        fetchExercises();
      }
    } else {
      enqueueSnackbar('This name already exists', { variant: 'error' });
    }

    closeModalHandler();
  };

  return (
    <div className="exercises">
      <div className="exercises-header">
        <div className="exercises-header-search">
          <TextInput
            value={searchValue}
            onChange={filterExercisesHandler}
            placeholder="Search"
            name="search"
          />
        </div>
        <div className="exercises-header-buttons">
          <button
            className="exercises-header-button"
            onClick={openAddModalHandler}
          >
            + Add Exercise
          </button>
        </div>

        {/* == modals == */}
        <>
          {/* delete modal */}
          <ConfirmModal
            onOk={deleteExerciseHandler}
            onCancel={closeModalHandler}
            showModal={modalData.type === 'delete'}
            title="Are you sure you want to delete the exercise?"
            isLoading={isLoadingDeleteExercise}
          />

          {/* add modal */}
          <ModalExercise
            show={modalData.type === 'add'}
            onClose={closeModalHandler}
            onSubmit={submitNewExerciseHandler}
            isLoading={createExercises.isLoading}
          />

          {/* edit modal */}
          <ModalExercise
            show={modalData.type === 'edit'}
            onClose={closeModalHandler}
            onSubmit={editExerciseHandler}
            exercise={modalData?.exercise}
            isLoading={editExercises.isLoading}
          />
        </>
      </div>
      <ul className="exercises-body">
        {!exercises.isLoading &&
          filteredExercises.map((exercise) => (
            <ExerciseItem
              exercise={exercise}
              openEditModalHandler={openEditModalHandler}
              openDeleteModalHandler={openDeleteModalHandler}
              key={exercise.id}
            />
          ))}
        {filteredExercises.length === 0 && !exercises.isLoading && (
          <div className="not-found">No data</div>
        )}
        {exercises.isLoading &&
          new Array(10)
            .fill(null)
            .map(() => (
              <Skeleton key={uuidv4()} className="exercises-body-skeleton" />
            ))}
      </ul>
    </div>
  );
});
