import React, { ChangeEvent, FC } from 'react';
import { ReactSVG } from 'react-svg';
import Modal from '@material-ui/core/Modal';
import TextInput from 'components/fields/textInput';
import gaEvents from 'utils/gaEvents';
import { isChatPage } from '../../../utils';

import type { TEventOnClick } from 'containers/app/trainer/models';

interface ISaveTemplateModalProps {
  saveTemplateValue: string;
  isTemplateModalShown: boolean;
  setIsTemplateModalShown: (arg: boolean) => void;
  changeDescriptionHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  submitSaveTemplate: () => void;
}

export const SaveTemplateModal: FC<ISaveTemplateModalProps> = ({
  submitSaveTemplate,
  changeDescriptionHandler,
  saveTemplateValue,
  isTemplateModalShown,
  setIsTemplateModalShown,
}) => {
  const closeModalHandler = (e: TEventOnClick) => {
    e.stopPropagation();
    setIsTemplateModalShown(false);
  };

  const closeSaveAsTemplateModal = (e: TEventOnClick) => {
    closeModalHandler(e);

    if (isChatPage()) {
      gaEvents.chatCloseSaveAsTemplateModal();
    } else {
      gaEvents.closeSaveAsTemplateModal();
    }
  }
  const outsideCloseSaveAsTemplateModal = (e: TEventOnClick) => {
    closeModalHandler(e);

    if (isChatPage()) {
      gaEvents.chatOutsideCloseSaveAsTemplateModal();
    } else {
      gaEvents.outsideCloseSaveAsTemplateModal();
    }
  }

  return (
    <Modal
      onClose={outsideCloseSaveAsTemplateModal}
      open={isTemplateModalShown}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modal phases-modal">
        <>
          <div className="modal__close modal__body--sm">
            <ReactSVG
              onClick={closeSaveAsTemplateModal}
              src="/assets/svg/close.svg"
            />
          </div>
          <div className="modal__body">
            <p className="modal-title">Save Phase As Template</p>
            <div className="modal-field">
              <TextInput
                label="Name"
                type="text"
                name="name"
                value={saveTemplateValue}
                onChange={changeDescriptionHandler}
                required
              />
            </div>
            <button
              disabled={!saveTemplateValue}
              className="modal-button"
              onClick={submitSaveTemplate}
            >
              Save
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
};