import { useContext, useEffect, useState } from 'react';
import { ExerciseReferenceContext } from 'providers/chatPage/ExerciseReferenceProvider';
import { useFetch } from './useFetch';
import { isChatPage } from '../../../utils';
import gaEvents from 'utils/gaEvents';

import type { TProgramExerciseReference } from 'components/_new/phase/models';
import type { IBiggerProgramPhaseProps } from '../models';
import type { TBiggerPhase, TEventOnClick } from '../../../../../models';

export const useHandlers = ({
  phase,
  phaseIndex,
  onDeletePhase,
  loadBigProgramHandler,
  bigProgram,
}: IBiggerProgramPhaseProps) => {
  const { navigatedExerciseReference, setNavigatedExerciseReference } =
    useContext(ExerciseReferenceContext);
  const [phaseData, setPhaseData] = useState<TBiggerPhase | null>(null);
  const [isShowAccordionBody, setShowAccordionBody] = useState(false);
  const [modalType, setModalType] = useState<string | null>(null);
  const [hoveredPhase, setHoveredPhase] = useState<number | null>(null);

  const {
    getCurrentPhaseMutation,
    isLoadingCurrentPhase,

    duplicatePhaseMutation,
    isLoadingDuplicatePhase,
  } = useFetch({
    setPhaseData,
    setShowAccordionBody,
    loadBigProgramHandler,
  });

  // open active phase by default
  useEffect(() => {
    const activeCurrentPhaseId = bigProgram.phases.find(
      (phase) => !phase.isCompleted,
    )?.id;
    if (
      activeCurrentPhaseId &&
      activeCurrentPhaseId === phase.id &&
      !isShowAccordionBody
    ) {
      getCurrentPhaseMutation({ phaseId: activeCurrentPhaseId });
    }
  }, []);

  const isPhaseFromNavigatedReference =
    navigatedExerciseReference?.phaseId === phase.id;

  useEffect(() => {
    // on click on reference in chat - to get and open phase(phaseId from reference)
    // if those phase wasn't loaded previously, and it is closed currently
    if (isPhaseFromNavigatedReference && !isShowAccordionBody && !phaseData) {
      getCurrentPhaseMutation({
        phaseId: navigatedExerciseReference.phaseId,
      });
    }
    // on click on reference in chat - just to open phase(phaseId from reference)
    // if those phase WAS loaded previously, and it is closed currently
    if (isPhaseFromNavigatedReference && phaseData && !isShowAccordionBody) {
      setShowAccordionBody(true);
    }
  }, [navigatedExerciseReference?.phaseId]);

  useEffect(() => {
    // to destroy navigatedExerciseReference after phaseId
    // from navigatedExerciseReference was collapsed
    if (isPhaseFromNavigatedReference && !isShowAccordionBody) {
      setNavigatedExerciseReference(null);
    }
  }, [isShowAccordionBody]);

  const openModal = (modalType: string) => setModalType(modalType);
  const closeModal = () => setModalType(null);

  const deleteLocalPhaseHandler = () => {
    if (isChatPage()) {
      gaEvents.chatSubmitDeletePhase();
    } else {
      gaEvents.submitDeletePhase();
    }

    closeModal();
    onDeletePhase(phase.id);
  };

  const openDeletingPhaseModalHandler = (e: TEventOnClick) => {
    e.stopPropagation();
    e.preventDefault();
    openModal('deletePhase');

    if (isChatPage()) {
      gaEvents.chatOpenDeletePhaseModal();
    } else {
      gaEvents.openDeletePhaseModal();
    }
  };

  const duplicatePhaseHandler = (e: TEventOnClick) => {
    e.stopPropagation();

    if (isChatPage()) {
      gaEvents.chatClickDuplicatePhaseButton();
    } else {
      gaEvents.clickDuplicatePhaseButton();
    }
    duplicatePhaseMutation(phase.id);
  };

  const hoveredPhaseOnMouseEnterHandler = () => {
    setHoveredPhase(phaseIndex);
  };

  const hoveredPhaseOnMouseLeaveHandler = () => {
    setHoveredPhase(null);
  };

  const getCurrentPhaseHandler = () => {
    if (phaseData) {
      setShowAccordionBody((prev) => {
        if (isChatPage()) {
          gaEvents.chatClickExpandOrCollapsePhase(!prev);
        } else {
          gaEvents.clickExpandOrCollapsePhase(!prev);
        }

        return !prev;
      });
    } else {
      getCurrentPhaseMutation({ phaseId: phase.id });
    }
  };

  const programExerciseReference: TProgramExerciseReference = {
    programId: bigProgram.id,
    programName: bigProgram.name,
    weeks: phaseData?.weeks,
  };

  const loading = isLoadingCurrentPhase || isLoadingDuplicatePhase;

  return {
    phaseData,
    setPhaseData,
    getCurrentPhaseMutation,
    programExerciseReference,
    isPhaseFromNavigatedReference,
    navigatedExerciseReference,
    modalType,
    deleteLocalPhaseHandler,
    closeModal,
    hoveredPhaseOnMouseEnterHandler,
    hoveredPhaseOnMouseLeaveHandler,
    getCurrentPhaseHandler,
    hoveredPhase,
    setHoveredPhase,
    openDeletingPhaseModalHandler,
    duplicatePhaseHandler,
    isShowAccordionBody,
    loading,
  };
};
