import React, { memo, forwardRef } from "react";
import { TextareaAutosizeProps } from "@mui/material/TextareaAutosize";
import S, { TResize } from "./Textarea.styled";

type Props = TextareaAutosizeProps & {
  label?: string;
  errorMessage?: string;
  isRequired?: boolean;
  resize?: TResize;
};

const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(
    ({
       label,
       errorMessage,
       isRequired,
       resize,
       ...props
     }, ref) => (
      <S.Wrapper>
        {label && (<S.Label isRequired={isRequired}>{label}</S.Label>)}

        <S.TextareaWrapper className="textareaWrapper">
          <S.Textarea {...props} resize={resize} ref={ref} />
        </S.TextareaWrapper>

        {errorMessage && <span className="errorMessage">{errorMessage}</span>}
      </S.Wrapper>
    )
  )
);

Textarea.displayName = "Textarea";

export default Textarea;
