import { styled } from '@mui/material';
import { exerciseCounter } from 'containers/app/trainer/currentClient/clientPhases';

const Wrapper = styled('div')<{
  index: number;
}>`
  background-color: ${({ index }) =>
  exerciseCounter[index % exerciseCounter.length].color};
  position: relative;
  display: flex;
  align-items: center;
  padding: 9px;
  border-radius: 4px;
  line-height: 14px;
  cursor: default;

  font-weight: 500;
  font-size: 14px;
  color: #fff;
`;

export default { Wrapper }