import React, { FC, useEffect, useState } from 'react';
import Loader from 'components/loader/Loader';
import ConfirmModal from 'components/modal/confirmModal';
import ProgressBar from 'components/progressBar';
import { LockUnlockPhase } from '../../../../lockUnlockPhase/LockUnlockPhase';
import { DONE_PROGRESS_COUNT } from 'constants/index';
import { useHandlers } from '../../../biggerProgram/biggerProgramPhase/hooks/useHandlers';
import { TooltipActions } from './components/TooltipActions';
import { Tooltip } from 'components/tooltip/Tooltip';
import { SaveTemplate } from '../../../biggerProgram/biggerProgramPhase/saveTemplate/SaveTemplate';

import type { IBiggerProgramPhaseProps } from '../../../biggerProgram/biggerProgramPhase/models';
import type { TBiggerPhase } from '../../../../../models';

import { DoneWithSuccessIc } from 'assets/svg';
import S from './Phase.styled';

interface IPhaseProps extends IBiggerProgramPhaseProps {
  setActivePhase: (phase: TBiggerPhase | null) => void;
  isActive: boolean;
}

export const Phase: FC<IPhaseProps> = ({
  setActivePhase,
  isActive,
  ...props
}) => {
  const [isSaveTemplateModalShown, setIsSaveTemplateModalShown] =
    useState(false);
  const {
    phaseData,
    hoveredPhase,
    setHoveredPhase,
    hoveredPhaseOnMouseEnterHandler,
    getCurrentPhaseHandler,
    duplicatePhaseHandler,
    hoveredPhaseOnMouseLeaveHandler,
    deleteLocalPhaseHandler,
    openDeletingPhaseModalHandler,
    modalType,
    closeModal,
    loading,
  } = useHandlers(props);

  useEffect(() => {
    if (phaseData) {
      setActivePhase(phaseData);
    }
  }, [phaseData]);

  const onGetCurrentPhase = () => {
    if (phaseData) {
      setActivePhase(phaseData);
    } else {
      getCurrentPhaseHandler();
    }
  };

  const tooltipActionsMenuButton = (
    <TooltipActions
      openDeletingPhaseModalHandler={openDeletingPhaseModalHandler}
      duplicatePhaseHandler={duplicatePhaseHandler}
      setIsSaveTemplateModalShown={setIsSaveTemplateModalShown}
    />
  );
  const tooltipStyles = {
    bottom: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '40px',
  };

  return (
    <>
      {loading && <Loader />}
      <Tooltip
        title={tooltipActionsMenuButton}
        placement={'bottom'}
        styles={tooltipStyles}
      >
        <S.Phase
          isActive={isActive}
          onMouseEnter={hoveredPhaseOnMouseEnterHandler}
          onMouseLeave={hoveredPhaseOnMouseLeaveHandler}
        >
          <div className="outerWrapper" onClick={onGetCurrentPhase}>
            <div className="progressBar">
              {props.phase.progress === DONE_PROGRESS_COUNT ? (
                <div className="progressDoneIcon">
                  <DoneWithSuccessIc />
                </div>
              ) : (
                <ProgressBar progress={props.phase.progress} size={34} />
              )}
            </div>
            <p className="outerTitle">Phase {props.phaseIndex + 1}</p>
            <div className="accessToPhaseIcon">
              {props.phase && (
                <LockUnlockPhase
                  program={props.bigProgram}
                  setDBDProgram={props.setDBDProgram}
                  hoveredPhase={hoveredPhase}
                  setHoveredPhase={setHoveredPhase}
                  phaseIndex={props.phaseIndex}
                  phase={props.phase}
                  loadCallbackData={props.loadBigProgramHandler}
                  pageId="BiggerPicture"
                />
              )}
            </div>
          </div>
        </S.Phase>
      </Tooltip>
      {modalType === 'deletePhase' && (
        <ConfirmModal
          title="Are you sure you want to delete this Phase?"
          showModal={true}
          onOk={deleteLocalPhaseHandler}
          onCancel={closeModal}
        />
      )}
      <SaveTemplate
        isSaveTemplateModalShown={isSaveTemplateModalShown}
        setIsSaveTemplateModalShown={setIsSaveTemplateModalShown}
        phaseId={props.phase.id}
      />
    </>
  );
};
