import type { TExercise } from 'components/_new/phase/models';
import type { TSelection } from 'components/_new/RadioButtonsGroup/RadioButtonsGroup';
import type { TExerciseRow, TWeek } from 'components/_new/phase/models';
import { KeyboardEvent } from 'react';

type TCatalog = TSelection & { data: TExercise['exerciseInfo'][] };
export type TExtendedExercise = TExercise & {
  catalog: TCatalog;
  isExpanded: boolean;
};

export enum catalogIdsEnum {
  admin = 0,
  trainer = 1,
}

export type TRowData = {
  sets: number;
  reps: number;
  weight: number;
  rest: string;
  rpe: string;
};

export type TChangeRowValueEventData = {
  newValue: string;
  userPressedBackspace?: boolean;
  isDeltaValue?: boolean;
};

export type TOnChangeRowValue = TChangeRowValueEventData & {
  exerciseId: TExtendedExercise['id'];
  weekName: TWeek['name'];
  rowId: TExerciseRow['id'];
  type?: 'string' | 'number' | undefined;
  rowKey: keyof TExerciseRow;
};

export type TOnChangeRowValuePayload = (data: TOnChangeRowValue) => void;

export type TExcludeSpecSymbolsForNumberInput = (
  e: KeyboardEvent<HTMLInputElement>,
  isDeltaValue?: boolean,
) => void;

export type TOnSubmitExerciseMasterRowPayload = {
  masterRowData: TRowData,
  masterRowDeltas: TRowData,
  exerciseId: TExtendedExercise['id'],
  week4NumberOfSets: number,
}

export type TWeekGroupProps = {
  weeks: TWeek[];
  onChangeRowValue: TOnChangeRowValuePayload;
  onAddSetToExercise: (
    exerciseId: TExtendedExercise['id'],
    weekName: TWeek['name'],
  ) => void;
  onSubmitRemoveLastSet: (
    exerciseId: TExtendedExercise['id'],
    weekName: TWeek['name'],
  ) => void;
  exerciseId: TExtendedExercise['id'];
  onSubmitExerciseMasterRow: (data: TOnSubmitExerciseMasterRowPayload) => void;
  modalId: string;
};