import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';

import type { TFeedbackData } from '../models';

export const useFetch = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: sendFeedbackMutation, isLoading: isLoadingSendFeedback } =
    useMutation<void, unknown, TFeedbackData>({
      mutationFn: (feedbackData) => {
        return instance.post('Feedback/create-feedback', {
          data: feedbackData,
        });
      },
      onSuccess: () => {
        enqueueSnackbar('Thank you! Your feedback was successfully sent.', {
          variant: 'success',
        });
      },
      onError: (e) => {
        enqueueSnackbar(String(e) || ERROR_MESSAGE, { variant: 'error' });
      },
    });

  return {
    sendFeedbackMutation,
    isLoadingSendFeedback,
  };
};
