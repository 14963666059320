export const calculateUserAge = (birthDate) => {
    if (birthDate) {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const m = today.getMonth() - birth.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
            age--;
        }

        return age || 'Nan';
    } else {
        return 'Nan';
    }
};

export const getQueryStringByObject = (paramObject = {}) => (Object.keys(paramObject).map((key) => (`${key}=${paramObject[key]}`)).join('&'));