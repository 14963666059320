import React, { useContext, useMemo, useState } from 'react';
import CircularProgress from 'components/_new/CircularProgress';
import Modal from 'components/_new/Modal';
import ConfirmModal from 'components/_new/Modal/sub/ConfirmModal';
import Exercise from './sub/Exercise/Exercise';
import useHandlers from './hooks/useHandlers';
import { AdminExercisesContext } from 'providers/AdminExercisesProvider';
import { TrainerExercisesContext } from 'providers/TrainerExercisesProvider';
import Accordion from '../../accordion';
import { Tooltip } from '../../tooltip/Tooltip';
import { ColoredLabel } from 'components/coloredLabel/ColoredLabel';
import {
  CREATE_EXERCISE_MODAL_ID,
  EDIT_COMPLEX_EXERCISES_MODAL_ID,
} from 'containers/app/trainer/currentClient/clientPhases/biggerProgram/PhaseContent/constants';

import { catalogIdsEnum } from './models';
import type { TExtendedExercise } from './models';
import type { TExercise } from '../phase/models';
import type { TCreateExerciseModalData } from 'containers/app/trainer/currentClient/clientPhases/biggerProgram/PhaseContent/models';
import type { TSelection } from 'components/_new/RadioButtonsGroup/RadioButtonsGroup';

import { DeleteIc } from 'assets/svg';
import S from './ManageComplexExerciseModal.styled';

type Props = {
  title: string;
  modalId?: string;
  isLoading: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onOutsideClose?: () => void;
  existExercises?: TExercise[];
  isSupportingMultipleExercises?: boolean;
  onSubmit: (exercises: TExtendedExercise[]) => void;
  excludedExerciseIDs?: TCreateExerciseModalData['excludedExerciseIDs'];
  letter?: string;
  complexExerciseOrderNumber?: number;
  infoTitle?: string;
};

const MODAL_STYLES = { width: '95%', maxWidth: '780px', maxHeight: '98vh' };

const ManageComplexExerciseModal = ({
  title,
  modalId = title,
  onClose,
  onOutsideClose = onClose,
  onCancel = onClose,
  onSubmit,
  isLoading,
  existExercises,
  isSupportingMultipleExercises = true,
  excludedExerciseIDs,
  letter,
  complexExerciseOrderNumber,
  infoTitle = '',
}: Props) => {
  const [exercises, setExercises] = useState<TExtendedExercise[]>([]);
  const [deleteExerciseModalData, setDeleteExerciseModalData] =
    useState<TExercise | null>(null);

  const { items: trainerExercises } = useContext(TrainerExercisesContext);
  const filteredTrainerExercises = useMemo(
    () =>
      excludedExerciseIDs?.length
        ? trainerExercises?.filter(
            (exercise) => !excludedExerciseIDs.includes(exercise.id),
          )
        : trainerExercises,
    [excludedExerciseIDs, trainerExercises],
  );

  const { items: adminExercises } = useContext(AdminExercisesContext);
  const filteredAdminExercises = useMemo(
    () =>
      excludedExerciseIDs?.length
        ? adminExercises?.filter(
            (exercise) => !excludedExerciseIDs.includes(exercise.id),
          )
        : adminExercises,
    [excludedExerciseIDs, adminExercises],
  );

  const catalogOptions: Record<
    catalogIdsEnum,
    TSelection & { data: TExercise['exerciseInfo'][] }
  > = {
    [catalogIdsEnum.admin]: {
      id: catalogIdsEnum.admin,
      title: 'Admin Exercises',
      data: filteredAdminExercises,
    },
    [catalogIdsEnum.trainer]: {
      id: catalogIdsEnum.trainer,
      title: 'My Exercises',
      data: filteredTrainerExercises,
    },
  };

  const initExerciseInfoData = catalogOptions[catalogIdsEnum.admin];
  // find first not used exercise to append to the superset
  const availableExerciseInfo =
    initExerciseInfoData.data.find(
      (element) =>
        !exercises.some((excluded) => excluded.exerciseInfo.id === element.id),
    ) || initExerciseInfoData.data[0];
  const initExercise: TExtendedExercise = {
    isExpanded: true,
    catalog: initExerciseInfoData,
    isCompleted: false,
    orderNumber: 0,
    type: 0,
    exerciseInfo: availableExerciseInfo,
    id: 0,
    description: '',
    weeks: new Array(4).fill(null).map((_, i) => ({
      exerciseId: 0,
      isCompleted: false,
      isDeleted: false,
      name: `Week ${i + 1}`,
      progress: 0,
      id: i + 1,
      exerciseRows: [{ id: i + 1, reps: 1, rest: '', sets: 1, weight: 0 }],
    })),
  };

  const {
    addSetHandler,
    removeLastSetHandler,
    changeCatalogHandler,
    changeRowValueHandler,
    appendExerciseHandler,
    changeDescriptionHandler,
    changeExerciseInfoHandler,
    handleSubmitExerciseMasterRow,
    changeExpandedItemHandler,
  } = useHandlers({
    setExercises,
    catalogOptions,
    initExercise,
    modalId,
    existExercises,
    exercises,
  });

  const handleSubmit = () => onSubmit(exercises);

  const exercisesName =
    existExercises?.length && letter
      ? (existExercises?.length || 0) > 1
        ? `${letter}1 - ${letter}${existExercises?.length}`
        : `${letter}1`
      : '';

  const accordionTitle = ({
    exercise,
    exerciseIndex,
  }: {
    exercise: TExercise;
    exerciseIndex: number;
  }) => {
    const oldExerciseData =
      existExercises?.find(
        (existExercise) => existExercise.id === exercise.id,
      ) || exercise;

    const deleteExerciseHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setDeleteExerciseModalData(exercise);
    };

    return (
      <div className="exerciseShortRow">
        <p className="identifier">
          Exercise {letter}
          {exerciseIndex + 1}
          {' - '}
        </p>
        <Tooltip title={exercise.exerciseInfo.name}>
          <p className="name">{oldExerciseData?.exerciseInfo.name}</p>
        </Tooltip>
        <button className="deleteButton" onClick={deleteExerciseHandler}>
          <DeleteIc className="deleteIcon" />
        </button>
      </div>
    );
  };

  const onSubmitDeleteExercise = () => {
    if (deleteExerciseModalData) {
      setExercises((prevExercises) => {
        return prevExercises.filter(
          (exercise) =>
            exercise.exerciseInfo.id !==
              deleteExerciseModalData.exerciseInfo.id &&
            exercise.orderNumber !== deleteExerciseModalData.orderNumber,
        );
      });
    }

    closeDeleteExerciseModal();
  };

  const closeDeleteExerciseModal = () => {
    setDeleteExerciseModalData(null);
  };

  const isSubmitButtonDisabled =
    !exercises.length && modalId === CREATE_EXERCISE_MODAL_ID;

  const addExerciseButton = (
    <button
      className="addExerciseButton"
      onClick={appendExerciseHandler}
      type="button"
    >
      + Add exercise
    </button>
  );
  const addExerciseButtonBasedOnModalType =
    !exercises.length && modalId === EDIT_COMPLEX_EXERCISES_MODAL_ID ? (
      <div className="noExercises">
        <p>
          There is no exercise in this superset. To add exercise click on the
          button below.
        </p>
        {addExerciseButton}
        <div className="separator"></div>
        <p>
          <span className="span">Note:</span> if you click on “Submit” with an
          empty superset, it will be deleted.
        </p>
      </div>
    ) : isSupportingMultipleExercises ? (
      addExerciseButton
    ) : (
      ''
    );

  if (isLoading) {
    return (
      <Modal onClose={onClose} show={true}>
        <S.Loader>
          <CircularProgress />
        </S.Loader>
      </Modal>
    );
  }

  return (
    <Modal
      onClose={onClose}
      onCloseOnOutsideClick={onOutsideClose}
      show={true}
      style={MODAL_STYLES}
    >
      <S.ModalContent>
        <S.Header>
          {title}
          {exercisesName && (
            <div className="coloredLabelWrapper">
              <ColoredLabel index={complexExerciseOrderNumber || 0}>
                {exercisesName}
              </ColoredLabel>
            </div>
          )}
        </S.Header>
        {infoTitle && <p className="infoTitle">{infoTitle}</p>}
        <S.Body>
          {exercises.map((exercise, exerciseIndex) => (
            <div className="exerciseWrapper" key={exerciseIndex}>
              <Accordion
                title={accordionTitle({ exercise, exerciseIndex })}
                expanded={exercise.isExpanded}
                onChange={() => changeExpandedItemHandler(exerciseIndex)}
              >
                <Exercise
                  exercise={exercise}
                  exerciseIndex={exerciseIndex}
                  catalogOptions={catalogOptions}
                  onAddSetToExercise={addSetHandler}
                  onChangeCatalog={changeCatalogHandler}
                  onSubmitRemoveLastSet={removeLastSetHandler}
                  onChangeRowValue={changeRowValueHandler}
                  onChangeDescription={changeDescriptionHandler}
                  showExerciseName={false}
                  onChangeExerciseInfo={changeExerciseInfoHandler}
                  trainerExercisesOptions={filteredTrainerExercises}
                  onSubmitExerciseMasterRow={handleSubmitExerciseMasterRow}
                  modalId={modalId}
                  exercises={exercises}
                />
              </Accordion>
            </div>
          ))}
          {addExerciseButtonBasedOnModalType}
        </S.Body>

        <S.ActionButtons>
          <button className="actionButton cancelButton" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="actionButton addButton"
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </S.ActionButtons>
        {deleteExerciseModalData && (
          <ConfirmModal
            show={true}
            title="Are you sure you want to delete this exercise?"
            onCancel={closeDeleteExerciseModal}
            onSubmit={onSubmitDeleteExercise}
            submitButtonText="Remove"
            isSubmitButtonDangerous
          />
        )}
      </S.ModalContent>
    </Modal>
  );
};

export default ManageComplexExerciseModal;
