import ReactGA from 'react-ga4';

const gaEvents = {
  // -------- < Program Events >-------------- //
  clickEditProgramButton: () => ReactGA.event('click_edit_program_button'),
  clickDBDButton: () => ReactGA.event('click_DBD_button'),
  clickBiggerPictureButton: () => ReactGA.event('click_bigger_picture_button'),
  // -------- </ Program Events >-------------- //

  // -------- < Bigger Picture Phases Screen Events >-------------- //
  openAddPhaseMenu: () => ReactGA.event('open_add_phase_menu'),
  clickTopAddPhaseButton: () => ReactGA.event('click_top_add_phase_button'),
  clickBottomAddPhaseButton: () =>
    ReactGA.event('click_bottom_add_phase_button'),
  openDeletePhaseModal: () => ReactGA.event('open_delete_phase_modal'),
  submitDeletePhase: () => ReactGA.event('submit_delete_phase'),
  clickDuplicatePhaseButton: () =>
    ReactGA.event('click_duplicate_phase_button'),
  clickExpandOrCollapsePhase: (toExpand: boolean) =>
    ReactGA.event('click_expand_or_collapse_phase', { toExpand }),

  clickOpenImportTemplateModalFromTop: () =>
    ReactGA.event('click_open_import_template_modal_from_top'),
  clickOpenImportTemplateModalFromBottom: () =>
    ReactGA.event('click_open_import_template_modal_from_bottom'),
  closeImportTemplateModal: () => ReactGA.event('close_import_template_modal'),
  outsideCloseImportTemplateModal: () =>
    ReactGA.event('outside_close_import_template_modal'),

  clickDuplicateDayButton: () => ReactGA.event('click_duplicate_day_button'),
  clickAddDayButton: () => ReactGA.event('click_add_day_button'),
  submitDeleteDay: () => ReactGA.event('submit_delete_day'),
  openDeleteDayModal: () => ReactGA.event('open_delete_day_modal'),
  closeDeleteDayModal: () => ReactGA.event('close_delete_day_modal'),
  outsideCloseDeleteDayModal: () =>
    ReactGA.event('outside_close_delete_day_modal'),
  cancelDeleteDayModal: () => ReactGA.event('cancel_delete_day_modal'),

  clickEditExerciseButton: () => ReactGA.event('click_edit_exercise_button'),
  clickDeleteExerciseButton: () =>
    ReactGA.event('click_delete_exercise_button'),
  clickDeleteComplexExerciseButton: () =>
    ReactGA.event('click_delete_complex_exercise_button'),
  clickRearrangeSuperSetButton: ({ up }: { up: boolean }) =>
    ReactGA.event('click_rearrange_super_set_button', { up }),
  clickAddExerciseButton: () => ReactGA.event('click_add_exercise_button'),

  openSaveAsTemplateModal: () => ReactGA.event('open_save_as_template_modal'),
  closeSaveAsTemplateModal: () => ReactGA.event('close_save_as_template_modal'),
  outsideCloseSaveAsTemplateModal: () =>
    ReactGA.event('outside_close_save_as_template_modal'),
  submitSaveAsTemplate: () => ReactGA.event('submit_save_as_template'),

  openQuickMessageModalFromBP: () =>
    ReactGA.event('open_quick_message_modal_from_bp'),
  closeQuickMessageModalFromBP: () =>
    ReactGA.event('close_quick_message_modal_from_bp'),
  sendQuickMessageFromBP: () => ReactGA.event('send_quick_message_from_bp'),
  // -------- </ Bigger Picture Phases Screen Events >-------------- //

  // -------- < Add Exercise Modal Events >-------------- //
  openCreateExerciseModal: () => ReactGA.event('open_create_exercise_modal'),
  closeCreateExerciseModal: () => ReactGA.event('close_create_exercise_modal'),
  cancelCreateExerciseModal: () =>
    ReactGA.event('cancel_create_exercise_modal'),
  outsideCloseCreateExerciseModal: () =>
    ReactGA.event('outside_close_create_exercise_modal'),
  submitAddExerciseModal: () => ReactGA.event('submit_add_exercise_modal'),
  // -------- </ Add Exercise Modal Events >-------------- //

  // -------- < Edit Complex/Single Exercise Modal Events >-------------- //
  openEditComplexExerciseModal: () =>
    ReactGA.event('open_edit_complex_exercise_modal'),
  closeEditComplexExerciseModal: () =>
    ReactGA.event('close_edit_complex_exercise_modal'),
  cancelEditComplexExerciseModal: () =>
    ReactGA.event('cancel_edit_complex_exercise_modal'),
  outsideCloseEditComplexExerciseModal: () =>
    ReactGA.event('outside_close_edit_complex_exercise_modal'),
  submitEditComplexExerciseModal: () =>
    ReactGA.event('submit_add_exercise_modal'),
  closeEditExerciseModal: () => ReactGA.event('close_edit_exercise_modal'),
  submitEditExerciseModal: () => ReactGA.event('submit_edit_exercise_modal'),
  // -------- </ Edit Exercise Modal Events >-------------- //

  // -------- < common Add and Edit Complex/Single Exercise Modal Events >-------------- //
  selectAdminExerciseCatalogOnAddOrEditComplexExerciseModal: (
    modalType: string,
  ) =>
    ReactGA.event(
      'select_admin_exercise_catalog_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise
  selectMyExerciseCatalogOnAddOrEditComplexExerciseModal: (modalType: string) =>
    ReactGA.event(
      'select_my_exercise_catalog_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise
  chooseExerciseFromDropdownOnAddOrEditComplexExerciseModal: (
    modalType: string,
  ) =>
    ReactGA.event(
      'choose_exercise_selector_from_dropdown_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise
  focusOnDescriptionOnAddOrEditComplexExerciseModal: (modalType: string) =>
    ReactGA.event(
      'focus_on_description_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise

  openAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: (
    modalType: string,
  ) =>
    ReactGA.event(
      'open_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
      { modalType },
    ), // createExercise | editExercise
  cancelAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'cancel_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  closeAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'close_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  outsideCloseAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'outside_close_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  submitAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'submit_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),

  clickRemoveSetButtonOnAddOrEditComplexExerciseModal: () =>
    ReactGA.event(
      'click_remove_set_button_on_add_or_edit_complex_exercise_modal',
    ),
  clickAddSetButtonOnAddOrEditComplexExerciseModal: () =>
    ReactGA.event('click_add_set_button_on_add_or_edit_complex_exercise_modal'),
  openRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'open_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  cancelRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'cancel_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  closeRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'close_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  outsideCloseRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'outside_cancel_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  // -------- </ common Add and Edit Complex/Single Exercise Modal Events >-------------- //

  // -------- < Banners Events >-------------- //
  openFeedbackModalFromBanner: () =>
    ReactGA.event('open_feedback_modal_from_banner'),
  clickSkipFeedbackButtonOnBanner: () =>
    ReactGA.event('click_skip_feedback_button_on_banner'),
  // -------- </ Banners Events >-------------- //

  // -------- < Clients page Events >-------------- //
  clickAddClientButton: () => ReactGA.event('click_add_client_button'),
  closeAddClientModal: () => ReactGA.event('close_add_client_modal'),
  useSortBySelect: (value: string) =>
    ReactGA.event('use_sort_by_select', { value }),
  clickFocusOnClientsSearchField: () =>
    ReactGA.event('click_focus_on_clients_search_field'),
  openActivationClientModal: () =>
    ReactGA.event('open_activation_client_modal'),
  closeOnCancelActivationModal: () =>
    ReactGA.event('close_on_cancel_activation_modal'),
  closeOnIconActivationModal: () =>
    ReactGA.event('close_on_icon_activation_modal'),
  closeOnOutsideClickActivationModal: () =>
    ReactGA.event('close_on_outside_click_activation_modal'),
  changeClientActivationStatus: (activated: boolean) =>
    ReactGA.event('change_client_activation_status', { activated }),
  // -------- </ Clients page Events >-------------- //

  // -------- < Current client page Events >-------------- //
  clickSaveNotesButton: () => ReactGA.event('click_save_notes_button'),
  clickCreateNewProgramButton: () =>
    ReactGA.event('click_create_new_program_button'),
  clickCreateBlankTemplateButton: () =>
    ReactGA.event('click_create_blank_template_button'),
  openDeleteCurrentProgramModal: () =>
    ReactGA.event('open_delete_program_modal'),
  openDeletePastProgramModal: () =>
    ReactGA.event('open_delete_current_program_modal'),
  openDeleteDraftProgramModal: () =>
    ReactGA.event('open_delete_draft_program_modal'),
  cancelDeleteProgramModal: () => ReactGA.event('cancel_delete__program_modal'),
  closeDeleteProgramModal: () => ReactGA.event('close_delete_program_modal'),
  outsideCloseDeleteProgramModal: () =>
    ReactGA.event('outside_close_delete_program_modal'),
  submitDeleteProgram: () => ReactGA.event('submit_delete_program'),

  openPublishConfirmationModal: () =>
    ReactGA.event('open_publish_confirmation_modal'),
  closePublishProgramModal: () => ReactGA.event('close_publish_program_modal'),
  outsideClosePublishProgramModal: () =>
    ReactGA.event('outside_close_publish_program_modal'),
  cancelPublishProgramModal: () =>
    ReactGA.event('cancel_publish_program_modal'),
  submitPublishProgram: () => ReactGA.event('submit_publish_program'),

  submitLockPhase: (shouldLock: boolean) =>
    ReactGA.event('submit_lock_phase', { shouldLock }),
  openLockPhaseModal: ({
    goingToLock,
    pageId,
  }: {
    goingToLock: boolean;
    pageId: string;
  }) => ReactGA.event(`open_lock_phase_modal_from_${pageId}`, { goingToLock }),
  cancelLockPhaseModal: () => ReactGA.event('cancel_lock_phase_modal'),
  closeLockPhaseModal: () => ReactGA.event('close_lock_phase_modal'),
  outsideCloseLockPhaseModal: () =>
    ReactGA.event('outside_close_lock_phase_modal'),

  clickOnCurrentProgram: (params: { clientId: string; programId: number }) =>
    ReactGA.event('click_on_current_program', params),
  clickOnDraftProgram: (params: { clientId: string; programId: number }) =>
    ReactGA.event('click_on_draft_program', params),
  clickOnPastProgram: (params: { clientId: string; programId: number }) =>
    ReactGA.event('click_on_past_program', params),
  // -------- </ Current client page Events >-------------- //

  // -------- < Sidebar >-------------- //
  clickOnSidebarItem: (params: { pageName: string }) =>
    ReactGA.event('click_on_sidebar_item', params),
  clickCollapseExpandSidebarItem: (params: {
    pageName: string;
    isExpanded: boolean;
  }) => ReactGA.event('click_collapse_expand_sidebar_item', params),
  clickOnDocumentationLink: () => ReactGA.event('close_on_documentation_link'),
  clickOnExpandCollapseSidebar: (params: { toExpand: boolean }) =>
    ReactGA.event('click_on_expand_collapse_sidebar', params),
  // -------- </ Sidebar >-------------- //

  // -------- < Header >-------------- //
  openFeedbackModalFromHeader: () =>
    ReactGA.event('open_feedback_modal_from_header'),
  closeSendFeedbackModal: () => ReactGA.event('close_send_feedback_modal'),
  cancelSendFeedbackModal: () => ReactGA.event('cancel_send_feedback_modal'),
  outsideCloseSendFeedbackModal: () =>
    ReactGA.event('outside_cancel_send_feedback_modal'),
  submitSendFeedback: () => ReactGA.event('submit_send_feedback'),

  openOnboardingFromHeader: () => ReactGA.event('open_onboarding_from_header'),
  openNotificationsFromHeader: () =>
    ReactGA.event('open_notifications_from_header'),
  clickOnNotificationItemFromList: (params: { isRead: boolean }) =>
    ReactGA.event('click_on_notification_item_from_list', params),
  clickOnProfileActionsArrowFromHeader: () =>
    ReactGA.event('click_on_profile_actions_arrow'),
  clickOnArrowWithBackRouteFromHeader: (params: { goTo: string }) =>
    ReactGA.event('click_on_profile_actions_arrow', params),

  openLogoutModalFromHeader: () =>
    ReactGA.event('open_logout_modal_from_header'),
  closeLogoutModalFromHeader: () =>
    ReactGA.event('close_logout_modal_from_header'),
  outsideCloseLogoutModalFromHeader: () =>
    ReactGA.event('outside_close_logout_modal_from_header'),
  cancelLogoutModalFromHeader: () =>
    ReactGA.event('cancel_logout_modal_from_header'),
  submitLogoutFromHeader: () => ReactGA.event('submit_logout_from_header'),

  openPersonalBrandingModalFromHeader: () =>
    ReactGA.event('open_personal_branding_modal_from_header'),
  closePersonalBrandingModalFromHeader: () =>
    ReactGA.event('close_personal_branding_modal_from_header'),
  outsideClosePersonalBrandingModalFromHeader: () =>
    ReactGA.event('outside_close_personal_branding_modal_from_header'),

  openChangePasswordModalFromHeader: () =>
    ReactGA.event('open_change_password_modal_from_header'),
  closeChangePasswordModalFromHeader: () =>
    ReactGA.event('close_change_password_modal_from_header'),
  outsideCloseChangePasswordModalFromHeader: () =>
    ReactGA.event('outside_close_change_password_modal_from_header'),
  submitSaveChangePasswordFromHeader: () =>
    ReactGA.event('submit_save_change_password_from_header'),

  openEditProfileModalFromHeader: () =>
    ReactGA.event('open_edit_profile_modal_from_header'),
  closeEditProfileModalFromHeader: () =>
    ReactGA.event('close_edit_profile_modal_from_header'),
  outsideCloseEditProfileModalFromHeader: () =>
    ReactGA.event('outsideClose_edit_profile_modal_from_header'),
  submitSaveEditProfileFromHeader: () =>
    ReactGA.event('submit_save_edit_profile_from_header'),
  // -------- </ Header >-------------- //

  // -------- < Chat page >-------------- //
  // Main chat page
  chatFocusOnSearchField: () => ReactGA.event('chat_focus_on_search_field'),
  chatChooseClientFromList: () => ReactGA.event('chat_choose_client_from_list'),
  chatGoBackToClientsList: () => ReactGA.event('chat_go_back_to_clients_list'),
  chatClickOnExerciseReference: () =>
    ReactGA.event('chat_click_on_exercise_reference'),
  chatFocusOnTypeMessageInput: () =>
    ReactGA.event('chat_focus_on_type_message_input'),
  chatClickSendMessageButton: () =>
    ReactGA.event('chat_click_send_message_button'),
  chatSendMessageOnEnter: () => ReactGA.event('chat_send_message_on_enter'),
  chatActivateExerciseReference: () =>
    ReactGA.event('chat_activate_exercise_reference'),
  chatDeactivateExerciseReference: () =>
    ReactGA.event('chat_deactivate_exercise_reference'),
  chatClickExpandButton: () => ReactGA.event('chat_click_expand_button'),
  chatClickCollapseButton: () => ReactGA.event('chat_click_collapse_button'),
  chatGoToUsersProfile: () => ReactGA.event('chat_go_to_users_profile'),

  // Bigger picture section
  chatClickEditProgramButton: () =>
    ReactGA.event('chat_click_edit_program_button'),
  chatOpenAddPhaseMenu: () => ReactGA.event('chat_open_add_phase_menu'),
  chatClickTopAddPhaseButton: () =>
    ReactGA.event('chat_click_top_add_phase_button'),
  chatClickBottomAddPhaseButton: () =>
    ReactGA.event('chat_click_bottom_add_phase_button'),
  chatClickOpenImportTemplateModalFromTop: () =>
    ReactGA.event('chat_click_open_import_template_modal_from_top'),
  chatClickOpenImportTemplateModalFromBottom: () =>
    ReactGA.event('chat_click_open_import_template_modal_from_bottom'),
  chatClickExpandOrCollapsePhase: (toExpand: boolean) =>
    ReactGA.event('chat_click_expand_or_collapse_phase', { toExpand }),
  chatClickDuplicatePhaseButton: () =>
    ReactGA.event('chat_click_duplicate_phase_button'),
  chatOpenDeletePhaseModal: () => ReactGA.event('chat_open_delete_phase_modal'),
  chatSubmitDeletePhase: () => ReactGA.event('chat_submit_delete_phase'),

  // BP lock\unlock
  chatSubmitLockPhase: (shouldLock: boolean) =>
    ReactGA.event('chat_submit_lock_phase', { shouldLock }),
  chatOpenLockPhaseModal: ({
    goingToLock,
    pageId,
  }: {
    goingToLock: boolean;
    pageId: string;
  }) =>
    ReactGA.event(`chat_open_lock_phase_modal_from_${pageId}`, { goingToLock }),
  chatCancelLockPhaseModal: () => ReactGA.event('chat_cancel_lock_phase_modal'),
  chatCloseLockPhaseModal: () => ReactGA.event('chat_close_lock_phase_modal'),
  chatOutsideCloseLockPhaseModal: () =>
    ReactGA.event('chat_outside_close_lock_phase_modal'),

  // BP save as templates
  chatOpenSaveAsTemplateModal: () =>
    ReactGA.event('chat_open_save_as_template_modal'),
  chatCloseSaveAsTemplateModal: () =>
    ReactGA.event('chat_close_save_as_template_modal'),
  chatOutsideCloseSaveAsTemplateModal: () =>
    ReactGA.event('chat_outside_close_save_as_template_modal'),
  chatSubmitSaveAsTemplate: () => ReactGA.event('chat_submit_save_as_template'),

  // BP quick message
  chatClickAttachExerciseReferenceToMessage: () =>
    ReactGA.event('chat_click_attach_exercise_reference_to_message'),

  // BP days
  chatClickDuplicateDayButton: () =>
    ReactGA.event('chat_click_duplicate_day_button'),
  chatClickAddDayButton: () => ReactGA.event('chat_click_add_day_button'),
  chatSubmitDeleteDay: () => ReactGA.event('chat_submit_delete_day'),
  chatOpenDeleteDayModal: () => ReactGA.event('chat_open_delete_day_modal'),
  chatCloseDeleteDayModal: () => ReactGA.event('chat_close_delete_day_modal'),
  chatOutsideCloseDeleteDayModal: () =>
    ReactGA.event('chat_outside_close_delete_day_modal'),
  chatCancelDeleteDayModal: () => ReactGA.event('chat_cancel_delete_day_modal'),
  chatClickRearrangeSuperSetButton: ({ up }: { up: boolean }) =>
    ReactGA.event('click_rearrange_super_set_button', { up }),
  chatClickAddExerciseButton: () => ReactGA.event('click_add_exercise_button'),

  // BP edit complex exercise (superset)
  chatOpenEditComplexExerciseModal: () =>
    ReactGA.event('chat_open_edit_complex_exercise_modal'),
  chatCloseEditComplexExerciseModal: () =>
    ReactGA.event('chat_close_edit_complex_exercise_modal'),
  chatCancelEditComplexExerciseModal: () =>
    ReactGA.event('chat_cancel_edit_complex_exercise_modal'),
  chatOutsideCloseEditComplexExerciseModal: () =>
    ReactGA.event('chat_outside_close_edit_complex_exercise_modal'),
  chatSubmitEditComplexExerciseModal: () =>
    ReactGA.event('chat_submit_add_exercise_modal'),

  // BP create complex exercise (superset)
  chatOpenCreateExerciseModal: () =>
    ReactGA.event('chat_open_create_exercise_modal'),
  chatCloseCreateExerciseModal: () =>
    ReactGA.event('chat_close_create_exercise_modal'),
  chatCancelCreateExerciseModal: () =>
    ReactGA.event('chat_cancel_create_exercise_modal'),
  chatOutsideCloseCreateExerciseModal: () =>
    ReactGA.event('chat_outside_close_create_exercise_modal'),
  chatSubmitAddExerciseModal: () =>
    ReactGA.event('chat_submit_add_exercise_modal'),

  // BP actions inside edit or create complex exercise (superset)
  chatSelectAdminExerciseCatalogOnAddOrEditComplexExerciseModal: (
    modalType: string,
  ) =>
    ReactGA.event(
      'chat_select_admin_exercise_catalog_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise
  chatSelectMyExerciseCatalogOnAddOrEditComplexExerciseModal: (
    modalType: string,
  ) =>
    ReactGA.event(
      'chat_select_my_exercise_catalog_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise
  chatChooseExerciseFromDropdownOnAddOrEditComplexExerciseModal: (
    modalType: string,
  ) =>
    ReactGA.event(
      'chat_choose_exercise_selector_from_dropdown_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise
  chatFocusOnDescriptionOnAddOrEditComplexExerciseModal: (modalType: string) =>
    ReactGA.event(
      'chat_focus_on_description_on_add_or_edit_complex_exercise_modal',
      { modalType },
    ), // createExercise | editExercise

  chatOpenAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: (
    modalType: string,
  ) =>
    ReactGA.event(
      'chat_open_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
      { modalType },
    ), // createExercise | editExercise
  chatCancelAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_cancel_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  chatCloseAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_close_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  chatOutsideCloseAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_outside_close_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  chatSubmitAddToFourWeeksAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_submit_add_to_four_weeks_on_add_or_edit_complex_exercise_confirmation_modal',
    ),

  chatClickRemoveSetButtonOnAddOrEditComplexExerciseModal: () =>
    ReactGA.event(
      'chat_click_remove_set_button_on_add_or_edit_complex_exercise_modal',
    ),
  chatClickAddSetButtonOnAddOrEditComplexExerciseModal: () =>
    ReactGA.event(
      'chat_click_add_set_button_on_add_or_edit_complex_exercise_modal',
    ),
  chatOpenRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_open_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  chatCancelRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_cancel_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  chatCloseRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_close_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  chatOutsideCloseRemoveSetOnAddOrEditComplexExerciseConfirmationModal: () =>
    ReactGA.event(
      'chat_outside_cancel_remove_set_on_add_or_edit_complex_exercise_confirmation_modal',
    ),
  // -------- </ Chat page >-------------- //
};

export default gaEvents;
