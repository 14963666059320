import { useEffect, useState } from 'react';

export default function useExitPrompt(when = false) {
  const [block, setBlock] = useState(when);

  // block && console.log('[exit-prompt]: on');

  useEffect(() => {
    if (block) {
      window.onbeforeunload = function () {
        return 'Are you sure you want to exit?';
      };
    }

    return () => {
      window.onbeforeunload = null;
      // console.log('[exit-prompt]: off');
    };
  }, [block]);

  return setBlock;
}
