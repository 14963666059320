import React from 'react';

export const EMOJI_UNICODE = [
  '\u2764\uFE0F',
  '\uD83E\uDD23',
  '\uD83D\uDE2D',
  '\uD83D\uDE21',
  '\uD83D\uDCA9',
  '\uD83D\uDC4D',
  '\uD83D\uDC4E',
  '\uD83D\uDD25',
];

export const UNKNOWN_FILE_TYPE = 'Unknown file type';
export const INCORRECT_IMAGE_URL = 'Something wrong with photo url';

export const MESSAGE_ATTACHMENT_DEFAULT_FILETYPE = 0;
export const MESSAGE_ATTACHMENT_IMAGE_FILETYPE = 1;
export const MESSAGE_ATTACHMENT_VIDEO_FILETYPE = 2;

export const DEFAULT_NEW_MESSAGE = {
  attachmentUrl: '',
  createdOn: new Date().toISOString(),
  id: '9999',
  isRead: false,
  isSending: true,
};
