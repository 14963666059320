import type { IPhaseComplexExerciseProps } from '../models';

export const useHandlers = ({
  complexExercise,
  navigatedExerciseReference,
  onDeleteComplexExercise,
  dayExerciseReference,
}: Pick<
  IPhaseComplexExerciseProps,
  | 'complexExercise'
  | 'navigatedExerciseReference'
  | 'onDeleteComplexExercise'
  | 'dayExerciseReference'
>) => {
  const { exercises, letter, orderNumber, id } = complexExercise;
  const isComplexExerciseFromNavigatedReference =
    navigatedExerciseReference &&
    navigatedExerciseReference?.complexExerciseId === complexExercise.id;

  const generateComplexExerciseReferenceName = () => {
    if (exercises.length === 1) {
      // A1 or C1...
      return `${letter}1`;
    } else {
      // A1-A2 or B1-B3...
      return `${letter}1-${letter}${[exercises.length]}`;
    }
  };

  const onDeleteComplexExerciseHandler = () => {
    onDeleteComplexExercise(orderNumber);
  };

  const complexExerciseReference = dayExerciseReference && {
    ...dayExerciseReference,
    complexExerciseId: id,
    complexExerciseName: generateComplexExerciseReferenceName(),
  };
  return {
    onDeleteComplexExerciseHandler,
    complexExerciseReference,
    isComplexExerciseFromNavigatedReference,
  };
};
