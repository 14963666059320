import React from 'react';

import S from './NoMessagesScreen.styled'
import { NoMessagesIc } from '../../assets/svg';

export const NoMessagesScreen = () => (
  <S.Wrapper>
    <NoMessagesIc />
    <p className="title">Start Conversation</p>
    <p className="description">Click on the specific client to start chatting</p>
  </S.Wrapper>
)