import { RefObject, useContext } from 'react';
import { InputFocusContext } from 'providers/InputFocusProvider';

export const useInputFocus = <T, >(): RefObject<T> => {
  const context = useContext(InputFocusContext);
  if (!context) {
    throw new Error('useFocus must be used within a InputFocusProvider');
  }
  return context.inputRef;
};
