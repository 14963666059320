// TODO - to tsx/styled/useFetch
import React, { useState, useCallback } from 'react';
import CustomMenu from 'components/menu';
import { postData } from 'lib/api/utils';
import CustomButton from 'components/button';
import Modal from '@material-ui/core/Modal';
import { ReactSVG } from 'react-svg';
import TextInput from 'components/fields/textInput';
import Loader from 'components/loader/Loader';
import PropTypes from 'prop-types';
import gaEvents from 'utils/gaEvents';

const CreateProgramButton = ({
    hasProgram,
    toggleOpenNewProgramModal,
    client,
    currentClientCreateNewProgramOptions,
    loadProgramsHandler,
    buttonName,
}) => {
    const [showModal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const [newDraftProgramName, setNewDraftProgramName] = useState('');

    const toggleOpenModalWithType = useCallback((type) => {
        setModal(true);
        setModalType(type);
    }, []);

    const toggleCloseModal = useCallback(() => {
        setModal(false);
        setError('');
        setNewDraftProgramName('');
    }, []);

    const clickOnCreatingNewProgramOptionsHandler = (item) => {
        switch (item.id) {
            case 'new':
                toggleOpenNewProgramModal();
                gaEvents.clickCreateNewProgramButton();
                break;
            case 'blank':
                toggleOpenModalWithType('blankName');
                gaEvents.clickCreateBlankTemplateButton();
                break;
            default:
                break;
        }
    };

    const createBlankProgramHandler = () => {
        setLoader(true);
        toggleCloseModal();

        postData('Program/create-blank-template', {
            clientId: client?.user?.id,
            programName: newDraftProgramName,
        }).then((answer) => {
            setLoader(false);
            if (answer.success) {
                loadProgramsHandler();
            } else {
                setError(`Error - ${answer}`);
                toggleOpenModalWithType('error');
            }
        });
    };

    return (
        <div>
            {loader && <Loader />}
            <CustomMenu
                button={
                    <CustomButton
                        dataTitle={
                            hasProgram
                                ? 'You can\'t create new while client has active program'
                                : null
                        }
                        type='button'
                        text={buttonName}
                    />
                }
                clickOnMenuItem={clickOnCreatingNewProgramOptionsHandler}
                menuItems={currentClientCreateNewProgramOptions}
            />
            <Modal onClose={toggleCloseModal} open={showModal}>
                <div className='modal phases-modal'>
                    <div className='modal__close modal__body--sm'>
                        <ReactSVG onClick={toggleCloseModal} src='/assets/svg/close.svg' />
                    </div>
                    <div className='modal__body'>
                        {modalType === 'blankName' ? (
                            <div className='modal__body bigger-modal-edit'>
                                <p className='modal-title'>New blank program name</p>
                                <div className='modal-field'>
                                    <TextInput
                                        value={newDraftProgramName}
                                        type={'text'}
                                        onChange={(e) => setNewDraftProgramName(e?.target?.value)}
                                        placeholder={'name'}
                                    />
                                </div>
                                <div className='modal-buttons'>
                                    <button
                                        className='modal-buttons-cancel'
                                        onClick={toggleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className='modal-buttons-delete'
                                        onClick={createBlankProgramHandler}
                                        disabled={!newDraftProgramName}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        ) : modalType === 'error' ? (
                            <div className='modal__body'>
                                <p className='modal-message'>{error}</p>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

CreateProgramButton.propTypes = {
    client: PropTypes.shape({
        user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            createdOn: PropTypes.string.isRequired,
            phoneNumber: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }),
        birthDate: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        weight: PropTypes.number.isRequired,
        gender: PropTypes.number.isRequired,
    }).isRequired,
    toggleOpenNewProgramModal: PropTypes.func.isRequired,
    loadProgramsHandler: PropTypes.func.isRequired,
    hasProgram:PropTypes.bool.isRequired,
    currentClientCreateNewProgramOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
    })),
    buttonName: PropTypes.string,
};

CreateProgramButton.defaultProps = {
    buttonName: '+ Create new program'
};

export default CreateProgramButton;
