import { styled } from '@mui/material';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
  background-color: #222429;
  border-radius: 4px;
  padding: 16px;
`;

export default { Wrapper };
