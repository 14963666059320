import React, { FC, ReactElement } from 'react';
import gaEvents from 'utils/gaEvents';
import { Tooltip } from 'components/tooltip/Tooltip';
import { CHAT } from 'constants/tooltips';
import { ROUTES } from 'containers/app/trainer/routes';
import { Link } from 'react-router-dom';

import type { TClientWithMessages } from '../../../models';

import { ArrowUpIc, ExpandIc } from 'assets/svg';
import S from './ChatHeader.styled';

interface IChatHeaderProps {
  backToClientsListHandler?: () => void;
  user: TClientWithMessages['user'];
  expandIcon?: ReactElement;
  expandHandler: () => void;
}

export const ChatHeader: FC<IChatHeaderProps> = ({
  backToClientsListHandler,
  user,
  expandIcon = <ExpandIc />,
  expandHandler,
}) => {
  const onCallGAEvent = () => {
    gaEvents.chatGoToUsersProfile();
  };

  return (
    <S.Header>
      {backToClientsListHandler && (
        <Tooltip title={CHAT.GO_BACK}>
          <button onClick={backToClientsListHandler} className="goBackButton">
            <ArrowUpIc />
          </button>
        </Tooltip>
      )}
      <Tooltip title={CHAT.OPEN_USER_PROFILE} placement={'bottom-end'}>
        <Link
          className="userData"
          to={`${ROUTES.TRAINERS_CLIENTS}/${user.id}`}
          onClick={onCallGAEvent}
        >
          <S.UserPhoto photoUrl={user.photoUrl} className="userPhoto" />
          <p className="userName">{`${user.firstName} ${user.lastName}`}</p>
        </Link>
      </Tooltip>
      <button onClick={expandHandler} className="resize">
        {expandIcon}
      </button>
    </S.Header>
  );
};
