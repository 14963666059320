import React, { memo, useState, useMemo, ChangeEvent, useEffect } from 'react';
import ExerciseItem from 'components/exerciseItem/';
import TextInput from 'components/fields/textInput';
import useFetch from './hooks/useFetch';
import { useLayout } from 'providers/layout/hooks/useLayout';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuidv4 } from 'uuid';
import { UploadVideoField } from 'components/fields/uploadVideoField/UploadVideoField';
import ReactPlayer from 'react-player';
import { ReactSVG } from 'react-svg';

import type { TExercise } from './hooks/useFetch';

import './styles.scss';

export const SsExercisesPage = memo(() => {
  const {
    exercises,
    fetchAddAlternativeVideo,
    fetchUpdateAlternativeVideo,
    fetchDeleteAlternativeVideo,
    UploadFile,
    DeleteFile,
  } = useFetch();

  const [searchValue, setSearchValue] = useState('');

  const { initLayoutState } = useLayout();

  useEffect(() => {
    initLayoutState({
      headerTitle: 'Admin Exercises',
      headerCountTitle: exercises.data.length || 0,
    });
  }, [exercises.data, initLayoutState]);
  // ==  == //

  const filteredExercises = useMemo(
    () =>
      exercises.data.filter((exercise) =>
        exercise.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [exercises.data, searchValue],
  );

  const filterExercisesHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target?.value || '');

  const changeAlternativeVideoUrl = async (
    e: ChangeEvent<HTMLInputElement>,
    id: number 
  ) => {
    e.preventDefault();

    const file = e.target.files?.[0] || null;

    if (!file) return;
    const fileUrl = await UploadFile(file);

    if (!fileUrl) return;
    await fetchAddAlternativeVideo(id, fileUrl);
  };

  const deleteAlternativeVideoHandler = async (exercise: TExercise) => {
    await DeleteFile(exercise.alternativeVideoUrl);
    await fetchDeleteAlternativeVideo(exercise);
  };

  return (
    <div className="exercises">
      <div className="exercises-header">
        <div className="exercises-header-search">
          <TextInput
            value={searchValue}
            onChange={filterExercisesHandler}
            placeholder="Search"
            name="search"
          />
        </div>
      </div>
      <ul className="exercises-body">
        {!exercises.isLoading &&
          filteredExercises.map((exercise) => (
            <ExerciseItem
              exercise={exercise}
              key={exercise.id}
              additionalElem={
                <div className="alternative-video">
                  { exercise?.alternativeVideoUrl ? (
                    <div className='content'>
                      <ReactSVG
                        className="video-cancel"
                        src="/assets/svg/close.svg"
                        onClick={() => deleteAlternativeVideoHandler(exercise)}
                      />
                      <ReactPlayer 
                        controls
                        className="video-uploaded"
                        width="100%"
                        height="100%"
                        url={exercise.alternativeVideoUrl}
                      />
                    </div>
                  ) : (
                    <UploadVideoField
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        changeAlternativeVideoUrl(e, exercise.id)}
                    />
                  )}
                </div>
              }
            />
          ))}
        {filteredExercises.length === 0 && !exercises.isLoading && (
          <div className="not-found">No data</div>
        )}
        {exercises.isLoading &&
          new Array(exercises.data.length || 10)
            .fill(null)
            .map(() => (
              <Skeleton key={uuidv4()} className="exercises-body-skeleton" />
            ))}
      </ul>
    </div>
  );
});
