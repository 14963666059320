import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import SelectField from 'components/fields/selectField';
import Button from 'components/_new/Button';
import Loader from 'components/loader/Loader';
import Modal from 'components/_new/Modal';
import gaEvents from 'utils/gaEvents';

import type { TImportTemplatePayload, TTemplate } from '../models';
import S from './ImportTemplateModal.styled';

interface IImportTemplateModalProps {
  closeModalHandler: () => void;
  importTemplateMutation: (data: TImportTemplatePayload) => void;
  programId?: number;
}

export const ImportTemplateModal: FC<IImportTemplateModalProps> = ({
  closeModalHandler,
  importTemplateMutation,
  programId,
}) => {
  const [templateOptions, setTemplateOptions] = useState<TTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TTemplate | null>(
    null,
  );

  const { fetchTemplateOptionsMutation, isLoadingFetchTemplateOptions } =
    useFetch({
      setTemplateOptions,
      setSelectedTemplate,
    });

  useEffect(() => {
    fetchTemplateOptionsMutation();
  }, []);

  const submitImportTemplateHandler = () => {
    importTemplateMutation({
      selectedTemplateId: selectedTemplate?.id || '',
      programId: programId || 0,
    });
    closeModalHandler();
  };

  const changeTemplateHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const targetId = e.target.value;

    if (!targetId) return;
    setSelectedTemplate(
      templateOptions.find(({ id }) => id === targetId) ||
        templateOptions[0] ||
        null,
    );
  };

  const closeImportTemplateModal = () => {
    closeModalHandler();
    gaEvents.closeImportTemplateModal();
  }
  const outsideCloseImportTemplateModal = () => {
    closeModalHandler();
    gaEvents.outsideCloseImportTemplateModal();
  }

  return (
    <Modal onClose={closeImportTemplateModal} onCloseOnOutsideClick={outsideCloseImportTemplateModal} show={true}>
      <S.Wrapper>
        {isLoadingFetchTemplateOptions ? (
          <S.LoaderWrapper>
            <Loader />
          </S.LoaderWrapper>
        ) : templateOptions.length === 0 ? (
          <p className="modal-title">There are no saved templates</p>
        ) : (
          <>
            <p className="modal-title">Import Phase From Templates</p>
            <div className="modal-buttons">
              <SelectField
                options={templateOptions}
                value={selectedTemplate}
                onChange={changeTemplateHandler}
              />
            </div>
            <div className="modal-button-import">
              <Button
                disabled={!selectedTemplate}
                onClick={submitImportTemplateHandler}
              >
                Import
              </Button>
            </div>
          </>
        )}
      </S.Wrapper>
    </Modal>
  );
};
