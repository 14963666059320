import React, { ChangeEvent, useState, memo, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import ReactPlayer from 'react-player/lazy';
import Modal from '@material-ui/core/Modal';
import TextInput from 'components/fields/textInput';
import { TextArea } from 'components/fields/textArea/TextArea';
import { UploadVideoField } from 'components/fields/uploadVideoField/UploadVideoField';
import { urlRegExpPattern } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import { Tooltip } from 'components/tooltip/Tooltip';
import { EXERCISES } from 'constants/tooltips';

import type { TExercisesItem } from 'components/exerciseItem/ExerciseItem';

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit: (e: ChangeEvent<HTMLFormElement>, formData: TFormData) => void;
  exercise?: TExercisesItem;
  isLoading: boolean;
};

export type TFormData = {
  name: string;
  description: string;
  videoUrl: string;
  localFile: { url: string; file: File | null };
};

export const ModalExercise = memo(
  ({ show, onClose, onSubmit, exercise, isLoading }: Props): JSX.Element => {
    const [formData, setFormData] = useState<TFormData>({
      name: exercise?.name || '',
      description: exercise?.description || '',
      videoUrl: exercise?.videoUrl || '',
      localFile: exercise?.localFile || { url: '', file: null },
    });

    // on edit fill form with exercise data
    useEffect(() => {
      if (exercise) {
        setFormData({
          description: exercise.description || '',
          localFile: exercise.localFile || { url: '', file: null },
          name: exercise.name || '',
          videoUrl: exercise.videoUrl || '',
        });
      }
    }, [exercise]);

    const clearFormData = () =>
      setFormData({
        description: '',
        name: '',
        videoUrl: '',
        localFile: { file: null, url: '' },
      });

    const changeNameHandler = (e: ChangeEvent<HTMLInputElement>) =>
      setFormData((prev) => ({ ...prev, name: e.target.value }));

    const changeDescriptionHandler = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) =>
      setFormData((prev) => ({ ...prev, description: e.target.value }));

    const deleteVideoHandler = () =>
      setFormData((prev) => ({
        ...prev,
        videoUrl: '',
        localFile: { file: null, url: '' },
      }));

    const closeModalHandler = () => {
      onClose();
      clearFormData();
    };

    const submitFormHandler = (e: ChangeEvent<HTMLFormElement>) => {
      onSubmit(e, formData);
      clearFormData();
    };

    const isFormValid = Boolean(formData.name);

    // == old == //
    const changeVideoHandler = (
      e: ChangeEvent<HTMLInputElement>,
      url?: string,
    ) => {
      e.preventDefault();
      if (url) {
        setFormData((prev) => ({
          ...prev,
          videoUrl: e.target.value,
        }));
      } else {
        const reader = new FileReader();
        const file = e.target.files?.[0] || null;

        reader.onloadend = () => {
          setFormData((prev) => ({
            ...prev,
            localFile: {
              url: String(reader.result),
              file,
            },
          }));
        };
        file && reader.readAsDataURL(file);
      }
    };
    // ==  == //

    const body = (
      <div className={'modal-scroll'}>
        <div className="modal__close modal__body--sm">
          <ReactSVG onClick={closeModalHandler} src="/assets/svg/close.svg" />
        </div>
        <div className="modal__body">
          <>
            <p className="modal-title">{exercise ? 'Edit' : 'Add'} exercise</p>
            <form className="exercises-modal" onSubmit={submitFormHandler}>
              <div className="modal-field">
                <TextInput
                  label="Name of exercise"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={changeNameHandler}
                  required
                />
              </div>
              <div className="modal-field">
                <TextArea
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={changeDescriptionHandler}
                  rows={2}
                  cols={15}
                  placeholder={undefined}
                  required={undefined}
                />
              </div>
              {formData.localFile.url ||
              !!urlRegExpPattern.test(formData.videoUrl) ? (
                <div className="modal-video">
                  <Tooltip title={EXERCISES.DELETE_VIDEO}>
                    <ReactSVG
                      className="modal-video-cancel"
                      src="/assets/svg/close.svg"
                      onClick={deleteVideoHandler}
                    />
                  </Tooltip>
                  <ReactPlayer
                    controls
                    height={272}
                    width="auto"
                    style={{ maxHeight: '300px' }}
                    url={formData.videoUrl || formData.localFile.url}
                  />
                </div>
              ) : (
                <div className="modal-field">
                  <UploadVideoField
                    onChange={changeVideoHandler}
                  />
                </div>
              )}
              <button
                disabled={!isFormValid}
                type="submit"
                className="modal-button"
              >
                {exercise ? 'Save' : 'Add exercise'}
              </button>
            </form>
          </>
        </div>
      </div>
    );

    return (
      <Modal onClose={closeModalHandler} open={show}>
        <div className="modal exercises-modal-add">
          {isLoading ? <CircularProgress /> : body}
        </div>
      </Modal>
    );
  },
);
