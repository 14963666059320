import { routes } from '../../../router/routes';

export const ROUTES = {
  TRAINERS_CLIENTS: `${routes.MAIN}/trainers-clients`,
  CURRENT_CLIENT: `${routes.MAIN}/trainers-clients/:id`,
  CURRENT_CLIENT_PHASES: `${routes.MAIN}/trainers-clients/:id/phases/`,
  MY_EXERCISES: `${routes.MAIN}/my-exercises`,
  ADMIN_EXERCISES: `${routes.MAIN}/admin-exercises`,
  TEMPLATES: `${routes.MAIN}/templates`,
  CHAT: `${routes.MAIN}/chat`,
}