import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  
  position: absolute;
  left: -25px;
  
  padding: 4px 4px;
  border-radius: 40px;
  
  background: #1C1F21;
  border: 1px solid #3E434B;
  
`;

export default { Wrapper };
