import { Switch } from '@material-ui/core';
import { styled } from '@mui/material';

const Toggle = styled(Switch)`
  &&&{
    width: 32px;
    height: 16px;
    padding: 0px;
  }

  &&& .MuiSwitch-track {
    border-radius: 8px;
    padding: 2px;
    background: #5f6975;
  }

  &&& .MuiButtonBase-root {
    padding: 2px;
    
    &.Mui-checked {
      padding: 2px;

      & .MuiSwitch-thumb {
        background: #1C1F21;
      }

      & + .MuiSwitch-track {
        background: #FFD600;
        opacity: 1;
      }
    }
  }

  &&& .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
  }
`;

const ToggleWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ToggleLabel = styled('p')`
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-right: 8px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export default { Toggle, ToggleWrapper, ToggleLabel }