import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';
import { noop } from 'utils';

import type { TBiggerProgram, TProgram } from '../../../../models';

type TGetBPProgramResponse = {
  data: {
    item: TBiggerProgram;
  };
};

type TUseFetch = {
  programId: string;
  setBigProgram?: (program: TBiggerProgram) => void;
  onDeletePhaseSuccessCallback: () => void;
  onAddPhaseSuccessCallback: () => void;
};

export const useFetch = ({
  programId,
  setBigProgram = noop,
  onDeletePhaseSuccessCallback,
  onAddPhaseSuccessCallback,
}: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: getBPProgramMutation, isLoading: isLoadingGetBPProgram } =
    useMutation<TGetBPProgramResponse, unknown>({
      mutationFn: () => {
        return instance.get(`Program/only-phases/${programId}`);
      },
      onSuccess: ({ data: { item } }) => {
        setBigProgram(item);
      },
      onError: (e) => {
        const error = String(e) || ERROR_MESSAGE;
        enqueueSnackbar(error, { variant: 'error' });
      },
    });

  const { mutate: deletePhaseMutation, isLoading: isLoadingDeletePhase } =
    useMutation<void, unknown, number>({
      mutationFn: (phaseId) => {
        return instance.delete(`/Phase/${phaseId}`);
      },
      onSuccess: () => {
        enqueueSnackbar('Phase was successfully deleted', {
          variant: 'success',
        });
        onDeletePhaseSuccessCallback();
      },
      onError: (e) => {
        const error = String(e) || ERROR_MESSAGE;
        enqueueSnackbar(error, { variant: 'error' });
      },
    });

  const { mutate: addPhaseMutation, isLoading: isLoadingAddPhase } =
    useMutation<void, unknown, TProgram | TBiggerProgram | null>({
      mutationFn: (program) => {
        const newPhaseOrderNumber = program?.phases?.length || 1;
        return instance.post('/Phase', {
          data: {
            name: `Phase ${+newPhaseOrderNumber + 1}`,
            programId: program?.id,
            orderNumber: +newPhaseOrderNumber,
          },
        });
      },
      onSuccess: () => {
        enqueueSnackbar('Phase was successfully added', {
          variant: 'success',
        });
        onAddPhaseSuccessCallback();
      },
      onError: (e) => {
        const error = String(e) || ERROR_MESSAGE;
        enqueueSnackbar(error, { variant: 'error' });
      },
    });

  return {
    getBPProgramMutation,
    isLoadingGetBPProgram,

    deletePhaseMutation,
    isLoadingDeletePhase,

    addPhaseMutation,
    isLoadingAddPhase,
  };
};
