import styled from '@emotion/styled';
import { CHAT_INPUT_BORDERS_HEIGHT } from '../../constants';
import { css } from '@emotion/react';

const Container = styled.div<{ withTopBorder: boolean }>`
  display: flex;
  flex-direction: column;
  
  flex: 1;
  padding: 12px;
  border-top: 1px solid #3E434B;
  
  ${({ withTopBorder }) => !withTopBorder && css`
    border-top-width: 0;
    padding-top: 4px;
  `};

  & .inputWrapper {
    display: flex;
    align-items: flex-end;
    gap: 12px;
  }
  
  & .exerciseReferenceWrapper {
    display: flex;
    margin-top: 16px;
  }
`;

const AttachButton = styled.button`
  display: flex;
  align-items: center;

  padding: 6px;
  color: #6e7987;
`;

const TextArea = styled.textarea`
  flex: 1;
  overflow-y: auto;
  resize: none;

  padding: 11px;
  border: ${CHAT_INPUT_BORDERS_HEIGHT / 2}px solid #3E434B;
  border-radius: 12px;
  background: #0F1012;
  outline: none;
  color: #fff;

  font-size: 16px;
  line-height: 18px;
`;

const SendButton = styled.button`
  height: 44px;
  width: 44px;
  color: #FFEF01;
`;

export default { Container, AttachButton, TextArea, SendButton };
