import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router';
import TextInput from '../../../components/fields/textInput';
import { routes } from '../../../router/routes';
import { UserContext } from '../../../providers/userProvider';

import '../styles.scss';
import { postData } from '../../../lib/api/utils';
import Loader from '../../../components/loader/Loader';
import { ReactSVG } from 'react-svg';

const ResetPassword = ({ history }) => {
    const { user } = React.useContext(UserContext);
    const [newData, setNewData] = React.useState({
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [error, setError] = React.useState('');
    const [redirectToSignIn, setRedirectToSignIn] = React.useState(false);
    const [urlParams, setUrlParams] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (!urlParams) {
            let obj = {};
            const queryParams = history.location.search;
            const searchParams = new URLSearchParams(queryParams);

            for (let p of searchParams) {
                obj[p[0]] = p[1];
            }
            setUrlParams(obj);
        }
    }, []);

    const resetPasswordHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        const email = urlParams.email.replaceAll(' ', '+');

        postData('Account/reset-password', {
            email: email,
            code: urlParams.code,
            newPassword: newData.password,
        }).then((ans) => {
            if (ans.success) {
                setRedirectToSignIn(true);
            } else {
                setError(ans);
            }
            setLoading(false);
        });
    };

    if (redirectToSignIn) {
        return <Redirect to={routes.SIGN_IN} />;
    }

    if (user) {
        return <Redirect to='/' />;
    }

    const inputHandler = (e) => {
        setNewData({
            ...newData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className='sign-in forgot'>
            <div className='forgot-logo'>
                <ReactSVG src='/assets/svg/logo-new.svg' />
            </div>
            <form
                className='sign-in-form forgot-form'
                onSubmit={resetPasswordHandler}
            >
                <div className='sign-in-form-fields'>
                    {loading ? <Loader /> : ''}
                    <p className='forgot-title'>Create new password</p>
                    <p className='forgot-adopt'>You can create new password</p>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            placeholder='Create password'
                            type='password'
                            name='password'
                            value={newData.password}
                            onChange={inputHandler}
                        />
                    </div>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            placeholder='Confirm password'
                            type='password'
                            name='confirmPassword'
                            value={newData.confirmPassword}
                            onChange={inputHandler}
                        />
                    </div>
                    {error ? <p className='error'>{error}</p> : ''}
                    <button
                        type='submit'
                        disabled={
                            !(
                                newData.password && newData.password === newData.confirmPassword
                            )
                        }
                        className='sign-in-form-button forgot-button'
                    >
                        Reset password
                    </button>
                </div>
            </form>
        </div>
    );
};

export default withRouter(ResetPassword);
