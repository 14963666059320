import React, { useEffect, useState, useContext } from 'react';
import MainRouter from './router';
import LimitedAccessPage from 'pages/LimitedAccessPage'
import { UserContext } from './providers/userProvider';
import Loader from './components/loader/Loader';
import { getApiData } from './lib/api/utils';

function App() {
    const { setUser, user } = useContext(UserContext);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        authListener();
    }, []);

    const authListener = async () => {
        const roles = await JSON.parse(localStorage.getItem('roles'));
        const response = await getApiData('Account/me');

        if (response?.item && roles) {
            setUser({
                ...response.item,
                isTemporaryPasswordChanged: user
                    ? user.isTemporaryPasswordChanged
                    : true,
                roles: localStorage.getItem('roles')
                    ? JSON.parse(localStorage.getItem('roles'))
                    : null,
            });
        } else {
            setUser(null);
        }
        setLoggedIn(true);
    };

    if (!loggedIn) {
        return <Loader />;
    }

    if (user?.isRestrictedAccess) {
        return <LimitedAccessPage />
    }

    return <MainRouter />;
}

export default App;
