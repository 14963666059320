import React, { FC, ReactElement, useEffect, useState } from 'react';
import { ProgramHeaderRow } from '../programHeaderRow/ProgramHeaderRow';
import { ImportTemplate } from '../importTemplate/ImportTemplate';
import { useFetch as importTemplateUseFetch } from '../importTemplate/hooks/useFetch';
import { useFetch } from '../../biggerProgram/hooks/useFetch';
import gaEvents from 'utils/gaEvents';
import { AddButton } from '../addButton/AddButton';
import Loader from 'components/loader/Loader';

import type { IBiggerProgramProps } from '../../models';

interface IBiggerPictureWrapper extends IBiggerProgramProps {
  children: ReactElement;
}

export const BiggerPictureWrapper: FC<IBiggerPictureWrapper> = ({
  viewModeId,
  setViewModeId,
  bigProgram,
  setBigProgram,
  programId,
  toggleOpenEditProgramModal,
  isChatPage,
  children,
}) => {
  const [isImportTemplateModalShown, setIsImportTemplateModalShown] =
    useState(false);
  const onSuccessCallback = () => {
    getBPProgramMutation();
  };

  const { getBPProgramMutation, addPhaseMutation, isLoadingAddPhase } = useFetch({
    programId,
    setBigProgram,
    onAddPhaseSuccessCallback: onSuccessCallback,
    onDeletePhaseSuccessCallback: onSuccessCallback,
  });

  useEffect(() => {
    getBPProgramMutation();
  }, []);

  const addNewPhaseHandler = () => {
    addPhaseMutation(bigProgram);
  };
  const addNewPhaseFromBottom = () => {
    addNewPhaseHandler();
    gaEvents.clickBottomAddPhaseButton();
  };
  const addNewPhaseFromTop = () => {
    addNewPhaseHandler();

    if (isChatPage) {
      gaEvents.chatClickTopAddPhaseButton();
    } else {
      gaEvents.clickTopAddPhaseButton();
    }
  };

  const importTemplateHandler = () => {
    setIsImportTemplateModalShown(true);
  };
  const importTemplateFromBottom = () => {
    importTemplateHandler();
    if (isChatPage) {
      gaEvents.chatClickOpenImportTemplateModalFromBottom();
    } else {
      gaEvents.clickOpenImportTemplateModalFromBottom();
    }
  };
  const importTemplateFromTop = () => {
    importTemplateHandler();

    if (isChatPage) {
      gaEvents.chatClickOpenImportTemplateModalFromTop();
    } else {
      gaEvents.clickOpenImportTemplateModalFromTop();
    }
  };

  const { importTemplateMutation, isLoadingImportTemplate } = importTemplateUseFetch({
    onImportTemplateSuccessCallback: onSuccessCallback,
  });

  const loading = isLoadingImportTemplate || isLoadingAddPhase;

  return (
    <div>
      {loading && <Loader />}
      <ProgramHeaderRow
        viewModeId={viewModeId}
        setViewModeId={setViewModeId}
        program={bigProgram}
        toggleOpenEditProgramModal={toggleOpenEditProgramModal}
        addNewPhaseHandler={addNewPhaseFromTop}
        importTemplateHandler={importTemplateFromTop}
        isChatPage={isChatPage}
      />
      {children}
      <AddButton
        title="+ Add New Phase"
        onClickFunction={addNewPhaseFromBottom}
      />
      <AddButton
        title="+ Import From Templates"
        onClickFunction={importTemplateFromBottom}
      />
      {isImportTemplateModalShown && (
        <ImportTemplate
          programId={bigProgram?.id}
          setIsImportTemplateModalShown={setIsImportTemplateModalShown}
          importTemplateMutation={importTemplateMutation}
        />
      )}
    </div>
  );
};
