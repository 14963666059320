import { styled } from '@mui/material';
import {
  ellipsisTextStyles,
  transitionOnIconsHover,
} from 'styles/commonStyleVariables';

const LETTER_WIDTH = 35;
const LETTER_BODY_GAP = 12;

const Wrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

const Content = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: bold;
  font-size: 16px;
  line-height: 1;

  & .arrowIc {
    color: #ffef01;
  }

  & .actionsButton {
    color: #6e7987;
  }

  & .exerciseDescription {
    min-width: 0;
    padding: 4px 8px;
    margin-left: 2px;

    border-radius: 16px;
    background: #191b1f;
    color: #fff;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    & > .exerciseDescriptionValue {
      ${ellipsisTextStyles}
    }
  }

  & .filter {
    margin-left: auto;
    color: #5f6975;

    ${transitionOnIconsHover}
    &:hover {
      color: #ffef01;
    }
  }
`;

const Body = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: calc(100% - (${LETTER_WIDTH}px + ${LETTER_BODY_GAP}px));
  white-space: nowrap;
`;

const ActionsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5f6975;
  justify-content: space-between;
  margin-bottom: 2px;
  overflow: hidden;
  width: ${LETTER_WIDTH}px;

  & .coloredLabelWrapper {
    margin-bottom: 10px;
  }

  & .actionIconsContainer {
    padding-top: 5px;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  & .marker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background-color: #ffef01;
    border-radius: 50%;
    position: relative;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      background: transparent;
      border: 1px solid #222429;
      border-radius: 50%;
    }

    &.notLast {
      margin-bottom: auto;

      &::before {
        content: '';
        width: 2px;
        height: 1000px;
        background-color: #ffef01;
        position: absolute;
        top: 100%;
      }
    }
  }
`;

const WeeksWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
`;

export default {
  Wrapper,
  Content,
  Body,
  ActionsWrapper,
  WeeksWrapper,
};
