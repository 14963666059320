import React, { CSSProperties, FC } from 'react';
import gaEvents from 'utils/gaEvents';
import { Tooltip } from 'components/tooltip/Tooltip';
import { CHAT } from 'constants/tooltips';

import type { TGeneralExerciseReference } from 'components/_new/phase/models';

import { CloseIc } from 'assets/svg';
import S from './ExerciseReference.styled';

interface IExerciseReferenceProps {
  exerciseReference: TGeneralExerciseReference;
  readOnly?: boolean;
  isInShortForm?: boolean;
  isGeneratedReferenceActive?: boolean;
  setIsGeneratedReferenceActive?: (active: boolean) => void;
  styles?: CSSProperties;
}

export const ExerciseReference: FC<IExerciseReferenceProps> = ({
  exerciseReference: {
    phaseName = '',
    dayName = '',
    exerciseName = '',
    complexExerciseName = '',
    exerciseInfoName = '',
  },
  readOnly = false,
  isInShortForm,
  isGeneratedReferenceActive = true,
  setIsGeneratedReferenceActive,
  styles,
}) => {
  const generateExerciseReference = () =>
    `${phaseName.replace('Phase ', 'Ph')} ${dayName.replace('Day ', 'D')} ${
      // "|| complexExerciseName" - is added to support old messages which have
      // attached previous built form for reference, where last point was - complexExerciseName
      exerciseName || complexExerciseName
    }`;

  const activateGeneratedReference = () => {
    gaEvents.chatActivateExerciseReference();
    setIsGeneratedReferenceActive?.(true);
  };

  const deactivateGeneratedReference = () => {
    gaEvents.chatDeactivateExerciseReference();
    setIsGeneratedReferenceActive?.(false);
  };

  return (
    <S.Wrapper isInShortForm={isInShortForm} isReferenceActive={isGeneratedReferenceActive} style={styles}>
      <p className="path">{generateExerciseReference()}</p>
      {exerciseInfoName && (
        <Tooltip title={exerciseInfoName}>
          <p className="exerciseReferenceName">{exerciseInfoName}</p>
        </Tooltip>
      )}
      {!readOnly && isGeneratedReferenceActive ? (
        <button onClick={deactivateGeneratedReference} className="deleteButton">
          <Tooltip title={CHAT.REMOVE_REFERENCE}>
            <CloseIc />
          </Tooltip>
        </button>
      ) : !isGeneratedReferenceActive ? (
        <button onClick={activateGeneratedReference} className="plusButton">
          <Tooltip title={CHAT.ADD_REFERENCE}>
            <CloseIc />
          </Tooltip>
        </button>
      ) : null}
    </S.Wrapper>
  );
};
