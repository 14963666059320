import { TExercise, TPhaseDay } from '../components/_new/phase/models';

export const getAllExerciseIDsFromProgramDay = (day: TPhaseDay) => {
  return day.complexExercises
    .map((complex) => {
      return complex.exercises.map((exercise) => exercise.exerciseInfo.id);
    })
    ?.flat(1);
};

export const getExcludedExerciseIDsOnEdit = ({ exercise, day }: { day: TPhaseDay, exercise: TExercise }) =>
  // filter to exclude from excluded ID's current editing exercise
  getAllExerciseIDsFromProgramDay(day)?.filter(
    (id) => id !== exercise.exerciseInfo.id,
  );

export const getExcludedComplexExerciseIDsOnEdit = ({ exercises, day }: { day: TPhaseDay, exercises: TExercise[] }) => {
  const currentComplexExerciseIDs = exercises.map(
    (exercise) => exercise.exerciseInfo.id,
  );
  // filter to exclude from excluded ID's current editing exercises
  return getAllExerciseIDsFromProgramDay(day).filter(
    (id) => !currentComplexExerciseIDs.includes(id),
  );
}