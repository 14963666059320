import styled from '@emotion/styled/macro';
import { CHAT_HEADER_HEIGHT } from '../../../constants';
import { flexCenteredStyles } from 'styles/commonStyleVariables';
import SClient from '../../../clientsList/client/Client.styled';
import userPhoto from 'assets/userPhoto.png';

const Header = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: ${CHAT_HEADER_HEIGHT}px;

  background-color: #1c1f21;
  border-bottom: 0.5px solid #3e434b;
  padding: 12px;
  gap: 8px;
  z-index: 2;

  & .goBackButton {
    padding: 6px;
    transform: rotate(-90deg);
  }

  & .userData {
    ${flexCenteredStyles};
    color: #fff;

    &:hover .userName {
      color: #ffef01;
    }
  }

  & .userName {
    line-height: 20px;
    transition: all 0.2s ease-out;
  }

  & .resize {
    ${flexCenteredStyles};

    margin-left: auto;
    color: #5f6975;
    transition: all 0.2s ease-out;

    &:hover {
      color: #ffef01;
    }
  }
`;

const UserPhoto = styled(SClient.UserPhoto)<{
  photoUrl?: string;
}>`
  background-image: ${({ photoUrl }) =>
  photoUrl ? `url(${photoUrl})` : `url(${userPhoto})`};
`;

export default { Header, UserPhoto }