import React, { createContext, ReactNode, useState } from 'react';
import { noop } from 'utils';

import type { TExercise } from 'components/_new/phase/models';
import { TCallbackOnSuccess, useFetch } from './hooks/useFetch';

type TFilteredExercise = TExercise & {
  letter: string;
  complexExerciseIndex: number;
  programId?: number;
}

const initialState: {
  filteredExercise: TFilteredExercise | null;
  setFilteredExercise: (exercise: TFilteredExercise | null) => void;
  getMessagesByExerciseHandler: (callbackOnSuccess: TCallbackOnSuccess) => void;
  isLoadingGetMessagesByExercise: boolean;
} = {
  filteredExercise: null,
  setFilteredExercise: noop,
  getMessagesByExerciseHandler: noop,
  isLoadingGetMessagesByExercise: false,
};

export const FilteredExerciseContext = createContext(initialState);

export const FilteredExerciseProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [filteredExercise, setFilteredExercise] = useState<TFilteredExercise | null>(null);
  
  const { getMessagesByExerciseMutation, isLoadingGetMessagesByExercise } = useFetch();

  const getMessagesByExerciseHandler = ({ callbackOnSuccess }: TCallbackOnSuccess) => {
    if (filteredExercise?.programId) {
      getMessagesByExerciseMutation({
        programId: filteredExercise.programId,
        exerciseInfoId: filteredExercise.exerciseInfo.id,
        callbackOnSuccess,
      });
    }
  };

  return (
    <FilteredExerciseContext.Provider
      value={{
        filteredExercise,
        setFilteredExercise,
        getMessagesByExerciseHandler,
        isLoadingGetMessagesByExercise,
      }}
    >
      {children}
    </FilteredExerciseContext.Provider>
  );
};
