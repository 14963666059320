import React, { ReactNode } from 'react';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { styled } from '@mui/material';
import { CloseIc } from 'assets/svg';

type Props = {
  children: ReactNode;
};

function SnackbarCloseButton({ id }: { id: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <CloseIc
      src="/assets/svg/close.svg"
      onClick={() => closeSnackbar(id)}
      className="snackbar-close"
      style={{ cursor: 'pointer' }}
    />
  );
}

const StyledSnackbarProvider = styled(({ children, ...props }: Props) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    action={(key) => <SnackbarCloseButton id={key} />}
    {...props}
  >
    {children}
  </SnackbarProvider>
))`
  &.SnackbarItem-variantSuccess {
    background-color: rgb(228, 255, 225);
    color: black;
    font-size: 16px;

    & .MuiSvgIcon-root {
      color: #1eb11b;
    }
  }

  &.SnackbarItem-variantError {
    background-color: rgb(255, 225, 225);
    color: black;
    font-size: 16px;

    & .MuiSvgIcon-root {
      color: #882222;
    }
  }
`;

export default StyledSnackbarProvider;
