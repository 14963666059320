import React, { FC } from 'react';
import { renderAttachmentByFileType } from '../../utils';
import { ExerciseReference } from 'pages/ChatPage/components/exerciseReference/ExerciseReference';

import type { TMessage } from '../../../../models';
import type { TGeneralExerciseReference } from 'components/_new/phase/models';

import { CloseIc, ReplyIc } from 'assets/svg';
import S from './ReplyPreview.styled';

interface IReplyPreview {
  message: TMessage;
  onSetReplyMessage: (arg: null) => void;
  setGeneratedExerciseReference: (
    reference: TGeneralExerciseReference | null,
  ) => void;
}

export const ReplyPreview: FC<IReplyPreview> = ({
  message: {
    attachmentUrl,
    attachmentThumbnailUrl,
    text,
    fileType: messageFileType,
    exerciseReference,
  },
  onSetReplyMessage,
  setGeneratedExerciseReference,
}) => {
  const onCloseReplyPreview = () => {
    // clear bottom ExRef after canceling reply section with own ref
    if (exerciseReference) {
      setGeneratedExerciseReference(null);
    }

    onSetReplyMessage(null);
  };

  return (
    <S.Wrapper>
      <div>
        <ReplyIc />
      </div>
      {attachmentUrl &&
        renderAttachmentByFileType({
          attachmentUrl,
          attachmentThumbnailUrl,
          fileType: messageFileType,
          isForPreview: true,
        })}
      <div className="textWrapper">
        <p className="label">Reply to message</p>
        <p className="text">{text}</p>
      </div>
      {exerciseReference && (
        <div className="exerciseReferenceWrapper">
          <ExerciseReference
            exerciseReference={exerciseReference}
            isInShortForm
            readOnly
          />
        </div>
      )}
      <button className="close" onClick={onCloseReplyPreview}>
        <CloseIc />
      </button>
    </S.Wrapper>
  );
};
