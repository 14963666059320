import styled from '@emotion/styled';

const ModalWrapper = styled.div`
  padding: 40px;
  
  & .editIcon {
    color: #000;
  }
`;

export default { ModalWrapper }