import React from 'react';
import { Redirect, withRouter } from 'react-router';
import TextInput from '../../../components/fields/textInput';
import { putData } from '../../../lib/api/utils';
import Loader from '../../../components/loader/Loader';
import { ReactSVG } from 'react-svg';
import '../styles.scss';

const FirstSignIn = () => {
    const [passwords, setPasswords] = React.useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [error, setError] = React.useState('');
    const [redirectToMain, setRedirectToMain] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    if (redirectToMain) {
        return <Redirect to='/' />;
    }

    const changePasswordHandler = (e) => {
        e.preventDefault();

        setError('');

        putData('Account/change-password', {
            oldPassword: passwords.oldPassword,
            newPassword: passwords.newPassword,
        }).then((answer) => {
            if (answer?.success) {
                setRedirectToMain(true);
            } else {
                setError(answer);
            }
        });
    };

    const inputsHandler = (e) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value,
        });
    };

    const checkMatchingNewPassword = () => {
        if (passwords.newPassword && passwords.confirmPassword) {
            if (passwords.newPassword !== passwords.confirmPassword) {
                setError('New password is not verified');
            } else {
                setError('');
            }
        }
    };

    const skipChangingPasswordHandler = () => {
        setRedirectToMain(true);
        putData('Account/isTemporaryPasswordChanged');
    };

    return (
        <div className='sign-in skip'>
            <div className='forgot-logo'>
                <ReactSVG src='/assets/svg/logo-new.svg' />
            </div>
            <form className='sign-in-form skip-form' onSubmit={changePasswordHandler}>
                <div className='sign-in-form-fields'>
                    {loading ? <Loader /> : ''}
                    <p className='forgot-title'>Create password</p>
                    <p className='forgot-adopt'>
                        You can create your own password or skip this step
                    </p>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            placeholder='Old password'
                            type='password'
                            name='oldPassword'
                            value={passwords.oldPassword}
                            onChange={inputsHandler}
                        />
                    </div>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            placeholder='Create password'
                            type='password'
                            name='newPassword'
                            value={passwords.newPassword}
                            onChange={inputsHandler}
                            onBlur={checkMatchingNewPassword}
                        />
                    </div>
                    <div className='sign-in-form-fields-field'>
                        <TextInput
                            placeholder='Confirm password'
                            type='password'
                            name='confirmPassword'
                            value={passwords.confirmPassword}
                            onChange={inputsHandler}
                            onBlur={checkMatchingNewPassword}
                        />
                    </div>
                    {error ? <p className='error'>{error}</p> : ''}
                    <button
                        type='submit'
                        disabled={
                            !(
                                passwords.newPassword &&
                passwords.newPassword === passwords.confirmPassword
                            )
                        }
                        className='sign-in-form-button forgot-button'
                    >
                        Save
                    </button>
                    <div className='skip-buttons'>
                        <button
                            className={'skip-buttons-button'}
                            onClick={skipChangingPasswordHandler}
                        >
                            Skip
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default withRouter(FirstSignIn);
