import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  padding: 16px;
  background: #23262B;
  width: 240px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 8px 20px 0px #10111399;


  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #6E7987;

    & .clearButton {
      font-weight: 400;
      color: #FFEF01;
    }
  }
`;

export default { Wrapper }