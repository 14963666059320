import React, { CSSProperties, memo } from 'react';

import { CloseIc } from 'assets/svg';

import S from './Modal.styled';

interface IModalProps {
  show: boolean;
  onClose?: (
    event: React.MouseEvent<HTMLDivElement>,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  onCloseOnOutsideClick?: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  children: JSX.Element | JSX.Element[];
  style?: CSSProperties;
}

export const Modal: React.FC<IModalProps> = memo(
  ({ show, onClose, onCloseOnOutsideClick = onClose, children, style = {} }): JSX.Element => (
    <S.Modal
      open={show}
      onClose={onCloseOnOutsideClick}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modalWrapper" style={style}>
        <div className="modalBody">
          {onClose && (
            <CloseIc className="modalCloseIcon" onClick={onClose} />
          )}
          {children}
        </div>
      </div>
    </S.Modal>
  ),
);
