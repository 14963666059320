import React, { FC } from 'react';
import { Tooltip } from 'components/tooltip/Tooltip';
import { PROGRAM } from 'constants/tooltips';

import type { TEventOnClick } from '../../../../../../models';

import { CopyIc, DeleteIc, SaveIc } from 'assets/svg';
import S from './TooltipActions.styled';

interface ITooltipActionsProps {
  openDeletingPhaseModalHandler: (e: TEventOnClick) => void;
  duplicatePhaseHandler: (e: TEventOnClick) => void;
  setIsSaveTemplateModalShown: (shouldBeOpen: boolean) => void;
}

export const TooltipActions: FC<ITooltipActionsProps> = ({
  duplicatePhaseHandler,
  openDeletingPhaseModalHandler,
  setIsSaveTemplateModalShown,
}) => {
  return (
    <S.Wrapper>
      <Tooltip title={PROGRAM.DELETE_PHASE}>
        <button className="actionButton">
          <DeleteIc onClick={openDeletingPhaseModalHandler} />
        </button>
      </Tooltip>
      <Tooltip title={PROGRAM.DUPLICATE_PHASE}>
        <button className="actionButton">
          <CopyIc
            onClick={duplicatePhaseHandler}
          />
        </button>
      </Tooltip>
      <Tooltip title={PROGRAM.SAVE_AS_TEMPLATE}>
        <button className="saveAsButton" onClick={() => setIsSaveTemplateModalShown(true)}>
          <SaveIc />
        </button>
      </Tooltip>
    </S.Wrapper>
  );
};
