import { styled } from '@mui/material';

const AddDayButton = styled('button')`
  color: #ffef01;
  font-weight: 400;
  font-size: 14px;
  margin: 16px;
  transition: ease 0.3s all;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export default {
  AddDayButton,
};
