import styled from '@emotion/styled/macro';
import { screenHD, screenSVGA } from 'constants/screenSizes';
import { css } from '@emotion/react';

const Wrapper = styled('div')`
  flex: 2 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${screenHD}px) {
    flex-basis: 48%;
  }

  @media screen and (max-width: ${screenSVGA}px) {
    flex: 2 1 100%;
  }
`;

const DataWrapper = styled('div')<{
  isHighlighted?: boolean;
}>`
  flex: 2 1;
  display: flex;
  border-radius: 8px;
  border: 1px solid #6e7987;
  transition: border-color 0.5s ease-in-out;
  gap: 8px;
  padding: 8px;

  ${({ isHighlighted }) => isHighlighted && css`
    border-color: #FFEF01;
    background-color: #5F6975;
    
    && * {
      color: #fff;
    }
  `};
`;

const WeekName = styled('div')<{
  isCompleted?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  background: ${({ isCompleted }) => (isCompleted ? '#33A56E' : '#6E7987')};
  padding: 2px 0;
  margin-bottom: 16px;
`;

export default { Wrapper, WeekName, DataWrapper };
