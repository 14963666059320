import React, { memo, PropsWithChildren, ReactNode } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AccordionArrowIc } from 'assets/svg';
import { Tooltip } from '../tooltip/Tooltip';

import './style.scss';

type Props = PropsWithChildren<{
  title: string | ReactNode;
  children: ReactNode;
  expanded?: boolean | undefined;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  id?: string;
}>;

export const Accordion = memo(
  ({ children, title, expanded, onChange, id = '' }: Props) => (
    <MuiAccordion
      disableGutters={true}
      className="accordion-wrapper"
      expanded={expanded}
      onChange={onChange}
    >
      <MuiAccordionSummary
        className="accordion-summary"
        expandIcon={
          <Tooltip title={expanded ? `Collapse ${id}` : `Expand ${id}`}>
            <AccordionArrowIc className="accordion-summary-icon" />
          </Tooltip>
        }
      >
        {title}
      </MuiAccordionSummary>
      <MuiAccordionDetails>{children}</MuiAccordionDetails>
    </MuiAccordion>
  ),
);

Accordion.displayName = 'Accordion';
