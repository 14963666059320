import styled from '@emotion/styled/macro';
import { transitionOnIconsHover } from 'styles/commonStyleVariables';

const Wrapper = styled.button`
  display: flex;
  align-self: center;
  margin-left: auto;
  
  & .chatButton {
    &:disabled .chatIcon {
      cursor: default;
      color: #b6b6b6;
      
      &:hover {
        color: #b6b6b6;
      }
    }

    & .chatIcon {
      height: 13px;
      ${transitionOnIconsHover};

      &:hover {
        color: #FFEF01;
      }
    }
  }
`;

export default { Wrapper };
