import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from 'constants/index';
import instance from 'lib/api/axios';

import type {
  TDeleteMessagePayload,
  TDeleteMessageResponse, TReactOnMessagePayload,
  TReactOnMessageResponse,
  TUseFetch,
} from '../models';

export const useFetch = ({
  onSuccessDeleteMessage,
  onSuccessAddReaction,
  setIsDeleteTimerActivated,
}: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteMessageMutation } = useMutation<
    TDeleteMessageResponse,
    Error,
    TDeleteMessagePayload
  >({
    mutationFn: async ({ messageId }) => {
      await instance.delete(`GeneralMessage/${messageId}`);
      return { messageId };
    },
    onSuccess: ({ messageId }) => {
      onSuccessDeleteMessage?.(messageId);
    },
    onError: (e) => {
      setIsDeleteTimerActivated?.(false);
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const { mutate: reactOnMessageMutation, isLoading: isReactOnMessageLoading } = useMutation<
    TReactOnMessagePayload,
    Error,
    TReactOnMessageResponse
  >({
    mutationFn: ({ messageId, reaction }) => {
      return instance.put(`GeneralMessage/react/${messageId}`, {
        data: {
          reaction,
        },
      });
    },
    onSuccess: (_, payload) => {
      onSuccessAddReaction(payload);
    },
    onError: (e, { messageId }) => {
      onSuccessAddReaction({ messageId, reaction: '' });
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return {
    deleteMessageMutation,

    reactOnMessageMutation,
    isReactOnMessageLoading,
  };
};
