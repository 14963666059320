import styled from '@emotion/styled/macro';

const Wrapper = styled('div')`
  flex: 2 1;

  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  height: fit-content;
  max-height: 300px;
  overflow-y: auto;
  font-size: 16px;
  padding: 2px;

  & > .weekSetsHeader {
    color: #6e7987;
  }

  & > .weekSetsValue {
    white-space: nowrap;
  }
`;

export default { Wrapper };
