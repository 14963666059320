import styled from '@emotion/styled';

const Wrapper = styled.div`
  padding: 12px;
  flex: 2;
  
  & .icon {
    left: 8px;
  }
`;

export default { Wrapper };
