import React, { FC } from 'react';
import { Constructor } from './components/constructor/Constructor';
import { Preview } from './components/preview/Preview';

import type { IHomePageStep } from '../../models';

import S from './HomePageStep.styled';

export const HomePageStep: FC<IHomePageStep> = ({
  brandingData,
  setBrandingData,
  onChangeBrandingFields,
}) => {
  return (
    <S.Wrapper>
      <Constructor
        brandingData={brandingData}
        setBrandingData={setBrandingData}
        onChangeBrandingFields={onChangeBrandingFields}
      />
      <Preview brandingData={brandingData} setBrandingData={setBrandingData} />
    </S.Wrapper>
  );
};
