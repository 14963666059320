import styled from '@emotion/styled';
import { HEADER_HEIGHT } from 'constants/index';
import { CHAT_HEADER_HEIGHT } from '../constants';
import { flexCenteredStyles } from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  color: #fff;
  background: #1c1f21;

  height: calc(100vh - ${HEADER_HEIGHT}px);

  & .header {
    ${flexCenteredStyles};

    background-color: #1c1f21;
    min-height: ${CHAT_HEADER_HEIGHT}px;
    height: ${CHAT_HEADER_HEIGHT}px;
    border-bottom: 0.5px solid #3e434b;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 100%;
  overflow: auto;
`;

export default { Wrapper, List };
